<template>
  <div>
    <div class="ui form">
      <div>
        <h1 style="display: inline; margin-right: 25px">Easily Share on Social Media!</h1>
      </div>
      <p>
        Shrpa provides a simple way to use the content from your adventures for a great social media post!
        <br />Clicking on the Share button will navigate to the stop details that can be shared.
      </p>

      <Loader v-if="isLoading"></Loader>
      <div v-else>
        <div>
          <div style="margin-bottom: 1rem; display: flex; align-items: center">
            <input v-model="filterText" type="text" placeholder="Search location or adventure name" style="max-width: 300px" @input="filterChanged" @keyup.enter="applyFilter()" />
            <div v-if="filterResultCount > 0" style="font-weight: bold; margin-left: 10px">{{ filterResultCount }} results</div>
          </div>
        </div>
        <div v-if="dataToShow.length > 0" class="ui very relaxed stackable one column grid">
          <div v-for="row in dataToShow" :key="row.adventure.id" class="ui container sixteen wide column">
            <div class="ui segment">
              <div style="margin-bottom: 15px">
                <router-link target="_blank" :to="{ name: 'ItineraryPreview', params: { itineraryId: row.adventure.uniqueName } }" class="ui header">
                  {{ row.adventure.title }}
                </router-link>
              </div>
              <div class="two column stackable ui grid stop-container" v-for="(stop, i) in row.stops" :key="`${row.adventure.id}-stop-${i}`">
                <div class="five wide column stop-row">
                  <div class="stop-header-wrapper">
                    <router-link target="_blank" :to="{ name: 'ItineraryStepsV2', params: { itineraryId: row.adventure.uniqueName, fromShrpa: true }, hash: `#sharestop=${stop.stopIndex + 1}` }">
                      <div class="stop-header">
                        <i class="map small marker alternate icon grey"></i>
                        {{ stop.title }}
                      </div>
                      <button class="ui small button basic">Share</button>
                    </router-link>
                  </div>
                </div>
                <div class="eleven wide column stop-row">
                  <div class="stop-media">
                    <template v-for="image in stop.exImages">
                      <template v-if="isVideo(image)">
                        <VideoRender :videoId="image" :showControls="false" :showViewerOnClick="false" :cropThumbnail="true" :key="image" :customClasses="'ui tiny rounded image'" />
                      </template>
                      <template v-else>
                        <img class="ui tiny rounded image" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${image}`" :key="image" />
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--No results-->
        <div v-else style="margin-top: 1rem">
          <div v-if="allAdventures.length === 0">
            <h4>There aren't any adventures in your area yet.</h4>
          </div>
          <div v-else>
            <h4>No stops match that criteria.</h4>
          </div>
        </div>
      </div>

      <br />
      <label class="cms-section-title">Questions?</label>
      <p>Email us anytime at <CopyText /></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import OrgContext from "@logic/OrgContext";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import CopyText from "@components/CopyText.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import VideoRender from "@components/VideoRender.vue";
import FileUtils from "@logic/FileUtils";
import { debounce } from "debounce";
import { inject } from "vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "ShareStopSummary",

  components: {
    Loader,
    CopyText,
    ItineraryTile,
    VideoRender,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Share on Social",
      pageId: "",
      orgInContext: null as PartnerNavInfo,
      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,

      filterText: "",
      filterInProgress: false,
      allAdventures: null,
      filteredAdventures: null,
      debounceCallback: null,
      filterResultCount: -1,
    };
  },

  computed: {
    dataToShow(): Array<object> {
      return this.filteredAdventures ?? this.allAdventures;
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    this.loadData();
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      const groupingsResponse = await axios.get(`${import.meta.env.VITE_API_URL}/social/${this.pageId}/adventure-summary?exampleImagesPerStop=8`);
      this.allAdventures = groupingsResponse.data.adventures;
      this.isLoading = false;
    },
    filterChanged() {
      // Note: Using Debounce here so it only actually fires periodically
      // this.globalLog.info("Filter changed " + this.filterText);
      if (this.debounceCallback) {
        this.debounceCallback();
      } else {
        this.debounceCallback = debounce(this.applyFilter, 500);
        this.debounceCallback();
      }
    },
    applyFilter() {
      this.globalLog.info("ApplyFilter " + this.filterText);
      if (!this.filterText || this.filterText.trim().length == 0) {
        this.filteredAdventures = null;
        this.filterResultCount = -1;
        return;
      }

      var startTime = performance.now();
      this.filterInProgress = true;
      let filterMatches = [];
      let matchCount = 0;
      this.allAdventures.forEach(adv => {
        // Also allow adventure name
        if (this.includesCaseInsensitive(adv.adventure.title, this.filterText)) {
          filterMatches.push(adv); // Include the adventure and all the stops
          matchCount++;
        } else {
          // Stop location name
          let stopMatches = [];
          adv.stops.forEach(stop => {
            if (this.includesCaseInsensitive(stop.title, this.filterText)) {
              stopMatches.push(stop);
              matchCount++;
            }
          });
          if (stopMatches.length > 0) {
            // NOTE: Must match the format the server returns
            filterMatches.push({ adventure: adv.adventure, stops: stopMatches });
          }
        }
      });
      var endTime = performance.now();
      this.filterResultCount = matchCount;
      this.filteredAdventures = filterMatches;
      this.filterInProgress = false;
      this.globalLog.info(`Filtering took ${endTime - startTime}ms`);
    },
    includesCaseInsensitive(text: string, searchTerm: string) {
      return text?.toLowerCase().includes(searchTerm.toLowerCase()) === true;
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.adventure-row {
  margin-bottom: 1rem;
  border-bottom: 2px solid $faint-gray;
}
.stop-container {
  display: flex;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.stop-header-wrapper {
  margin-right: 2rem;
  margin-top: 0.5rem;
  line-height: 1;
}
.stop-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.stop-header {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  font-weight: 600;
  font-size: 18pt;
  color: $brand-color;
}
.stop-media {
  display: flex;
  justify-content: flex-start;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  span.video-wrap {
    margin-right: 0.5rem;
  }
  img {
    margin-right: 0.5rem;
  }
}
.stop-media::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.ui.flex.image {
  display: flex;
}
</style>
