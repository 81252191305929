import axios from "axios";
import { AdventureList, AdventureListMembership, CustomerListUpdate } from "@contracts/lists";
import { PageAdventureList } from "@contracts/pages";

const listTypeHomepage = "Homepage";

// Manages Adventure Lists
export default {
  // ---Methods for any list use case---

  // Loads all of a customers lists from the server
  async loadListsFromServer(communityId: string): Promise<Array<AdventureListMembership>> {
    // If not already loaded, hit the server
    if (!adventureListsByCommunity[communityId]) {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/lists/${communityId}`);
      adventureListsByCommunity[communityId] = data;
    }
    return adventureListsByCommunity[communityId];
  },

  async getListsAdventureIsMemberOf(communityId: string, adventureId: string): Promise<Array<AdventureList>> {
    var lists = await this.loadListsFromServer(communityId);
    // Return those that contain the passed adventure
    return lists.filter(l => l.memberIds?.indexOf(adventureId) >= 0);
  },
  // Gets all possible lists. Optional param to filter those an adventure is already a part of
  async getAllLists(communityId: string, adventureIdToFilter: string = null): Promise<Array<AdventureList>> {
    var lists = await this.loadListsFromServer(communityId);
    // Optional filter (ex. for dropdowns)
    if (adventureIdToFilter) {
      // Remove lists the adventure is already a part of
      lists = lists.filter(l => l.memberIds?.indexOf(adventureIdToFilter) === -1);
    }
    return lists;
  },

  async addToList(communityId: string, listId: string, adventureId: string) {
    const { data } = await axios.put(`${import.meta.env.VITE_API_URL}/lists/${communityId}/list/${listId}/adventure/${adventureId}`);
    adventureListsByCommunity[communityId] = data;
  },

  async removeFromList(communityId: string, listId: string, adventureId: string) {
    const { data } = await axios.delete(`${import.meta.env.VITE_API_URL}/lists/${communityId}/list/${listId}/adventure/${adventureId}`);
    adventureListsByCommunity[communityId] = data;
  },

  // Updates an individual list (does NOT impact the list pointers, ex. homepage lists)
  async updateList(communityId: string, listToUpdate: PageAdventureList) {
    var allLists = adventureListsByCommunity[communityId] as Array<AdventureListMembership>;
    // Build the update payload
    const adventureIds = listToUpdate.adventures.map(i => i.id);
    const adventureIdsString = adventureIds.join(",");
    var payload = {
      id: listToUpdate.id,
      name: listToUpdate.name,
      includeAllAdventures: listToUpdate.includeAllAdventures,
      maxCount: listToUpdate.maxCount,
      randomize: listToUpdate.randomize,
      adventureIds: adventureIdsString,
    } as CustomerListUpdate;

    // update the local list
    var index = allLists?.findIndex(l => l.id === listToUpdate.id);
    if (index >= 0) {
      allLists[index].name = listToUpdate.name;
      allLists[index].memberIds = adventureIds;
    }

    const config = { headers: { "Content-Type": "application/json" } };
    await axios.put(`${import.meta.env.VITE_API_URL}/lists/${communityId}/lists/${listToUpdate.id}`, JSON.stringify(payload), config);
  },

  // Loads an individual list from the server
  async loadAdventureSummaryForList(communityId: string, listId: string): Promise<PageAdventureList> {
    const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/hotels/${communityId}/lists/${listId}`);
    // Add to the cache
    this.syncListToCache(communityId, data);
    return data;
  },

  syncListToCache(communityId: string, singleList: PageAdventureList) {
    var customerLists = adventureListsByCommunity[communityId];
    if (!customerLists) {
      // Add the first list
      adventureListsByCommunity[communityId] = [singleList];
      return;
    }
    var index = customerLists.findIndex(l => l.id === singleList.id);
    if (index < 0) customerLists.push(singleList);
    else customerLists[index] = singleList;
  },

  // ---Homepage specific---

  async getAllHomepageLists(communityId: string): Promise<Array<AdventureList>> {
    var allLists = await this.getAllLists(communityId, null);
    var homepageLists = allLists.filter(l => l.type == listTypeHomepage);
    return homepageLists;
  },

  async createHomepageList(communityId: string, name: string, adventureId: string) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(`${import.meta.env.VITE_API_URL}/lists/${communityId}?adventureId=${adventureId}`, JSON.stringify(name), config);
    adventureListsByCommunity[communityId] = data;
  },

  // Updates the items/order of a set of homepage lists
  async updateHomepageLists(communityId: string, inputs: Array<PageAdventureList>, listIdsToPerminentlyDelete: Array<string>) {
    var payload = [] as Array<CustomerListUpdate>;
    var allLists = adventureListsByCommunity[communityId] as Array<AdventureListMembership>;
    for (let i = 0; i < inputs.length; i++) {
      // Build the update payload
      const input = inputs[i];
      const adventureIds = input.adventures.map(i => i.id);
      const adventureIdsString = adventureIds.join(",");
      var payloadItem = {
        id: input.id,
        name: input.name,
        adventureIds: adventureIdsString,
      } as CustomerListUpdate;
      payload.push(payloadItem);

      // update the local list
      var index = allLists.findIndex(l => l.id === input.id);
      allLists[index].name = input.name;
      allLists[index].memberIds = adventureIds;
    }
    let query = "";
    if (listIdsToPerminentlyDelete?.length > 0) {
      query = `?listIdsToPerminentlyDelete=${listIdsToPerminentlyDelete.join(",")}`;
    }
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.put(`${import.meta.env.VITE_API_URL}/lists/${communityId}/lists${query}`, JSON.stringify(payload), config);
  },

  // ---Integration specific---

  async createSiteIntegrationList(communityId: string, name: string): Promise<string> {
    // Server generates the Id
    const config = { headers: { "Content-Type": "application/json" } };
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/widget-admin/${communityId}/widgets`, JSON.stringify(name), config);
    // Add to the cache
    this.syncListToCache(communityId, response.data);

    return response.data.id;
  },

  async deleteSiteIntegrationList(communityId: string, listId: string) {
    await axios.delete(`${import.meta.env.VITE_API_URL}/widget-admin/${communityId}/widgets/${listId}`);
    // Remove from cache
    var allLists = adventureListsByCommunity[communityId] as Array<AdventureListMembership>;
    var index = allLists?.findIndex(l => l.id === listId);
    if (index >= 0) allLists.splice(index, 1);
  },
};

// ---DATA CACHE---
// NOTE!: This sticks around in memory so shouldn't re-load as the user navigates around the site.
const adventureListsByCommunity = {};
