<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>

    <div v-if="pageDetails">
      <!--Allow Community Members to upload their photos!<br/>
    <router-link class="ui small primary button" :to="{name: 'UserUploadCampaigns', params: { pageId: pageDetails.page.id }}" style="margin-bottom: 1.5em; margin-top:1em;">Community Uploads</router-link>-->

      <CustomerOnboard
        v-if="showOnboarding"
        :page="pageDetails.page"
        :invitedUsers="pageDetails.page.invites"
        :unusedInvites="pageDetails.unusedInvites"
        @update="updatePage"
        @invite="inviteCommunityCreator"
        @deleted="inviteDeleted"
        @closeOnboarding="closeOnboarding"
      />

      <div v-if="!showOnboarding">
        <div class="custom-tabs">
          <ul class="custom-tabs__tabs-panel">
            <li
              :class="{
                'custom-tabs__tab': true,
                'custom-tabs__tab--active': activeTabIndex === 0,
              }"
              @click="activeTabIndex = 0"
            >
              <h2 class="global-h2">Adventures</h2>
            </li>
            <li
              :class="{
                'custom-tabs__tab': true,
                'custom-tabs__tab--active': activeTabIndex === 1,
              }"
              @click="activeTabIndex = 1"
            >
              <h2 class="global-h2">Creator Guidance</h2>
            </li>
          </ul>

          <div v-if="activeTabIndex === 0" class="custom-tabs__tab-content">
            <div class="admin-dashboard">
              <div class="ui mobile reversed stackable two column grid">
                <div class="twelve wide column section">
                  <div class="main-section">
                    <div class="title">
                      <h3 class="ui header" style="margin: 0">Invited Users</h3>
                      <div @click="activeTabIndex = 1" class="flex invite-btn">
                        <i class="paper plane outline icon"></i>
                        Invite More Creators
                      </div>
                    </div>

                    <InvitedUsers :page="pageDetails" @resendInvite="inviteCommunityCreator" />

                    <h3 v-if="communityCreators.length > 0" class="ui header">{{ isHotel ? "" : "Community " }}Adventures</h3>
                    <CreatorSummary :creatorsToShow="communityCreators" />

                    <h3 v-if="paidCreators.length > 0" class="ui header">Paid Adventures</h3>
                    <CreatorSummary :creatorsToShow="paidCreators" :paid="true" />
                  </div>
                </div>
                <div class="four wide column section">
                  <CommunityStats :stats="pageDetails.stats" :communityId="pageDetails.page.id" />

                  <div v-if="hasCreatorsWithAdventures">
                    <br />
                    <button lang="en" hreflang="en" class="ui fluid basic blue button centered" @click="viewCommunityPage">View My {{ isHotel ? "Hotel" : "Community" }} Page</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTabIndex === 1" class="custom-tabs__tab-content">
            <CustomerOnboard
              :page="pageDetails.page"
              :invitedUsers="pageDetails.page.invites"
              :unusedInvites="pageDetails.unusedInvites"
              :showAll="true"
              @update="updatePage"
              @invite="inviteCommunityCreator"
              @deleted="inviteDeleted"
              @closeOnboarding="closeOnboarding"
            />
          </div>
        </div>
      </div>

      <!--<button v-if="!forceShowOnboarding" @click="forceShowOnboarding = true">Force Show onboarding</button>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import CreatorSummary from "./CreatorSummary.vue";
import Loader from "@components/Loader/Loader.vue";
import { Page, PageDetailsAdmin, PageInvite } from "@contracts/pages";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";
import { ItinerarySummary } from "@contracts/itinerary";
import CustomerOnboard from "./CustomerOnboard.vue";
import CommunityStats from "./CommunityStats.vue";
import InvitedUsers from "./InvitedUsers.vue";
import { RouteHelper } from "@helpers/RouteHelper";
import FeatureFlags from "@logic/FeatureFlags";
import OrgContext from "@logic/OrgContext";
import AdminContext from "@logic/AdminContext";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "CommunityEngagement",

  components: {
    CreatorSummary,
    Loader,
    CustomerOnboard,
    CommunityStats,
    InvitedUsers,
  },

  data() {
    return {
      title: "Community Engagement",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      pageDetails: null as PageDetailsAdmin,
      communityCreators: [],
      paidCreators: [],
      isLoading: true,
      forceShowOnboarding: false, // debugging
      // This variable holds the initial state, so when they invite their first user it doesn't bump them off the onboarding flow (since showOnboarding is computed).
      showOnboardingInitialState: null as boolean | null,
      // If the user clicks the "Go to the Admin" button. Note: This overrides the initialState
      hideOnboarding: false,
      activeTabIndex: 0,

      isHotel: false,
    };
  },

  computed: {
    showOnboarding(): boolean {
      // User closed it
      if (this.hideOnboarding) return false;
      // Debug wants to see it
      if (this.forceShowOnboarding) return true;

      if (this.showOnboardingInitialState == null) {
        // Show if they haven't created any data
        this.showOnboardingInitialState = this.pageDetails && this.pageDetails.communityCreators.length == 0 && this.pageDetails.users.length == 0 && this.pageDetails.unusedInvites.length == 0;
      }
      return this.showOnboardingInitialState;
    },
    hasCreatorsWithAdventures(): boolean {
      return this.pageDetails && (this.pageDetails.communityCreators.length > 0 || this.pageDetails.paidCreators.length > 0);
    },
  },

  watch: {
    // For remembering which tab they're on
    activeTabIndex: {
      handler(newVal, oldVal) {
        this.tabClick(newVal);
      },
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // Load the page details
    let id = this.$route.params.pageId;
    await this.loadPageDetails(id);
    if (RouteHelper.getQueryStringOrHashParam("onboard") !== null) this.forceShowOnboarding = true;

    this.initializeTabIndex();
    this.isHotel = OrgContext.isOrgInContextHotel(this);
  },

  methods: {
    async loadPageDetails(identifier) {
      let uri = `${import.meta.env.VITE_API_URL}/pages/${identifier}/community-engagement`;
      const response = await axios.get(uri);
      this.pageDetails = response.data;
      this.isLoading = false;
      this.communityCreators = this.groupAdventuresByCreator(response.data.communityCreators, response.data.communityAdventures);
      this.paidCreators = this.groupAdventuresByCreator(response.data.paidCreators, response.data.paidAdventures);
      this.title += ` - ${this.pageDetails.page.title}`;

      this.setOrgInContext();
    },
    tabClick(tabId: number) {
      // Note: Making this 1-based
      var tab = tabId + 1;
      window.location.hash = "tab=" + tab;
    },
    initializeTabIndex() {
      var tab = RouteHelper.getQueryStringOrHashParam("tab");
      if (tab !== null) {
        // Note: tab on the hash is 1-based
        this.activeTabIndex = +tab - 1;
        // Don't show the onboarding
        this.hideOnboarding = true;
      }
    },
    groupAdventuresByCreator(creators: Array<CreatorPublicProfileFields>, allAdventures: Array<ItinerarySummary>) {
      var grouped = creators.map(creator => {
        let adventures = allAdventures.filter(ad => ad.sherpaId === creator.sherpaId);
        return { creator, adventures };
      });
      return grouped;
    },
    async updatePage(page: Page) {
      this.pageDetails.page = page;
      let uri = `${import.meta.env.VITE_API_URL}/pages/${page.id}`;
      const response = await axios.put(uri, page);
      // Note: If we put this back we should lengthen out the markIsDirty wait a bunch more on the CommunityPageAdmin
      // this.pageDetails.page = response.data;
    },
    async inviteCommunityCreator(email: string, wasFirstInvite: boolean, individualGuidance: string = null) {
      // NOTE: Sending logic is in the child component now, this just updates the parent collections

      if (wasFirstInvite) {
        var invite = { email: email, message: individualGuidance, invitedOn: new Date().toISOString(), acceptedOn: null };
        this.pageDetails.page.invites.push(invite);
        // Note: This isn't accurate if the user exists and is auto-added (a page refresh should show correct data though)
        this.pageDetails.unusedInvites.push(invite);
      } else {
        var inviteData = this.pageDetails.page.invites.filter(i => i.email === email);
        if (inviteData && inviteData.length > 0) inviteData[0].invitedOn = new Date().toISOString();
        inviteData[0].message = individualGuidance;
      }
    },
    async inviteDeleted(emailToDelete: string) {
      // The child actually deleted the invite, we just need to keep our local store updated
      this.pageDetails.page.invites = this.pageDetails.page.invites.filter(i => i.email !== emailToDelete);
      this.pageDetails.unusedInvites = this.pageDetails.unusedInvites.filter(i => i.email !== emailToDelete);
    },
    closeOnboarding() {
      this.hideOnboarding = true;
    },
    setOrgInContext() {
      this.globalRoot.orgInContext = AdminContext.setOrgInContextFromCustomer(this.pageDetails.page);
    },
    viewCommunityPage() {
      this.$router.push({ name: "Pages", params: { pageId: this.pageDetails.page.uniqueName } });
    },
  },
});
</script>

<style scoped lang="scss">
.community-tabs {
  .community-tab-content {
    border: none;
    padding: 0;
  }
}

.admin-dashboard {
  .title {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .invite-btn {
      color: #ec563b;
      font-weight: bold;
      cursor: pointer;
    }
  }

  // @media screen and (max-width: 420px) {
  //   flex-direction: column;

  //   .stats-section {
  //     width: 100%;
  //     margin-left: 0;
  //     margin-bottom: 20px;
  //   }
  // }
}

// Custom tabs ================================================================
.custom-tabs {
  &__tabs-panel {
    padding: 0;
    margin: 0 0 30px;
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__tab {
    padding-bottom: 4px;
    margin-right: 30px;
    border-bottom: 2px transparent solid;
    color: aqua;
    user-select: none;
    cursor: pointer;

    &--active {
      color: black;
      border-bottom-color: #1279c6;
    }
  }

  &__tab-content {
  }
}
</style>
