// Sends Community Engagement Invites

import axios from "axios";
import { RouteHelper } from "@helpers/RouteHelper";
import Logger from "@helpers/Logger";

export default {
  async inviteIndividualUser(communityId: string, emailToInvite: string, resend: boolean, individualGuidance: string, globalGuidance: string, useGlobalGuidance: boolean): Promise<boolean> {
    // Invite the user (and save guidance)
    var sendEmail = true;
    if (RouteHelper.getQueryStringOrHashParam("nosend") !== null) sendEmail = false; // for debugging

    Logger.info(`Inviting Community Creator: nosend=${!sendEmail}, email=${emailToInvite}, resend=${resend}`);
    var wasFirstInvite = true;
    if (sendEmail) {
      const individualGuidanceEncoded = individualGuidance ? encodeURIComponent(individualGuidance) : "";
      const globalGuidanceEncoded = globalGuidance ? encodeURIComponent(globalGuidance) : "";
      const emailEncoded = encodeURIComponent(emailToInvite.trim());
      const uri = `${import.meta.env.VITE_API_URL}/pages/${communityId}/invite?email=${emailEncoded}&resend=${resend}&individualGuidance=${individualGuidanceEncoded}&useGlobalGuidance=${useGlobalGuidance}&globalGuidance=${globalGuidanceEncoded}`;
      const response = await axios.put(uri);
      wasFirstInvite = response.data;
      return wasFirstInvite;
    } else {
      // no-send path
      return !resend;
    }
  },
};
