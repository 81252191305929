<template>
  <div class="collab-opportunity">
    <!-- Long Distance Collab Reminder -->
    <LongDistanceCollabWarning v-if="collab?.inRange == false" class="collab-opportunity__long-distance-reminder" />

    <!-- Header -->
    <div v-if="collab" class="header collab-opportunity__header">
      <h1 class="global-h1 header__h1">{{ collab?.collabInput?.name }} Collaboration</h1>

      <!-- Client snippet -->
      <router-link :to="{ name: 'Pages', params: { pageId: collab.communityId } }" target="_blank" class="client-snippet header__client-snippet">
        <img v-if="collab" class="client-snippet__logo" :src="`${contentBaseUri}/cms/images/orgs/${collab.communityIcon}`" alt="Logo" />
        <div class="client-snippet__name">{{ collab.communityName }}</div>
      </router-link>
      <!-- / Client snippet -->

      <div v-if="!isLoadingData" class="header__buttons-side">
        <!-- Header button container -->
        <div class="header-btn-container header__btn-container">
          <MsgCountBadge v-if="newMessagesCount > 0" class="header-btn-container__msg-count-badge" @click="showMessageThread" :count="newMessagesCount" isHoverable />

          <SrpButton v-if="collab?.collabInput?.collaboration && currentUserIsAssignedToCollab" class="header-btn-container__btn-itself" size="small" fill="outlined" @click="contactCommunity">
            <template #icon><IconEmbedded name="chat_2-5" :size="17" /></template>
            Message Destination
          </SrpButton>
        </div>
        <!-- / Header button container -->

        <!-- Header button container -->
        <div class="header-btn-container header__btn-container">
          <SrpButton class="header-btn-container__btn-itself" size="small" fill="outlined" @click="contactShrpa">
            <template #icon><IconEmbedded name="envelope_2-5" :size="17" /></template>
            Contact Shrpa
          </SrpButton>
        </div>
        <!-- / Header button container -->
      </div>
    </div>
    <!-- / Header -->

    <Loader v-if="isLoadingData"></Loader>

    <div v-else-if="collab?.collabInput?.version === 1">
      <div class="collab-opportunity__warning-wrap">
        <NoteWithIcon class="collab-opportunity__warning" color="green">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <h3 class="global-h3">Collaboration Complete!</h3>
            <span>
              Note: This collab was completed before the new collaboration experience was developed. <br />Thanks for being one of our first creators and we look forward to your feedback on the
              newexperience!
            </span>
          </template>
        </NoteWithIcon>
      </div>
    </div>

    <!-- Show a no-tab layout for the application process -->
    <div v-else-if="!currentUserIsAssignedToCollab">
      <!-- CREATOR DECLINED NOTIFICATION -->
      <div v-if="collab.creatorInput.declinedDateTime" class="collab-opportunity__warning-wrap">
        <NoteWithIcon class="collab-opportunity__warning" color="green">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <h6 class="global-h6">Thanks for letting us know you can't make it. We look forward to collaborating with you in the future!</h6>
          </template>
        </NoteWithIcon>
      </div>

      <!-- A DIFFERENT CREATOR SELECTED -->
      <div v-else-if="collabHasCreatorAssigned" class="collab-opportunity__warning-wrap">
        <NoteWithIcon class="collab-opportunity__warning" color="yellow">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <h6 class="global-h6">
              The community has selected another creator for this collaboration.<br />
              We look forward to collaborating with you on future collabs!
            </h6>
          </template>
        </NoteWithIcon>
      </div>

      <div v-else-if="collab.creatorInput.acceptedDateTime" class="collab-opportunity__warning-wrap">
        <NoteWithIcon class="collab-opportunity__warning" color="green">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <span v-if="!collabHasCreatorAssigned">
              <h4 class="global-h4">You've {{ collab.creatorInput.manuallyInvited ? "accepted" : "applied for" }} this collaboration!</h4>
              <br />
              <span>Please sit tight while the community finalizes their choice for creator.</span>
            </span>
          </template>
        </NoteWithIcon>
      </div>

      <div v-else-if="!collab.creatorInput.acceptedDateTime && !collab.creatorInput.declinedDateTime" class="collab-opportunity__warning-wrap">
        <NoteWithIcon class="collab-opportunity__warning" color="green">
          <template #icon><img src="https://cdn.shrpa.com/images/misc/high-five.png" style="width: 24px; height: 24px" alt="Hands" /></template>
          <template #text>
            <span>
              <h4 class="global-h4">You have a collaboration opportunity!<br /></h4>
              <span>
                Please fill out the details and let us know
                <span v-if="daysUntilExpiration() > 0">in the next {{ daysUntilExpiration() }} days.</span>
                <span v-else>as soon as possible.</span>
              </span>
            </span>
          </template>
        </NoteWithIcon>
      </div>

      <ul class="collab-opportunity__tiles-list">
        <li class="collab-opportunity__tiles-group">
          <!-- Collab Details -->
          <div class="tile tile--gray-bg collab-opportunity__tile collab-opportunity__tile--collab-details">
            <div class="tile__header">
              <div class="global-h3 tile__title">Destination Collab Details</div>
            </div>
            <CollabInputsSummary
              class="tile__content"
              :isCreatorFlow="true"
              :collabInput="collab.collabInput"
              :creatorInput="collab.creatorInput"
              :communityName="collab.communityName"
              :allowDateChange="currentUserIsAssignedToCollab"
            />
          </div>
          <!-- / Collab Details -->
          <!-- Your Compensation -->
          <div class="tile collab-opportunity__tile collab-opportunity__tile--your-compensation">
            <div class="tile__header">
              <div class="global-h3 tile__title">Your Compensation</div>
            </div>
            <TotalCollabValue
              class="tile__content"
              :collabInput="collab.collabInput"
              :creatorCurrentRate="collab.creatorCurrentRate"
              :otherPaymentAmount="collab.otherPaymentAmount"
              :otherPaymentDetails="collab.otherPaymentDetails"
            />
          </div>
          <!-- / Your Compensation -->
        </li>

        <li class="collab-opportunity__tiles-group">
          <CollabApplicationCard
            v-if="!currentUserIsAssignedToCollab"
            :saveStatus="saveStatus"
            :collab="collab"
            :collabHasCreatorAssigned="collabHasCreatorAssigned"
            :errors="errors"
            v-model:date-range="dateRange"
            @click:save="save"
            @click:decline="askToDecline"
            @click:accept="askToAccept"
          />

          <CollabExpectationsCard :requestedLocationCount="collab?.collabInput?.requestedLocationCount" :requestedAdventureCount="collab?.collabInput?.requestedAdventureCount" />
        </li>
      </ul>
    </div>
    <!-- keeping tabs alive to allow photo uploads running in the background -->
    <SrpTabs v-else v-model:activeTab="activeTab" tabsFillWidth isKeepTabsAlive :style="screenSize === 'mobile' && { width: 'calc(100vw)', margin: '0 0 0 -32px' }">
      <template #Plan-header="{ active }">
        <TabHeader :is-active="active" title="Plan" :description="`Visit on ${visitStartDateString}`" icon="calendar_1-5" :stepNumber="1" />
      </template>
      <template #Plan>
        <div>
          <div class="collab-opportunity__warning-wrap">
            <NoteWithIcon class="collab-opportunity__warning" color="green">
              <template #icon><IconEmbedded name="info-simple_4" /></template>
              <template #text>
                <!--Current User is assigned to this collab, show a good message based on that state of the collab-->
                <!--Late (post-creation) states-->
                <span v-if="collab.collabInput.collaboration.completedDate">
                  <h4 class="global-h4">Collaboration Complete!</h4>
                  Nice job, we look forward to more collabs in the future!
                </span>
                <span v-else-if="hasCorrectionsForCreator"
                  ><b>Corrections Requested!</b><br />Please review the requested changes and update as needed.<br />
                  <button @click="activeTab = 'Deliver'" class="ui small primary button" style="margin-top: 8px">Review Requested Changes</button>
                </span>
                <span v-else-if="adventuresAreCreated"><b>Adventures Created!</b><br />Nice job, we'll let you know if the destination has any feedback.</span>

                <!--Early (pre-adventure) states-->
                <span v-else-if="!isReadyForVisit && !visitDateArrived">
                  <h4 class="global-h4" style="margin-bottom: 4px">Congratulations, you have been chosen for this collaboration!</h4>

                  <span style="margin-bottom: 7px; display: block">
                    {{ collab.creatorInput.confirmedOrNotDateTime ? "Adventure awaits!" : "Please confirm your avilability as soon as possible." }}
                  </span>

                  <SrpButton v-if="!collab.creatorInput.confirmedOrNotDateTime" class="positive-msg-with-confirm-button__button" color="orange" @click="isCollabConfirmModalVisible = true">
                    Confirm
                  </SrpButton>

                  <SrpButton v-else-if="collab.showFirstCollabGuidance" color="orange" @click="isCollabCreatorGuideVisible = true" style="margin-left: -2px"> Complete 1st Collab Guidance </SrpButton>

                  <SrpButton v-else-if="!collab.creatorInput.visitPlanSentDate" color="orange" @click="isPlanEditModalVisible = true" style="margin-left: -2px">
                    {{ collab.creatorInput.visitPlan?.length > 0 ? "Finish your Visit Plan" : "Make a Visit Plan" }}
                  </SrpButton>

                  <SrpModal v-model:isVisible="isCollabConfirmModalVisible" :isClosable="Boolean(collab.creatorInput.confirmedOrNotDateTime)" isWithScroll>
                    <template #content>
                      <transition name="fade-70ms">
                        <ConfirmAvailability
                          v-if="isCollabConfirmModalVisible"
                          @confirmed="collabConfirmResponse"
                          @close="isCollabConfirmModalVisible = false"
                          :collab="collab"
                          :totalPaymentFromShrpa="CollabValueCalculator.totalPaymentFromShrpa(collab.collabInput, collab.creatorCurrentRate, collab.otherPaymentAmount)"
                        />
                      </transition>
                    </template>
                  </SrpModal>
                </span>

                <span v-else>
                  <span v-if="visitDateArrived" style="margin-bottom: 8px; display: block">
                    <h4 class="global-h4">
                      We hope your visit went well! Be sure to create your adventures
                      <span style="text-decoration: underline"> by {{ moment(adventuresDueBy).format("MMM Do") }} </span>.
                    </h4>
                    <div style="font-weight: 500; margin-top: 3px">
                      <a target="_blank" :href="globalGetLink('AdventureCreationTips')" style="text-decoration: underline">Here</a> are some tips to help you get started.
                    </div>
                  </span>
                  <span v-else style="margin-bottom: 8px; display: block">
                    <h4 class="global-h4">
                      Looks like everything is lined up!?<br />
                      Please validate that you're all set, let us know if you have any questions.
                    </h4>
                    <span>Once your visit is complete, create the adventures!</span>
                  </span>
                </span>

                <span v-if="adventuresAreCreated">
                  <router-link
                    :to="{
                      name: 'CollabSummaryPublic',
                      params: { communityId: collab.communityId, collabInputId: collab.collabInput.id },
                    }"
                    target="_blank"
                  >
                    <SrpButton fill="outlined" size="tiny">View the Collab Summary</SrpButton>
                  </router-link>
                </span>
              </template>
            </NoteWithIcon>
          </div>

          <ul class="collab-opportunity__tiles-list">
            <li class="collab-opportunity__tiles-group">
              <!-- Visit Plan Tile -->
              <div class="tile collab-opportunity__tile collab-opportunity__tile--collab-details">
                <div v-if="collab.collabInput.useLocationsInVisitPlan" style="padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.2) solid">
                  <h6 class="global-h6" style="margin-bottom: 3px">{{ collab?.communityName }} requested:</h6>
                  <span style="color: rgba(91, 91, 91, 1); font-family: sans-serif; white-space: nowrap">
                    <b>{{ collab.collabInput.requestedLocationCount }}</b> locations highlighted </span
                  ><br />
                  <span style="color: rgba(91, 91, 91, 1); font-family: sans-serif; white-space: nowrap">
                    <b>{{ collab.collabInput.requestedAdventureCount }}</b> adventures created
                  </span>
                </div>

                <div class="tile__header">
                  <div class="global-h3 tile__title">Visit Plan</div>

                  <!-- Plan status line -->
                  <div v-if="!collab.creatorInput.visitPlanSentDate && !collab.creatorInput.visitPlanSignOffDate" class="plan-status-line tile__additional-title">
                    <div class="plan-status-line__circle"></div>
                    <div class="plan-status-line__text">Not yet sent to Destination</div>
                  </div>
                  <!-- / Plan status line -->

                  <!-- Plan status line -->
                  <div
                    v-else-if="collab.creatorInput.visitPlanSentDate && !collab.creatorInput.visitPlanSignOffDate"
                    class="plan-status-line plan-status-line--orange plan-status-line--green tile__additional-title"
                  >
                    <div class="plan-status-line__circle"></div>
                    <div class="plan-status-line__text">Sent to Destination for Review</div>
                  </div>
                  <!-- / Plan status line -->

                  <!-- Plan status line -->
                  <div v-else-if="collab.creatorInput.visitPlanSignOffDate" class="plan-status-line plan-status-line--green plan-status-line--green tile__additional-title">
                    <div class="plan-status-line__circle"></div>
                    <div class="plan-status-line__text">Destination Signed Off</div>
                  </div>
                  <!-- / Plan status line -->
                </div>

                <!-- Old Visit plan content (text-based) -->
                <div
                  v-if="!collab.creatorInput.visitPlan"
                  style="margin-bottom: 25px; color: rgba(109, 109, 109, 1); font-family: sans-serif; white-space: pre-wrap"
                  v-html="DOMPurify.sanitize(collab.creatorInput?.visitPlanSentDate ? collabLocationsStore.locationsTextRepresentation : locationsTextRepresentationWithPlaceholders)"
                ></div>

                <!-- New Visit plan content (location-based) -->
                <div class="visit-plan-tile tile__content">
                  <div v-if="collab.creatorInput.visitPlan" class="visit-plan-tile__plan-text-wrap">
                    <div
                      :class="{
                        'visit-plan-tile__plan-text': true,
                        'visit-plan-tile__plan-text--clamped': collab.creatorInput.visitPlan?.length > 220 && !isVisitPlanSectionExpanded,
                      }"
                    >
                      {{ collab.creatorInput.visitPlan }}
                    </div>
                    <LinkWithIcon v-if="collab.creatorInput.visitPlan?.length > 220" @click="isVisitPlanSectionExpanded = !isVisitPlanSectionExpanded" color="blue" isDottedUnderline>
                      <span v-if="isVisitPlanSectionExpanded">show less</span>
                      <span v-else>show more</span>
                    </LinkWithIcon>
                  </div>

                  <SrpButton
                    v-if="!collab?.collabInput.collaboration.completedDate"
                    class="visit-plan-tile__edit-btn"
                    @click="isPlanEditModalVisible = true"
                    :color="collab?.creatorInput?.visitPlanSentDate ? 'gray' : 'teal'"
                    :fill="collab?.creatorInput?.visitPlanSentDate ? 'outlined' : 'solid'"
                    size="small"
                  >
                    Edit Plan
                  </SrpButton>
                </div>
                <PlannedVisit class="tile__content" :collab="collab" :saveStatus="saveStatus" @save="save" />
                <!-- / Visit plan content -->
              </div>
              <!-- / Visit Plan Tile -->
              <PlanEditModal
                :showVisitPlanFields="true"
                :isVisible="isPlanEditModalVisible"
                :communityId="collab.communityId"
                :collabInput="collab.collabInput"
                :creatorId="creatorIdInContext"
                :creatorInput="collab.creatorInput"
                @update:isVisible="v => (isPlanEditModalVisible = v)"
                @showChat="showMessageThread"
                @updatePlan="populateVisitPlanFields"
              />

              <!-- Your Compensation -->
              <div class="tile collab-opportunity__tile collab-opportunity__tile--your-compensation">
                <div class="tile__header">
                  <div class="global-h3 tile__title">Your Compensation</div>
                </div>
                <TotalCollabValue
                  class="tile__content"
                  :collabInput="collab.collabInput"
                  :creatorCurrentRate="collab.creatorCurrentRate"
                  :otherPaymentAmount="collab.otherPaymentAmount"
                  :otherPaymentDetails="collab.otherPaymentDetails"
                />
              </div>
              <!-- / Your Compensation -->
            </li>

            <li class="collab-opportunity__tiles-group">
              <!-- Collab Details -->
              <div class="tile tile--gray-bg collab-opportunity__tile collab-opportunity__tile--collab-details">
                <div class="tile__header">
                  <div class="global-h3 tile__title">Destination Collab Details</div>
                </div>
                <CollabInputsSummary
                  class="tile__content"
                  :isCreatorFlow="true"
                  :collabInput="collab.collabInput"
                  :creatorInput="collab.creatorInput"
                  :communityName="collab.communityName"
                  :allowDateChange="true"
                />
              </div>
              <!-- / Collab Details -->

              <!-- Tile -->
              <CollabExpectationsCard :requestedLocationCount="collab?.collabInput?.requestedLocationCount" :requestedAdventureCount="collab?.collabInput?.requestedAdventureCount" />
              <!-- / Tile -->
            </li>
          </ul>
        </div>
      </template>
      <template #Create-header="{ active }">
        <TabHeader :is-active="active" title="Create" description="Capture and upload content" icon="aperture_1-5" :stepNumber="2" />
      </template>
      <template #Create>
        <CreateTab
          :collabInput="collab.collabInput"
          :creatorId="collab.collabInput?.collaboration?.creatorId"
          :customerId="collab.collabInput?.customerId"
          @goToNextTab="() => (activeTab = 'Deliver')"
        />
      </template>
      <template #Deliver-header="{ active }">
        <TabHeader :is-active="active" title="Deliver" description="Submit your content for review" icon="send_1-5" :stepNumber="3" />
      </template>
      <template #Deliver>
        <DeliverTab :collab="collab" :collabAdventures="collabAdventures" />
      </template>
    </SrpTabs>

    <!-- ================================================================================== -->
    <!-- ================================================================================== -->
    <!-- ================================================================================== -->
    <!-- ================================================================================== -->
    <!-- ================================================================================== -->

    <div class="very relaxed grid ui container">
      <div v-if="isSuperOrSalesUser && collab && collab.creatorInput" class="sixteen wide column" style="margin-top: 12px">
        <router-link :to="{ name: 'PaidCollabCommunityAdmin', params: { communityId: collab.communityId }, hash: `#collab=${collab.collabInput.id}` }" class="ui tiny basic right floated button"
          >View Collab</router-link
        >
        <button v-if="!collabHasCreatorAssigned && collab.creatorInput && !collab.creatorInput.declinedDateTime" class="ui tiny basic right floated button" @click="askToDecline">
          Decline Collab
        </button>
      </div>

      <SideFlyout v-model:isVisible="isMessagesVisible" side="right" title="Message community" :subTitle="isMessageThreadImpersonationOn ? 'Impersonating the creator' : ''">
        <CollabMessagesThread
          class="messages-thread-flyout"
          :messageThreadId="lodashGet(collab, 'collabInput.collaboration.messageThreadId')"
          :customerId="lodashGet(collab, 'collabInput.customerId')"
          :collabInputId="lodashGet(collab, 'collabInput.id')"
          messageSenderType="CreatorId"
          @isImpersonateChange="value => (isMessageThreadImpersonationOn = value)"
        />
      </SideFlyout>

      <!--Contact Community Modal-->
      <ContactCommunityModal
        v-model:isVisible="showContactCommunityModal"
        :communityName="collab ? collab.communityName : 'the community'"
        :showFooter="!!collab"
        v-model:skipIntermediateMessagingModal="skipIntermediateMessagingModal"
        @click:contactShrpa="contactShrpa"
        @click:message-destination="showMessageThread"
        @click:cancel="cancelModals"
      />

      <!--Contact Shrpa Modal-->
      <ContactUser
        :visible="showContactShrpaModal"
        :sendToFirstName="'Shrpa'"
        :sendToEmail="'collabs@shrpa.com'"
        :sendToImageUri="'https://cdn.shrpa.com/images/logo.png'"
        :subject="contactShrpaSubject"
        :messagePrefix="contactShrpaSubject"
        @close="cancelModals"
      />

      <!--Accept Modal-->
      <SrpModal v-model:isVisible="showAcceptModal" :isClosable="false" :size="'large'" isWithScroll>
        <template #header>
          <h2 class="global-h2">Collaboration Expectations</h2>
        </template>
        <template #content>
          <CollabExpectations
            :requestedLocationCount="collab?.collabInput?.requestedLocationCount"
            :requestedAdventureCount="collab?.collabInput?.requestedAdventureCount"
            style="padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"
          />
          <label style="margin-bottom: 14px !important; display: inline-flex; align-items: center">
            <SrpCheckbox class="apply-form__form-element">
              <template #input><input type="checkbox" v-model="userAgreesToTerms" /></template>
              <template #text>I agree to the terms</template>
            </SrpCheckbox>
          </label>
          <div style="color: red; margin-top: 10px" v-if="showTermsRequired"><b>You must agree to the terms before accepting.</b></div>
        </template>
        <template #footer>
          <SrpButton @click="cancelModals" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
          <SrpButton @click="acceptTerms" color="orange">
            {{ collab?.creatorInput?.manuallyInvited ? "Accept" : "Apply" }}
          </SrpButton>
        </template>
      </SrpModal>

      <!--Decline Modal-->
      <DeclineModal
        v-if="collab"
        :collab="collab"
        v-model:isVisible="showDeclineModal"
        v-model:declineReason="collab.creatorInput.declineReason"
        v-model:notesForShrpa="collab.creatorInput.notesForShrpa"
        @click:declineCollab="declineCollab"
        @click:cancel="cancelModals"
      />
    </div>

    <CollabCreatorGuideModal
      :requestedLocationCount="collab?.collabInput?.requestedLocationCount"
      :requestedAdventureCount="collab?.collabInput?.requestedAdventureCount"
      v-model:isVisible="isCollabCreatorGuideVisible"
      @complete="firstCollabGuidanceComplete"
    />
  </div>
</template>

<script lang="ts">
import axios from "axios";
import CollabStatuses from "@logic/CollabStatuses";
import DateUtils from "@logic/DateUtils";
import DOMPurify from "dompurify";
import moment from "moment";
import lodashGet from "lodash-es/get";
import { RouteHelper } from "@helpers/RouteHelper";
import { defineComponent, inject } from "vue";
import CollabValueCalculator from "@logic/CollabValueCalculator";

// Types
import CollabConsts, { CreatorCollab, VisitPlanFields } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import CollabExpectationsCard from "../CollabExpectationsCard.vue";
import LongDistanceCollabWarning from "../LongDistanceCollabWarning.vue";
import ContactCommunityModal from "../ContactCommunityModal.vue";
import CollabCreatorGuideModal from "../CollabCreatorGuideModal.vue";
import DeclineModal from "../DeclineModal.vue";
import CollabApplicationCard from "../CollabApplicationCard.vue";
import TabHeader from "./Tabs/TabHeader.vue";
import DeliverTab from "./Tabs/DeliverTab.vue";
import CreateTab from "./Tabs/CreateTab.vue";
import CollabInputsSummary from "@views/PaidCollab/CollabInputsSummary.vue";
import CollabStatusBar from "@views/PaidCollab/CollabStatusBar.vue";
import ConfirmAvailability from "../ConfirmAvailavility.vue";
import ContactUser from "@components/Modals/ContactUser.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Loader from "@components/Loader/Loader.vue";
import TotalCollabValue from "@views/Creator/TotalCollabValue.vue";
import SideFlyout from "@components/ui/SideFlyout.vue";
import CollabMessagesThread from "@views/PaidCollab/CollabMessagesThread/index.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

import PlannedVisit from "../PlannedVisit.vue";
import ApplyForm from "../ApplyForm.vue";

import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import CollabExpectations from "@views/Creator/CollabExpectations.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import CollabCreatorGuide from "@components/CollabCreatorGuide/index.vue";
import PlanEditModal from "../PlanEditModal/index.vue";

import { mapActions, mapState } from "pinia";
import logger from "@helpers/Logger";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpTabs from "@components/ui/SrpTabs.vue";
import { fetchCollabAdventures } from "@repos/CreatorCollabRepo";
import { ItinerarySummaryCms } from "@contracts/itinerary";
import MsgCountBadge from "@components/MsgCountBadge.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import { useCollabLocationsStore } from "@stores/collabLocations";
import { useHead } from "@unhead/vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

export default defineComponent({
  name: "CollabOpportunity",

  // prettier-ignore
  components: { MsgCountBadge, SrpTabs, IconEmbedded, CollabExpectations, CollabExpectationsCard, ContactCommunityModal, CollabCreatorGuideModal, TabHeader, DeliverTab, CreateTab, DeclineModal, LinkWithIcon, SrpCheckbox, SrpButton, SrpModal, CollabMessagesThread, SideFlyout, CollabInputsSummary, CollabStatusBar, ConfirmAvailability, ContactUser, VueDatePicker, Loader, TotalCollabValue, PlannedVisit, ApplyForm, CollabApplicationCard, NoteWithIcon, SrpDetailsSummary, CollabCreatorGuide, PlanEditModal, LongDistanceCollabWarning },

  data() {
    return {
      activeTab: "Plan" as "Plan" | "Create" | "Deliver",
      globalLog: inject("globalLog") as any,
      globalGetLink: inject("globalGetLink") as any,
      screenSize: inject("screenSize") as ScreenSize,

      apiBaseUri: import.meta.env.VITE_API_URL as string,
      inviteExpirationInDays: CollabConsts.CollabInviteExpirationInDays,

      title: "Collab Opportunity",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      creatorIdInContext: null as string | null,
      isLoadingData: true,
      saveStatus: null as string | null,
      showRequired: false,
      userConfirmedDatesAreValid: false,
      errors: [],
      // modal to ask if they have a proper question for the community
      showContactCommunityModal: false,
      // modal that lets the user actually send an email to the community
      showActualContactCommunityModal: false,
      showContactShrpaModal: false,
      skipIntermediateMessagingModal: false,
      showAcceptModal: false,
      userAgreesToTerms: false,
      showTermsRequired: false,
      showDeclineModal: false,
      expectationsCollapsed: false,

      collab: null as CreatorCollab | null,
      collabAdventures: null as ItinerarySummaryCms[] | null,
      dateRange: null as Array<Date> | null,
      sendingEmail: false,
      sentEmail: false,

      isMessageThreadImpersonationOn: false,

      declineReasons: [
        { key: "unavailable", text: "I'm not available on those dates" },
        { key: "nogear", text: "I don't have the necessary gear" },
        { key: "notafitforthemes", text: "I'm not a good fit for the themes or activities" },
        { key: "other", text: "Other (tell us why below please)" },
      ],

      isCollabConfirmModalVisible: false,

      isMessagesVisible: false,

      newMessagesCount: 0,
      messagesListRefreshTimeoutInSeconds: 180,
      // Only do the message checks for a period of time
      firstMessageCheckTime: null,
      messageCheckStopAfterMinutes: 20,
      messagesListRefreshTimeoutId: null as ReturnType<typeof setTimeout>,

      userProfileStore: useUserProfileStore(),
      collabLocationsStore: useCollabLocationsStore(),

      isCollabCreatorGuideVisible: false,

      isPlanEditModalVisible: false,

      isVisitPlanSectionExpanded: false,
    };
  },

  computed: {
    CollabValueCalculator() {
      return CollabValueCalculator;
    },
    DOMPurify() {
      return DOMPurify;
    },
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isSuperOrSalesUser"]),
    locationsTextRepresentationWithPlaceholders(): string {
      if (!this.collab) return "";
      return this.collabLocationsStore.useLocationsTextRepresentation(this.collab.collabInput.requestedLocationCount).value;
    },
    visitDateString(): string {
      return DateUtils.formatDateRange(this.collab.creatorInput?.proposedVisitDateTime, this.collab.creatorInput?.numberOfDaysStaying);
    },
    visitStartDateString(): string {
      // example, January 1st, 2020
      return moment(this.collab.creatorInput.proposedVisitDateTime).format("MMMM Do");
    },
    currentUserIsAssignedToCollab(): boolean {
      return this.getViewingUserProfile?.sherpaId === this.collab?.collabInput?.collaboration?.creatorId;
    },
    collabHasCreatorAssigned(): boolean {
      return this.collab?.collabInput?.collaboration?.creatorId?.length > 0;
    },
    adventuresAreCreated(): boolean {
      return +this.collab?.collabInput.collaboration.status >= +CollabStatuses.AdventuresCreated;
    },
    hasCorrectionsForCreator(): boolean {
      return +this.collab?.collabInput.collaboration.status >= +CollabStatuses.AdventuresCreated && this.collab?.collabInput.correctionsStatus === CollabStatuses.CorrectionStatusPendingCreator;
    },
    isReadyForVisit(): boolean {
      if (!this.collab?.collabInput) return false;
      if (!this.collab?.creatorInput?.confirmedOrNotDateTime) return false;
      if (!this.collab?.showFirstCollabGuidance) return false;
      if (!this.collab?.creatorInput?.visitPlanSentDate) return false;
      // A simple status check would be better, but we don't currently have a status for "ready to visit"
      const pendingHotel = this.collab.creatorInput?.nightsRequired > 0 && !(this.collab?.collabInput.hotelIsBooked || this.collab.creatorInput.hotelIsBooked);
      const canCompSomeActivities = this.collab?.collabInput?.requestingBigTicketItems || this.collab?.collabInput?.bigTicketItemBookinNotes?.length > 0;
      const bigTicketPending = canCompSomeActivities && !this.collab?.collabInput.bigTicketItemsAllBooked;
      // this.globalLog.info(`isReadyForVisit: pendingHotel=${pendingHotel}, bigTicketPending=${bigTicketPending}`)
      return !pendingHotel && !bigTicketPending;
    },
    visitDateArrived(): boolean {
      // If the visit date is here, then show the message/button for creating adventures
      let visitDateIsHere = false;
      if (this.collab.creatorInput?.proposedVisitDateTime) visitDateIsHere = new Date(this.collab.creatorInput.proposedVisitDateTime) < new Date();

      return visitDateIsHere;
    },
    adventuresDueBy(): Date | null {
      if (this.collab?.creatorInput) {
        let adventuresReadyDate = DateUtils.addDays(new Date(this.collab.creatorInput.proposedVisitDateTime), this.collab.creatorInput.numberOfDaysStaying);
        adventuresReadyDate = DateUtils.addDays(adventuresReadyDate, CollabConsts.DaysCreatorHasToCreateAdventures);
        return adventuresReadyDate;
      } else return null;
    },
    contactCommunitySubject(): string {
      return `${this.getViewingUserProfile?.firstName} has a collab question about ${this.collab?.collabInput?.name}`;
    },
    contactShrpaSubject(): string {
      return `Collab question for Shrpa from ${this.getViewingUserProfile?.firstName} about ${this.collab?.collabInput?.name} - ${this.collab?.communityName}`;
    },
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      // Auth will have run when mounted gets called but the profile might not be loaded yet,
      // which the impersonate call needs. Should find a better pattern for this...
      if (profile) {
        logger.info("CollabOpportunity: loggedInUser watch fired");
        this.impersonateProfile(this.creatorIdInContext);
      }
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // AuthGuard is set in the router so this shouldn't fire until the user is authed
    var communityIdParam = this.$route.params.communityId as string;
    var collabInputIdParam = this.$route.params.collabInputId as string;
    this.creatorIdInContext = this.$route.params.creatorId as string;
    await this.collabLocationsStore.loadCollabLocationsData(collabInputIdParam, this.creatorIdInContext, communityIdParam);
    await this.impersonateProfile(this.creatorIdInContext);
    await this.loadCollabAdventures(communityIdParam as string, collabInputIdParam as string, this.creatorIdInContext);
    await this.loadCollab(communityIdParam as string, collabInputIdParam as string, this.creatorIdInContext);

    await this.checkForNewMessages();
    await this.checkFor1stCollabGuidanceModal();
    this.setDefaultSelectedTab();
  },

  beforeUnmount() {
    clearTimeout(this.messagesListRefreshTimeoutId);
  },

  methods: {
    lodashGet,
    moment,
    ...mapActions(useUserProfileStore, { impersonateProfile: "impersonateProfile" }),
    populateVisitPlanFields(visitPlanFields: VisitPlanFields): void {
      if (visitPlanFields.visitPlan) this.collab.creatorInput.visitPlan = visitPlanFields.visitPlan;
      if (visitPlanFields.visitPlanSentDate) this.collab.creatorInput.visitPlanSentDate = visitPlanFields.visitPlanSentDate;
      if (visitPlanFields.visitPlanSignOffDate) this.collab.creatorInput.visitPlanSignOffDate = visitPlanFields.visitPlanSignOffDate;
    },
    setNewMessagesCheckTimeout(): void {
      clearTimeout(this.messagesListRefreshTimeoutId);
      // Only run message checks for a set period (it has dimishing returns and browsers now memory optimize tabs)
      if (this.firstMessageCheckTime) {
        const timeDifference = new Date().getTime() - this.firstMessageCheckTime.getTime();
        const minutesDifference = timeDifference / (1000 * 60);
        if (minutesDifference > this.messageCheckStopAfterMinutes) {
          getGlobalRemoteLogger().info(
            `MaxMessageCheckTime creator for user=${this.getActingUserProfile.sherpaId}, collab=${this.collab.collabInput.id}, thread=${this.collab.collabInput.collaboration.messageThreadId}, minutes=${minutesDifference}`
          );
          return;
        }
      }
      this.messagesListRefreshTimeoutId = setTimeout(() => this.checkForNewMessages(), this.messagesListRefreshTimeoutInSeconds * 1000);
    },
    async checkForNewMessages(): Promise<void> {
      clearTimeout(this.messagesListRefreshTimeoutId);

      // Messaging buttons are hidden once the collab is complete
      if (this.collab?.collabInput.collaboration.completedDate) return;

      // @ts-ignore
      const { data } = await axios.get(`${this.apiBaseUri}/messaging/${this.collab?.collabInput.collaboration.messageThreadId}/new-count?senderIdInContext=${this.collab.creatorInput.creatorId}`);

      this.newMessagesCount = data;
      if (!this.firstMessageCheckTime) {
        this.firstMessageCheckTime = new Date();
      }

      this.setNewMessagesCheckTimeout();
    },
    async loadCollab(communityId: string, collabInputId: string, creatorId: string) {
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${communityId}/inputs/${collabInputId}/creator/${creatorId}`;

      const response = await axios.get(uri);
      this.collab = response.data;
      this.newMessagesCount = this.collab.unreadMessages;
      this.title += ` - ${this.collab.communityName}`;
      if (RouteHelper.getQueryStringOrHashParam("nocredits")) {
        this.collab.creatorCreditCount = 0; // For testing
      }

      this.checkIfMulitpleCreatorsChosen();
      this.setupDatePicker();
      this.isLoadingData = false;
      this.defaultNonPersistedFields();
      this.checkForShowMessageCommunity();
      this.checkForShowConfirmModal();
      this.defaultSkipIntermediateModal();
    },
    async loadCollabAdventures(customerId: string, collabInputId: string, creatorId: string) {
      const loadedAdventures = await fetchCollabAdventures(customerId, collabInputId, creatorId);
      this.collabAdventures = loadedAdventures;
    },
    checkIfMulitpleCreatorsChosen() {
      // If multiple creators are chosen, some of the creatorInputs will be moved to newly cloned collabs,
      // which will break old links, so we redirect them back to the dashboard where they can see collabs they're chosen for.
      if (this.collab.collabInput.multipleCreatorsChosen === true && this.collab.creatorInput.collabInputId === null) {
        this.globalLog.info("Multiple creators chosen, redirecting to dashboard");
        this.$router.push({ name: "CreatorDashboard", params: { creatorId: this.getViewingUserProfile?.sherpaId } });
      }
    },
    defaultNonPersistedFields() {
      // Default the 'dates are valid' checkbox to true if they accepted
      if (this.collab?.creatorInput?.acceptedDateTime !== null) {
        this.userConfirmedDatesAreValid = true;
      }
    },
    checkForShowMessageCommunity() {
      // This allows us to build a link (ex. for emails) that sends the user here and pops up the messages panel
      if (RouteHelper.getHashParam("messages") === "true") {
        this.showMessageThread();
        location.hash = location.hash.replace("messages=true", "messages=false");
      }
    },
    checkForShowConfirmModal() {
      // Shortcut if it's already confirmed
      if (this.collab.creatorInput.confirmedOrNotDateTime) return;
      if (RouteHelper.getQueryStringOrHashParam("confirm")) {
        this.isCollabConfirmModalVisible = true;
        location.hash = "";
      }
    },
    checkFor1stCollabGuidanceModal() {
      // Shortcut if it's already completed it
      // if (this.collab.showFirstCollabGuidance === false) return;
      if (RouteHelper.getQueryStringOrHashParam("collabguidance")) {
        this.isCollabCreatorGuideVisible = true;
        location.hash = "";
      }
    },
    collabConfirmResponse(willWork: boolean) {
      // Mimicking the server logic
      this.collab.creatorInput.confirmedOrNotDateTime = new Date().toISOString();
      this.collab.creatorInput.creatorConfirmedWillWork = willWork;
      // The new flow shows them a next steps pane, which they close out of manually
      if (!willWork) {
        this.isCollabConfirmModalVisible = false;
      }
    },
    setupDatePicker() {
      // Sets the field that the dateipcker binds to
      if (this.collab.creatorInput?.proposedVisitDateTime && this.collab.creatorInput.proposedVisitDateTime?.length > 0) {
        var startDate = new Date(this.collab.creatorInput.proposedVisitDateTime);
        var endDate = DateUtils.addDays(new Date(startDate), this.collab.creatorInput.numberOfDaysStaying);
        this.dateRange = [startDate, endDate];
      }
    },
    daysUntilExpiration(): number {
      // We use a different field depending on the invite flow.
      // Manual Invites have an invite date but posted collabs wont't so we use the posted date.
      var startDateString = this.collab?.creatorInput?.manuallyInvited === true ? this.collab?.creatorInput?.invitedDateTime : this.collab?.collabInput?.postedToCreators;
      if (!startDateString) return -1;

      const startDate = new Date(startDateString);
      const expirationDate = DateUtils.addDays(startDate, this.inviteExpirationInDays);
      const daysBetween = DateUtils.daysBetween(new Date(), expirationDate);
      var roundedDays = Math.round(daysBetween);
      return roundedDays;
    },
    async askToAccept() {
      this.showAcceptModal = true;
    },
    async acceptTerms() {
      if (!this.userAgreesToTerms) {
        this.showTermsRequired = true;
        return;
      }

      // Mark accepted
      this.collab.creatorInput.acceptedDateTime = new Date().toISOString();
      // Null this out since the creator can now change their mind and accept after declining
      this.collab.creatorInput.declinedDateTime = null;
      await this.save();
      this.showAcceptModal = false;
    },
    askToDecline() {
      this.showDeclineModal = true;
    },
    async declineCollab() {
      // Update: Making the decline reasons more optional now that it's Not Interested as part of the Collab Application process
      // Decline reason used the notesForCommunity field
      // Dec Update: Dropdown values that we show to the community and free-text that goes to Shrpa
      // Old Update: Cutting this field, may re-add with a specific set of replies (keeps creators from complaining to communities)

      // Mark Declined
      this.collab.creatorInput.declinedDateTime = new Date().toISOString();
      this.collab.creatorInput.notesForCommunity = ""; // In case they added notes in the main screen and selected Other below
      if (this.collab.creatorInput.declineReason !== "other") {
        this.collab.creatorInput.notesForCommunity = this.declineReasons.find(r => r.key === this.collab.creatorInput.declineReason)?.text;
      }
      await this.save();
      this.dateRange = null;
      this.showDeclineModal = false;
    },
    async save() {
      // Note: Validation is done by the calling methods since it varies
      this.saveStatus = "Saving...";
      const config = { headers: { "Content-Type": "application/json" } };
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.collab.communityId}/inputs/${this.collab?.collabInput.id}/creator/${this.collab.creatorInput.creatorId}`;
      const { data } = await axios.put(uri, JSON.stringify(this.collab.creatorInput), config);
      // Update the input in case the server reset any fields (ex. if you accept and then decline it clears out a bunch)
      this.collab.creatorInput = data.creatorInput;
      // Update the status in case the server updated that
      this.collab.collabInput.collaboration.status = data.collabStatus;
      this.saveStatus = "Saved!";
      setTimeout(() => (this.saveStatus = null), 5000);
    },
    contactCommunity() {
      if (this.skipIntermediateMessagingModal === true) {
        this.showMessageThread();
      } else {
        this.showContactCommunityModal = true;
      }
    },
    actuallyContactCommunity() {
      this.showActualContactCommunityModal = true;
    },
    showMessageThread() {
      if (this.isSuperOrSalesUser !== true) {
        // Clear out message count since we're about to show them the messages, which will zero it out.
        this.newMessagesCount = 0;
      }
      this.showContactCommunityModal = false;
      this.isMessagesVisible = true;
      this.checkForSkipIntermediateModal();
    },
    checkForSkipIntermediateModal() {
      // We let the user suppress "Contact Shrpa first..." modal per collab (since it slows down getting to the messages)
      if (this.skipIntermediateMessagingModal === true) {
        // Storing it in local storage so it persists across page loads
        localStorage.setItem(`skipIntermediateMessagingModal-${this.collab?.collabInput.id}`, "true");
      }
    },
    defaultSkipIntermediateModal() {
      // Check if they've suppressed this modal
      if (localStorage.getItem(`skipIntermediateMessagingModal-${this.collab?.collabInput.id}`) === "true") {
        this.skipIntermediateMessagingModal = true;
      }
    },
    contactShrpa() {
      this.showContactShrpaModal = true;
    },
    cancelModals() {
      this.showContactCommunityModal = false;
      this.showActualContactCommunityModal = false;
      this.showAcceptModal = false;
      this.showTermsRequired = false;
      this.showDeclineModal = false;
      this.showContactShrpaModal = false;
      setTimeout(() => {
        this.sendingEmail = false;
        this.sentEmail = false;
      }, 1000);
    },
    firstCollabGuidanceComplete() {
      this.collab.showFirstCollabGuidance = false;
      this.isCollabCreatorGuideVisible = false;
    },
    getCreatorAcceptedText(acceptedCount: number): string {
      // NOTE: Also in CollabOpportunity.vue
      if (acceptedCount <= 0) return "Open collab! No creators have applied";
      else if (acceptedCount === 1)
        // singular
        return "1 creator applied";
      // plural
      else return `${acceptedCount} creators applied`;
    },
    setDefaultSelectedTab() {
      const isCollabStartDateInPast = new Date(this.collab.collabInput.preferredDateRangeStart).getTime() < new Date().getTime();
      const isCollabEndDateInPast = new Date(this.collab.collabInput.preferredDateRangeEnd).getTime() < new Date().getTime();
      const hasCollabAdventures = this.collabAdventures?.length > 0;

      if (hasCollabAdventures && isCollabStartDateInPast) {
        // has adventures and visit date is in the past show deliever tab
        this.activeTab = "Deliver";
      } else if (isCollabEndDateInPast) {
        // visit end date is in the past, show create tab
        this.activeTab = "Create";
      } else {
        // default to plan tab
        this.activeTab = "Plan";
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Plan status line ===========================================================
.plan-status-line {
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: bold;

  &__circle {
    width: 7px;
    height: 7px;
    margin: -1px 5px 0 0;
    border-radius: 100px;
    background: #666666;
  }

  &--green {
    color: #3d8528;

    .plan-status-line__circle {
      background: #3d8528;
    }
  }
  &--orange {
    color: #bb9227;

    .plan-status-line__circle {
      background: #bb9227;
    }
  }

  &__text {
  }
}

// Visit plan tile ============================================================
.visit-plan-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  &__plan-text-wrap {
    margin-bottom: 16px;
  }

  &__plan-text {
    color: #5b5b5b;
    font: 14px/19px sans-serif;
    white-space: pre-wrap !important;

    &--clamped {
      display: -webkit-box !important;
      overflow: hidden !important;
      line-clamp: 3 !important;
      -webkit-line-clamp: 3 !important;
      text-overflow: ellipsis !important;
      -webkit-box-orient: vertical !important;
    }
  }

  &__edit-btn {
  }
}

// Positive message with confirm button =======================================
.positive-msg-with-confirm-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__text {
    margin: 0 25px 0 0;
  }

  &__button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .positive-msg-with-confirm-button {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__text {
      margin: 0 0 10px 0;
    }
  }
}

.hands {
  width: 30px;
  height: 30px;
  margin-bottom: -8px;
}
.customer-icon {
  object-fit: contain;
  max-height: 80px;
  max-width: 200px;
  border-radius: 4px;
  @media screen and (max-width: 420px) {
    max-width: 55px;
  }
}
.contact-shrap-modal {
  .content {
    position: relative;
    .progress-wrapper {
      position: absolute;
      top: 0;
      bottom: -5rem;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .ui.progress {
        margin-bottom: 5rem;
      }
    }
  }
}

.date-range-picker {
  display: block;

  :deep(.dp__input) {
    padding-left: 35px !important;
  }
}

// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================
// ===================================================================================================

@import "@/scss/screen-size-ranges.scss";

// Back link section ==========================================================
.back-link-section {
  padding-bottom: 12px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__link {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .back-link-section {
    flex-direction: column;
    align-items: flex-start;

    &__link {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Client snippet =============================================================
.client-snippet {
  display: flex;
  align-items: center;

  &__logo {
    max-width: 40px;
    max-height: 40px;
    margin-right: 13px;
  }

  &__name {
    font:
      500 19px/24px "Quicksand",
      sans-serif;
  }
}

// Header button container ====================================================
.header-btn-container {
  position: relative;
  z-index: 0;

  &__msg-count-badge {
    position: absolute;
    inset: -10px 7px auto auto;
    z-index: 1;
    cursor: pointer;
  }

  &__btn-itself {
    z-index: 0;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header-btn-container {
    &:last-child {
      margin-top: 7px;
    }
  }
}

// Header =====================================================================
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__h1 {
    width: 100%;
    margin-bottom: 15px;
  }

  &__client-snippet {
  }

  &__buttons-side {
  }

  &__btn-container {
    margin-right: 13px;
    display: inline-flex;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    &__h1 {
      margin-bottom: 11px;
    }

    &__client-snippet {
      margin-bottom: 19px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    &__h1 {
      margin-bottom: 11px;
    }

    &__client-snippet {
      margin-bottom: 17px;
    }

    &__buttons-side {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__button {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Tile =======================================================================
.tile {
  padding: 21px 25px 24px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  outline: 2px rgba(0, 0, 0, 0.1) solid;
  outline-offset: -2px;
  transform: translateZ(0);

  /* TODO: remove when a proper fix is found */
  :deep(> *) {
    filter: blur(0); // the safari 17.4 has trouble rendering certain tiles so hopefully this hack will fix it
  }

  &--gray-bg {
    outline: none;
    background: #f0f0f0;
    box-shadow: none;
  }

  &--teal-bg {
    background: #d3ecee;
    box-shadow: none;
  }

  &__header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__additional-title {
    margin-top: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .tile {
    padding: 12px 18px 17px;

    &__header {
      margin-bottom: 13px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

// Collab opportunity =========================================================
.collab-opportunity {
  width: calc(var(--grid-col-width) * 9 + var(--grid-col-gap-width) * 8);
  margin: 0 auto;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;

  &__back-link-section {
    margin-bottom: 23px;
  }

  &__header {
    margin-bottom: 25px;
  }

  &__steps-guide {
    width: 100%;
    margin-bottom: 26px;
  }

  &__warning-wrap {
    width: 100%;
    padding-bottom: 27px;
    margin-bottom: 31px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__warning {
  }
  &__long-distance-reminder {
    margin-bottom: 1rem;
  }

  &__tiles-list {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
  }

  &__tiles-group {
    width: calc(50% - 13px);
    margin-bottom: 25px;
    break-inside: avoid-column;
    page-break-inside: avoid;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__tile {
    margin-bottom: 27px;
    break-inside: avoid-column;
    page-break-inside: avoid;

    &--collab-details {
    }
    &--your-compensation {
    }
    &--planned-visit {
    }
    &--collab-expectations {
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .collab-opportunity {
    width: calc(var(--grid-col-width) * 9 + var(--grid-col-gap-width) * 8);

    &__tiles-group {
      width: calc(50% - 12px);
    }

    &__step-snippet {
      width: 164px;
      margin-right: 24px;
    }

    &__tiles-list {
      column-gap: 24px;
    }

    &__tile {
      margin-bottom: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collab-opportunity {
    width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7);

    &__step-snippet {
      width: 109px;
      margin-right: 22px;
    }

    &__warning {
      :deep(span + *) {
        margin-top: 12px;
      }
    }

    &__tiles-list {
      column-gap: 22px;
    }

    &__tile {
      margin-bottom: 22px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-opportunity {
    width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7);

    &__tiles-group {
      width: 100%;
    }

    &__steps-guide {
      margin-bottom: 23px;
    }

    &__warning-wrap {
      padding-bottom: 23px;
      margin-bottom: 24px;
    }

    &__warning {
      :deep(span + *) {
        margin-top: 12px;
      }
    }

    &__tiles-list {
      column-count: 1;
      display: flex;
      flex-direction: column;
    }

    &__tile {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-opportunity {
    width: calc(100% - 36px);

    &__tiles-group {
      width: 100%;
    }

    &__steps-guide {
      margin-bottom: 20px;
    }

    &__warning-wrap {
      padding-bottom: 17px;
      margin-bottom: 18px;
    }

    &__warning {
      :deep(span + *) {
        margin-top: 12px;
      }
    }

    &__tiles-list {
      column-count: 1;
      display: flex;
      flex-direction: column;
    }

    &__tile {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
