<template>
  <div class="highlights-section">
    <div class="highlights-section__header">
      <h3 class="global-h3 highlights-section__title">Highlights</h3>

      <LinkWithIcon
        v-if="!isCreator && !isHotel"
        class="highlights-section__printable-summary-link"
        :to="{
          name: 'CommunityStatsSummary',
          params: { pageId: communityId },
        }"
        tag="RouterLink"
      >
        <template #icon><IconEmbedded name="printer_2" :size="21" /></template>
        <span>Printable summary</span>
      </LinkWithIcon>
    </div>

    <ul class="highlights-section__highlights-table">
      <HighlightSnippet class="highlights-section__highlight-snippet" v-for="snippet in highlightsList" :key="snippet.iconName" :data="snippet" />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { CustomerStatsOverview } from "@contracts/pages";
import NumberFormatting from "@logic/NumberFormatting";

// Components
import HighlightSnippet from "./HighlightSnippet.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Highlight {
  name: string;
  iconName: string;
  number: number;
  isClickable?: boolean;
  routerLinkTo?: object;
  onClickHandler?: Function;
}

export default defineComponent({
  name: "HighlightsList",

  components: {
    IconEmbedded,
    HighlightSnippet,
    LinkWithIcon,
  },

  props: {
    stats: { type: Object as () => CustomerStatsOverview, default: null },
    communityId: { type: String, default: null },
    isHotel: { type: Boolean, default: false },
    isCreator: { type: Boolean, default: false },
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  computed: {
    highlightsList(): Array<Highlight> {
      const newList: Array<any> = [{ name: "Adventures", iconName: "treasuremap", number: this.stats.communityAdventureCount + this.stats.paidAdventureCount }];

      if (this.isCreator) {
        // Show Communities how many creators there are
        newList.push({ name: "Collabs", iconName: "pencil", number: this.stats.totalCollaborationsCount });
      } else {
        // That obviously doesn't make sense to show creators so show Collab Count instead
        newList.push({ name: "Creators", iconName: "user", number: this.stats.communityCreatorCount + this.stats.paidCreatorCount });
      }

      newList.push({ name: "Locations", iconName: "store", number: this.abbreviatedFormat(this.stats.locationCount) });

      // If we're showing the creator stats, we don't have a photos page.  Maybe add that in the future
      if (this.isCreator) {
        newList.push({ name: "Photos", iconName: "photocamera", number: this.abbreviatedFormat(this.stats.photoCount) });
      } else {
        newList.push({
          name: "Photos",
          iconName: "photocamera",
          number: this.abbreviatedFormat(this.stats.photoCount),
          // isClickable: true,
          // routerLinkTo: { name: "CommunityPhotosV2", params: { pageId: this.communityId } },
        });
      }

      // Hide a handful of these that don't make sense for hotels
      if (false && this.hasViewStats && !this.isHotel) {
        newList.push({ name: "Impressions", iconName: "thumbsup", number: this.abbreviatedFormat(this.stats.impressionCount) });
        newList.push({ name: "Interactions", iconName: "mapmark", number: this.abbreviatedFormat(this.stats.previewCount + this.stats.exploreCount), onClickHandler: this.showDetailedStats });
      }

      return newList;
    },
    hasStats(): boolean {
      if (!this.stats) return false;

      return this.stats.communityAdventureCount > 0 || this.stats.paidAdventureCount > 0;
    },
    hasViewStats(): boolean {
      if (!this.stats) return false;

      return this.isCreator || this.stats.previewCount > 0 || this.stats.exploreCount > 0;
    },
  },

  methods: {
    abbreviatedFormat(value: number): string {
      return NumberFormatting.abbreviatedFormat(value);
    },
    showDetailedStats() {
      this.globalLog.info(`${this.stats.previewCount} and ${this.stats.exploreCount}`);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Highlights section =========================================================
.highlights-section {
  padding: 19px 25px 19px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: white;
  }

  &__header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
  }

  &__printable-summary-link {
  }

  &__subscript {
  }

  &__highlights-table {
    padding: 0;
    margin: 0;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__highlight-snippet {
    width: 50%;

    &::before {
      border-width: 0 1px 1px 0;
    }

    &:nth-child(2n) {
      &::before {
        border-right-width: 0;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      &::before {
        border-bottom-width: 0;
      }
    }
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop =============================
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large =======================
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet =============================
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile =============================
@media (max-width: $mobile-max-width) {
  .highlights-section {
    padding: 17px 19px 19px;
  }
}
</style>
