import couple from "./couple.svg";
import event from "./event.svg";
import family from "./family.svg";
import foodie from "./foodie.svg";
import guysgals from "./guysgals.svg";
import location from "./location.svg";
import outdoors from "./outdoors.svg";
import seasonal from "./seasonal.svg";
import solo from "./solo.svg";
import town from "./town.svg";

export default {
  couple,
  event,
  family,
  foodie,
  guysgals,
  location,
  outdoors,
  seasonal,
  solo,
  town,
};
