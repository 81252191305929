<template>
  <ul class="visit-checklist">
    <VisitStepRow
      :class="{
        'visit-checklist__visit-step': true,
        'visit-checklist__visit-step--hidden': visitStep.text.toLowerCase() === 'upcoming actions',
      }"
      v-for="(visitStep, index) in checklistRows"
      :key="visitStep.text"
      :allSteps="checklistRows"
      :index="index"
      :isExpandable="visitStep.isCompedActivitySection || visitStep.isLodgingSection || visitStep.isVisitPlanSection"
      :isExpanded="
        (visitStep.isLodgingSection && !props.lodgingDetailsCollapsed) ||
        (visitStep.isCompedActivitySection && !props.compDetailsCollapsed) ||
        (visitStep.isVisitPlanSection && !props.isVisitPlanCollapsed)
      "
      @toggle="
        () => {
          if (visitStep.isCompedActivitySection) emit('update:compDetailsCollapsed', !props.compDetailsCollapsed);
          if (visitStep.isLodgingSection) emit('update:lodgingDetailsCollapsed', !props.lodgingDetailsCollapsed);
          if (visitStep.isVisitPlanSection) emit('update:isVisitPlanCollapsed', !props.isVisitPlanCollapsed);
        }
      "
    >
      <!-- Lodging Section -->
      <div v-if="visitStep.isLodgingSection">
        <!--Mimic the logic in CreateCollab (to decide if they see the lodging step)-->
        <template v-if="collabRemainingCounts && collabRemainingCounts?.showLodgingStep !== false">
          <div style="margin-bottom: 10px">Lodging details here.</div>
          <!-- Note: Similar in CreateCollab.vue -->
          <div class="ui form field">
            <!--NOTE! Any changes here update StepLodging.vue also!-->
            <select class="timeRequired" ref="hotelCovered" v-model="props.collabInput.hotelNightsCovered" @change="nightsCoveredChanged()" style="width: 100%; margin-bottom: 10px">
              <!-- Note: These need to be numbers, and not strings -->
              <option v-if="collabRemainingCounts.mustCoverLodging === false" :value="0">{{ customerNotCoveringLodgingText }}</option>
              <!-- Show the 1 night option if it's been selected. This is temporary and will be removed when in-flight collabs using 1-night options are completed. -->
              <option v-if="props.collabInput.hotelNightsCovered === 1" :value="1">Can arrange a 1-night stay</option>
              <option :value="2">Can arrange a 2-night stay</option>
            </select>
          </div>

          <template v-if="props.collabInput.hotelNightsCovered > 0">
            <div class="ui input" style="width: 100%; margin-bottom: 10px">
              <input type="text" v-model="props.collabInput.hotelName" placeholder="Lodging name" />
            </div>

            <div class="ui input" style="width: 100%; margin-bottom: 10px">
              <input type="text" v-model="props.collabInput.hotelDateBooked" placeholder="Lodging dates" />
            </div>

            <!-- We let them save the above field but this doesn't make sense to set until a creator has been chosen -->
            <div v-if="props.creatorInput" class="lodging-checkbox-n-info" style="width: 175px">
              <SrpCheckbox class="lodging-checkbox-n-info__checkbox-component">
                <template #input>
                  <input type="checkbox" class="hidden" id="hotelIsBooked" v-model="props.collabInput.hotelIsBooked" />
                </template>
                <template #text>Lodging is coordinated</template>
              </SrpCheckbox>

              <InfoIconWithTooltip class="lodging-checkbox-n-info__tooltip-wrap"> Check this when the lodging has been fully arranged for the creator. </InfoIconWithTooltip>
            </div>
          </template>
        </template>

        <!-- Creator Hotel covered -->
        <div v-if="creatorCoveringHotel" class="field">
          <div><b>Creator's Lodging Details</b></div>
          <div v-if="props.creatorInput.hotelName && props.creatorInput.hotelName.length > 0">{{ props.creatorInput.hotelName }}</div>
          <div v-if="props.creatorInput.hotelDateBooked && props.creatorInput.hotelDateBooked.length > 0">{{ props.creatorInput.hotelDateBooked }}</div>
          <div v-else>Creator hasn't filled this out yet.</div>
        </div>
      </div>
      <!-- / Lodging Section -->

      <!-- Comped Activities Section -->
      <div v-else-if="visitStep.isCompedActivitySection">
        <div>Any activities you would like to assist with:</div>
        <CollabCompTags :collabInput="props.collabInput" />

        <!-- Only show the below if a tag is checked -->
        <div v-if="willCompActivities" class="ui form field">
          <textarea
            style="width: 100%; margin-top: 15px; margin-bottom: 15px"
            type="text"
            maxlength="1000"
            v-model="props.collabInput.bigTicketItemBookinNotes"
            placeholder="Ex. Meal at the Hungry Gator"
          ></textarea>
        </div>

        <!-- We let them save the above field but this doesn't make sense to set until a creator has been chosen -->
        <div v-if="props.creatorInput && willCompActivities" class="lodging-checkbox-n-info" style="width: 190px">
          <SrpCheckbox class="lodging-checkbox-n-info__checkbox-component">
            <template #input>
              <input type="checkbox" class="hidden" id="hotelIsBooked" v-model="props.collabInput.bigTicketItemsAllBooked" />
            </template>
            <template #text>Activities are coordinated</template>
          </SrpCheckbox>

          <InfoIconWithTooltip class="lodging-checkbox-n-info__tooltip-wrap">Check this when activities have been coordinated for the creator.</InfoIconWithTooltip>
        </div>
      </div>
      <!-- / Comped Activities Section -->

      <!-- Visit plan section -->
      <div v-else-if="visitStep.isVisitPlanSection">
        <!-- Plan status line -->
        <div v-if="!props.creatorInput?.visitPlanSentDate && !props.creatorInput?.visitPlanSignOffDate" class="plan-status-line" style="margin-bottom: 9px">
          <div class="plan-status-line__circle"></div>
          <div class="plan-status-line__text">Not yet received from the creator</div>
        </div>
        <!-- / Plan status line -->

        <!-- Plan status line -->
        <div v-else-if="props.creatorInput?.visitPlanSentDate && !props.creatorInput?.visitPlanSignOffDate" class="plan-status-line plan-status-line--orange" style="margin-bottom: 9px">
          <div class="plan-status-line__circle"></div>
          <div class="plan-status-line__text">Waiting to be reviewed</div>
        </div>
        <!-- / Plan status line -->

        <!-- Plan status line -->
        <div v-else-if="props.creatorInput?.visitPlanSignOffDate" class="plan-status-line plan-status-line--green" style="margin-bottom: 9px">
          <div class="plan-status-line__circle"></div>
          <div class="plan-status-line__text">Signed Off</div>
        </div>
        <!-- / Plan status line -->

        <template v-if="props.creatorInput?.visitPlanSentDate">
          <SrpButton v-if="props.creatorInput?.visitPlanSignOffDate" size="small" @click="isVisitPlanApprovalModalVisible = true"> View </SrpButton>
          <SrpButton v-else color="orange" size="small" @click="isVisitPlanApprovalModalVisible = true"> Review </SrpButton>
        </template>
      </div>
      <!-- / Visit plan section -->
    </VisitStepRow>

    <VisitPlanApprovalModal
      v-model:isVisible="isVisitPlanApprovalModalVisible"
      @connectWithTheCreator="
        () => {
          isVisitPlanApprovalModalVisible = false;
          emit('openChat');
        }
      "
      :planText="props.collabInput.useLocationsInVisitPlan ? collabLocationsStore.locationsTextRepresentation : props.creatorInput?.visitPlan"
      :signOffDate="props.creatorInput?.visitPlanSignOffDate"
      :communityId="props.communityId"
      :collabInputId="props.collabInput.id"
      :requestedLocationCount="props.collabInput?.requestedLocationCount"
      :requestedAdventureCount="props.collabInput?.requestedAdventureCount"
      @signOff="signOff"
      :creatorName="props.creatorName"
    />
  </ul>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "VisitCheckList" };</script>

<script setup lang="ts">
import axios from "axios";
import Logger from "@helpers/Logger";
import { ref, computed, onMounted, watch } from "vue";
import { RouteHelper } from "@helpers/RouteHelper";
import { useRoute } from "vue-router";

// Types
import { CollabChecklistRow } from "@contracts/collabChecklist";
import { CollabCreatorInput, CollabInput, CustomerCollabRemainingCounts } from "@contracts/collab";
import { CollabLocation, CollabLocationEmpty } from "@contracts/collabLocations";

// Components
import CollabCompTags from "@views/PaidCollab/CollabCompTags.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import TooltipWrapper from "@components/ui/TooltipWrapper.vue";
import VisitPlanApprovalModal from "@views/PaidCollab/CollabSummary/VisitCheckList/VisitPlanApprovalModal.vue";
import VisitStepRow from "./VisitStepRow.vue";

// Stores
import { useCollabLocationsStore } from "@stores/collabLocations";
const collabLocationsStore = useCollabLocationsStore();

const route = useRoute();

const props = withDefaults(
  defineProps<{
    checklistRows: Array<CollabChecklistRow>;
    lodgingDetailsCollapsed: boolean;
    collabInput: CollabInput | null;
    creatorInput: CollabCreatorInput | null;
    collabRemainingCounts: CustomerCollabRemainingCounts | null;
    adventuresAreCreated: boolean;
    compDetailsCollapsed: boolean;
    isVisitPlanCollapsed: boolean;
    calculateChecklist?: Function;
    communityId: string;
    creatorName: string;
  }>(),
  {
    checklistRows: () => [],
    lodgingDetailsCollapsed: false,
    collabInput: null,
    creatorInput: null,
    collabRemainingCounts: null,
    adventuresAreCreated: false,
    compDetailsCollapsed: false,
    calculateChecklist: () => {},
    communityId: "",
    creatorName: "",
  }
);

const emit = defineEmits<{
  (e: "update:lodgingDetailsCollapsed", value: boolean): void;
  (e: "update:compDetailsCollapsed", value: boolean): void;
  (e: "update:isVisitPlanCollapsed", value: boolean): void;
  (e: "save"): void;
  (e: "openChat"): void;
}>();

// ============================================================================
const creatorCoveringHotel = computed<boolean>(() => {
  return props.collabInput.hotelNightsCovered === 0 && props.creatorInput?.nightsRequired > 0;
});

const willCompActivities = computed<boolean>(() => {
  return props.collabInput?.compedActivitiesTags?.length > 0;
});

const customerNotCoveringLodgingText = computed<string>(() => {
  if (props.collabInput.hotelCoverageUsed === "Grandfathered") return "Can't arrange Lodging";
  else {
    return `Use Paid Lodging AddOn`;
  }
});

// Hotel Coverage ==============================================================
function nightsCoveredChanged() {
  Logger.info("StepLodging nightsCoveredChanged", props.collabInput.hotelCoverageUsed);

  // NOTE! Duplicated in StepLodging!
  if (props.collabInput.hotelCoverageUsed === "Grandfathered") {
    // Nothing to adjust in the old model
    return;
  }

  // Otherwise we update the the hotelCoverageUsed for tracking
  if (props.collabInput.hotelNightsCovered === 0) {
    props.collabInput.hotelCoverageUsed = "CustomerChosePaidAddOn";
  } else {
    props.collabInput.hotelCoverageUsed = "CustomerChoseToArrange";
  }
}

// SignOff ====================================================================

async function signOff() {
  // Reloading the creatorInput is problematic because we don't use stores. Therefore, it's easier to manually update the visitPlanSignOffDate field with a value that is almost identical to the one in the database
  // NOTE: Refactor creatorInput to utilize a centralized store and populate the 'visitPlanSignOffDate' field with the data returned from the endpoint to avoid property mutation.
  props.creatorInput.visitPlanSignOffDate = new Date().toISOString();
  props.calculateChecklist();
}

// Toggle Visit Plan modal ====================================================
const isVisitPlanApprovalModalVisible = ref(false);

watch(
  () => props.creatorInput,
  () => {
    checkForVisitPlanShow();
  }
);

function checkForVisitPlanShow() {
  if (RouteHelper.getHashParam("isReviewVisitPlanModalVisible") && props.creatorInput?.visitPlan && props.creatorInput?.visitPlanSentDate) {
    console.info("Auto-opening the visit plan modal");
    isVisitPlanApprovalModalVisible.value = true;
  }
}

// Load collab locations data =================================================
onMounted(async () => {
  if (props.collabInput?.id && props.creatorInput?.creatorId && props.communityId) {
    await collabLocationsStore.loadCollabLocationsData(props.collabInput.id, props.creatorInput?.creatorId, props.communityId);
  }
});

watch(
  () => [props.creatorInput, props.collabInput, props.communityId],
  async () => {
    await collabLocationsStore.loadCollabLocationsData(props.collabInput.id, props.creatorInput?.creatorId, props.communityId);
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Plan status line ===========================================================
.plan-status-line {
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: bold;

  &__circle {
    width: 7px;
    height: 7px;
    margin: -1px 5px 0 0;
    border-radius: 100px;
    background: #666666;
  }

  &--green {
    color: #3d8528;

    .plan-status-line__circle {
      background: #3d8528;
    }
  }
  &--orange {
    color: #bb9227;

    .plan-status-line__circle {
      background: #bb9227;
    }
  }

  &__text {
  }
}

// Info icon ==================================================================
.info-icon {
  min-width: 23px;
  min-height: 23px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    background: rgba(0, 0, 0, 0.1);
    transition:
      width 0.05s ease-in-out,
      height 0.05s ease-in-out,
      top 0.05s ease-in-out,
      left 0.05s ease-in-out;
  }

  &::after {
    content: "";
    width: calc(100% + 22px);
    height: calc(100% + 22px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &--expanded {
    &::before {
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      inset: -3px auto auto -3px;
    }
  }

  &__symbol {
  }
}

// Lodging checkbox & info ====================================================
.lodging-checkbox-n-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  &__checkbox-component {
  }

  &__tooltip-wrap {
    position: absolute;
    right: -35px;
    z-index: 4;
  }

  &__info-icon {
  }
}

// Visit checklist ============================================================
.visit-checklist {
  padding: 0;
  margin: 0;
  list-style: none;

  &__visit-step {
    width: calc(100% + 25px + 23px);
    margin-left: -25px;

    &--hidden {
      display: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .visit-checklist {
    &__visit-step {
      width: calc(100% + 25px + 22px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .visit-checklist {
    &__visit-step {
      width: calc(100% + 15px + 13px);
      margin-left: -14px;
    }
  }
}
</style>
