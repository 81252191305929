<template>
  <!--Note: Hide/show is owned by the parent since the buttons firing this live there-->
  <SrpModal :isVisible="visible" @close="close" :isClosable="false" className="email-modal-message" size="large">
    <template #header>
      <h2 class="global-h2">Contact Shrpa</h2>
      <span style="font-size: small"><CopyText :text="sendToEmail" /></span>
    </template>
    <template #content>
      <div v-if="!sentEmail">
        <textarea class="message-area" autocapitalize="sentences" placeholder="What can we do to help?" v-model="messageToSend"></textarea>
        <h5 v-if="emailMessageRequired" style="color: red">Required</h5>

        <div>Thanks for being part of Shrpa!</div>
      </div>
      <div v-else>
        <div class="ui small positive icon message">
          <i class="paper plane icon"></i>
          <div class="content">
            <div class="header header-margin-override">Email Sent</div>
            <p>A copy of this message was sent to your email.</p>
            <p>{{ sendToFirstName }} will reply to that email.</p>
          </div>
        </div>
      </div>
      <div class="progress-wrapper" style="padding: 30px !important; margin-top: 1rem" v-if="sendingEmail">
        <SrpProgressBar :progress="percentCompleted" />
      </div>
    </template>
    <template #footer>
      <h5 v-if="sendingEmail">Sending...</h5>
      <div v-if="!sentEmail">
        <SrpButton @click="close" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton class="ui primary button" :isDisabled="sendingEmail" @click="sendEmail()">Send Email</SrpButton>
      </div>
      <div v-else class="ui basic button" @click="close()">Close</div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { RouteHelper } from "@helpers/RouteHelper";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpProgressBar from "@components/ui/SrpProgressBar.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

// email to CC if the email is not already going to shrpa
const shrpaCCEmailAddress = "collabs@shrpa.com";

export default defineComponent({
  name: "ContactUser",

  components: {
    SrpButton,
    SrpProgressBar,
    SrpModal,
    CopyText,
  },

  props: {
    visible: { type: Boolean, required: true },
    sendToFirstName: { type: String, required: true },
    sendToEmail: { type: String, required: true },
    sendToPhone: { type: String, required: false },
    sendToImageUri: { type: String, required: true },
    subject: { type: String, required: true },
    messagePrefix: { type: String, required: true },
    isCommunityIcon: { type: Boolean, default: false },
  },

  data() {
    return {
      sendingEmail: false,
      emailMessageRequired: false,
      messageToSend: null as string | null,
      sentEmail: false,
      percentCompleted: 0,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile"]),
  },

  methods: {
    close() {
      this.$emit("close");
      setTimeout(() => {
        this.sentEmail = false;
      }, 1000);
    },
    async sendEmail() {
      if (!this.messageToSend || this.messageToSend.trim().length < 10) {
        this.emailMessageRequired = true;
        return;
      }
      this.emailMessageRequired = false;
      this.sendingEmail = true;
      this.percentCompleted = 0;
      // Send the email
      var subject = encodeURIComponent(this.subject);
      var toEmail = encodeURIComponent(this.sendToEmail);
      var messagePostfix = "";
      var unEncodedMessage = `${this.messagePrefix}:\n\n${this.messageToSend}\n\n\n${messagePostfix}`;
      var encodedMessage = JSON.stringify(unEncodedMessage);
      // @ts-ignore
      let currentUserEmailEncoded = encodeURIComponent(this.getActingUserProfile?.email);
      // Always CC the current user (since the email comes from Shrpa with the sending user's email CCed)
      let ccEmail = currentUserEmailEncoded;
      // Set the replyTo to the current user so they get the reply (even if they forget to Reply All)
      let replyTo = currentUserEmailEncoded;

      // This controller currently sends from collabs@shrpa.com
      const config = {
        headers: { "Content-Type": "application/json" },
        onUploadProgress: (progressEvent: any) => {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      };
      var skipSend = false;
      if (RouteHelper.getQueryStringOrHashParam("nosend") === "true") {
        skipSend = true;
      }
      if (!skipSend) {
        var uri = `${import.meta.env.VITE_API_URL}/contact-user?toEmail=${toEmail}&subject=${subject}&ccEmail=${ccEmail}`;
        if (replyTo) uri += `&replyTo=${replyTo}`;
        await axios.post(uri, encodedMessage, config);
      }

      this.percentCompleted = 100;
      this.messageToSend = null;
      this.sendingEmail = false;
      this.sentEmail = true;
      // They manually close the UI now that we have a confirmation message
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.email-modal-message {
  .message-area {
    margin-bottom: 1rem;
  }
  .info-message {
    float: right;
    text-align: right;
    width: 100%;
    color: $gray;
  }
  .action-buttons {
    margin-top: 3rem;
  }
  .content {
    position: relative;
    .progress-wrapper {
      position: absolute;
      top: 0;
      bottom: -5rem;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .ui.progress {
        margin-bottom: 5rem;
      }
    }
  }

  /* Some pages apply a broad header margin, which we don't want here (ex. featured-expeditions) */
  .header-margin-override {
    margin-top: 0;
  }
}

.creator-info-wrapper {
  display: flex;
  align-items: center;
}
</style>
