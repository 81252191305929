import { CreatorPublicProfileFields } from "./creatorPublicProfileFields";
import { IconName } from "@contracts/IconName";

export interface Subscription {
  instanceId: string; // guid
  id: string | null; // ex. weekender-2023
  name: string | null;
  startDate: string | null;
  endDate: string | null;
  paidCollabCount: number; // Count just for this subscription (total is now on the Customer object)
  lodgingAddOnCount: number; // Used when editing
  paidLodgingAddon: boolean; // Used for creating (the server has two different objects here...)
  wasMigrated: boolean;
  hasPaid: boolean;
  coopProgram: string | null;
}

export interface CollabDates {
  preferredDateRangeStart: string | null;
  preferredDateRangeEnd: string | null;
  changeReason: string | null;
}

export interface CollabValueFields {
  hotelNightsCovered: number | null;
  hotelEstimatedValue: number;
  compedActivitiesTags: Array<string>;
  compedActivitiesEstimatedValue: number;
  requestingBigTicketItems: boolean | null;
  collabThemes: string;
}

export interface CustomerCollabRemainingCounts {
  totalPaidCollabs: number;
  showLodgingStep: boolean | null;
  mustCoverLodging: boolean | null;
  allRemaininingHavePaidCoverage: boolean | null;
  collabHotelsGrandfathered: number;
  collabHotelsPaidRemaining: number;
  collabInputIdInContext: string;
}

export interface CollabDateChangeProposal extends CollabDates {
  wasProposedByTheCreator: boolean;
  // Dates are inherited
  latestAcceptDeclineDateTime: string | null;
  declineReason: string | null;
}

export interface CollabDateChangeResponsePayload {
  proposedDateChange: CollabDateChangeProposal;
  creatorInput: CollabCreatorInput;
}

const CollabCompTags: Array<{
  text: string;
  icon: IconName;
  key: "meal" | "activity" | "cash" | "discount" | "other";
}> = [
  { text: "Food/Drink", icon: "meal_2", key: "meal" },
  { text: "Activity", icon: "ticket_2", key: "activity" },
  { text: "Spending Cash", icon: "dollar_2", key: "cash" },
  { text: "Discount", icon: "discount_2", key: "discount" },
  { text: "Other", icon: "gift_2", key: "other" },
];

// Not currently enforced but we push them to take action
const CollabInviteExpirationInDays = 7; // Server side in v3InviteCreatorsReminderInDaysAfterPosted
const DaysAfterCreatorsDoneApplyingCommunityToChoose = 3; // Loosely tied to email reminders like v3ChooseCreatorsReminderInDaysAfterPosted2
const DaysCreatorHasToCreateAdventures = 10;
const DaysCommunityHasToReviewAdventures = 10; // Server side in markCompleteAfterShrpaApprovalInDays

export default {
  CollabCompTags,
  CollabInviteExpirationInDays,
  DaysAfterCreatorsDoneApplyingCommunityToChoose,
  DaysCreatorHasToCreateAdventures,
  DaysCommunityHasToReviewAdventures,
};

// NOTE: Update ThemeTypeHelper if these change!
export const themeTypes = Object.freeze(["couple", "event", "family", "foodie", "guysgals", "location", "outdoors", "seasonal", "town"] as const);
export type ThemeType = null | (typeof themeTypes)[number];

export type CollabHotelCoverage = "Unset" | "PaidAddOn" | "Grandfathered" | "CustomerChosePaidAddOn" | "CustomerChoseToArrange" | /* "CustomerNeedsToChoose" | just use null */ null;

export interface CollabInput extends CollabDates, CollabValueFields {
  version: number; // For transitioning to the new Collab Automation flow

  customerId: string | null;
  id: string | null;
  name: string | null;
  // Note: Is only set after the creator has been chosen
  contactEmail: string | null;

  useLocationsInVisitPlan: boolean;
  useLocationsInAdventures: boolean;

  // Dates are on CollabDates
  // Text nuance about the specified date range
  preferredTiming: string | null;
  proposedDateChange: CollabDateChangeProposal;

  themes: string | null;
  themeType: ThemeType;
  contentFocus: string | null;
  contentUsage: string | null;
  excludedAttractions: string | null;
  destinationContactName: string | null;
  destinationPhoneNumber: string | null;
  hotelCoverageUsed: CollabHotelCoverage;
  hotelNightsCovered: number | null;
  hotelName: string | null;
  hotelDateBooked: string | null;
  hotelIsBooked: boolean;
  hotelEstimatedValue: number;
  compedActivitiesTags: Array<string>;
  bigTicketItemBookinNotes: string | null;
  bigTicketItemsAllBooked: boolean;
  compedActivitiesEstimatedValue: number;
  notesForCreator: string | null;
  creatorPerspective: string | null;
  gearToBring: string | null;
  requestedAdventureCount: number;
  requestedLocationCount: number;

  suggestedAttractions: string | null;
  requiredAttractions: string | null;
  requestingBigTicketItems: boolean | null;

  multipleCreatorsChosen: boolean;
  clonedFromCollabInputId: string | null;

  shrpaIsChoosingCreators: boolean;
  // If the user requested a reminder since the collab is far enough in the future
  reminderForFarOutRequested: boolean;
  // OLD
  businesses: string | null;
  bigTicketItems: string | null;

  // V3
  readyToPost: string | null;
  postedToCreators: string | null;
  manualCreatorSelection: string | null;

  isConcierge: boolean;

  areaBoundaries: string | null;
  referenceMaterial: string | null;

  correctionsForCreator: string | null;
  correctionsStatus: string;
  // Audit fields
  createdDate: string;
  createdBy: string;
  updatedDate: string | null;
  updatedBy: string | null;

  collaboration: CollabInstance;

  feedback: CollabFeedback | null;
  creatorSelectionFeedback: CollabCreatorSelectionFeedback | null;

  remindersSent: Array<string>;
}

export interface CollabInstance {
  id: string | null;
  actualTiming: string | null;
  status: string | null;
  creatorId: string | null;
  creatorChosenDate: string | null;
  notifications: CollabNotifications | null;
  messageThreadId: string | null;
  creatorsAccepted?: number;
  creatorsDeclined?: number;

  manualWatch: string | null;
  overrideStatusUntil: string | null;
  firstCollabCheckin: string | null;
  firstCollabCheckinConfirmed: boolean;
  firstTimeCreatorCheckin: string | null;
  firstTimeCreatorCheckinConfirmed: boolean;

  // Deprecated creatorInputs: Array<CollabCreatorInput>

  completedDate: string | null;
  homepageEligible: boolean;

  // Only returned by backend endpoints
  adminOnly: CollabAdminOnlyFields;
}

export interface CollabAdminOnlyFields {
  collabCost: number;
  hotelCost: number;
  otherCost: number;
  otherDetails: string | null;
  creatorWasPaid: boolean;
  manualIntervention: boolean;
  creatorRating: number | null;
  collabRating: number | null;
}

export interface CollabNotifications {
  shrpaApprovedCount: number;
  firstApproveNotification: string | null;
  lastApproveNotification: string | null;
  lastApproveNotificationToCreator: string | null;
}

export interface VisitPlanFields {
  visitPlan: string | null;
  visitPlanSentDate: string | null;
  visitPlanSignOffDate: string | null;
}

export interface CollabCreatorInput extends VisitPlanFields {
  creatorId: string;
  collabInputId: string | null;
  invitedDateTime: string | null;
  acceptedDateTime: string | null;
  declinedDateTime: string | null;

  proposedVisitDateTime: string | null;
  numberOfDaysStaying: number;
  travelingWith: string | null;
  stayingOverNight: boolean;
  nightsRequired: number;
  hotelName: string | null;
  hotelDateBooked: string | null;
  hotelIsBooked: boolean;
  canBringNeededGear: boolean | null;
  gearNeeded: string | null;
  notesForCommunity: string | null;
  notesForShrpa: string | null;
  declineReason: string | null;

  manuallyInvited: boolean;
  createdOn: string | null;

  creatorConfirmedWillWork: boolean | null;
  confirmedOrNotDateTime: string | null;

  highlightAssetIds: Array<string> | null;

  rank?: number | null;
}

// Used by the Creator Dashboard
export interface CreatorCollab {
  communityId: string;
  communityName: string;
  communityIcon: string;
  collabInput: CollabInput;
  creatorInput: CollabCreatorInput;
  creatorCurrentRate: number;
  otherPaymentAmount: number;
  otherPaymentDetails: string | null;
  creatorCreditCount: number;
  creatorPhoneNumber: string | null;
  unreadMessages: number;
  creditCostToApply?: number;
  showFirstCollabGuidance?: boolean;
  inRange: boolean;
}

export interface CreatorCollabMatch {
  creatorId: string;
  name: string;
  email: string;
  tileImageLocation: string;
  imageIsExternal: boolean;
  proximityInMiles: number;
  difference: number;
  // creatorPreferences
  recentAdventures: Array<any>;
  featuredMedia: string[];
  collabCreatorInput: CollabCreatorInput;
  profileImageUri: string;
}

export interface CollabAssignedCreator extends CreatorPublicProfileFields {
  email: string;
  phoneNumber: string;
  fullName: string;
}

export interface CollabFeedback {
  photoRating: number;
  writingRating: number;
  creatorRating: number;
  overallRating: number;
  feedbackForCreator: string | null;
  feedbackForShrpa: string | null;
  submittedDate: string | null;
}

export interface CollabCreatorSelectionFeedback {
  numberOfCreators: number;
  qualityOfCreators: number;
  additionalDetails: string | null;
}

export interface CreatorCollabSummary {
  collabInputId: string;
  collabName: string;
  communityId: string;
  communityName: string;
  communityIcon: string;
  collabDate: string;
  // Corrections requested by the community
  correctionsForCreator: string;
  correctionsStatus: string;
  completedDate: string;
  creatorName: string;
  creatorProfileImage: string;
  coverImage: string;
  useLocationsInAdventures: boolean;
  useLocationsInVisitPlan: boolean;
}

export interface ChooseCreatorInputPayload {
  creatorSelectionFeedback: CollabCreatorSelectionFeedback;

  // Destination can review and optionally update these fields
  themes: string;
  contentFocus: string;
  contentUsage: string;

  // Set for the first time by the destination--
  requestedLocationCount: number;
  requestedAdventureCount: number;

  destinationContactName: string;
  destinationPhoneNumber: string;
}

export interface CollabAdminSummary {
  communityId: string;
  communityName: string;
  contactEmail: string | null;
  adventureCount: number;
  subscription: Subscription;
  collaborationInput: CollabInput;
  collaboration: CollabInstance;
  chosenCreatorInput: CollabCreatorInput;
  collabNumber: number | null;
  creatorConfirmedWillWork: boolean;

  creatorName: string | null;
  creatorEmail: string | null;
  creatorFullName: string | null;
  creatorProfileImageUri: string | null;
  firstCollabGuidanceCompleteTimeSec: number | null;

  totalPhotoCount: number;
  totalVideoCount: number;
  uniquePhotoCount: number;
  uniqueVideoCount: number;
  totalStopCount: number;
  locationsVisitedWithPlaceId: Array<string>;
  locationsVisitedNoPlaceId: Array<string>;
  locationsByName: Array<string>;
}

export interface CollabAndCreatorSummary {
  collabInputId: string;
  collabName: string;
  visitDate?: string | null;
  completedDate?: string | null;
  activeOrRecentlyCompleted: boolean;

  creatorId: string;
  creatorName: string;
  creatorProfileImage: string;

  coverImage?: string | null;
  unreadMessageCount?: number | null;
}
