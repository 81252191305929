<template>
  <span class="video-wrap" :style="containerStyles()">
    <!--Note: Setting data-height/width since the iframe for fancybox video isn't smart enough to auto-size vertical videos on mobile
        The % doesn't actually seem to work, but it forces it to full screen, which is basically what we want-->
    <a
      v-if="showViewerOnClick"
      lang="en"
      hreflang="en"
      :data-fancybox="`gallery${galleryPostfix}`"
      data-height="100%"
      data-width="100%"
      :href="`${contentBaseUri}/cms/videos/${videoFileName}`"
      style="margin: 0; display: flex"
    >
      <!--All other cases after the initial upload-->
      <img v-if="videoFailedToLoad" src="/images/video-working.png" :style="customStyles" style="width: 100%; height: 100%" @load="$emit('imgOnLoad')" />
      <img
        v-else
        :class="customClasses"
        :style="customStyles"
        style="width: 100%; height: 100%; margin-bottom: 0"
        :src="`${contentBaseUri}${getThumbnailPathFromVideo(videoId)}`"
        @error="videoError($event)"
        @load="
          $event => {
            videoLoaded($event);
            $emit('imgOnLoad');
          }
        "
      />
      <div class="video-wrap__progress-label">{{ progressLabel }}</div>
      <SrpProgressBar v-if="videoFailedToLoad" :size="'small'" :progress="progressPercent" class="progress-wrap" />
      <div v-else class="play-icon-wrap">
        <i class="icon play circle outline"></i>
      </div>
    </a>

    <template v-else-if="!showControls">
      <i v-if="!videoFailedToLoad" class="video small circular inverted icon videoIcon"></i>
      <!--All other cases after the initial upload-->
      <img v-if="videoFailedToLoad" src="/images/video-working.png" :style="customStyles" style="width: 100%; height: 100%" @load="$emit('imgOnLoad')" />
      <img
        v-else
        :class="customClasses"
        :style="customStyles"
        :src="`${contentBaseUri}${getThumbnailPathFromVideo(videoId)}`"
        @error="videoError($event)"
        @load="
          $event => {
            videoLoaded($event);
            $emit('imgOnLoad');
          }
        "
      />
      <div class="video-wrap__progress-label">{{ progressLabel }}</div>
      <SrpProgressBar v-if="videoFailedToLoad" :size="'small'" :progress="progressPercent" class="progress-wrap" />
    </template>

    <template v-else>
      <!--Note: This isn't used in many places now-->
      <!--NOTE: Removed crossorigin="anonymous" since we were hitting CDN caching issues with that. -->
      <video
        width="100%"
        preload="metadata"
        :class="customClasses"
        :style="customStyles"
        style="width: 100%"
        playsinline
        muted
        :controls="showControls"
        controlslist="nodownload noplaybackrate"
        disablepictureinpicture="true"
        poster="/images/video-working.png"
        @error="videoError($event)"
        @load="videoLoaded($event)"
      >
        <!--Note: Using the poster attribute to convey that it's processing.-->
        <!--NOTE: The #t=0.001 is a hack to force Safari mobile to show the preview (otherwise it's just blank with a play button even though we set preload="metadata"-->
        <source :src="`${contentBaseUri}/cms/videos/${videoFileName}#t=0.001`" />
      </video>
      <div class="video-wrap__progress-label">{{ progressLabel }}</div>
      <SrpProgressBar v-if="videoFailedToLoad" :size="'small'" :progress="progressPercent" class="progress-wrap" />
    </template>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { inject } from "vue";
import SrpProgressBar from "@components/ui/SrpProgressBar.vue";

// This now handles both Upload and rendering flows (it used to be separate components but there was a ton of overlap).
const VideoThumbnailExtension = ".jpg";
const VideoQuality = "-mid";

export default defineComponent({
  name: "VideoRender",

  components: { SrpProgressBar },

  props: {
    videoId: { type: String, required: true },
    customClasses: { type: String, default: "" },
    customStyles: { type: String, default: "" },
    customContainerStyles: { type: String, default: "" },
    showControls: { type: Boolean, default: true },
    showViewerOnClick: { type: Boolean, default: false },
    showProgressBarOnUpload: { type: Boolean, default: false },
    cropThumbnail: { type: Boolean, default: false },
    // If we should show the thumbnail or the full-size image
    showFullSizeImage: { type: Boolean, default: false },
    galleryPostfix: { type: String, default: "" },
  },

  emits: ["imgOnLoad"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // Note! The CDN takes a bit to propogate these larger files (5 minutes is common) so for Preview to work (and the photos page for recent uploads)
      // we need to go direct to blob storage or add a way of knowing how long it's been since the last video was uploaded...
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentBlobBaseUri,

      // How long bewteen reties to load videos that were recently uploaded (ie. can't be resolved yet because they're being encoded)
      secondsBetweenVideoReload: 15,
      videoFailedToLoad: false,
      progressInterval: null,
      progressPercent: 0,
      progressLabel: "Uploading...",
    };
  },

  computed: {
    videoFileName(): string {
      // Note: Used to try to render the uncompressed video but was too flakey so cut that code
      let dotIndex = this.videoId.lastIndexOf(".");
      return this.videoId.substring(0, dotIndex) + VideoQuality + this.videoId.substring(dotIndex);
    },
  },

  async mounted() {
    this.startProgressBar();
  },

  methods: {
    videoLoaded(event) {
      let element = event.target;
      // this.globalLog.info("VideoRender: Loaded " + element?.src);
      this.videoFailedToLoad = false;
      if (this.progressInterval) clearInterval(this.progressInterval);
    },
    videoError(event) {
      this.startProgressBar();
      // Note: We fire this for both image and video elements
      this.videoFailedToLoad = true; // This causes the image to try to reload
      // This event fires when a video doesn't resolve, which should be because we're not done encoding it yet.
      // This will reload it periodically to see if it's done.
      let videoElement = event.target;
      // this.globalLog.warn("Video did not resolve: " + videoElement?.src);
      setTimeout(() => {
        this.globalLog.warn("Reloading Video: " + videoElement?.src);
        // This method only exists on the video element (not img)
        videoElement.load?.();
        // Flip it back in case it doesn't fail this time (so this event won't get fired)
        this.videoFailedToLoad = false;
      }, this.secondsBetweenVideoReload * 1000);
    },
    startProgressBar() {
      if (!this.showProgressBarOnUpload) return;
      if (this.progressInterval) return;
      // On average takes ~40 seconds to process
      this.progressInterval = setInterval(() => {
        if (this.progressPercent >= 100) {
          clearInterval(this.progressInterval);
          return;
        }

        // Currently every second bumps 2% so 50 seconds worth
        this.progressPercent += 2;
        if (this.progressPercent > 90) this.progressLabel = "Finishing up...";
        else if (this.progressPercent > 20) this.progressLabel = "Encoding...";
      }, 1000);
    },
    getThumbnailPathFromVideo(videoId: string): string {
      // Expect all the videos to end in .mp4 and during encoding we generate the thumbnails (like we do for adventure image uploads)
      let subPath = `${this.cropThumbnail ? "thumbnails" : "thumbnocrop"}/`;
      if (this.showFullSizeImage) subPath = "";
      let path = `/cms/images/expeditions/${subPath}`;
      return path + videoId.substr(0, videoId.lastIndexOf(".")) + VideoThumbnailExtension;
    },
    containerStyles(): string {
      // This keeps the play button vertically centered, but messes with the margin if present on the PhotoWall (where we show inline controls)
      if (this.showViewerOnClick || !this.showControls) {
        return "display: inline-block; " + this.customContainerStyles;
      }
      return this.customContainerStyles;
    },
  },
});
</script>

<style scoped lang="scss">
.video-wrap {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex !important;

  .play-icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    .icon {
      color: #fff;
      font-size: 3rem;
      line-height: 3.2rem;
    }
  }

  .progress-wrap {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 10px;
    z-index: 9;
  }

  .videoIcon {
    font-size: 14px !important;
    z-index: 20;
    position: absolute;
    left: 6px;
    top: 6px;
  }

  &__progress-label {
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: -1;
  }
}
</style>
