<template>
  <div v-if="adventureLists">
    <div v-if="allowEditMode" class="ui container edit-lists-section">
      <div v-if="isEditMode" class="list-option dropdown">
        <select v-model="listIdToAddTo" @change="addList($event)">
          <option disabled value="">{{ notAddedLists.length > 0 ? "Pick a List to add to the page" : "All Lists have been added" }}</option>
          <option v-for="item in notAddedLists" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
        <div id="add-list-desc">
          Add to your lists with
          <router-link :to="{ name: 'Search' }" class="link-underline">Search</router-link> or the <router-link :to="{ name: 'Map' }" class="link-underline">Map</router-link>
          <!--<a :href="`/search?partner=${customerId}`" class="link-underline" target="_blank">Search</a> or the <a :href="`/map?partner=${customerId}`" class="link-underline" target="_blank">Map</a>-->
        </div>
      </div>
      <div v-if="isUpdating"><Loader /></div>
      <div v-if="isEditMode" class="list-option">
        <button class="ui small primary button" @click="save()">Save Changes</button>
        <button class="ui small button" @click="cancelEdit()">Cancel</button>
      </div>
      <button v-else class="ui small basic button list-option" @click="enableEditMode()">Edit Lists</button>
    </div>
    <div v-else class="edit-lists-section"><!--div for spacing--></div>

    <div class="ui container list-section" v-for="(list, index) in adventureLists" :key="list.id">
      <!--Readonly-->
      <template v-if="!isEditMode">
        <h1 class="ui header list-name">
          {{ list.name }}
        </h1>
        <div class="ui four doubling raised link cards adventure-cards">
          <ItineraryTile
            v-for="itinerary in listIdsToViewAll.indexOf(list.id) !== -1 ? list.adventures : list.adventures.slice(0, adventuresToShowPerList)"
            :itinerary="itinerary"
            :allowShortImage="false"
            :source="'hotelpage'"
            :key="itinerary.id"
          />
        </div>
        <div v-if="list.adventures.length > adventuresToShowPerList" class="ui stackable center aligned grid container" style="margin-top: 1.75rem">
          <button class="ui icon button no-border" @click="toggleViewAllAdventures(list.id)">
            <i v-if="listIdsToViewAll.indexOf(list.id) === -1" class="icon angle down big"></i>
            <i v-else class="icon angle up big"></i>
          </button>
        </div>
      </template>

      <!--Editable-->
      <template v-else>
        <div class="edit-list-name">
          <input class="ui input text-field" type="text" v-model="list.name" />
        </div>
        <div class="edit-list">
          <button class="ui tiny basic red button" @click="askToRemoveList(index)">Remove List</button>
          <div class="order-section-btns" v-if="adventureLists.length > 1">
            <i :class="{ disabled: index === 0 }" class="icon angle up" @click="moveListUp(index)"></i>
            <i :class="{ disabled: index === adventureLists.length - 1 }" class="icon angle down" @click="moveListDown(index)"></i>
          </div>
        </div>
        <h4 class="ui header">Drag and drop adventures to reorder</h4>
        <DropList :items="list.adventures" class="ui four doubling raised link cards adventure-cards" @reorder="onReorder($event, list.adventures)">
          <template #item="{ item }">
            <Drag class="ui card drag" :key="item.id">
              <ItineraryTile
                :styles="'margin: 0; box-shadow: none;'"
                :itinerary="item"
                :allowShortImage="false"
                :disableLink="true"
                :showRemoveButton="true"
                :source="null"
                @removeItinerary="removeAdventureFromList(list.id, item.id)"
              />
            </Drag>
          </template>
          <template #feedback></template>
        </DropList>
      </template>
    </div>

    <!--Remove List Modal-->
    <SrpModal v-model:isVisible="showRemoveListModal">
      <template #header><h2 class="global-h2">Remove this list?</h2></template>
      <template #content>
        <div style="margin: 10px 20px">
          This operation removes the list from your page.<br />
          <div class="ui checkbox" style="margin-top: 10px">
            <input type="checkbox" v-model="shouldHardDeleteList" />
            <label>Permanently delete this list (if you'll never need it again)</label>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelRemoveList()">Cancel</div>
        <div class="ui red button" @click="removeList()">Yep, remove it.</div>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Drag, DropList } from "vue-easy-dnd";
import Loader from "@components/Loader/Loader.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import ListRepo from "@logic/ListRepo";
import AdminContext from "@logic/AdminContext";
import { AdventureList } from "@contracts/lists";
import { PageAdventureList } from "@contracts/pages";
import SrpModal from "@components/ui/SrpModal.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

/* Provides a way to manage "lists" that the user has created.
   Ex. Add/Remove both lists themselves and ordering the adventures within those lists.
*/

// configureCompat({
//   COMPONENT_V_MODEL: false,
//   RENDER_FUNCTION: false,
// });

export default defineComponent({
  name: "ListManager",

  components: {
    SrpModal,
    Drag,
    DropList,
    ItineraryTile,
    Loader,
  },

  props: {
    customerId: { type: String },
    adventureLists: { type: Array as () => Array<PageAdventureList> | null, default: null },
  },

  emits: ["isInEditMode", "reset", "saved"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // How many adventures to show before using the expand/collapse ui
      adventuresToShowPerList: 8,
      isUpdating: false,
      isEditMode: false,
      notAddedLists: null as Array<AdventureList>,
      // Note: Using the @change event for the add but this to reset the selection
      listIdToAddTo: "" as string,
      // Context for the remove modal
      showRemoveListModal: false,
      listIndexInContextToRemove: -1,
      shouldHardDeleteList: false,
      // Passed to the serer on save
      listIdsToPerminentlyDelete: [] as Array<string>,
      listIdsToViewAll: [] as Array<string>,
    };
  },

  async mounted() {},

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile"]),
    allowEditMode(): boolean {
      return AdminContext.isAdminOf(this.customerId, this.getActingUserProfile, false);
    },
  },

  methods: {
    onReorder(event, adventureList) {
      const element = adventureList[event.from];
      adventureList.splice(event.from, 1);
      adventureList.splice(event.to, 0, element);
    },
    async enableEditMode() {
      if (!this.notAddedLists) {
        await this.loadLists();
      }
      this.isInEditMode(true);
    },
    isInEditMode(isInEditMode: boolean) {
      this.$emit("isInEditMode", isInEditMode);
      this.isEditMode = isInEditMode;
    },
    async cancelEdit() {
      // force re-load from the server
      this.isUpdating = true;
      // Tell the parent to reset the list data
      this.$emit("reset");
      await this.loadLists();
      this.listIdsToPerminentlyDelete = [];
      this.isUpdating = false;
      this.isInEditMode(false);
    },
    async loadLists() {
      this.isUpdating = true;
      var allLists = await ListRepo.getAllHomepageLists(this.customerId);
      // Remove lists that are already shown
      this.notAddedLists = allLists.filter(l => this.adventureLists.findIndex(a => a.id === l.id) === -1);
      this.isUpdating = false;
    },
    async addList(event) {
      this.isUpdating = true;
      const listId = event.target.value;
      this.globalLog.info(`Loading Summary of List to ${listId} for ${this.customerId}`);
      var summary = await ListRepo.loadAdventureSummaryForList(this.customerId, listId);
      this.adventureLists.unshift(summary);
      var indexToRemoveFromNotAdded = this.notAddedLists.findIndex(l => l.id === listId);
      this.notAddedLists.splice(indexToRemoveFromNotAdded, 1);
      this.isUpdating = false;
      this.listIdToAddTo = "";
    },
    askToRemoveList(index: number) {
      this.listIndexInContextToRemove = index;
      this.showRemoveListModal = true;
    },
    cancelRemoveList() {
      this.showRemoveListModal = false;
      this.listIndexInContextToRemove = -1;
      this.shouldHardDeleteList = false;
    },
    removeList() {
      const index = this.listIndexInContextToRemove;
      var list = this.adventureLists[index];

      if (this.shouldHardDeleteList) {
        this.listIdsToPerminentlyDelete.push(list.id);
      } else {
        // Re-add it to the dropdown
        this.notAddedLists.push(list);
      }

      // Remove it from the homepage list
      this.adventureLists.splice(index, 1);

      // resets the context fields
      this.cancelRemoveList();
    },
    removeAdventureFromList(listId: string, adventureId: string) {
      this.globalLog.info(`Removing ${adventureId} from list ${listId}`);
      var listIndex = this.adventureLists.findIndex(l => l.id === listId);
      var list = this.adventureLists[listIndex];
      var adventureIndex = list.adventures.findIndex(a => a.id === adventureId);
      list.adventures.splice(adventureIndex, 1);
    },
    moveListDown(fromIndex: number) {
      var toIndex = fromIndex + 1;
      var element = this.adventureLists[fromIndex];
      this.adventureLists.splice(fromIndex, 1);
      this.adventureLists.splice(toIndex, 0, element);
    },
    moveListUp(fromIndex: number) {
      var toIndex = fromIndex - 1;
      var element = this.adventureLists[fromIndex];
      this.adventureLists.splice(fromIndex, 1);
      this.adventureLists.splice(toIndex, 0, element);
    },
    async save() {
      this.isUpdating = true;
      this.globalLog.info(`Saving Homepage Lists! for ${this.customerId}`);
      await ListRepo.updateHomepageLists(this.customerId, this.adventureLists, this.listIdsToPerminentlyDelete);
      this.isUpdating = false;

      this.$emit("saved");
      this.isInEditMode(false);
    },
    toggleViewAllAdventures(listId: string) {
      if (this.listIdsToViewAll.indexOf(listId) === -1) {
        this.listIdsToViewAll.push(listId);
      } else {
        const index = this.listIdsToViewAll.indexOf(listId);
        if (index > -1) {
          this.listIdsToViewAll.splice(index, 1);
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
.heroLogo {
  display: block;
  float: left;
  margin-left: 75px;
  margin-top: -28px;
  @media screen and (max-width: 420px) {
    margin-left: 25px;
    margin-top: -18px;
  }

  img {
    max-width: 250px;
    @media screen and (max-width: 420px) {
      max-width: 120px;
    }
  }
}
.list-section {
  position: relative;
  margin-bottom: 85px;
  .edit-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }
  h1 {
    text-align: center;
  }
}
.list-option {
  &.dropdown {
    font-size: 0.92857143rem;
    margin: 0 0.25em 0 0;
    width: 25%;
  }
}
.edit-lists-section {
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.link-underline {
  cursor: pointer;
  text-decoration: underline;
}
#add-list-desc {
  margin-top: 5px;
}
.list-name {
  text-align: center;
}
.edit-list-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.ui.input.text-field {
  width: 25%;
  font-weight: 500;
}
.order-section-btns {
  margin-left: 1.5rem;
  i {
    display: block;
    color: #058587;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.ui.card.drag {
  position: relative;
}
</style>
