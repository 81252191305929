<template>
  <div class="what-to-share">
    <ul class="what-to-share__snippets-list">
      <!-- Snippet -->
      <li
        v-for="postType in shareStopSummaryStore.postTypesList"
        :key="postType.title"
        :class="{
          snippet: true,
          'snippet--selected': postType.id === shareStopSummaryStore.editedPost.contextType,
          'what-to-share__snippet': true,
        }"
      >
        <label class="snippet__label-wrap">
          <input class="snippet__radio" type="radio" name="whatToShare" :value="postType.id" v-model="shareStopSummaryStore.editedPost.contextType" />

          <span class="snippet__text-side">
            <span class="global-h3 snippet__title">{{ postType.title }}</span>
            <span class="snippet__description">{{ postType.description }}</span>
          </span>
        </label>
      </li>
      <!-- / Snippet -->
    </ul>

    <SrpButton class="what-to-share__next-button" @click="loadDataAndSwitchToNext" color="orange" :isDisabled="!shareStopSummaryStore.editedPost.contextType || isLoading">
      Next
      <template #iconRight>
        <IconEmbedded v-if="isLoading" name="loader_3-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
        <IconEmbedded v-else name="arrow-bottom_2-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
      </template>
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

// Components
import SrpButton from "@components/ui/SrpButton.vue";

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
  (e: "update"): void;
}>();

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
const shareStopSummaryStore = useShareStopSummaryStore();
import { useCollabSummariesStore } from "@stores/collabSummaries";
const collabSummariesStore = useCollabSummariesStore();
import { useItinerariesStore } from "@stores/itineraries";
const itinerariesStore = useItinerariesStore();
import { useLocationsStore } from "@stores/locations";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
const locationsStore = useLocationsStore();

// Crutch which informs the StepsWizard about the slot names update ===========
watch(
  () => shareStopSummaryStore.editedPost.contextType,
  () => emit("update")
);

// Load needed data and switch to next slide ==================================
const isLoading = ref(false);
async function loadDataAndSwitchToNext(): Promise<void> {
  const pageId = String(route.params.pageId);
  const postTypeId = shareStopSummaryStore.editedPost.contextType;

  isLoading.value = true;

  if (postTypeId === "collabInput") await collabSummariesStore.loadAllCollabSummaries(pageId);
  else if (postTypeId === "adventure") await itinerariesStore.loadAllItineraries(pageId);
  else if (postTypeId === "location") await locationsStore.loadAllLocations(pageId);

  isLoading.value = false;

  emit("goToNextSlide");
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Snippet ====================================================================
.snippet {
  display: flex;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.06);
  transition: background-color 0.07s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &--selected {
    outline: 3px #118689 solid;
    outline-offset: -3px;
    background: white !important;
    transition: none;
  }

  &--selected &__label-wrap {
    cursor: default;
  }

  &__label-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 21px 19px 20px;
    display: flex;
    cursor: pointer;
    user-select: none;
  }

  &__radio {
    width: 15px;
    min-width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
  }

  &__text-side {
    display: block;
  }

  &__title {
    margin: -6px 0 4px;
    display: block;
    font-weight: 600;
  }

  &__description {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-family: Arial, sans-serif;
  }
}

// What to share ==============================================================
.what-to-share {
  &__snippets-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__snippet {
    width: calc(33.3% - 18px);
    margin: 0 27px 27px 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__next-button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .what-to-share {
    &__snippet {
      width: calc(33.3% - 16px);
      margin: 0 24px 24px 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .what-to-share {
    &__snippet {
      width: calc(33.3% - 15px);
      margin: 0 22px 22px 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .what-to-share {
    &__snippet {
      width: 100%;
      margin: 0 0 18px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .what-to-share {
    &__snippet {
      width: 100%;
      margin: 0 0 17px 0;
    }
  }
}
</style>
