<template>
  <div class="spoiler-section">
    <!-- Delegate Creator Selection modal -->
    <SrpModal v-model:isVisible="showDelegateCreatorSelection">
      <template #header><h2 class="global-h2">You want Shrpa to choose a creator for this visit?</h2></template>
      <template #footer>
        <SrpButton @click="cancelModals" fill="outlined" color="gray" style="margin-right: 12px">No, I'd like to select</SrpButton>
        <SrpButton @click="delegateCreatorSelectionConfirmed(true)" color="orange">Yes, choose a creator for me</SrpButton>
      </template>
    </SrpModal>
    <!-- / Delegate Creator Selection modal -->

    <SrpDetailsSummary class="spoiler-section__details-summary" v-model:is-opened="isOpened" :transition-duration="150">
      <template #heading="{ isOpened }">
        <div
          :class="{
            'global-h3': true,
            'spoiler-section__summary': true,
            'spoiler-section__summary--with-bottom-padding': !isOpened,
          }"
        >
          <span id="creatorSelectionHeading"
            ><span>
              {{ collabInput.shrpaIsChoosingCreators ? "Creator Selection" : "Select Potential Creators" }}
            </span></span
          >
        </div>
      </template>

      <template #details>
        <div class="spoiler-section__content">
          <NoteWithIcon v-if="collabInput.shrpaIsChoosingCreators" color="green" style="margin-bottom: 22px">
            <template #icon><IconEmbedded name="bulb_2-5" /></template>
            <template #text>
              <div>
                <h5 class="global-h5" style="margin-bottom: 3px">Shrpa is lining up a creator for you!</h5>
                <span>We'll reach out soon. If you have any questions just let us know! <CopyText :text="'collabs@shrpa.com'" /></span>
              </div>
            </template>
          </NoteWithIcon>

          <template v-else>
            <NoteWithIcon color="gray" style="margin-bottom: 15px">
              <template #icon><IconEmbedded name="bulb_2-5" /></template>
              <template #text>
                <div>
                  <h5 class="global-h5">Below are potential creators you can invite for this visit.</h5>
                  Once invited, creators can accept or decline, and from there you can finalize the creator you want to work with.
                </div>
              </template>
            </NoteWithIcon>

            <div style="margin-bottom: 22px">
              <SrpButton @click="shrpaSelectCreator" fill="outlined" color="gray" size="small">Have Shrpa select my Creator!</SrpButton>
              <!--<div v-if="collabInput">{{collabInput.id}}</div>-->
              <br />
              <h4 v-if="matches?.length > 8">Showing {{ matches.length }} of {{ totalMatches }}</h4>
            </div>

            <template v-if="additionalCreators">
              <ul class="spoiler-section__creator-snippets-list" style="padding: 0; margin: 0; list-style: none">
                <PaidCreatorSummary
                  class="spoiler-section__creator-snippet"
                  v-for="creator in additionalCreators"
                  :key="creator.creatorId"
                  :creator="creator"
                  @selectCreatorToggle="selectCreatorToggle"
                  :isManualInviteFlow="true"
                  :customerId="communityId"
                  :collabInputId="collabInput.id"
                  :communityCanCoverLodging="collabInput.hotelNightsCovered > 0"
                />
              </ul>
            </template>
          </template>

          <!-- Manage buttons panel -->
          <div
            :class="{
              'manage-buttons-panel': true,
              'manage-buttons-panel--stuck': isButtonsPanelStuck,
              'spoiler-section__manage-buttons-panel': true,
              'spoiler-section__manage-buttons-panel--stuck': isButtonsPanelStuck,
            }"
          >
            <div class="manage-buttons-panel__left-side">
              <SrpButton v-if="hasMoreCreatorsToLoad" :is-disabled="isLoading" @click="loadMoreCreators" size="small" color="gray" style="margin-right: 12px">
                {{ isLoading ? "Loading..." : "Load more" }}
              </SrpButton>
              <SrpButton v-if="matches?.length > 8" @click="backToTop" color="gray" fill="outlined" size="small" style="margin-right: 12px">Back to top</SrpButton>

              <div v-if="isOpened && !collabInput.shrpaIsChoosingCreators" style="font-size: 16px">
                <b>({{ selectedCreatorCount }} selected, {{ howManyCreatorsLeftToSelect > 0 ? `up to ${howManyCreatorsLeftToSelect}` : "none" }} remaining)</b>
              </div>
            </div>
            <div class="manage-buttons-panel__right-side">
              <SrpButton :is-disabled="nextEnabled" @click="next">Next</SrpButton>
            </div>
          </div>
          <!-- / Manage buttons panel -->

          <div ref="intersectionDetectionTarget"></div>
        </div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import CollabStatuses from "@logic/CollabStatuses";
import { defineComponent, inject } from "vue";

// Types
import { CollabInput, CreatorCollabMatch, CollabCreatorInput } from "@contracts/collab";

// Components
import CopyText from "@components/CopyText.vue";
import PaidCreatorSummary from "@components/PaidCreatorSummary.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpModal from "@components/ui/SrpModal.vue";

export default defineComponent({
  name: "CreatorSelection",

  components: {
    CopyText,
    PaidCreatorSummary,
    SrpDetailsSummary,
    SrpModal,
  },

  props: {
    communityId: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput, required: true },
    collabIndex: { type: Number, required: true },
  },

  emits: ["creatorsSelected"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      minimumCreatorsToShow: 3,
      maxCreatorInvites: 10,
      // Max requested from the server (allows simple paging of sorts)
      maxRequested: 20,
      // Total possible matches on the server
      totalMatches: 0,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      isOpened: true,
      matches: null as Array<CreatorCollabMatch> | null,
      additionalCreators: null,
      isLoading: false,
      hasMoreCreatorsToLoad: false,
      selectedCreatorCount: 0,
      showDelegateCreatorSelection: false,

      intersectionObserver: null,
      isButtonsPanelStuck: false,
    };
  },

  computed: {
    nextEnabled(): boolean {
      return this.selectedCreatorCount === 0;
    },
    howManyCreatorsLeftToSelect(): number {
      // Max minus selected but excluding anyone who's declined
      let declineCount = 0; // ignoring this now since we don't have the invites in this component... let declineCount = this.collabInput.collaboration.creatorInputs.filter(i => i.declinedDateTime).length;
      return this.maxCreatorInvites - (this.selectedCreatorCount - declineCount);
    },
  },

  watch: {
    // Resets the state since they changed collabs
    collabIndex: function (newVal, oldVal) {
      this.reset();
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    isOpened() {
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
  },

  async mounted() {
    this.reset();

    await this.setIntersectionObservers();
  },

  beforeUnmount() {
    this.intersectionObserver.disconnect();
  },

  methods: {
    async setIntersectionObservers(): Promise<void> {
      // Detect whether button panels are stuck (is "sticky" position applied) to move it a bit higher to the top to avoid overlapping with mobile bottom navigation panel
      this.intersectionObserver = new IntersectionObserver(
        ([e]) => (this.isButtonsPanelStuck = e.intersectionRatio === 0 && (this.$refs.intersectionDetectionTarget as HTMLDivElement)?.getBoundingClientRect().top > 0),
        { threshold: 0.1 }
      );

      await this.$nextTick();
      if (this.$refs.intersectionDetectionTarget) {
        this.intersectionObserver.observe(this.$refs.intersectionDetectionTarget as Element);
      }
    },
    reset() {
      this.setDefaultIsOpened();
      this.selectedCreatorCount = 0;
      this.load(false);
    },
    async load(isLoadMoreOperation: boolean) {
      // Shortcut if a creator is already chosen
      if (this.collabInput.collaboration.creatorId) return;

      this.isLoading = true;
      let uri = `${import.meta.env.VITE_API_URL}/community/${this.communityId}/collab/${this.collabInput.id}/creatormatch-v2?returnCount=${this.maxRequested}`;
      let response = await axios.get(uri);
      this.isLoading = false;
      if (response.status != 200) {
        this.globalLog.error("Failed to load creators: " + response.status + " ");
        return;
      }
      // If we don't have enough choices, delegate selection to Shrpa
      // NOTE! This fires fairly early in the flow (depends on how the calling code v-if is structured)
      if (!isLoadMoreOperation && response.data.length < this.minimumCreatorsToShow) {
        await this.delegateCreatorSelectionConfirmed(false);
        return;
      }

      this.matches = response.data?.creatorMatches || null;
      // We now split the creators up by section
      // this.prioritizedCreators = this.matches.filter(m => m.isPrioritized === true);
      this.additionalCreators = this.matches;
      this.totalMatches = response.data.totalMatches;
      this.hasMoreCreatorsToLoad = this.matches.length < this.totalMatches;
      this.selectedCreatorCount = this.matches.filter(m => m.collabCreatorInput != null).length;
    },
    async loadMoreCreators() {
      // This blows away the newly selectedCreators that haven't been saved so here's a workaround
      let selectedCreatorIds = this.matches.filter(m => m.collabCreatorInput && !m.collabCreatorInput.createdOn).map(m => m.creatorId);
      // Load more
      this.maxRequested = this.maxRequested + 20;
      await this.load(true);
      // Restore the selectedCreators
      this.additionalCreators.forEach(creator => {
        if (selectedCreatorIds.includes(creator.creatorId)) this.selectCreatorToggle(creator);
      });
    },
    backToTop() {
      var creatorSelectionElement = document.getElementById("creatorSelectionHeading");
      if (creatorSelectionElement) creatorSelectionElement.scrollIntoView({ behavior: "smooth" });
    },
    async next() {
      if (this.selectedCreatorCount === 0) {
        this.globalLog.warn("Must select a creator."); // Next button disable logic shouldn't allow this to happen
        return;
      }

      // Note: The user reviews their selections on the next screen and sends invites at that point
      // Find teh creators that were selected but haven't been saved
      let selectedCreatorIds = this.matches.filter(m => m.collabCreatorInput && !m.collabCreatorInput.createdOn).map(m => m.creatorId);
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/invites?creatorIds=${selectedCreatorIds.join(",")}&sendInvites=false`;
      await axios.put(uri);
      // Fire an event so the Invited section re-loads the data
      this.$emit("creatorsSelected");
      this.isOpened = false;
    },
    selectCreatorToggle(creator: CreatorCollabMatch) {
      // Toggle the state
      if (!creator.collabCreatorInput) {
        creator.collabCreatorInput = {
          creatorId: creator.creatorId,
          manuallyInvited: true,
          invitedDateTime: null,
        } as CollabCreatorInput;
        this.selectedCreatorCount++;
      } else {
        creator.collabCreatorInput = null;
        this.selectedCreatorCount--;
      }
    },
    shrpaSelectCreator() {
      this.showDelegateCreatorSelection = true;
    },
    cancelModals() {
      this.showDelegateCreatorSelection = false;
    },
    async delegateCreatorSelectionConfirmed(userInitiated: boolean) {
      this.globalLog.warn(`Delegating CreatorSelection! CollabInputId=${this.collabInput.id} UserInitiated=${userInitiated}`);
      await axios.post(`${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/delegate-creator-selection?isUserInitiated=${userInitiated}`);
      // Manually mark what the server just did and close the modal
      this.collabInput.shrpaIsChoosingCreators = true;
      this.cancelModals();
    },
    setDefaultIsOpened() {
      this.globalLog.info(`CreatorSelection: defaultIsOpened. CollabInputId=${this.collabInput.id} Status=${this.collabInput.collaboration.status}`);
      // We used to do this check which could work but we'd need to do it after the data loads: if(this.selectedCreators.length > 0) {
      // Now just collapsing this once they've invited creators
      this.isOpened = !(+this.collabInput.collaboration.status >= +CollabStatuses.CreatorsInvitedStatusId);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./AllCollabsV2.scss";

.ui.form {
  .adventure-heading {
    display: inline;
  }
}
.top-links {
  height: 5em;
}

.large-accordion-dropdown {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative !important;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: "";
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    height: 30px;
    margin: 0;
    border-bottom: 1px transparent solid;
    display: inline-block;
    transition:
      border-color 0.12s ease-in-out,
      opacity 0.12s ease-in-out;
  }

  &:hover h2 {
    border-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
</script>
