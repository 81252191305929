<template>
  <SrpModal :isVisible="isVisible" @close="$emit('close')" :isClosable="false">
    <template #content>
      <div text-align="center" id="modal">
        <img class="shrpa-logo" src="https://cdn.shrpa.com/images/shrpa-full-color.png" width="150px" height="auto" alt="Shrpa logo" />
        <div id="copyableSection">
          <br />
          <div>
            <h3>Help create awesome adventures for our community!</h3>
            <br />
            <div>Shrpa adventures are a great way to find, create, and share day trip ideas and inspiration.</div>
            <a style="margin-top: 1em" class="ui small orange primary button" :href="inviteLink()">Get Started!</a>
            <div style="margin-top: 1em">Have questions? Click <a style="text-decoration: underline" :href="inviteLink()">here</a> to find out more. <br />It's super easy!</div>
          </div>
        </div>
        <div style="margin-top: 2em; margin-bottom: 35px; border-top: 1px solid rgba(0, 0, 0, 0.15)">
          <button style="margin-top: 1em" class="ui primary button" @click="copy">Copy Email Text</button>
          <div v-if="showCopiedMessage">Copied to your clipboard</div>
        </div>
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MetricSender } from "@helpers/MetricSender";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";

export default defineComponent({
  name: "LoginModal",

  components: { SrpModal },

  props: {
    pageId: { type: String, required: true },
    isVisible: { type: Boolean, required: true },
  },

  emits: ["close"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      showCopiedMessage: false,
    };
  },

  methods: {
    inviteLink() {
      const inviteLink = `${window.location.origin}/redirect?joincommunity=${this.pageId}`;
      return inviteLink;
    },
    copy() {
      this.globalLog.info("Copied example email to clipboard");
      this.copyElementToClipboard("copyableSection");

      this.showCopiedMessage = true;
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, 3000);
    },
    // https://stackoverflow.com/questions/34191780/javascript-copy-string-to-clipboard-as-text-html
    copyElementToClipboard(element) {
      window.getSelection().removeAllRanges();
      let range = document.createRange();
      range.selectNode(typeof element === "string" ? document.getElementById(element) : element);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
  },
});
</script>

<style scoped lang="scss">
#modal {
  display: block;
  position: relative;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
.shrpa-logo {
  width: 120px;
  height: 50px;
}
</style>
