<template>
  <div>
    <div class="invited-users">
      <div class="ui segment" v-if="page.unusedInvites.length == 0 && page.users.length == 0">
        {{ page.communityCreators.length > 0 ? "All your invites have been accepted!" : "None yet, try inviting someone!" }}
      </div>
      <div class="ui segment" v-if="page.unusedInvites.length > 0">
        <div class="ui stackable three column grid" v-if="page.unusedInvites.length > 0">
          <div class="column" v-for="invite in unusedInvitesToShow" :key="invite.email">
            <div class="invite-email">{{ invite.email }}</div>
            <div style="margin-bottom: 5px">Invited {{ moment(invite.invitedOn).fromNow() }}</div>
            <button @click="sendInvite(invite)" class="ui basic mini blue button">Resend Invite</button>
          </div>
        </div>

        <div v-if="showEmailMessage" style="margin-top: 10px" class="desc">{{ emailJustSent ? "Email Sent!" : "This user recently received and invite." }}</div>

        <div v-if="page.unusedInvites.length > defaultUnusedInvitesToShow" @click="showAllUnusedInvites = !showAllUnusedInvites" class="ui tiny primary button" style="margin-top: 10px">
          {{ showAllUnusedInvites ? "Show Fewer" : "Show All" }}
        </div>
      </div>
    </div>

    <div class="ui segment" v-if="page.users.length > 0">
      <div v-for="user in page.users" :key="user.sherpaId" class="ui flex user-content">
        <!--<div style="flex-shrink: 0;" lang="en" hreflang="en"
        :to="{ name: 'CreatorProfilePublic', params: { creatorId: user.uriKey}}">-->
        <img class="ui centered circular tiny image" style="height: 55px !important" :src="user.profileImageUri" />

        <div class="user-info">
          <h3 class="ellipsis" style="margin: 0">{{ user.firstName }}</h3>
          <div v-if="user.hasAdventuresInAnyStatus" class="desc">Created a draft adventure!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PageDetailsAdmin, PageInvite } from "@contracts/pages";
import InviteRepo from "@repos/InviteRepo";
import moment from "moment";

export default defineComponent({
  name: "InvitedUsers",

  props: {
    page: { type: Object as () => PageDetailsAdmin, default: null },
  },

  emits: ["resendInvite"],

  data() {
    return {
      showEmailMessage: false,
      // Whether the email was sent or we wouldn't let them
      emailJustSent: true,
      // Simple logic to prevent them from repeatedly re-sending
      lastEmailSentTo: null,
      defaultUnusedInvitesToShow: 9,
      // Hide some of the unused invites by default if they invited a lot of people
      showAllUnusedInvites: false,
    };
  },

  computed: {
    unusedInvitesToShow(): Array<PageInvite> {
      if (this.showAllUnusedInvites) {
        return this.page.unusedInvites;
      } else {
        return this.page.unusedInvites.slice(0, this.defaultUnusedInvitesToShow);
      }
    },
  },

  methods: {
    moment,
    async sendInvite(invite: PageInvite) {
      this.showEmailMessage = true;
      this.emailJustSent = this.lastEmailSentTo !== invite.email;
      // Emit the actual invite event so it gets sent
      if (this.emailJustSent) {
        await InviteRepo.inviteIndividualUser(this.page.page.id, invite.email, true, null, this.page.page.guidance, this.page.page.useGlobalGuidance);
        this.$emit("resendInvite", invite.email, false, null); // No optionalNote for resend
        this.lastEmailSentTo = invite.email;
        // Update invitedOn
        invite.invitedOn = new Date().toISOString();
      }

      this.showEmailMessage = false;
    },
  },
});
</script>

<style scoped lang="scss">
// .invited-users {
//   .invites {
//     display: flex;
//     flex-wrap: wrap;
//     .user-item {
//       flex: 1;
//       font-size: 12px;
//       margin-bottom: 15px;
//       color: black;
//       &:last-child {
//         margin-bottom: 0;
//       }
//     }
//   }
// }

.invite-email {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.user-content {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  .user-info {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
  }
}

.desc {
  font-size: 12px;
  font-weight: bold;
  color: #058587;
}
</style>
