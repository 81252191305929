<template>
  <label>
    <slot name="label">
      {{ label }}
    </slot>
    <input v-maska:[maskOptions]="maskDetails" type="tel" placeholder="+1 (555) 555-1234" v-model="maskedPhoneNumber" />
  </label>
</template>

<script setup lang="ts">
import { MaskOptions, MaskaDetail, vMaska } from "maska";
import { reactive, ref, watchEffect } from "vue";

const props = defineProps<{ modelValue: string; label?: string }>();
const emit = defineEmits<{ (e: "update:modelValue", value: string): void }>();
defineSlots<{ label: void }>();

const maskDetails = reactive<MaskaDetail>({ completed: false, masked: "", unmasked: "" });
const maskOptions = reactive<MaskOptions>({
  mask: "+1 (###) ###-####",
});
const maskedPhoneNumber = ref(props.modelValue);
watchEffect(() => {
  emit("update:modelValue", maskDetails.unmasked);
});
</script>
