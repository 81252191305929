<template>
  <div class="spoiler-section">
    <ChooseCreatorModal
      :isVisible="showChooseMultipleCreatorsModal"
      :selectedCreatorIds="selectedCreatorIds"
      :collabsRemaining="collabsRemaining"
      :chooseCreatorIsSaving="chooseCreatorIsSaving"
      :communityId="communityId"
      :collabInput="collabInput"
      @confirm="chooseMultipleCreators"
      @cancel="cancelModals"
      @close="showChooseMultipleCreatorsModal = false"
    />

    <SrpDetailsSummary class="spoiler-section__details-summary" v-model:is-opened="isOpened" :transition-duration="150">
      <template #heading="{ isOpened }">
        <div
          :style="{
            width: '100%',
            paddingBottom: isOpened ? 0 : '15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }"
          class="global-h3 spoiler-section__summary"
        >
          <span><span>Creators</span></span>
          <span v-if="isSuperOrSalesUser === true" class="rank-creators-link" @click.stop="() => (isCreatorsRankingModalVisible = true)"> rank creators (super) </span>
        </div>

        <CreatorsRankingModal v-model:isVisible="isCreatorsRankingModalVisible" :allCreators="invitedCreatorsClient" :customerId="communityId" :collabInputId="collabInput.id" @save="load" />
      </template>

      <template #details>
        <div class="spoiler-section__content">
          <NoteWithIcon v-if="selectedCreatorIds.length > 1" color="green" style="margin-bottom: 15px">
            <template #icon><IconEmbedded name="info-simple_4" :size="26" /></template>
            <template #text>
              <div>
                <div class="global-h4">You have selected multiple creators</div>
                <div>A separate creator visit will be created for each creator chosen. You will use a creator visit for each (so choosing 2 creators will use 2 creator visits)</div>
              </div>
            </template>
          </NoteWithIcon>

          <!--No creators applied yet-->
          <div v-if="invitedCreatorsClient?.length === 0" class="spoiler-section__notifications-list">
            <div class="spoiler-section__notifications-divider"></div>
            <!--Normal "posted" flow-->
            <template v-if="collabInput.postedToCreators || collabInput.readyToPost">
              <!--Creators still have time to apply-->
              <NoteWithIcon v-if="daysUntilCommunityIsNotified > 0" color="green">
                <template #icon><IconEmbedded name="info-simple_4" /></template>
                <template #text>
                  <div>
                    <h4 class="global-h4" style="margin-bottom: 3px">Hang tight while we send your details to creators!</h4>
                    <span>
                      These creators will review your visit details and put their application together.<br />
                      We will send you an email in {{ daysUntilCommunityIsNotified }} day{{ daysUntilCommunityIsNotified > 1 ? "s" : "" }} to come back and choose your preferred creator for the visit!
                    </span>
                  </div>
                </template>
              </NoteWithIcon>
              <!-- / Creators still have time to apply-->

              <!--Time is up and no creators applied-->
              <NoteWithIcon v-else color="green" style="margin-bottom: 15px">
                <template #icon><IconEmbedded name="info-simple_4" /></template>
                <template #text>
                  <h4 class="global-h4">We're working to line up creators for your visit!</h4>
                </template>
              </NoteWithIcon>
              <!-- / Time is up and no creators applied-->
            </template>
            <!-- / Normal "posted" flow-->

            <!--Manual Invite flow-->
            <NoteWithIcon v-else color="green">
              <template #icon><IconEmbedded name="info-simple_4" /></template>
              <template #text>
                <h4 class="global-h4">Please select some creators</h4>
              </template>
            </NoteWithIcon>
            <!-- / Manual Invite flow-->
          </div>
          <!-- / No creators applied yet-->

          <!--Old Manual Invites flow-->
          <div v-else-if="manuallyInvitedCount > 0" class="spoiler-section__notifications-list">
            <div class="spoiler-section__notifications-divider"></div>

            <NoteWithIcon color="green">
              <template #icon><IconEmbedded name="info-simple_4" /></template>
              <template #text>
                <h4 class="global-h4">
                  {{
                    manuallyInvitedCount === invitedCreatorsClient?.length ? "Please review your choices. Ready to send out the invites?" : "Some of the selected creators haven't been invited yet."
                  }}
                </h4>
              </template>
            </NoteWithIcon>

            <SrpButton style="margin-bottom: 20px" @click="sendInvites">Send Invites!</SrpButton>

            <h4 v-if="inviteStatusMessage" style="margin: 0">{{ inviteStatusMessage }}</h4>
          </div>
          <!-- / Old Manual Invites flow-->

          <!--Creators applied/accepted-->
          <div v-else class="spoiler-section__notifications-list">
            <div class="spoiler-section__notifications-divider"></div>

            <!-- Creators applied in Normal "posted" flow -->
            <template v-if="collabInput.postedToCreators || collabInput.readyToPost">
              <!-- Creators applied but more can still apply -->
              <template v-if="daysUntilCommunityIsNotified > 0">
                <NoteWithIcon color="blue" style="margin-bottom: 15px">
                  <template #icon><IconEmbedded name="bulb_2-5" /></template>
                  <template #text>
                    <div>
                      <h5 class="global-h5">We've sent this visit to creators in range.</h5>
                      <div>
                        Creators still have {{ daysUntilCommunityIsNotified }} day{{ daysUntilCommunityIsNotified > 1 ? "s" : "" }} to apply but you can choose a creator at any point if you like.
                      </div>
                    </div>
                  </template>
                </NoteWithIcon>
              </template>
              <!-- / Creators applied but more can still apply -->

              <!-- Community should choose a creator -->
              <NoteWithIcon v-else color="blue" style="margin-bottom: 13px">
                <template #icon><img style="width: 65%; height: 65%" src="https://cdn.shrpa.com/images/misc/high-five.png" /></template>
                <template #text>
                  <div v-if="areCreatorsRanked">
                    <h4 class="global-h4">Creators Applied and Ranked!</h4>
                    <div>
                      Please select a creator (or multiple!) for this visit.<br />
                      We have ranked the applicants to help you identify the best fit for your needs.
                    </div>
                  </div>
                  <div v-else-if="atLeastOneCreatorAccepted">
                    <h4 class="global-h4">Creators Applied!</h4>
                    <div>Please choose a creator (or multiple!) for this visit.</div>
                  </div>
                </template>
              </NoteWithIcon>
              <!-- / Community should choose a creator -->
            </template>
            <!-- / Creators applied in Normal "posted" flow -->

            <!-- Manual Invite flow -->
            <template v-else>
              <NoteWithIcon color="gray" style="margin-bottom: 15px">
                <template #icon><IconEmbedded name="bulb_2-5" /></template>
                <template #text>
                  As creators accept your invite, you can finalize which creator(s) you want to work with.<br />
                  You are able to select a creator to work with even if all creators haven't responded to your invitation. Creators will have {{ daysCreatorHasToCreateAdventures }} days to accept your
                  invitation and those who are not chosen will receive an email update.
                </template>
              </NoteWithIcon>

              <NoteWithIcon color="green" style="margin-bottom: 15px">
                <template #icon><img src="https://cdn.shrpa.com/images/misc/high-five.png" style="width: 24px; height: 24px" alt="Hands" /></template>
                <template #text>
                  <div>
                    <h4 class="global-h4">
                      {{ atLeastOneCreatorAccepted ? "Creators Accepted!" : "Invites sent!" }}
                    </h4>
                    <span>
                      {{ atLeastOneCreatorAccepted ? "Once you're ready, please choose a creator for this visit." : "We'll let you know as creators accept." }}
                    </span>
                  </div>
                </template>
              </NoteWithIcon>
            </template>
            <!-- / Manual Invite flow -->
          </div>
          <!-- /Creators applied-->

          <ul v-if="invitedCreatorsClient?.length" class="spoiler-section__creator-snippets-list">
            <PaidCreatorSummary
              class="spoiler-section__creator-snippet"
              v-for="creator in invitedCreatorsClient"
              :key="`creators-${creator.creatorId}`"
              :creator="creator"
              @removeCreator="removeCreator(creator.creatorId)"
              :isManualInviteFlow="false"
              :customerId="communityId"
              :collabInputId="collabInput.id"
              :communityCanCoverLodging="collabInput.hotelNightsCovered > 0"
            >
              <input type="checkbox" :value="creator.creatorId" v-model="selectedCreatorIds" style="width: 20px" />
            </PaidCreatorSummary>
          </ul>

          <div
            :style="{
              paddingBottom: selectedCreatorIds.length > 0 ? '25px' : '0',
              marginBottom: selectedCreatorIds.length > 0 ? '25px' : 0,
              borderBottom: selectedCreatorIds.length > 0 ? '1px rgba(0,0,0,0.15) solid' : 'none',
            }"
          >
            Have any questions or issues with a creator? Contact us anytime! <b><CopyText :text="'collabs@shrpa.com'" /></b>
          </div>

          <!-- Manage buttons panel -->
          <div
            v-if="selectedCreatorIds.length > 0"
            :class="{
              'manage-buttons-panel': true,
              'manage-buttons-panel--stuck': isButtonsPanelStuck,
              'spoiler-section__manage-buttons-panel': true,
              'spoiler-section__manage-buttons-panel--stuck': isButtonsPanelStuck,
            }"
          >
            <div class="manage-buttons-panel__left-side"></div>

            <div class="manage-buttons-panel__right-side">
              <SrpButton
                :size="screenSize === 'mobile' ? 'small' : 'normal'"
                class="manage-buttons-panel__button"
                isBiggerSidePaddings
                :isDisabled="isSaving"
                @click="showChooseMultipleCreatorsModal = true"
              >
                Finalize Choice
              </SrpButton>
            </div>
          </div>
          <!-- / Manage buttons panel -->

          <div ref="intersectionDetectionTarget"></div>
        </div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import CollabStatuses from "@logic/CollabStatuses";
import DateUtils from "@logic/DateUtils";
import { defineComponent, inject } from "vue";
import { mapState, mapStores } from "pinia";

// Types
import CollabConsts, { CollabCreatorInput, CollabInput, CollabCreatorSelectionFeedback, CreatorCollabMatch, ChooseCreatorInputPayload } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Stores
import { useInvitedCreatorsStore } from "@stores/invitedCreators";
import { useUserProfileStore } from "@stores/userProfileStore";

// Components
import ChooseCreatorModal from "./ChooseCreatorModal/index.vue";
import CopyText from "@components/CopyText.vue";
import CreatorsRankingModal from "./CreatorsRankingModal.vue";
import Loader from "@components/Loader/Loader.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import PaidCreatorSummary from "@components/PaidCreatorSummary.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CreatorInvites",

  components: {
    IconEmbedded,
    ChooseCreatorModal,
    CopyText,
    CreatorsRankingModal,
    Loader,
    NoteWithIcon,
    PaidCreatorSummary,
    SrpButton,
    SrpDetailsSummary,
    SrpModal,
  },

  props: {
    communityId: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput, required: true },
    collabIndex: { type: Number, required: true },
    reloadTrigger: { type: Number, required: false },
    collabsRemaining: { type: Number, required: true },
  },

  emits: ["selectMultipleCreators"],

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      daysToApplyAfterPosted: CollabConsts.CollabInviteExpirationInDays,
      daysCreatorHasToCreateAdventures: CollabConsts.DaysCreatorHasToCreateAdventures,

      isSaving: false,
      showRequired: false,
      // Full invite with creator details
      invitedCreatorsClient: null as Array<CreatorCollabMatch>,
      inviteStatusMessage: null as string,

      showChooseMultipleCreatorsModal: false,
      chooseCreatorIsSaving: false,
      creatorIdInContext: null as string,
      creatorNameInContext: null as string,

      isOpened: true,

      isButtonsPanel2Stuck: false,

      selectedCreatorIds: [],

      intersectionObserver: null,
      isButtonsPanelStuck: false,

      isCreatorsRankingModalVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    ...mapStores(useInvitedCreatorsStore),
    manuallyInvitedCount(): number {
      return this.invitedCreatorsClient?.filter(i => i.collabCreatorInput.manuallyInvited && !i.collabCreatorInput.invitedDateTime).length ?? 0;
    },
    atLeastOneCreatorAccepted(): boolean {
      return this.invitedCreatorsClient?.some(i => i.collabCreatorInput.acceptedDateTime) === true;
    },
    areCreatorsRanked(): boolean {
      return this.invitedCreatorsClient?.some(i => i.collabCreatorInput.rank) === true;
    },
    // Only applies for application flow currently
    daysUntilCommunityIsNotified(): number {
      if (!this.collabInput?.postedToCreators) return -1;

      let postedDate = new Date(this.collabInput.postedToCreators);
      let notifyDate = DateUtils.addDays(postedDate, this.daysToApplyAfterPosted);
      let daysBetween = DateUtils.daysBetween(new Date(), notifyDate);
      var roundedDays = Math.round(daysBetween);
      return roundedDays;
    },
  },

  watch: {
    // Resets the state since they changed collabs
    collabIndex: function (newVal, oldVal) {
      this.setDefaultIsOpened();
      this.load();
      this.inviteStatusMessage = null;
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    isOpened() {
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    reloadTrigger: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.load();
      }
    },
  },

  async mounted() {
    this.setDefaultIsOpened();
    await this.setIntersectionObservers();
    await this.load();
  },

  beforeUnmount() {
    this.intersectionObserver.disconnect();
  },

  methods: {
    async setIntersectionObservers(): Promise<void> {
      // Detect whether button panels are stuck (is "sticky" position applied) to move it a bit higher to the top to avoid overlapping with mobile bottom navigation panel
      this.intersectionObserver = new IntersectionObserver(
        ([e]) => (this.isButtonsPanelStuck = e.intersectionRatio === 0 && (this.$refs.intersectionDetectionTarget as HTMLDivElement)?.getBoundingClientRect().top > 0),
        { threshold: 0.1 }
      );
      await this.$nextTick();
      if (this.$refs.intersectionDetectionTarget) {
        this.intersectionObserver.observe(this.$refs.intersectionDetectionTarget as Element);
      }
    },
    async load() {
      // Shortcut if a creator is already chosen
      if (this.collabInput.collaboration.creatorId) return;
      // Null this out so the loading indicators fires
      this.invitedCreatorsClient = null;
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/invites`;
      const { data } = await axios.get(uri);
      this.invitedCreatorsClient = data;
      this.invitedCreatorsStore.addCreatorsList(this.communityId, this.collabInput.id, data);
    },
    async sendInvites() {
      this.inviteStatusMessage = "Sending...";
      var creatorIdsToInvite = this.invitedCreatorsClient.filter(i => i.collabCreatorInput.manuallyInvited && !i.collabCreatorInput.invitedDateTime).map(i => i.collabCreatorInput.creatorId);
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/invites?creatorIds=${creatorIdsToInvite.join(",")}&sendInvites=true`;
      await axios.put(uri);
      var nowIsoString = new Date().toISOString();
      // Mimicing what the server does here
      this.invitedCreatorsClient.forEach(i => {
        if (i.collabCreatorInput.manuallyInvited) i.collabCreatorInput.invitedDateTime = nowIsoString;
      });
      this.inviteStatusMessage = "Invites Sent!";
    },
    setDefaultIsOpened() {
      // Note: Could we just remove this since it's always expanded when showing?
      this.isOpened = !(+this.collabInput.collaboration.status > +CollabStatuses.CreatorsChosenStatusId);
    },
    getFormattedVisitTime(creatorInput: CollabCreatorInput): string {
      return DateUtils.formatDateRange(creatorInput.proposedVisitDateTime, creatorInput.numberOfDaysStaying);
    },
    async removeCreator(creatorId: string) {
      // Can only remove un-invited so not using a modal here (can easily re-add them)
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/invites?creatorId=${creatorId}`;
      const { data } = await axios.delete(uri);
      // Remove from the collection
      this.invitedCreatorsClient.splice(
        this.invitedCreatorsClient.findIndex(i => i.collabCreatorInput.creatorId === creatorId),
        1
      );
    },
    cancelModals() {
      this.showChooseMultipleCreatorsModal = false;
      this.creatorIdInContext = null;
      this.creatorNameInContext = null;
    },
    async chooseMultipleCreators(chooseCreatorInputs: ChooseCreatorInputPayload): Promise<void> {
      this.chooseCreatorIsSaving = true;
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/invites/choose-creator/v3?creatorIds=${this.selectedCreatorIds.join("&creatorIds=")}`;
      const { data } = await axios.post(uri, chooseCreatorInputs);
      // No need to update the state since the parent will reload all the collabs

      this.selectedCreatorIds = [];
      this.chooseCreatorIsSaving = false;
      // Now have a final "Next steps slide so let the modal call close"
      // This causes the parent to reload all the collabs
      this.$emit("selectMultipleCreators", this.communityId);
      // TODO: add a toaster notification that multiple creators was selected
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/views/PaidCollab/AllCollabsV2.scss";

// Rank creators link =========================================================
.rank-creators-link {
  display: inline-flex;
  position: relative;
  color: rgba(91, 91, 91, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.3);
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  text-decoration-style: dashed;
  font: 14px/14px sans-serif;

  &::before {
    content: "";
    width: calc(100% + 26px);
    height: calc(100% + 26px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    text-decoration: none;
  }
}

// ============================================================================

.ui.form {
  .feedback {
    margin-top: 25px;

    .section-title {
      margin: 0 0 5px 0 !important;
    }
    .rating-section {
      margin: 5px 0 15px 0 !important;
    }
    .feedback-text {
      height: 80px;
      min-height: 50px;
      margin-bottom: 10px;
    }
  }
}
.accepted {
  color: $brand-color;
}
.declined {
  color: $orange;
}
.invite-revoked {
  color: $orange;
  font-weight: 800;
}
.invite-date {
  margin-bottom: 1em;
}
.not-invited-yet {
  color: $orange;
}

.hands {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline;
}

.close {
  z-index: 20;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -10px;
  right: -10px;
}

.large-accordion-dropdown {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative !important;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: "";
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    height: 30px;
    margin: 0;
    border-bottom: 1px transparent solid;
    display: inline-block;
    transition:
      border-color 0.12s ease-in-out,
      opacity 0.12s ease-in-out;
  }

  &:hover h2 {
    border-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
