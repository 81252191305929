<template>
  <div class="clamped-text">
    <div class="clamped-text__full-text-overlay-wrap">
      <div class="clamped-text__full-text-overlay" ref="domRefFullText">
        <slot />
      </div>
    </div>
    <div class="clamped-text__always-clamped-text-overlay" ref="domRefClampedText" :style="`line-clamp: ${props.visibleLines}; -webkit-line-clamp: ${props.visibleLines};`">
      <slot />
    </div>

    <div
      :class="{
        'clamped-text__text-itself': true,
        'clamped-text__text-itself--clamped': isClamped,
      }"
      :style="isClamped ? `line-clamp: ${props.visibleLines}; -webkit-line-clamp: ${props.visibleLines};` : ''"
    >
      <slot />
    </div>

    <LinkWithIcon v-if="isShowMoreVisible" class="clamped-text__show-more-link" isDottedUnderline color="blue" @click="isClamped = !isClamped" iconInset="1px auto auto 2px">
      <template #icon><IconEmbedded :name="isClamped ? 'caret-bottom_4' : 'caret-top_4'" :size="13" /></template>
      <span v-if="isClamped">Show more</span>
      <span v-else>Show less</span>
    </LinkWithIcon>
  </div>
</template>

<script setup lang="ts">
import { ref, onUpdated, nextTick, onMounted } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

const props = withDefaults(
  defineProps<{
    visibleLines: number;
  }>(),
  {
    visibleLines: 3,
  }
);

// Is Clamped =================================================================
const isClamped = ref<boolean>(true);

// Calculate "show more" link visibility ======================================
const isShowMoreVisible = ref<boolean>(true);
const textsHeightDifferenceThreshold = 10;

onUpdated(async () => {
  // onUpdated is the simplest way to track the slots changes
  await nextTick();
  setShowMoreLinkVisibility();
});

onMounted(async () => {
  await nextTick();
  setShowMoreLinkVisibility();
});

function setShowMoreLinkVisibility() {
  isShowMoreVisible.value = checkHowMuchFullTextBiggerThanClamped() > textsHeightDifferenceThreshold;
}

const domRefFullText = ref<HTMLElement | null>(null);
const domRefClampedText = ref<HTMLElement | null>(null);

function checkHowMuchFullTextBiggerThanClamped(): number {
  return domRefFullText.value?.getBoundingClientRect().height - domRefClampedText.value?.getBoundingClientRect().height;
}
</script>

<style scoped lang="scss">
// Clamped text ===============================================================
.clamped-text {
  position: relative;

  &__full-text-overlay-wrap {
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  &__full-text-overlay {
    position: absolute;
    inset: 0 auto auto 0;
    color: rgba(255, 0, 0, 1);
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  &__always-clamped-text-overlay {
    display: -webkit-box;
    position: absolute;
    inset: 0 auto auto 0;
    overflow: hidden;
    color: rgba(255, 0, 0, 1);
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  &__text-itself {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;

    &--clamped {
      text-overflow: ellipsis;
    }
  }
}
</style>
