<template>
  <div class="ui form">
    <div v-if="pageDetailsNotFound" class="stackable very relaxed ui grid container">
      <br />
      <h4>
        Sorry, we couldn't find that page. <br /><br />
        Email us at hello@shrpa.com if you think you found a bug!
      </h4>
    </div>

    <div v-else>
      <div v-if="isLoading"><Loader /></div>
      <div v-if="pageDetails">
        <div>
          <div class="expedition-hero" :style="`background-image: url(${contentBaseUri}/cms/images/expeditions/${pageDetails.page.primaryImage})`">
            <!--<div class="heroLogo" v-if="pageDetails.page.icon">
                    <img :src="`${contentBaseUri}/cms/images/orgs/${pageDetails.page.icon}`" style="background:white"/>
                </div>-->
          </div>
        </div>

        <div>
          <ListManager :customerId="pageDetails.page.id" :adventureLists="pageDetails.adventureLists" @reset="reloadListData" @saved="setIsDirty(false)" @isInEditMode="setIsInEditMode" />

          <div class="ui container list-section" v-if="pageDetails.otherItineraries.length > 0">
            <h1 class="ui header">More Experiences</h1>
            <div class="ui four doubling raised link cards adventure-cards">
              <ItineraryTile v-for="itinerary in pageDetails.otherItineraries" :itinerary="itinerary" :allowShortImage="false" :key="itinerary.id" :source="'hotelpage'" />
            </div>
          </div>
        </div>

        <br />
        <br />
        <div class="ui stackable center aligned grid container">
          <router-link :to="{ name: 'Home' }" class="ui primary button">Find even more experiences!</router-link>
        </div>
      </div>
    </div>

    <!--Unsaved Changes Modal-->
    <SrpModal v-model:isVisible="showUnsavedChangesModal" :isClosable="false">
      <template #header>
        <h2 class="global-h2">There are unsaved changes, are you sure you want to close?</h2>
      </template>
      <template #footer>
        <SrpButton @click="showUnsavedChangesModal = false" style="margin-right: 10px">No</SrpButton>
        <SrpButton @click="navigateAwayConfirmed" color="gray" fill="outlined">Yep, I've saved what I need</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import ItineraryTile from "@components/ItineraryTile.vue";
import Loader from "@components/Loader/Loader.vue";
import { HotelPageModel } from "@contracts/pages";
import ListManager from "@components/ListManager.vue";
import AdminContext from "@logic/AdminContext";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "HotelsComponent",

  components: {
    SrpButton,
    SrpModal,
    ItineraryTile,
    Loader,
    ListManager,
  },

  // IsDirty guard
  beforeRouteLeave(to, from, next) {
    this.nextNav = next;
    // Note: isDirty may get out of sync because of watch timing (specifically the reload call after the cancel button click)
    if (this.isInEditMode && this.isDirty) {
      this.showUnsavedChangesModal = true;
      // Maybe more elegant than storing nextNav: //https://www.npmjs.com/package/vuejs-dialog
    } else {
      next();
    }
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      title: "Shrpa",
      isLoading: true,
      pageDetails: null as HotelPageModel,
      pageDetailsNotFound: false,
      // Note: There are timing issues with the watch so we check isInEditMode to work around that
      isInEditMode: false,
      isDirty: false,
      showUnsavedChangesModal: false,
      // Saved on the route guard for the isDirty modal
      nextNav: null,
    };
  },

  watch: {
    "pageDetails.adventureLists": {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          this.setIsDirty(true);
        }
      },
      deep: true,
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    let id = this.$route.params.pageId;
    // @ts-ignore
    await this.loadPageDetails(id);
  },

  methods: {
    async loadPageDetails(identifier: string) {
      var found = await this.loadPageDetailsFromServer(identifier);
      if (found) {
        if (this.pageDetails.page) this.title = "Shrpa - By " + this.pageDetails.page.title;

        this.setOrgInContext();
      }
      this.isLoading = false;
    },
    // returns true if found
    async loadPageDetailsFromServer(identifier: string): Promise<boolean> {
      try {
        let uri = `${import.meta.env.VITE_API_URL}/hotels/${identifier}`;
        const response = await axios.get(uri);
        this.pageDetails = response.data;
        setTimeout(() => {
          // After the isDirty watch fires
          this.setIsDirty(false);
        }, 500);
        return true;
      } catch (err) {
        if (err.response.status == 404) {
          this.pageDetailsNotFound = true;
          return false;
        }
        return true;
      }
    },
    async setOrgInContext() {
      // Note: We have logic in logoClicked in App.vue to handle the hotel flow whether the user is an admin or not
      // @ts-ignore
      this.globalRoot.orgInContext = AdminContext.setOrgInContextFromCustomer(this.pageDetails.page);
    },
    async reloadListData() {
      // Just reload all the data to reset the adventure lists
      await this.loadPageDetailsFromServer(this.pageDetails.page.id);
    },
    setIsDirty(dirty: boolean) {
      this.globalLog.info(`isDirty=${dirty}`);
      this.isDirty = dirty;
    },
    setIsInEditMode(isInEdit: boolean) {
      this.globalLog.info(`isInEdit=${isInEdit}`);
      this.isInEditMode = isInEdit;
    },
    navigateAwayConfirmed() {
      // Should have been stored by the route guard
      this.nextNav();
    },
  },
});
</script>

<style scoped lang="scss"></style>
