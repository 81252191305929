<template>
  <SrpModal :isVisible="isVisible" @close="$emit('closePublished')" :isContentPaddings="false" size="small" isWithScroll maxHeight="calc(100dvh - 60px)">
    <template #content>
      <div id="published-wrapper" style="overflow: auto">
        <div text-align="center" id="published" class="publishedModal">
          <div class="shrpa-logo">
            <img src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="Shrpa logo" />
          </div>
          <div class="published-header">
            <div class="header-over">
              <img class="hands" src="https://cdn.shrpa.com/images/misc/high-five.png" />
            </div>
          </div>
          <div class="published-content">
            <p class="wrapper" style="margin-bottom: 8px; font-size: 24px; line-height: 1">
              <span v-if="justBecamePaidEligible">You're Eligible for Paid Collabs!!!</span>
              <span v-else>You're awesome!</span>
            </p>
            <p v-if="!pendingReview && !justBecamePaidEligible" class="wrapper2" style="margin-bottom: 25px; font-size: 24px; line-height: 1">Your adventure is LIVE.</p>
            <div v-if="itinerarySummary" style="margin-bottom: 25px">
              <router-link v-if="previewUri" :to="previewUri" class="link">View your adventure</router-link>
              <br />
              <br />
              <div v-if="itinerarySummary.thumbnail !== 'thumbnails/' && itinerarySummary.thumbnail" class="image-content">
                <img :src="`${contentBaseUri}/cms/images/expeditions/${itinerarySummary.thumbnail}`" style="width: 30vw; max-width: 220px" />
              </div>
            </div>

            <div style="width: 100%; height: 1px; margin-bottom: 25px; background: rgba(0, 0, 0, 0.1)"></div>

            <p v-if="pendingReview" class="wrapper2" style="font-size: 16px; line-height: 1.2">
              We're doing a quick proof, and your adventure will be live shortly!<br />
              (we'll notify you when it's live)
            </p>
            <span v-else-if="justBecamePaidEligible">
              <SrpButton :to="{ name: 'CreatorDashboard', params: { creatorId: getViewingUserProfile.sherpaId } }" color="orange">Go to My Dashboard</SrpButton>
            </span>
            <span v-else>
              <p class="wrapper" style="margin-bottom: 10px">Tell people about your adventure!</p>
              <ShareButton :sourceContext="'postPublish'" :shareCurrentPageUri="false" :entityIdToShare="itinerarySummary.id" :useButtonBar="true" />
            </span>
          </div>
        </div>
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ItineraryHelper from "@helpers/ItineraryHelper";
import ShareButton from "@components/ShareButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { ItinerarySummary } from "@contracts/itinerary";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import SrpButton from "@components/ui/SrpButton.vue";

export default defineComponent({
  name: "PublishedComponent",

  components: {
    SrpModal,
    ShareButton,
    SrpButton,
  },

  props: {
    itinerarySummary: { type: [Object, null], required: true },
    isVisible: { type: Boolean, required: true },
    justBecamePaidEligible: { type: Boolean, required: false, default: false },
  },

  emits: ["closePublished"],

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      useConfetti: true,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isSuperOrSalesUser"]),
    pendingReview(): boolean {
      if (this.itinerarySummary) return ItineraryHelper.isPendingReview(this.itinerarySummary.secondaryStatus);
      return false;
    },
    previewUri(): any {
      if (this.itinerarySummary) {
        // @ts-ignore
        return ItineraryHelper.getPreviewLink(this.itinerarySummary);
      }
      return false;
    },
  },

  mounted() {
    if (this.useConfetti) {
      // @ts-ignore
      // this.$confetti?.start({
      //   defaultType: "rect",
      //   canvasId: "confetti-canvas",
      //   particlesPerFrame: 2,
      //   defaultDropRate: 5,
      //   defaultSize: 2,
      // });
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

#published {
  display: block;
  justify-content: space-around;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.shrpa-logo {
  padding: 32px 0 20px;

  img {
    height: 60px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .shrpa-logo {
    padding: 22px 0 15px;

    img {
      height: 40px;
    }
  }
}

@media screen and (max-width: 420px) {
  #published-wrapper {
  }

  #published {
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    min-height: auto;
    margin-top: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}

.published-header {
  padding-top: 20px;
  position: relative;
  background: rgba(0, 0, 0, 0.06);
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .published-header {
  }
}

#confetti-canvas {
  width: 0;
  height: 0;
  z-index: 1;
  display: none;
}

.header-over {
}

.hands {
  height: 60px;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .hands {
    height: 40px;
  }
}

.published-content {
  padding: 0 30px 50px;
  background: rgba(0, 0, 0, 0.06);
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.wrapper {
  font-size: 17px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0;
}

.wrapper2 {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 16px;
}

.link {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  text-decoration: underline;
}

.image-content {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.image-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
