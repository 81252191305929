import axios from "axios";
import { ref } from "vue";
import { defineStore } from "pinia";

// Types
import { IconName } from "@contracts/IconName";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

export const useIconsStore = defineStore("icons", () => {
  const remoteLogger = getGlobalRemoteLogger();

  type IconCache = Partial<Record<IconName, Promise<string>>>;
  const iconSvgCodesCache = ref<IconCache>({});

  async function fetchIcon(iconName: IconName): Promise<string> {
    const uri = `${import.meta.env.BASE_URL}icons/${iconName}.svg`;
    try {
      const response = await axios.get(uri);
      // quick sanity check that the response is a proper svg
      if (response?.data?.trim()?.toLowerCase().slice(0, 4) !== "<svg") {
        remoteLogger.error(`Icon response for ${iconName} is invalid: ${response?.status} ${response?.data?.slice(0, 500)}`);
        return FallbackIconSvgCode;
      }
      return response.data;
    } catch (error) {
      remoteLogger.error(`Failed to fetch icon: ${iconName}: ${error}`);
      return FallbackIconSvgCode;
    }
  }

  async function fetchIconWithCaching(iconName: IconName): Promise<string> {
    const iconFromCache = iconSvgCodesCache.value[iconName];
    if (iconFromCache) {
      // console.debug("IconCache: Cache hit %s", iconName);
      return iconFromCache;
    }

    // console.debug("IconCache: Cache miss %s", iconName);
    const iconPromise = fetchIcon(iconName);
    iconSvgCodesCache.value[iconName] = iconPromise;
    return iconPromise;
  }

  return {
    // exposing for dev tools
    _cache: iconSvgCodesCache,
    fetchIcon: fetchIconWithCaching,
  };
});

export const FallbackIconSvgCode = `
<svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.05878 5.32031C5.65309 5.32031 5.32422 5.64919 5.32422 6.05487V19.9468C5.32422 20.2059 5.45838 20.4337 5.66102 20.5644L16.0907 10.1348C16.3251 9.90038 16.643 9.76868 16.9745 9.76868C17.3061 9.76868 17.624 9.90038 17.8584 10.1348L20.6852 12.9616V6.05487C20.6852 5.64919 20.3564 5.32031 19.9507 5.32031H6.05878ZM23.1852 15.9385V6.05487C23.1852 4.26847 21.7371 2.82031 19.9507 2.82031H6.05878C4.27238 2.82031 2.82422 4.26847 2.82422 6.05487V19.9468C2.82422 21.7004 4.21979 23.1281 5.96091 23.1799C6.01962 23.1845 6.0786 23.185 6.13736 23.1813H19.9507C21.7371 23.1813 23.1852 21.7332 23.1852 19.9468V16.0216C23.1862 15.9939 23.1862 15.9662 23.1852 15.9385ZM20.6852 16.4972L16.9745 12.7864L9.07964 20.6813H19.9507C20.3564 20.6813 20.6852 20.3525 20.6852 19.9468V16.4972ZM9.53313 9.29081C9.40146 9.29081 9.29471 9.39755 9.29471 9.52923C9.29471 9.6609 9.40146 9.76765 9.53313 9.76765C9.66481 9.76765 9.77155 9.6609 9.77155 9.52923C9.77155 9.39755 9.66481 9.29081 9.53313 9.29081ZM6.79471 9.52923C6.79471 8.01684 8.02074 6.79081 9.53313 6.79081C11.0455 6.79081 12.2715 8.01684 12.2715 9.52923C12.2715 11.0416 11.0455 12.2676 9.53313 12.2676C8.02074 12.2676 6.79471 11.0416 6.79471 9.52923Z"
  />
</svg>
`.trim();
