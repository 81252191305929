<template>
  <div>
    <div class="ui segment" v-for="ctr in creatorsToShow" :key="ctr.creator.sherpaId">
      <div class="adventure-creator">
        <router-link class="contributing-sherpa" v-bind:key="ctr.creator.sherpaId" lang="en" hreflang="en" :to="{ name: 'CreatorProfilePublic', params: { creatorId: ctr.creator.uriKey } }">
          <img class="ui centered circular tiny image" :src="ctr.creator.profileImageUri" />
          <div>
            <h3>{{ ctr.creator.firstName }}</h3>
            <div>Last Adventure created {{ moment(ctr.creator.stats.lastPublishedDate).fromNow() }}</div>
            <div>{{ ctr.creator.stats.adventureCount }} Adventures</div>
            <div>{{ ctr.creator.stats.locationCount }} Locations</div>
          </div>
        </router-link>
      </div>

      <div class="ui four doubling raised link cards adventure-cards">
        <ItineraryTile v-for="itinerary in ctr.adventures" :itinerary="itinerary" :allowShortImage="false" :source="null" :key="itinerary.id" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ItineraryTile from "@/components/ItineraryTile.vue";
import moment from "moment";

export default defineComponent({
  name: "CreatorSummary",

  components: {
    ItineraryTile,
  },

  props: {
    creatorsToShow: { type: Array as () => Array<object>, default: () => [] },
    paid: { type: Boolean, default: false },
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  methods: {
    moment,
  },
});
</script>

<style scoped lang="scss">
.main-section {
  .paid-check {
    display: flex;
    justify-content: flex-end;
  }
  .adventure-creator {
    margin-bottom: 20px;
    .contributing-sherpa {
      width: max-content;
      display: flex;
      align-items: center;
      text-align: left;
      img {
        margin-right: 15px;
      }
      h3 {
        margin: 0;
      }
      .paid-sign {
        color: #ec563b;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
</style>
