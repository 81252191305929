<template>
  <div>
    <div v-if="placeDetailsNotFound" class="stackable very relaxed ui grid container">
      <br />
      <h4>
        Sorry, we couldn't find that place. <br /><br />
        Email us at hello@shrpa.com if you think you found a bug!
      </h4>
    </div>

    <div v-else>
      <div v-if="isLoading"><Loader /></div>
      <div v-if="placeDetails">
        <div class="expedition-hero" :style="`background-image: url('${contentBaseUri}/cms/images/expeditions/${placeDetails.itineraries[0].thumbnail.replace('thumbnails/', '')}')`">
          <div v-if="placeDetails.page && placeDetails.page.title">
            <div class="expedition-overlay wow fadeIn">
              <h1 class="ui inverted header header-title">{{ placeDetails.page.title }}</h1>
            </div>
          </div>
        </div>
      </div>

      <br /><br />

      <div>
        <div v-if="placeDetails" class="ui container all-expeditions">
          <h2 class="ui header">Things to Do in {{ placeDetails.page.title }}!</h2>
          <div class="ui four doubling raised link cards adventure-cards">
            <ItineraryTile v-for="itinerary in placeDetails.itineraries" :itinerary="itinerary" :allowShortImage="false" :key="itinerary.id" :source="'cities'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ItineraryTile from "@components/ItineraryTile.vue";
import Loader from "@components/Loader/Loader.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "PlacesComponent",

  components: {
    ItineraryTile,
    Loader,
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      placeDetails: null,
      placeDetailsNotFound: false,
      title: "Shrpa - Things to do!",
      description: "Shrpa - Things to do!",
    };
  },

  async mounted() {
    useHead({
      title: () => this.title ?? "",
      meta: [{ name: "description", content: () => this.description ?? "" }],
    });

    let id = this.$route.params.cityId;
    await this.loadplaceDetails(id);
  },

  methods: {
    loadplaceDetails: async function (identifier) {
      let uri = `${import.meta.env.VITE_API_URL}/city/${identifier}`;
      const response = await axios.get(uri);
      if (response.status == 204) {
        this.placeDetailsNotFound = true;
      } else {
        this.placeDetails = response.data;
        var place = this.placeDetails.page.title;
        this.title = `Things to do in ${place}`;
        this.description = `If you are looking for things to do in ${place}. Here is a list the top fun things to do in ${place} from locals.`;
      }
      this.isLoading = false;
    },
  },
};
</script>
