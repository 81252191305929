<template>
  <div>
    <div v-if="isLoading"><Loader /></div>
    <div v-if="places">
      <table class="ui sortable celled table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>PlaceId</th>
            <th>Used In</th>
            <th>Adventures</th>
          </tr>
        </thead>
        <tbody v-for="(place, index) in places" :key="place.placeName + place.externalId">
          <tr>
            <td>{{ places.length - index }}</td>
            <td>{{ place.placeName }}</td>
            <td>{{ trim(place.externalId) }}</td>
            <td>{{ place.itinerariesUniqueNames.length }}</td>
            <td>
              <span v-for="uniqueName in place.itinerariesUniqueNames" :key="uniqueName">
                <a :href="`/steps/${uniqueName}`" target="_blank">{{ uniqueName }}</a
                ><br />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "PlacesSummary",

  components: {
    Loader,
  },

  data() {
    return {
      isLoading: true,
      places: null,
    };
  },

  async mounted() {
    useHead({ title: "Places Summary - Super" });

    await this.getPlacesSummary();
  },

  methods: {
    trim(value) {
      if (!value) return "";
      value = value.toString();
      const maxLength = 50;
      if (value.length <= maxLength) return value;
      return value.substring(0, maxLength) + "...";
    },
    async getPlacesSummary() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/superuser/placessummary`);
      this.places = data;
      this.isLoading = false;
    },
  },
};
</script>
