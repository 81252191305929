<template>
  <div>
    <div class="ui form">
      <Loader v-if="!selectedList || !pageDetails"></Loader>
      <div v-else>
        <h1 style="display: inline; margin-right: 25px">
          Adventure Wall - <span v-if="selectedList">{{ selectedList.name }}</span>
        </h1>
        <div class="settings">
          <div class="ui stackable grid">
            <div class="ui four wide column settings-item-wrapper">
              <div class="ui toggle checkbox top">
                <input type="checkbox" v-model="selectedList.includeAllAdventures" />
                <label>Include All Adventures</label>
              </div>
              <div>
                <div class="ui toggle checkbox">
                  <input type="checkbox" v-model="selectedList.randomize" />
                  <label>Randomize Adventures</label>
                </div>
              </div>
            </div>
            <div class="ui three wide column">
              <div class="sub-section-title">Max to Show (Optional)</div>
              <div class="ui input">
                <input v-model="selectedList.maxCount" type="number" />
              </div>
            </div>
            <div class="ui four wide column">
              <label>Name</label>
              <input v-if="selectedList" class="ui input text-field" style="max-width: 500px" type="text" v-model="selectedList.name" />
              <p class="cms">The name isn't shown, it's just for readability</p>
            </div>
            <div class="ui four wide right floated column" style="display: flex; align-items: flex-end">
              <button class="ui small primary right floated button" @click="save()">Save</button>
              <button @click="copy()" class="ui basic small right floated button"><b v-if="justCopied">Copied!</b><span v-else>Copy Code</span></button>
              <button @click="askToRemoveList()" class="ui basic small right floated button">Delete</button>
              <div style="margin-left: 5px">
                <h5 v-if="saveMessage">{{ saveMessage }}</h5>
              </div>
            </div>
          </div>
        </div>

        <!--Including All-->
        <div v-if="selectedList.includeAllAdventures">
          <label class="cms-section-title">All Adventures</label>
          <p style="margin-bottom: 5px">
            Adventures within your
            <router-link lang="en" hreflang="en" :to="{ name: 'CommunityPageAdmin', params: { pageId: pageId } }" target="_blank" style="text-decoration: underline">
              destination's boundary</router-link
            >
            will be included.
          </p>
          <div class="ui four doubling raised link cards adventure-cards">
            <ItineraryTile v-for="itinerary in pageDetails.itineraries" :itinerary="itinerary" :allowShortImage="false" :source="null" :key="itinerary.id" />
          </div>
          <h4 v-if="pageDetails.itineraries.length === 0">No adventures yet. As they're added, they will automatically show in your adventure wall!</h4>
        </div>
        <!--They want to select which adventures-->
        <AdventureSelector v-else :customerId="pageId" :adventureList="selectedList" :allAdventures="pageDetails.itineraries" :saveMessage="saveMessage" @save="save" @cancel="back" />

        <label class="cms-section-title">The Code</label>
        <p>
          <!--Note: This is in AdventureWidgets now also-->
          <code id="widgetCode" style="font-size: 1em"> &lt;script id="shrpa-adv__{{ pageId }}--{{ listId }}" src="https://shrpa.com/widget/adv.js" async&gt;&lt;/script&gt; </code>
          <br />
          <button style="margin-top: 15px" class="ui primary small button" @click="copy()">Copy the Code</button> <span v-if="justCopied"><b>Copied!</b></span>
        </p>

        <label class="cms-section-title">What do I do with the code?</label>
        <p>
          It depends what your site is build on (Wordpress, Simpleview, etc...)
          <br />
          Here's a full walkthrough!
          <br />
          <a style="text-decoration: underline" target="_blank" href="https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/"
            >https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/</a
          >
        </p>

        <br />
        <label class="cms-section-title">Questions?</label>
        <p>
          Having issues integrating this into your site? If you or your developer have any questions we're happy to help! <br />
          Email us anytime at <CopyText />
        </p>
      </div>
    </div>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showRemoveListModal">
      <template #header><h2 class="global-h2">Delete this integration?</h2></template>
      <template #footer>
        <div class="ui basic black button" @click="cancelRemoveList()">Cancel</div>
        <div class="ui red button" @click="deleteList()">Yep, delete it.</div>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { Page, PageAdventureList } from "@contracts/pages";
import OrgContext from "@logic/OrgContext";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import AdventureSelector from "@components/AdventureSelector.vue";
import ListRepo from "@logic/ListRepo";
import ItineraryTile from "@components/ItineraryTile.vue";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { useHead } from "@unhead/vue";

// NOTE: This does keep the in-memory lists in sync

export default defineComponent({
  name: "CustomIntegration",

  components: {
    SrpModal,
    Loader,
    ItineraryTile,
    AdventureSelector,
    CopyText,
  },

  data() {
    return {
      title: "Custom Adventure Wall",
      pageId: null as string,
      listId: null as string,
      pageDetails: null,
      orgInContext: null as PartnerNavInfo,
      selectedList: null as PageAdventureList,
      code: "",
      justCopied: false,
      isLoading: false,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isHotel: false,
      saveMessage: null as string | null,
      showRemoveListModal: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    // @ts-ignore
    this.listId = this.$route.params.listId;
    this.isHotel = OrgContext.isOrgInContextHotel(this);
    this.orgInContext = OrgContext.getOrgInContext(this);
    // Need to await these since we
    await this.loadList(this.listId);
    await this.loadCustomerPageDetails();
    this.removeSelectedItemsFromList();
  },

  methods: {
    async loadCustomerPageDetails() {
      let uri = `${import.meta.env.VITE_API_URL}/pages/${this.pageId}`;
      const response = await axios.get(uri);
      this.pageDetails = response.data;
      this.title += ` - ${this.pageDetails.page.title}`;
    },
    async loadList(listId: string) {
      this.selectedList = await ListRepo.loadAdventureSummaryForList(this.pageId, listId);
    },
    removeSelectedItemsFromList() {
      if (!this.pageDetails?.itineraries || !this.selectedList?.adventures || this.selectedList.adventures.length === 0) return;
      // Remove the items the user has selected from the "to pick from" list
      this.selectedList.adventures.forEach(selectedAdventure => {
        var index = this.pageDetails.itineraries.findIndex(i => i.id === selectedAdventure.id);
        if (index >= 0) this.pageDetails.itineraries.splice(index, 1);
      });
    },
    async save() {
      this.saveMessage = "Saving...";
      await ListRepo.updateList(this.pageId, this.selectedList);
      this.saveMessage = "Saved!";
      setTimeout(() => {
        this.saveMessage = null;
      }, 3000);
    },
    askToRemoveList() {
      this.showRemoveListModal = true;
    },
    cancelRemoveList() {
      this.showRemoveListModal = false;
    },
    async deleteList() {
      await ListRepo.deleteSiteIntegrationList(this.pageId, this.listId);
      this.back();
    },
    async copy() {
      const div = document.getElementById("widgetCode");
      await navigator.clipboard.writeText(div.textContent);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    back() {
      this.$router.push({ name: "AdventureWidgets", params: { pageId: this.pageId } });
    },
  },
});
</script>

<style scoped lang="scss">
.settings {
  margin-top: 1rem;
  padding: 12px 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
}
.settings-item-wrapper {
  .ui.toggle.checkbox {
    &.top {
      margin-bottom: 1rem;
    }
  }
}
</style>
