<template>
  <IconEmbedded name="loader_3" class="with-loading-animation" :size="props.size" />
</template>

<script setup lang="ts">
// Types
import { IconName } from "@contracts/IconName";

const props = withDefaults(
  defineProps<{
    size?: number;
    name?: IconName;
  }>(),
  {
    size: 20,
    name: "loader_3",
  }
);

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
</script>

<style scoped lang="scss">
.with-loading-animation {
  :deep(svg) {
    animation: loadingAnimation 0.6s step-start infinite;
  }
}

@keyframes loadingAnimation {
  0% {
    mask: conic-gradient(from calc(0deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  12.5% {
    mask: conic-gradient(from calc(45deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  25% {
    mask: conic-gradient(from calc(90deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  37.5% {
    mask: conic-gradient(from calc(135deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  50% {
    mask: conic-gradient(from calc(180deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  62.5% {
    mask: conic-gradient(from calc(225deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  75% {
    mask: conic-gradient(from calc(270deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  87.5% {
    mask: conic-gradient(from calc(315deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  100% {
    mask: conic-gradient(from calc(360deg - 15deg), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}
</style>
