<template>
  <div>
    <Loader v-if="isLoading" />

    <template v-else>
      <label style="display: flex; align-items: center">
        <SrpSwitch v-model:isChecked="requiresApproval" :isDisabled="isSaving" @change="$event => requiresApprovalToggle($event.target.checked)" style="margin-right: 12px" />
        Require Approval for adventures before showing them on your destination page and website integrations.
      </label>
      <h5 class="saving-text"><span v-if="isSaving">Saving...</span></h5>

      <div v-if="requiresApproval">
        <h1 style="margin-top: 3rem">Pending Approval</h1>
        <div class="top-summary">Adventures that are pending your approval to be shown on your community's page.</div>
        <div class="ui segment" v-if="pendingAdventures.length">
          <TransitionGroup tag="div" name="fade" class="ui stackable six column grid adventure-card-container">
            <div class="adventure-card column" v-for="(adventure, index) in pendingAdventures" :key="adventure.id">
              <ItineraryTile :openNewWindow="true" :itinerary="adventure" :allowShortImage="false" :disableLink="false" :showRemoveButton="false" :source="null" />
              <div class="ui buttons actions">
                <button class="ui vertical primary animated button" :disabled="isSaving" @click="updatedFeatureAdventure(true, true, adventure, index)" tabindex="0">
                  <div class="hidden content">Approve</div>
                  <div class="visible content">
                    <i class="check icon"></i>
                  </div>
                </button>
                <button class="ui vertical animated button" :disabled="isSaving" @click="updatedFeatureAdventure(false, true, adventure, index)" tabindex="0">
                  <div class="hidden content">Exclude</div>
                  <div class="visible content">
                    <i class="close icon"></i>
                  </div>
                </button>
              </div>
            </div>
          </TransitionGroup>
        </div>
        <div v-else style="margin-top: 1rem">
          <div class="ui info message">There aren't any adventures waiting for your approval.</div>
        </div>
      </div>

      <div style="display: flex; align-items: end; justify-content: space-between">
        <div>
          <h1 style="margin-top: 3rem; margin-bottom: 1rem">{{ requiresApproval ? "Approved " : "" }}Adventures</h1>
          <div class="top-summary">Adventures currently visible on your community's page and website integration.</div>
        </div>
        <div>
          <router-link lang="en" hreflang="en" class="ui primary button" :to="{ name: 'Pages', params: { pageId: customerId } }" target="_blank">View My Destination Page</router-link>
        </div>
      </div>
      <div class="ui segment" style="border: 2px solid #058587">
        <div v-if="approvedAdventures.length === 0" style="margin-bottom: 25px">No adventures are currently approved.</div>
        <TransitionGroup tag="div" name="fade" class="ui stackable six column grid adventure-card-container">
          <div class="adventure-card column" v-for="(adventure, index) in approvedAdventures" :key="adventure.id">
            <!-- <div class="ui card wow fadeIn card-content"> -->
            <ItineraryTile :openNewWindow="true" :itinerary="adventure" :allowShortImage="false" :disableLink="false" :showRemoveButton="false" :source="null" />
            <!-- </div> -->
            <div class="actions">
              <button class="ui fluid basic small button" :disabled="isSaving" @click="updatedFeatureAdventure(false, false, adventure, index)">Exclude</button>
            </div>
          </div>
        </TransitionGroup>
      </div>

      <h5 class="saving-text" style="margin-top: 0.3em"><span v-if="isSaving">Saving...</span></h5>

      <div style="margin-top: 3rem; cursor: pointer" @click="collapsed = !collapsed">
        <h1 style="display: inline">Excluded Adventures</h1>
        <span style="margin-left: 0.5rem">
          <div style="vertical-align: initial !important" v-if="collapsed" name="angle down" class="large angle down" />
          <div style="vertical-align: initial !important" v-else name="angle up" class="large angle up" />
        </span>
      </div>
      <div v-if="!collapsed">
        <div class="top-summary" style="margin-top: 1rem">Adventures that are excluded from being shown on your community's profile page and website integration.</div>
        <div class="ui segment">
          <div v-if="excludedAdventures.length === 0" style="margin-bottom: 25px">No adventures are currently excluded.</div>
          <TransitionGroup tag="div" name="fade" class="ui stackable six column grid adventure-card-container">
            <div class="adventure-card column" v-for="(adventure, index) in excludedAdventures" :key="adventure.id">
              <ItineraryTile :openNewWindow="true" :itinerary="adventure" :allowShortImage="false" :disableLink="false" :showRemoveButton="false" :source="null" />
              <div class="actions">
                <button class="ui basic fluid button" :disabled="isSaving" @click="updatedFeatureAdventure(true, false, adventure, index)">Include</button>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
      <button class="ui tiny basic right floated button" style="margin-top: 10px" @click="markAllUnapproved" :disabled="isSaving" v-if="requiresApproval && isSuperOrSalesUser">
        (super) reset all to unapproved
      </button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpSwitch from "@components/ui/SrpSwitch.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useHead } from "@unhead/vue";

// NOTE! The View my Destination Page currently assumes this is NOT a hotel (we don't currently allow this feature for hotels)

export default defineComponent({
  name: "CommunityAdventureApproval",

  components: {
    SrpSwitch,
    Loader,
    ItineraryTile,
  },

  data() {
    return {
      title: "Adventures",
      customerId: null as string | null,
      isLoading: true,
      isSaving: false,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      adventureApprovalPayload: null,
      approvedAdventures: [],
      pendingAdventures: [],
      excludedAdventures: [],
      requiresApproval: true,
      collapsed: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });
    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    await this.getData();
  },

  methods: {
    async getData() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/${this.customerId}/approvals/summary`);

      this.adventureApprovalPayload = data;
      this.title += ` - ${this.adventureApprovalPayload.customerName}`;

      this.requiresApproval = this.adventureApprovalPayload.newAdventuresRequireApproval;
      this.approvedAdventures = this.adventureApprovalPayload.approved;
      this.pendingAdventures = this.adventureApprovalPayload.pendingApproval;
      this.excludedAdventures = this.adventureApprovalPayload.excluded;

      this.isLoading = false;
    },
    async requiresApprovalToggle(newVal) {
      this.isSaving = true;
      await axios.put(`${import.meta.env.VITE_API_URL}/${this.customerId}/approvals?requiresApproval=${newVal}`);
      await this.getData();
      this.isSaving = false;
    },
    async updatedFeatureAdventure(isApproved: boolean, wasPending: boolean, data, index: number) {
      this.isSaving = true;
      // Update the server
      const response = await axios.put(`${import.meta.env.VITE_API_URL}/${this.customerId}/approvals/${data.id}?isApproved=${isApproved}`);

      // Update the local state
      if (isApproved) {
        this.approvedAdventures.unshift(data);
        if (wasPending) this.pendingAdventures.splice(index, 1);
        else this.excludedAdventures.splice(index, 1);
      } else {
        // Excluded
        this.excludedAdventures.unshift(data);
        if (wasPending) this.pendingAdventures.splice(index, 1);
        else this.approvedAdventures.splice(index, 1);
      }

      this.isSaving = false;
    },
    async markAllUnapproved() {
      // Resets all approvals, useful for testing
      this.isSaving = true;
      await axios.delete(`${import.meta.env.VITE_API_URL}/${this.customerId}/approvals`);
      await this.getData();
      this.isSaving = false;
    },
  },
});
</script>

<style scoped lang="scss">
.adventure-card-container {
  .adventure-card {
    // width: 20% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    .ui.card {
      flex: 1;
    }
    .actions {
      width: 100%;
    }
  }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0);
}
.fade-leave-active {
  position: absolute;
}

.saving-text {
  position: absolute;
  margin-top: 1em;
  margin-bottom: 0;
}
</style>
