<template>
  <div class="content-wrapper map-container" id="adventure-map">
    <div class="close-map-button">
      <button class="ui icon button close-btn" @click="navigateClose">
        <i class="angle left icon"></i>
        Close
      </button>
      <!--<div
        class="labeled icon stops-close-btn-more"
        icon="ellipsis vertical"
      >
        <div>
          <div>
            <a lang="en" hreflang="en" @click="useOldExperience()">Use the old Experience</a>
          </div>
          <div>
            <sui-checkbox label="Enable zoom animation" @click="toggleZoomAnimation()" v-model="enableZoomAnimation" />
          </div>
        </div>
      </div>-->
    </div>

    <GMapMap ref="mapRef" :center="center" :options="{ ...gMapOptions }" :zoom="zoomLevel">
      <template v-for="(step, i) in allSteps">
        <GMapMarker v-if="step.latitude" :key="'marker' + i" @click="markerClick(i)" :position="{ lat: step.latitude, lng: step.longitude }" :icon="markerIcon" />
        <GMapInfoWindow
          v-if="step.latitude"
          :ref="'info' + i"
          :key="`info-${i}-${step.externalId}`"
          :options="{
            pixelOffset: {
              width: 0,
              height: -40,
            },
            zIndex: allSteps.length - i + 10,
          }"
          :position="{ lat: step.latitude, lng: step.longitude }"
        >
          <div @click="infoWindowClick(i)" style="cursor: pointer">
            <h3 style="max-width: 112px">{{ i + 1 }}: {{ step.title }}</h3>
            <template v-if="step.images.length > 0">
              <template v-if="isVideo(step.images[0])">
                <VideoRender :videoId="step.images[0]" :customClasses="'stop-info-window-content'" :showControls="false" />
              </template>
              <template v-else>
                <img class="stop-info-window-content" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${step.images[0]}`" alt="Expedition Thumbnail" />
              </template>
            </template>
          </div>
        </GMapInfoWindow>
      </template>
    </GMapMap>

    <button v-if="showStartButton" class="ui icon massive orange button go-map-btn" @click="startClick">Let's Go!</button>

    <div id="nonMapSection" v-if="showStopDetails && currentStop">
      <div class="non-map-content">
        <div class="ui doubling raised link cards adventure-cards">
          <div class="wow adventure-card">
            <transition name="slide-fade">
              <div
                v-if="!sliderToggled"
                class="adventure-content"
                :class="{ hidden: sliderToggled, noimgs: !currentStop.isPostStopExperience && (!currentStop.images || currentStop.images.length === 0) }"
              >
                <i class="icon close" @click.prevent.stop="onSliderToggleUp" />
                <div class="adventure-title-address mobile" :class="{ hidden: currentStop.isPostStopExperience }">
                  <i class="icon map marker alternate large" />
                  <div>
                    <h3>{{ currentStop.title }}</h3>
                    <span
                      ><a lang="en" hreflang="en" :href="getGoogleMapLink(currentStop)" target="_blank">{{ getGoogleMapText(currentStop) }}</a></span
                    >
                    <div v-if="showShareStopsButton" class="share-btn">
                      <button class="ui basic primary centered small button" @click="shareVisible = true">Share this stop</button>
                    </div>
                  </div>
                </div>
                <!--Setting a ref so we can programatically reset the scrollTo-->
                <div v-if="!currentStop.isPostStopExperience" ref="adventurecardslider" class="adventure-cards-slider">
                  <div class="adventure-card-left">
                    <div class="adventure-title-address">
                      <i class="icon map marker alternate large" />
                      <div>
                        <h1>{{ currentStop.title }}</h1>
                        <span id="stop-address"
                          ><a lang="en" hreflang="en" :href="getGoogleMapLink(currentStop)" target="_blank">{{ getGoogleMapText(currentStop) }}</a></span
                        >
                        <div v-if="showShareStopsButton" class="share-btn">
                          <SrpButton @click="shareVisible = true" size="small" fill="outlined">Share this stop</SrpButton>
                        </div>
                      </div>
                    </div>
                    <div class="adventure-others-infos">
                      <div class="adventure-description" :class="{ flexGrow: currentStop.images.length > 0 }">{{ currentStop.details }}</div>
                      <!-- <div> -->
                      <div class="info-box tip-box" v-if="currentStop.proTip">
                        <div class="shrpa-logo">
                          <img :src="`${contentBaseUri}/images/shrpa-mark-inverted.png`" />
                        </div>
                        <h4>Pro Tip!</h4>
                        <div>
                          {{ currentStop.proTip }}
                        </div>
                      </div>
                      <div class="info-box links-box" v-if="currentStop.website || currentStop.customLinkText">
                        <div class="links-icon">
                          <i class="icon linkify" />
                        </div>
                        <h4>Links</h4>
                        <div class="adventure-info-item" v-if="currentStop.website">
                          <a :href="getWebsiteUri(currentStop.website)" target="_blank">{{ currentStop.title }} Website</a>
                        </div>
                        <div class="adventure-info-item" v-if="currentStop.customLinkText">
                          <a :href="getWebsiteUri(currentStop.customLinkUri)" target="_blank">{{ currentStop.customLinkText }}</a>
                        </div>
                      </div>
                      <!-- </div> -->
                      <div class="info-box next-box" v-if="nextStop" :class="{ hidden: currentStop.images.length > 0 }">
                        <h4>Where to Next?</h4>
                        <div v-if="nextStop" class="next-info"><i class="icon map marker alternate" /> {{ nextStop.title }}</div>
                        <div class="next-info"><i class="icon map" /> {{ getDistanceToNextStop(currentStop) }}</div>
                        <div class="next-info"><i class="icon stopwatch" /> Takes about {{ getTimeToNextStop(currentStop) }} {{ navigationMode }}</div>
                      </div>
                    </div>
                    <!-- <button @click="closeStopDetails">Close</button> -->
                  </div>
                  <div v-if="currentStop.images.length > 0" class="adventure-card-right">
                    <div v-if="screenSize !== 'mobile'" class="adventure-card-imgs" ref="adImgsRef">
                      <!--Lots of images
                          Note: The "how many columns" logic is also used in the Preview in SelectedFilesPanel.vue-->
                      <SrpMasonry v-if="currentStop.images.length > 1" :items="currentStop.images" :columns="currentStop.images.length > 10 ? 3 : 2" :columnGap="screenSize === 'mobile' ? 9 : 11">
                        <template #default="{ item, rebuildMasonry }">
                          <div class="adventure-card-img" style="margin-bottom: 10px">
                            <template v-if="isVideo(item)">
                              <VideoRender
                                :videoId="item"
                                :showControls="false"
                                :showViewerOnClick="true"
                                :customClasses="'ui fluid rounded image middle aligned centered wow fadeInUp'"
                                :galleryPostfix="'-desk'"
                                @imgOnLoad="rebuildMasonry"
                              />
                            </template>
                            <template v-else>
                              <!--Note: Grouping the desk and mobile gallery here, also see galleryPostfix setting on VideoRender-->
                              <a lang="en" hreflang="en" data-fancybox="gallery-desk" :href="`${contentBaseUri}/cms/images/expeditions/${item}`">
                                <img class="ui rounded image middle aligned centered wow fadeInUp" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${item}`" @load="rebuildMasonry" />
                              </a>
                            </template>
                          </div>
                        </template>
                      </SrpMasonry>

                      <!--Single Image-->
                      <div v-else class="adventure-card-img single">
                        <template v-if="isVideo(currentStop.images[0])">
                          <VideoRender
                            :videoId="currentStop.images[0]"
                            :showControls="false"
                            :showViewerOnClick="true"
                            :customClasses="'ui rounded image middle aligned centered wow fadeInUp'"
                            :galleryPostfix="'-desk'"
                          />
                        </template>
                        <template v-else>
                          <a lang="en" hreflang="en" data-fancybox="gallery-desk" :href="`${contentBaseUri}/cms/images/expeditions/${currentStop.images[0]}`">
                            <img class="ui rounded image middle aligned centered wow fadeInUp" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${currentStop.images[0]}`" />
                          </a>
                        </template>
                      </div>
                      <div class="shadow-box" ref="shadow" v-if="currentStop.images.length > 1" />
                    </div>

                    <div v-if="screenSize === 'mobile'" class="columns-layout columns-layout--2-col side-margins horizontal-scroll" :class="{ shareStopPadding: showShareStopsButton }">
                      <div class="columns-layout__item" v-for="(image, index) in currentStop.images" :key="`img-mobile-${index}`" style="border-radius: 5px; position: relative; overflow: hidden">
                        <template v-if="isVideo(image)">
                          <VideoRender :videoId="image" :showControls="false" :showViewerOnClick="true" :galleryPostfix="'-mobile'" style="display: flex !important" />
                        </template>
                        <template v-else>
                          <a lang="en" hreflang="en" data-fancybox="gallery-mobile" :href="`${contentBaseUri}/cms/images/expeditions/${image}`" style="display: flex">
                            <img :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`" style="width: 100%" />
                          </a>
                        </template>
                      </div>
                    </div>
                    <div v-if="nextStop" class="info-box next-box">
                      <h4>Where to Next?</h4>
                      <div v-if="nextStop" class="next-info"><i class="icon map marker alternate" /> {{ nextStop.title }}</div>
                      <div class="next-info"><i class="icon map" /> {{ getDistanceToNextStop(currentStop) }}</div>
                      <div class="next-info"><i class="icon stopwatch" /> Takes about {{ getTimeToNextStop(currentStop) }} {{ navigationMode }}</div>
                    </div>
                  </div>
                </div>

                <!--Post-Stops Experience-->
                <div v-else id="post-stops-content">
                  <div class="ui stackable grid">
                    <div class="sixteen wide centered column">
                      <div class="ui info message centered-text">
                        <h2>What did you think?</h2>
                        <div class="row">
                          <ShareButton :sourceContext="'steps'" :entityName="itinerary.title" :entityIdToShare="itinerary.id" />
                          <!--todo: Save-->
                          <ListsButton :entityName="itinerary.title" :entityId="itinerary.id" />
                          <ReportButton :entityName="itinerary.title" :entityId="itinerary.id" />
                        </div>
                      </div>
                      <div class="sixteen wide column" id="adventure-recommendations">
                        <h3 class="centered-text">More adventure recommendations</h3>
                        <div class="slider-container">
                          <Slider :type="'adventures'" :items="recommendedAdventures" :loaded="recommendedAdventures.length > 0" />
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div v-if="community" class="ten wide column">
                      <router-link :to="attributedCustomerLink">
                        <div class="ui icon message">
                          <img :src="`${contentBaseUri}/cms/images/orgs/${community.icon}`" style="max-width: 125px; max-height: 80px; margin-right: 1em" :alt="community.title" />
                          <div class="content">
                            <h4 class="ui header">
                              <span v-if="community.createdForCollab">In partnership with</span>
                              <span v-else>Find more adventures near</span>
                            </h4>
                            <div class="ui header primary" style="margin-bottom: 0.5em">{{ community.title }}</div>
                            <button v-if="!community.customerIsDisabled" class="ui basic primary tiny button">View {{ community.isHotel ? "Hotel" : "Community" }}</button>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="six wide column">
                      <router-link :to="{ name: 'CreatorProfilePublic', params: { creatorId: itinerary.sherpaId } }">
                        <div class="ui icon message" id="created-by">
                          <img v-if="creator" class="ui circular tiny image" :src="creator.profileImageUri" style="margin-right: 1em" />
                          <div class="content">
                            <h4 class="ui header">Created by</h4>
                            <span class="ui header primary" style="margin-bottom: 0.5em">{{ itinerary.sherpa.firstName }}</span>
                            <button class="ui basic primary tiny button">View Creator</button>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--End Post-Stops Experience-->
              </div>
            </transition>
            <div class="control-btns">
              <button :disabled="currentStopIndex === 0" class="ui icon button adventure-card-arrow-btn" @click="changeStop(-1)">
                <i class="angle left big icon" />
                Previous Stop
              </button>
              <!--Last Stop-->
              <button v-if="currentStopIndex === lastStopIndex" class="ui icon button adventure-card-arrow-btn" @click="navigateClose">
                <div style="margin-right: 10px">Back to Preview<br /></div>
                <div style="margin-right: 10px">
                  <i class="reply icon" />
                </div>
              </button>
              <!--Not the Last Stop-->
              <button v-else class="ui icon button adventure-card-arrow-btn" @click="changeStop(1)">
                <div>
                  {{ currentStopIndex >= allSteps.length - 1 ? "Summary" : "Next Stop" }}
                  <br />
                </div>
                <i class="angle right big icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SrpModal v-model:isVisible="shareVisible" :size="'large'" v-if="currentStop" :isClosable="false" className="share-adventure-modal" :zIndex="9999" :isWithScroll="true">
      <template #header><h2 class="global-h2">Share Stop</h2></template>
      <template #content>
        <ShareStop :itinerary="itinerary" :currentStop="currentStop" :currentStopIndex="currentStopIndex" :creator="creator" />
      </template>
      <template #footer>
        <SrpButton @click="shareVisible = false" fill="outlined" color="gray">Close</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import AdminContext from "@logic/AdminContext";
import axios from "axios";
import BoundsLogic from "@logic/GoogleMapBoundsLogic";
import { defineComponent, inject } from "vue";
import FeatureFlags from "@logic/FeatureFlags";
import FileUtils from "@logic/FileUtils";
import { getInstance } from "@auth/authWrapper";
import ItineraryRepo from "@repos/ItineraryRepo";
import { MetricSender } from "@helpers/MetricSender";
import { RouteHelper } from "@helpers/RouteHelper";
import ShareStop from "./ShareStop.vue";

// Types
import { AttributedToCustomer } from "@contracts/pages";
import { Itinerary, ItineraryStep } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";

// Components
import ListsButton from "@components/ListsButton.vue";
import Loader from "@components/Loader/Loader.vue";
import ReportButton from "@components/ReportButton.vue";
import ShareButton from "@components/ShareButton.vue";
import Slider from "@components/Slider.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import VideoRender from "@components/VideoRender.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import { useHead } from "@unhead/vue";

/*
Lower Pri
- Re-open the info window when clicking on the marker
- Clicking away from the StopDetails should close it

Missing lat/lng examples
- http://localhost:8080/stops/girlsgetawayinthebuffaloarea
- http://localhost:8080/stops/giantfamilyfuninblueearthparttwo
*/

// DOCUMENTATION: https://diegoazh.github.io/gmap-vue/#about-gmapvue

export default defineComponent({
  name: "StopsMap",

  components: {
    SrpMasonry,
    ListsButton,
    Loader,
    ReportButton,
    Slider,
    SrpModal,
    SrpButton,
    ShareButton,
    ShareStop,
    VideoRender,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      screenSize: inject("screenSize") as ScreenSize,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      title: "Shrpa - Adventure Stops",
      itinerary: null as Itinerary | null,
      creator: null as CreatorPublicProfileFields | null,
      community: null as AttributedToCustomer | null,
      allSteps: null as Array<ItineraryStep> | null,
      currentStopIndex: 0,
      center: { lat: 44.1473, lng: -92.3814 } as globalThis.google.maps.LatLngLiteral,
      zoomLevel: 12,
      gMapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUi: false,
      },

      markerIcon: {
        url: "https://cdn.shrpa.com/images/siteicon.png",
      } as globalThis.google.maps.Icon,

      map: null as globalThis.google.maps.Map,

      zoomInOnStopLevel: 16,
      maxAutoZoomOutLevel: 6, // Could probably be higher if we want
      showStartButton: true,
      showStopDetails: false,
      sliderToggled: false,
      // The Google Zoom animation performs really poorly on large (4K) screens so giving the user a disable option
      enableZoomAnimation: true,

      recommendedAdventures: [],
      showShareStopsButton: false,
      shareVisible: false,
      feedbackFeatureEnabled: false,
    };
  },

  computed: {
    currentStop(): ItineraryStep | null {
      if (!this.itinerary) return null;

      if (this.currentStopIndex < this.allSteps.length) return this.allSteps[this.currentStopIndex];
      else
        return {
          title: "What did you think?",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          isPostStopExperience: true,
        };
    },
    lastStopIndex(): number {
      // One more than the actual number of stops to account for the updated "last stop" summary
      return this.allSteps.length;
    },
    nextStop(): ItineraryStep | null {
      if (!this.itinerary) return null;
      if (this.currentStopIndex >= this.allSteps.length) return null;

      return this.allSteps[this.currentStopIndex + 1];
    },
    navigationMode(): string {
      if (!this.itinerary) return "";
      else if (this.itinerary.directionsTravelMode?.toUpperCase() === "DRIVING") return "to drive there";
      else if (this.itinerary.directionsTravelMode?.toUpperCase() === "WALKING") return "to walk there";
      else if (this.itinerary.directionsTravelMode?.toUpperCase() === "BICYCLING") return "to bike there";
      else if (this.itinerary.directionsTravelMode?.toUpperCase() === "TRANSIT") return "via transit";
      else if (this.itinerary.useWalkingDirections) return "to walk there";
      else return "to drive there";
    },
    attributedCustomerLink(): any {
      if (!this.community) return null;
      if (this.community.isHotel) return { name: "Hotels", params: { pageId: this.community.uniqueName } };
      else return { name: "Pages", params: { pageId: this.community.uniqueName } };
    },
  },

  mounted() {
    useHead({ title: () => this.title ?? "" });

    this.defaultEnableZoom();
    // Share Stop button requires authentication
    let authService = getInstance();
    if (authService.loading === false) {
      this.authLoaded();
    } else {
      authService.$watch("loading", loading => {
        if (loading === false) {
          this.authLoaded();
        }
      });
    }

    (this.$refs.mapRef as any).$mapPromise.then(map => {
      this.map = map;

      this.markerIcon = {
        url: "https://cdn.shrpa.com/images/siteicon.png",
        scaledSize: new globalThis.google.maps.Size(40, 40),
      } as globalThis.google.maps.Icon;

      this.initMap();
      this.getPlacesSummaryFromServer();
      this.getAttributedToCustomer();
    });
  },

  methods: {
    initMap() {
      // https://developers.google.com/maps/documentation/javascript/events
      this.map.addListener("zoom_changed", () => {
        this.globalLog.debug("zoom_changed to " + this.map.getZoom());
      });
      this.map.addListener("tilesloaded", () => {
        this.globalLog.debug("tiles loaded");
      });
    },
    async getPlacesSummaryFromServer() {
      let id = this.$route.params.itineraryId;
      let status = RouteHelper.getQueryStringParam("status");
      // @ts-ignore
      var result = await ItineraryRepo.getItineraryWithSteps(id, status);
      this.itinerary = result.itinerary;
      this.creator = result.sherpa;
      this.setTitle();
      // Set all steps, the marker logic obviously can't show those without a marker
      this.allSteps = this.itinerary.steps;

      BoundsLogic.fitMapToLocations(this.map, this.itinerary?.steps);

      this.drawRoutePath();

      MetricSender.exploreAdventure(this.itinerary.id, this.itinerary.uniqueName);

      // Check if we should auto-show a stop
      let jumpTo = this.getHashParameter("stop");
      if (jumpTo) {
        // We 1 base the query parameter but the code below assumes zero based
        this.changeStop(+jumpTo - 1);
        this.showStopDetails = true;
        location.hash = ""; // Clear this out since we don't update it (and any copy/paste should start at the first stop)
      }
    },
    async authLoaded() {
      let authService = getInstance();
      if (authService.isAuthenticated) {
        await this.checkShowShareStopButton();
      }
    },
    // Checks if we should show the Share Stop button
    async checkShowShareStopButton() {
      // ONLY call this if the user is authenticated.  It's the only authenticated endpoint on this page
      let id = this.$route.params.itineraryId;
      let status = RouteHelper.getQueryStringParam("status");
      let uri = `${import.meta.env.VITE_API_URL}/itineraries/${id}/steps/show-share`;
      if (status) uri += `?status=${status}`;
      const { data } = await axios.get(uri);
      this.showShareStopsButton = data;

      // Check if we should auto-show the share UI
      let jumpTo = this.getHashParameter("sharestop");
      if (jumpTo) {
        // Note: I think we have a possible race condition here depending how fast auth loads vs. the stop details.  Currently we only link here via a new window so auth always takes longer.
        // We 1 base the query parameter but the code below assumes zero based
        this.changeStop(+jumpTo - 1);
        this.showStopDetails = true;
        this.shareVisible = true;
        location.hash = ""; // Clear this out since we don't update it (and any copy/paste should start at the first stop)
      }
    },
    getHashParameter(key: string): string | null {
      if (window.location.hash.length < 2) return null;
      const parsedHash = new URLSearchParams(window.location.hash.substring(1));
      return parsedHash.get(key);
    },
    async getAttributedToCustomer() {
      let id = this.$route.params.itineraryId;
      let uri = `${import.meta.env.VITE_API_URL}/itineraries/${id}/attributed-customer`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await AdminContext.defaultOrgInContext();
      var communityIdInContext = AdminContext.getCommunityIdInContext();
      if (communityIdInContext) uri += `?preferredCommunityId=${communityIdInContext}`;
      const { data } = await axios.get(uri);
      this.community = data;
    },
    setTitle() {
      if (this.itinerary) {
        this.title = this.itinerary.title;
      }
    },
    defaultEnableZoom() {
      var width = window.innerWidth;
      var height = window.innerHeight;
      // NOTE! Google's Zoom performs really poorly on large screens.
      // Originally defaulted it to off if you're over ~1080p, but updated so only mobile auto-zooms.  Desktop defaults to just panning.
      this.enableZoomAnimation = width <= 500;
      this.globalLog.info(`EnableZoom=${this.enableZoomAnimation} Window width=${width}, height=${height}, pixelRatio=${window.devicePixelRatio}`);
    },
    async sleep(milliseconds: number) {
      await new Promise(r => setTimeout(r, milliseconds));
    },
    async startClick() {
      if (!this.itinerary) return;

      const stop = this.allSteps[this.currentStopIndex];

      // If we have a lat/lng do a nice transition
      if (stop.latitude) {
        this.showStartButton = false;
        // Center and Zoom in and open the first step
        this.updateMapLocation(stop.latitude, stop.longitude);
        this.smoothMapZoom(this.zoomInOnStopLevel, async () => {
          await this.sleep(this.enableZoomAnimation ? 1000 : 500); // Allow more time for the zoom
          this.showStopDetails = true;
          await this.scrollToTop();
        });
      } else {
        // Just show the stop
        this.showStopDetails = true;
        await this.scrollToTop();
      }
    },
    closeStopDetails() {
      this.showStopDetails = false;
    },
    markerClick(index) {
      this.showStartButton = false;
      this.currentStopIndex = index;
      this.showStopDetails = true;
      // something like this? this.$refs['info' + i].openWindow();
      this.checkForRecommendedAdventuresLoad(this.currentStopIndex);
    },
    async infoWindowClick(index) {
      this.sliderToggled = false;
      this.showStartButton = false;
      this.currentStopIndex = index;
      this.showStopDetails = true;
      await this.scrollToTop();
      this.checkForRecommendedAdventuresLoad(this.currentStopIndex);
    },
    onSliderToggleUp: function () {
      this.sliderToggled = !this.sliderToggled;
    },
    async changeStop(indexAdjustment: number) {
      this.sliderToggled = false;
      var oldStopIndex = this.currentStopIndex;
      var newStopIndex = this.currentStopIndex + indexAdjustment;
      this.currentStopIndex = newStopIndex;
      // If we're going to the post-stops experience OR coming from it (via the previous button)
      // Then the below transitions don't apply
      if (newStopIndex >= this.allSteps?.length || oldStopIndex >= this.allSteps?.length) {
        return;
      }
      this.checkForRecommendedAdventuresLoad(this.currentStopIndex);
      const oldStop = this.allSteps?.[oldStopIndex];
      const newStop = this.allSteps?.[newStopIndex];
      this.showStopDetails = false;
      // If we have a lat/lng do a nice transition
      if (oldStop?.latitude && newStop?.latitude) {
        // This pan just "jumps".  If we zoom out so both stops are in view then it'll be a smooth pan
        this.ensureAllStopsInView([oldStop, newStop], async () => {
          await this.sleep(500); // Seems needed if steps are far apart, otherwise the map jumps
          this.updateMapLocation(newStop.latitude, newStop.longitude);
          if (this.enableZoomAnimation) {
            await this.sleep(1000); // Give it time to pan, we don't currently listen for this completing
          }
          this.smoothMapZoom(this.zoomInOnStopLevel, async () => {
            await this.sleep(600); // Wait a second before we overlay the steps, without this delay it's jarring
            this.showStopDetails = true;
            await this.scrollToTop();
          });
        });
      } else {
        // Otherwise just show the next stop
        this.showStopDetails = true;
        await this.scrollToTop();
      }
    },
    async scrollToTop() {
      // NOTE! ScrollTop seems to need a negative value here, not sure why that is.
      // Seem to need to sleep until things are rendered
      await this.sleep(250);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.$refs.adventurecardslider) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.adventurecardslider.scrollTop = -5000;
      }
      if (this.$refs.adImgsRef) {
        const el = this.$refs.adImgsRef as HTMLElement;
        const shadow = this.$refs.shadow as HTMLElement;
        el.addEventListener("scroll", function () {
          if (el.scrollHeight - el.scrollTop - el.offsetHeight <= 100) {
            shadow.style.display = "none";
          } else {
            shadow.style.display = "block";
          }
        });
      }
    },
    updateMapLocation: function (latitude: number, longitude: number) {
      // https://diegoazh.github.io/gmap-vue/#quickstart-webpack-nuxt
      this.map.panTo({ lat: latitude, lng: longitude });
    },
    ensureAllStopsInView(stops: Array<ItineraryStep>, callback: () => void) {
      if (this.allStopsInView(stops)) {
        callback();
        return;
      }
      // Otherwise zoom out
      const currentZoom = this.map.getZoom();
      const targetZoom = currentZoom - 1;
      if (currentZoom < this.maxAutoZoomOutLevel) {
        this.globalLog.info("Max zoom out reached: " + currentZoom);
        return;
      }

      globalThis.google.maps.event.addListenerOnce(this.map, "zoom_changed", event => {
        this.globalLog.info("AllStopsInView: zoom_changed to " + this.map.getZoom());
        this.ensureAllStopsInView(stops, callback);
      });
      setTimeout(() => {
        this.map.setZoom(targetZoom);
      }, 80);
    },
    allStopsInView(stops: Array<ItineraryStep>): boolean {
      const currentMapBounds = this.map.getBounds();
      for (let i = 0; i < stops.length; i++) {
        let stop = stops[i];
        // If any stop isn't in the bounds, return false
        if (!currentMapBounds.contains(new globalThis.google.maps.LatLng(stop.latitude, stop.longitude))) {
          this.globalLog.debug("AllStops NOT in view");
          return false;
        }
      }
      // If we got this far they're all in view
      this.globalLog.debug("AllStops in view");
      return true;
    },
    smoothMapZoom(targetZoom: number, callback: () => void) {
      // Inspired by https://stackoverflow.com/a/34007969/336486
      var currentZoom = this.map.getZoom();
      // If we've reached target zoom OR if it's disabled (for perf reasons)
      if (currentZoom === targetZoom || !this.enableZoomAnimation) {
        callback();
        return;
      }

      globalThis.google.maps.event.addListenerOnce(this.map, "zoom_changed", event => {
        this.globalLog.info("SmoothZoom: zoom_changed to " + this.map.getZoom());
        this.smoothMapZoom(targetZoom, callback);
      });
      let zoomMultiple = 2;
      let newZoomLevel = currentZoom + (targetZoom > currentZoom ? zoomMultiple : -zoomMultiple);
      // Verify we didn't jump over the targetZoom
      if ((targetZoom > currentZoom && newZoomLevel > targetZoom) || (targetZoom < currentZoom && newZoomLevel < targetZoom)) {
        newZoomLevel = targetZoom;
      }

      setTimeout(() => {
        this.map.setZoom(newZoomLevel);
      }, 300);
    },
    drawRoutePath() {
      // From "overview_polyline" of the DirectionService results
      let overviewPolyline = this.itinerary.directionsOverviewPolyline;

      if (overviewPolyline) {
        let decodedPath = globalThis.google.maps.geometry?.encoding.decodePath(overviewPolyline);
        const directionsPath = new globalThis.google.maps.Polyline({
          path: decodedPath,
          geodesic: false,
          strokeColor: "#669DF6",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        directionsPath.setMap(this.map);
      }
    },
    isDraft(): boolean {
      let draft = this.itinerary.status !== "Live";
      return draft;
    },
    navigateClose() {
      const navigatedFromOurSite = this.$route.params.fromShrpa ? true : false;
      if (navigatedFromOurSite || this.$route.query.ret === "back") {
        this.globalLog.info("Using history.back");
        // Use browser history to go back so the back button from the Preview works.
        // It should be rare that user comes directly into this page not from Shrpa.
        history.back();
      } else {
        this.globalLog.info("Navigating back to Preview");
        // Otherwise send them back to the Preview page (ex. if they came in from another site)
        var identifier = this.getAdventureIdForRoute();
        var params = { itineraryId: identifier };
        var queryParams = {};
        if (this.isDraft()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          queryParams.status = this.itinerary.status;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$router.push({ name: "ItineraryPreview", params: params, query: queryParams });
      }
    },
    getAdventureIdForRoute(): string {
      return this.itinerary.uniqueName ? this.itinerary.uniqueName : this.itinerary.id;
    },
    getDistanceToNextStop(stop: ItineraryStep): string {
      const meters = stop.distanceToNextStopInMeters;
      if (meters < 800) return "< 1 mile";
      if (meters < 2400) return "1 mile";

      const miles = Math.round(meters / 1609.34);
      return miles + " miles";
    },
    getTimeToNextStop(stop: ItineraryStep): string {
      const seconds = stop.timeToNextStopInSeconds;
      if (seconds < 100) {
        return "1 minute";
      }
      const minutes = Math.round(seconds / 60.0);

      if (minutes > 59) {
        const hours = Math.floor(minutes / 60.0);
        var remainingMinutes = minutes - hours * 60;
        const hourTerm = hours === 1 ? "hour" : "hours";
        let result = `${hours} ${hourTerm}`;
        if (remainingMinutes > 0) result += ` ${remainingMinutes} minutes`;

        return result;
      }

      return minutes + " minutes";
    },
    getWebsiteUri(website): string {
      // Forces an absolute path (ex. if they start with www), probably better to do this in the CMS?
      if (website && website.startsWith("http") != true) {
        return "http://" + website;
      } else {
        return website;
      }
    },
    getGoogleMapText(stop: ItineraryStep): string {
      if (stop?.address) {
        return stop.address;
      }
      return "View on Google Maps"; // 'Get Directions';
    },
    getGoogleMapLink(stop: ItineraryStep): string {
      // https://developers.google.com/maps/documentation/urls/get-started and https://stackoverflow.com/a/51993646 and https://stackoverflow.com/a/52943975
      var uri = `https://www.google.com/maps/search/?api=1`;
      if (stop.latitude && stop.longitude) {
        uri += `&query=${stop.latitude},${stop.longitude}`;
      } else {
        uri += `&query=${encodeURIComponent(stop.address)}`;
      }

      // If they have a Google PlaceId then link to google with that so they get the business details.
      if (stop.externalId) {
        uri += `&query_place_id=${encodeURIComponent(stop.externalId)}`;
      }
      return uri;
    },
    async checkForRecommendedAdventuresLoad(stopIndex: number) {
      if (this.recommendedAdventures.length > 0) {
        // Already loaded
        return;
      }
      // If we're on the last stop, pre-load recommended adventures for the post-stop experience
      if (this.allSteps && stopIndex >= this.allSteps.length - 1) {
        this.globalLog.info("Loading recommended adventures");
        await this.loadRecommendedAdventures();
      }
    },
    async loadRecommendedAdventures() {
      let uri = `${import.meta.env.VITE_API_URL}/recommendations/itineraries?adventureId=${this.itinerary.id}`;
      const { data } = await axios.get(uri);
      this.recommendedAdventures = data;
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.hidden {
  display: none;
}
.flexGrow {
  flex: 1;
}

.map-container {
  width: 100%;
  margin-top: 0px;
  position: relative;
  overflow: hidden;

  :deep(.vue-map-container) {
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 420px) {
      width: 100%;
      height: 90vh;
      overflow-y: hidden;
    }
  }

  .go-map-btn {
    position: absolute;
    bottom: 10%;
    left: 48%;

    @media screen and (max-width: 420px) {
      width: 60%;
      left: 20%;
      bottom: 5rem;
    }
  }

  #nonMapSection {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    z-index: 102;
    @media screen and (max-width: 420px) {
      position: fixed;
      bottom: 0;
      top: initial;
      transform: initial;
      transition: bottom 0.35s ease 0s;
      width: 100%;
      height: auto;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .non-map-content {
      max-width: 980px;
      color: black;
      margin: 0 auto;
      @media screen and (max-width: 420px) {
        width: 100%;
      }
      .adventure-cards {
        @media screen and (max-width: 420px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: hidden;
          padding: 0.6rem;
          margin: 0;
        }
      }
      .adventure-card {
        width: 100% !important;
        transform: unset !important;
        background: unset !important;
        @media screen and (max-width: 420px) {
          display: flex;
          flex-direction: column;
        }
        .adventure-content {
          background: white !important;
          padding: 2.4rem 0.5rem 1rem 1rem;
          position: relative;
          box-shadow: 0px 0px 4px 0px #d2d2d2;
          border-radius: 4px;
          max-height: 85vh;
          @media screen and (max-width: 420px) {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 5.5rem 0.5rem 1rem 0.5rem;
            overflow: hidden;
            max-height: 75vh;
          }
          #post-stops-content {
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: auto;
            max-height: 80vh;
            overflow-x: hidden;
            .slider-container {
              padding: 0 2em;
              @media screen and (max-width: 420px) {
                padding: 0;
                margin: 0;
              }
            }
            .centered-text {
              text-align: center;
            }
            #adventure-recommendations {
              @media screen and (max-width: 420px) {
                margin-top: 3em;
              }
            }
            #created-by {
              background-color: white;
            }
            hr {
              border-color: $off-white-dull;
              width: 80%;
              margin-top: 2.5em;
            }
          }
          #post-stops-content::-webkit-scrollbar {
            display: none;
          }
          &.noimgs {
            max-width: 60%;
            margin: 0 auto;
            @media screen and (max-width: 420px) {
              max-width: 100%;
            }
            .adventure-cards-slider {
              width: 100%;
              @media screen and (max-width: 420px) {
                & > a {
                  flex: 0;
                  min-width: calc(100vw - 90px);
                  margin-top: 0;
                  margin-bottom: 0;
                  margin-right: 15px;
                  &:first-child:nth-last-child(1) {
                    min-width: calc(100vw - 50px);
                  }
                }
              }
              .adventure-card-left {
                width: 100%;
              }
            }
          }
          .icon.close {
            position: absolute;
            top: 0.8rem;
            right: 1rem;
            font-size: 1.6rem;
            color: #058587;
            margin: 0;
            @media screen and (max-width: 420px) {
              right: 0.6rem;
              z-index: 999;
            }
          }
          .icon.map.marker.alternate {
            margin-top: 0.3em;
            @media screen and (max-width: 420px) {
              margin-top: 0;
            }
          }
          .adventure-title-address {
            display: flex;
            align-items: flex-start;
            margin-top: 0.5rem;
            margin-bottom: 1.2rem;
            overflow-wrap: break-word;

            @media screen and (max-width: 420px) {
              display: none;
            }
            @media screen and (max-width: 1240px) {
              margin-bottom: 0.8rem;
              overflow-wrap: break-word;
            }
            &.mobile {
              display: none;
              @media screen and (max-width: 420px) {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background: white;
                z-index: 99;
                margin: 0 0 15px 0;
                padding: 1rem 3rem 0.5rem 0.5rem;
                overflow-wrap: break-word;
                &.hidden {
                  display: none;
                }
                .icon {
                  margin-top: 0.3rem !important;
                }
              }
              a {
                @media screen and (max-width: 420px) {
                  text-decoration: underline;
                }
              }
            }
            .icon {
              margin-right: 0.5rem;
            }
            h3 {
              margin: 0;
            }
            #stop-address {
              font-size: 1.4rem;
              @media screen and (max-width: 420px) {
                font-size: 1.3rem;
              }
            }
            a:hover {
              text-decoration: underline;
            }
            .share-btn {
              margin: 1rem 0 0 0;
              // text-align: center;
            }
          }

          .adventure-cards-slider {
            display: flex;
            overflow-y: auto;
            transition: all 0.3s ease-out;
            max-height: 75vh;
            @media screen and (max-width: 420px) {
              flex-direction: column-reverse;
              overflow: auto;
              max-width: 400px;
              padding-right: initial;
              position: relative;
              margin-top: 1.4rem;
              &::-webkit-scrollbar {
                display: none;
              }
            }

            .adventure-card-left {
              width: 40%;
              position: relative;
              max-height: 100%;
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 420px) {
                width: 100%;
              }
              .adventure-others-infos {
                height: auto;
                overflow: auto;
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 0 2rem;
                @media screen and (max-width: 420px) {
                  padding: 0;
                }
                @media screen and (max-width: 1240px) {
                  padding: 0 1.2rem;
                }
                .next-box {
                  @media screen and (max-width: 420px) {
                    display: block;
                  }
                }
                .adventure-description {
                  white-space: pre-line;
                  font-size: 1.2rem;
                  padding: 0.4em 1rem 0.4em 0.5em;
                  margin-bottom: 2.5rem;
                  line-height: 2.2rem;
                  background: #fbfbfb;
                  border-radius: 0.6rem;
                  overflow-wrap: break-word;
                  @media screen and (max-width: 420px) {
                    flex: unset;
                    max-height: unset;
                    font-size: 1rem;
                    line-height: 1.3rem;
                  }
                  @media screen and (max-width: 1240px) {
                    font-size: 1rem;
                    line-height: 1.6rem;
                    padding: 0.8rem;
                    overflow-wrap: break-word;
                  }
                }
                .tip-box {
                  background: #fae7e3;
                  font-size: 1.2rem;
                  line-height: 2rem;
                  margin: 2rem 0 1rem 1rem;
                  border-radius: 0.6rem;
                  box-shadow: 0px 0px 4px 0px #d2d2d2;
                  position: relative;
                  padding: 0.6rem 0.6rem 0.6rem 2rem;
                  @media screen and (max-width: 420px) {
                    font-size: 1rem;
                    line-height: 1.3rem;
                    padding-left: 1rem;
                  }
                  @media screen and (max-width: 420px) {
                    font-size: 1rem;
                    line-height: 1.6rem;
                  }
                  .shrpa-logo {
                    position: absolute;
                    top: -1.2rem;
                    left: -1rem;
                    width: 2.6rem;
                    height: 2.6rem;
                    border-radius: 50%;
                    padding: 4px;
                    background: #dc6047;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 420px) {
                      position: absolute;
                      top: -0.8rem;
                      left: -1rem;
                      width: 2rem;
                      height: 2rem;
                    }
                    img {
                      height: 85%;
                      transform: rotate(30deg);
                    }
                  }
                }
                .links-box {
                  background: #d9eded;
                  border-radius: 0.6rem;
                  padding: 0.6rem 0.6rem 0.6rem 2rem;
                  margin: 2rem 0 1rem 1rem;
                  font-size: 1.1rem;
                  position: relative;
                  box-shadow: 0px 0px 4px 0px #d2d2d2;
                  @media screen and (max-width: 420px) {
                    padding-left: 1rem;
                  }
                  .links-icon {
                    position: absolute;
                    top: -1.2rem;
                    left: -1rem;
                    width: 2.6rem;
                    height: 2.6rem;
                    border-radius: 50%;
                    padding: 4px;
                    background: #058587;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 420px) {
                      align-items: inherit;
                      position: absolute;
                      top: -0.8rem;
                      left: -1rem;
                      width: 2rem;
                      height: 2rem;
                    }
                    i {
                      color: white;
                      font-size: 1.4rem;
                      margin: 0;
                      @media screen and (max-width: 420px) {
                        font-size: 1.2rem;
                      }
                    }
                  }
                  a {
                    color: #058587;
                    font-size: 1.2rem;
                    @media screen and (max-width: 420px) {
                      font-size: 1rem;
                      line-height: 1.3rem;
                    }
                    @media screen and (max-width: 1240px) {
                      font-size: 1rem;
                      line-height: 1.6rem;
                    }
                  }
                  .adventure-info-item {
                    word-break: keep-all;
                    margin: 0.4em 0 0.4em 0;
                    text-wrap: balance;
                  }
                }
              }
            }

            .adventure-card-right {
              width: 60%;
              max-height: 100%;
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 420px) {
                width: 100%;
                // margin-top: 1.5rem;
              }
              .next-box {
                @media screen and (max-width: 420px) {
                  display: none;
                }
              }
              .adventure-card-imgs {
                padding: 0 14px;
                max-height: inherit;
                display: flex;
                flex-wrap: wrap;
                overflow: auto;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                position: relative;
                &::-webkit-scrollbar {
                  display: none;
                }
                &.mobile {
                  display: none;
                }
                @media screen and (max-width: 420px) {
                  flex-wrap: nowrap;
                  margin-bottom: 1rem;
                  margin-top: 1rem;
                  display: none;
                  &.mobile {
                    display: flex;
                    &.shareStopPadding {
                      //If "Share this stop" button is not enabled, this padding is conditionally removed
                      padding-top: 2rem;
                    }
                  }
                }
                .shadow-box {
                  content: "";
                  position: sticky;
                  bottom: 0;
                  height: 100px;
                  width: 100%;
                  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                }
                .adventure-card-img {
                  @media screen and (max-width: 420px) {
                    //width: 95%; Letting it keep the correct aspect ratio
                    flex: 0 0 auto;

                    /*&:nth-child(1) { //see width comment above
                          width: 92%;
                        }*/
                  }

                  //NOTE! Some of this was moved to main.scss so it can be applied to the VideoRender component also
                }
              }
            }

            .info-box h4 {
              margin: 0;
              font-size: 1.4rem;
              @media screen and (max-width: 420px) {
                font-size: 1.2rem;
                margin-bottom: 0.6rem;
              }
              @media screen and (max-width: 1240px) {
                font-size: 1.3rem;
              }
            }
            .next-box {
              background: #f8f8f8;
              border-radius: 0.6rem;
              padding: 1rem;
              margin: 1rem 0.5rem 0 1rem;
              font-size: 1.4rem;
              position: relative;
              box-shadow: 0px 0px 4px 0px #d2d2d2;
              @media screen and (max-width: 420px) {
                margin: 1rem 0 1rem 0;
              }
              @media screen and (max-width: 1240px) {
                font-size: 1.2rem;
                line-height: 1.4rem;
              }
              .next-info {
                margin-top: 1rem;
                i {
                  margin-right: 1rem;
                  font-size: 1.2rem;
                }
              }
            }
          }
        }

        .control-btns {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          background: white;
          box-shadow: 0px 0px 4px 0px #d2d2d2;
          border-radius: 4px;
          @media screen and (max-width: 420px) {
            margin-top: 0.6rem;
          }
          .adventure-card-arrow-btn {
            display: flex;
            align-items: center;
            background: white !important;
            color: #058587 !important;
            margin: 0;
            text-align: right;
            span {
              font-weight: normal;
              line-height: 2rem;
              @media screen and (max-width: 420px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.close-map-button {
  display: flex;
  position: absolute;
  z-index: 101;
  top: 10px;
  right: 80px;
  color: black;
  @media screen and (max-width: 420px) {
    right: 5px;
  }
}
.close-btn {
  //margin-right: 0;
  background-color: white;
  //border-top-right-radius: 0;
  //border-bottom-right-radius: 0;
}
.stops-close-btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0 6px;
  border-left: 2px solid lightgray;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.share-adventure-modal {
  .content.scrolling {
    max-height: calc(95vh - 10em);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.side-margins {
  margin: 0 8px 0 13px;
}
@media (max-width: 420px) {
  .horizontal-scroll {
    width: calc(100% - 15px);
    max-width: calc(100% - 15px);
    height: 230px;
    margin-top: 10px;
    display: block !important;
    position: relative;
    overflow-x: scroll;
    column-gap: 0 !important;
    column-count: unset !important;
    white-space: nowrap;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .columns-layout__item {
      height: calc(100% - 9px);
      margin: 0 8px 0 0 !important;
      display: inline-flex;

      * {
        margin: 0 !important;
        padding: 0 !important;
      }

      a,
      span {
        height: 100%;
        display: flex !important;
      }

      img {
        width: auto !important;
        height: 100%;
      }
    }
  }
}
</style>
