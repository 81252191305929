<template>
  <component class="adventure-placeholder" :is="props.to ? 'router-link' : 'div'" :to="props.to" target="_blank">
    <div class="adventure-placeholder__plus">+</div>
    <div class="adventure-placeholder__text">Add<br />adventure</div>
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: any;
  }>(),
  {
    to: null,
  }
);
</script>

<style scoped lang="scss">
// Adventure placeholder ======================================================
.adventure-placeholder {
  min-height: 240px;
  padding-bottom: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  color: rgba(0, 0, 0, 0.7);
  font-family: sans-serif;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border: 2px #a6aaac dashed;
    border-radius: 8px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #f0f0f0;
    transition:
      width 0.07s ease-in-out,
      left 0.07s ease-in-out;
  }

  &:hover::before {
    width: calc(100% + 6px);
    left: -3px;
  }

  &__plus {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font:
      100 60px/60px "Quicksand",
      sans-serif;
    text-align: center;

    &::before {
      content: "";
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 50%;
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%) scale(0.5);
      background: rgba(#5b5b5b, 0.15);
      opacity: 0;
      transition:
        transform 0.09s ease-in-out,
        opacity 0.09s ease-in-out;
    }
  }

  &:hover &__plus::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  &__text {
    font-size: 17px;
    line-height: 19px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-decoration-style: dashed;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-align: center;
  }

  &:hover &__text {
    text-decoration: none;
  }
}
</style>
