<template>
  <div class="itinerary-details">
    <template v-if="itineraryNotFound">Itinerary not found</template>

    <template v-else>
      <!-- Gallery section -->
      <div class="gallery-section itinerary-details__gallery-section">
        <RouterLink class="gallery-section__main-image-wrap" :to="adventurePreviewLinkTo" :target="props.target">
          <Loader v-if="!isImageLoaded" style="margin: 0" />
          <img
            :class="{
              'gallery-section__main-image': true,
              'gallery-section__main-image--blurred': !isImageLoaded,
            }"
            :src="FileUtils.getAdventureImagePath(selectedImageId, true)"
            alt="Photo"
            @load="() => (isImageLoaded = true)"
          />
        </RouterLink>

        <TinyThumbnailsCarousel
          class="gallery-section__thumbnails-carousel"
          :thumbnailsList="itineraryDetails?.allImages || []"
          @selectImage="
            imageId => {
              isImageLoaded = false;
              selectedImageId = imageId;
            }
          "
        />
      </div>
      <!-- / Gallery section -->

      <div class="itinerary-details__text-content">
        <div class="itinerary-details__title-n-description">
          <RouterLink class="global-h3 itinerary-details__title" :to="adventurePreviewLinkTo" :target="props.target">
            {{ props.itinerary.title }}
          </RouterLink>

          <div class="itinerary-details__description">
            {{ itineraryDetails?.whatToExpectText }}
          </div>
        </div>

        <!-- Roadmap section -->
        <div class="roadmap-section itinerary-details__roadmap-section">
          <div class="global-h4 roadmap-section__title">Destinations</div>

          <div
            :class="{
              'roadmap-section__roadmap-scheme-wrap-1': true,
              'roadmap-section__roadmap-scheme-wrap-1--with-left-curtain': roadmapSchemeCurtains.isLeft.value,
              'roadmap-section__roadmap-scheme-wrap-1--with-right-curtain': roadmapSchemeCurtains.isRight.value,
            }"
          >
            <div class="roadmap-section__roadmap-scheme-wrap-2" ref="domRefRoadmapSchemeContainer">
              <ul class="roadmap-section__roadmap-scheme">
                <li class="global-h6 roadmap-section__stop" v-for="stopTitle in itineraryDetails?.stepNames" :key="stopTitle">{{ stopTitle }}</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- / Roadmap section -->
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { onMounted, computed, ref, nextTick, watch } from "vue";
import { RouteLocationRaw } from "vue-router";
import FileUtils from "@logic/FileUtils";

// Types
import { Itinerary } from "@contracts/itinerary";

// Components
import Loader from "@components/Loader/Loader.vue";
import TinyThumbnailsCarousel from "@components/TinyThumbnailsCarousel.vue";

// Composables
import useScrollCurtains from "@composables/useScrollCurtains";

const props = withDefaults(
  defineProps<{
    itinerary: Itinerary;
    target: "_self" | "_blank";
  }>(),
  {
    itinerary: () => ({}) as Itinerary,
    target: "_self",
  }
);

// Is image loaded ============================================================
const isImageLoaded = ref<boolean>(false);

// Selected image id ==========================================================
const selectedImageId = ref<string>(null);

// Load itinerary details =====================================================
const itineraryDetails = ref<Itinerary>(null);
const itineraryNotFound = ref<boolean>(false);
const isLoading = ref<boolean>(false);

onMounted(async () => {
  isLoading.value = true;
  await loadItineraryDetails(props.itinerary.id, props.itinerary.status);
  isLoading.value = false;

  selectedImageId.value = itineraryDetails.value?.tileImageLocation || itineraryDetails.value?.allImages?.[0];
});

async function loadItineraryDetails(itineraryId: string, status: string) {
  let uri = `${import.meta.env.VITE_API_URL}/itineraries/${itineraryId}/preview?status=${status}`;
  const response = await axios.get<Itinerary>(uri);

  if (response.status == 204) {
    itineraryNotFound.value = true;
  } else {
    itineraryDetails.value = response.data;
  }
}

// Link building ==============================================================
const adventurePreviewLinkTo = computed((): RouteLocationRaw => {
  const isLive = props.itinerary.status === "Live";
  const link = {
    name: "ItineraryPreview",
    params: { itineraryId: isLive ? props.itinerary.uniqueName : props.itinerary.id },
    query: {},
  };
  if (!isLive) link.query = { status: props.itinerary.status };
  return link;
});

// Set roadmap scheme container curtains ======================================
const domRefRoadmapSchemeContainer = ref<HTMLElement>(null);

const roadmapSchemeCurtains = useScrollCurtains(domRefRoadmapSchemeContainer);

onMounted(async () => {
  await nextTick();
  roadmapSchemeCurtains.setCurtainsVisibility();
});

watch(itineraryDetails, async () => {
  await nextTick();
  roadmapSchemeCurtains.setCurtainsVisibility();
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Gallery section ============================================================
.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &__main-image-wrap {
    width: 100%;
    min-width: 100%;
    aspect-ratio: 100/65;
    margin-bottom: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__main-image {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    object-fit: cover;
    background: rgba(230, 230, 230, 1);

    &--blurred {
      filter: blur(10px);
      transform: translate3d(1px);
    }
  }

  &__thumbnails-carousel {
    width: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Roadmap section ============================================================
.roadmap-section {
  &__title {
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__roadmap-scheme-wrap-1 {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      width: 60px;
      height: 100%;
      position: absolute;
      inset: 0 auto auto auto;
      z-index: 1;
      background: linear-gradient(90deg, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 0) 100%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s ease-in-out;
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      background: linear-gradient(90deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 100%);
    }

    &--with-left-curtain {
      &::before {
        opacity: 1;
      }
    }
    &--with-right-curtain {
      &::after {
        opacity: 1;
      }
    }
  }

  &__roadmap-scheme-wrap-2 {
    width: 100%;
    position: relative;
    z-index: 0;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: thin; /* Firefox */
    scrollbar-gutter: stable;
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }
  }

  &__roadmap-scheme {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    position: relative;
    z-index: 0;
  }

  &__stop {
    width: 95px;
    min-width: 135px;
    padding: 20px 30px 0 0;
    box-sizing: border-box;
    position: relative;
    font-weight: 600;

    &::before {
      content: "";
      width: 13px;
      height: 13px;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 1;
      background: rgba(17, 134, 137, 1);
    }

    &::after {
      content: "";
      width: 100%;
      height: 0;
      border-bottom: 2px rgba(0, 0, 0, 0.3) dashed;
      position: absolute;
      inset: 6px auto auto 0;
      z-index: 0;
    }

    &:last-child::after {
      display: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Itinerary details ==========================================================
.itinerary-details {
  padding: 25px 20px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  background: rgba(243, 243, 243, 1);

  &__gallery-section {
    width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    min-width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    margin-right: var(--grid-col-gap-width);
  }

  &__text-content {
    width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 20px);
    min-width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 20px);
  }

  &__title-n-description {
    margin-bottom: 23px;
  }

  &__title {
    margin: 0 0 8px 0;
    display: inline-flex;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    color: rgba(0, 0, 0, 0.5);
    font-family: sans-serif;
  }

  &__roadmap-section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .itinerary-details {
    &__gallery-section {
      width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
      min-width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      min-width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .itinerary-details {
    flex-direction: column;

    &__gallery-section {
      width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      min-width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      margin: 0 0 20px 0;
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7 - 40px);
      min-width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7 - 40px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .itinerary-details {
    flex-direction: column;

    &__gallery-section {
      width: calc(var(--grid-col-width) * 4 + var(--grid-col-gap-width) * 3 - 20px);
      min-width: calc(var(--grid-col-width) * 4 + var(--grid-col-gap-width) * 3 - 20px);
      margin: 0 0 20px 0;
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 40px);
      min-width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 40px);
    }
  }
}
</style>
