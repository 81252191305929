<template>
  <div>
    <div class="ui container">
      <div v-if="isLoading" style="margin-bottom: 5em">
        <Loader></Loader>
        <h3 style="text-align: center">Loading...</h3>
      </div>
      <div v-else-if="!campaignData">
        <!--Custom "Not found" message-->
        <h3 style="margin-top: 30px">Sorry! This upload link doesn't exist.</h3>
        <div>
          If you think this is in error feel free to reach out to support@shrpa.com<br />
          Or you can return to the Destination Page<br />
          <router-link :to="{ name: 'Pages', params: { pageId: pageId } }" class="ui primary button">Destination Page</router-link>
        </div>
      </div>
      <div v-else-if="campaignData.customerIsDisabled">
        <!--Customer distabled -->
        <NoteWithIcon icon="warning_2-5" color="yellow">
          <template #text>
            <h3>This Upload Link is no longer active</h3>
            <div>Please contact the destination for more information.</div>
          </template>
        </NoteWithIcon>
      </div>
      <template v-else>
        <!--Header-->
        <h2 class="ui header">
          <img :src="`${contentBaseUri}/cms/images/orgs/${campaignData.customerIcon}`" />
          <div class="content">
            {{ campaignData.customerName }}
          </div>
        </h2>

        <!--Deleted/Expired UI. Currently we just have an "archive" button so we don't ever have expiry dates in the future. -->
        <div v-if="campaignData.deletedDateTime || campaignData.expiryDateTime">
          <h3 style="margin-top: 15px">Sorry, this upload link is no longer active.</h3>
          <div>The time period to upload with this link has likely passed. The community may have another you can use.</div>
          <h3>Other Resources</h3>
          <div>
            You can also check out the Destination Page for
            <router-link :to="{ name: 'Pages', params: { pageId: pageId } }" class="ui small primary button">{{ campaignData.customerName }}</router-link>
          </div>
          <div style="margin-top: 10px">If you have additional questions you can always reach out to <CopyText :text="'support@shrpa.com'" /></div>
          <div style="text-align: center; margin-top: 50px">Thanks for visiting Shrpa!</div>
        </div>

        <!--Content upload UI-->
        <template v-else-if="!uploadComplete">
          <div class="ui attached message" style="margin-top: 2rem">
            <div class="header">
              {{ campaignData.title }}
            </div>
            <p style="white-space: pre-wrap">{{ campaignData.description }}</p>
          </div>

          <form class="ui form attached fluid segment">
            <div class="field">
              <UploadPhotoForm :img="images" :showClose="false" :showRemove="true" :autoSelect="false" @imageUploadedToServer="image => images.push(image)" @removeMediaFile="removeImageByServerId" />
            </div>
            <div class="two fields">
              <div class="field">
                <label>Name</label>
                <input v-model="uploaderName" placeholder="Name" type="text" />
              </div>
              <div class="field">
                <label>Email</label>
                <input v-model="uploaderEmail" placeholder="user@domain.com" type="email" />
              </div>
            </div>
            <div class="field">
              <label>(Optional) Any additional info about these images?</label>
              <textarea v-model="additionalInfo" style="height: 80px; min-height: 80px" placeholder="Ex. Location names or context, your social media handle, etc..." />
            </div>
            <div class="inline field">
              <div class="ui checkbox">
                <input v-model="acceptedTerms" type="checkbox" id="terms" />
                <label for="terms" style="display: inline; cursor: pointer; user-select: none"
                  >By uploading images or videos, I grant the community ({{ campaignData.customerName }}) and Shrpa rights per the </label
                ><span style="font-weigth: 500; color: #2c5566; cursor: pointer" @click="showTermsModal = true">Terms and Conditions</span>.
              </div>
            </div>
            <div>
              <span class="ui primary submit button" :class="{ disabled: images.length === 0 || saveMessage !== null }" @click="upload">Upload</span>
              <h3 class="save-message">{{ saveMessage }}</h3>
            </div>
            <div class="validation-errors">
              <div v-for="error in validationErrors" :key="error">{{ error }}</div>
            </div>
          </form>
        </template>

        <!--Post-upload Experience-->
        <template v-else>
          <h1 class="ui header">
            <div class="content">
              Upload Complete
              <!-- <div class="sub header">Thanks for uploading {{images.length}} photos and helping to highlight your local community!</div> -->
            </div>
          </h1>
          <div class="ui info icon message" style="margin-top: 2rem; flex-direction: column; align-items: flex-start">
            <div style="display: flex; align-items: center">
              <img class="ui tiny image hands" src="https://cdn.shrpa.com/images/misc/high-five.png" style="margin-right: 1rem" />
              <div class="content">
                <div class="header">Thanks for uploading!</div>
                <p>Your {{ images.length }} photos and videos will help highlight the local community.</p>
              </div>
            </div>
            <div style="margin-top: 20px">
              <div class="columns-layout columns-layout--4-col">
                <div v-for="image in images.map(i => i.serverId)" :key="image" style="padding: 5px; position: relative" class="columns-layout__item">
                  <template v-if="isVideo(image)">
                    <VideoRender :videoId="image" :showControls="false" :showViewerOnClick="true" :customClasses="'ui fluid rounded image middle aligned centered wow fadeInUp'" />
                  </template>
                  <template v-else>
                    <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                    <a lang="en" hreflang="en" data-fancybox="gallery" :href="`${contentBaseUri}/cms/images/expeditions/${image}`"
                      ><img class="ui fluid rounded image middle aligned centered wow fadeInUp" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`"
                    /></a>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="ui mini icon message">
            <i class="envelope icon"></i>
            <div class="content">
              <p>We'll send a confirmation email to the email address you provided.</p>
            </div>
          </div>
          <div class="row">
            <router-link class="ui primary button" :to="{ name: 'Pages', params: { pageId: pageId } }"> View Community's Page </router-link>
          </div>
        </template>
      </template>
    </div>

    <!--Terms Modal-->
    <SrpModal v-model:isVisible="showTermsModal" :isClosable="true">
      <template #header><h2 class="global-h2">Terms and Conditions</h2></template>
      <template #content>
        <div class="ui form">
          <div>
            By uploading photos or videos you confirm that you have the rights to do so.<br />
            You also consent for these images and videos to be used by the community and Shrpa.<br />
            Shrpa's full Terms are here:
            <router-link lang="en" hreflang="en" target="_blank" :to="{ name: 'Terms' }" style="font-weigth: 500; color: #2c5566; cursor: pointer">Terms and Conditions</router-link>.
          </div>
        </div>
      </template>
      <template #footer>
        <div class="ui basic black button" @click="showTermsModal = false">Done</div>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import UploadPhotoForm from "../CMS/UploadPhotoForm.vue";
import FileUtils from "@logic/FileUtils";
import VideoRender from "@components/VideoRender.vue";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import { useHead } from "@unhead/vue";

interface UserUploadCampaignGetResponse {
  customerId: string;
  customerName: string;
  customerIcon: string;
  customerIsDisabled: boolean;
  campaignId: string;

  title: string;
  description: string;
  isTheDefault: boolean;
  expiryDateTime: string | null;
  deletedDateTime: string | null;
}

export default defineComponent({
  name: "NonUserPhotoUpload",

  components: {
    SrpModal,
    Loader,
    UploadPhotoForm,
    VideoRender,
    CopyText,
    NoteWithIcon,
  },

  data() {
    return {
      title: "Upload Photos",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      pageId: null as string | null,
      campaignId: null as string | null,
      campaignData: null as UserUploadCampaignGetResponse | null,
      isLoading: true,
      saveMessage: null,
      validationErrors: [] as Array<string>,
      uploadComplete: false,

      uploaderName: null,
      uploaderEmail: null,
      additionalInfo: null,
      acceptedTerms: false,
      images: [],

      showTermsModal: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    // @ts-ignore
    this.campaignId = this.$route.params.uploadIdentifier;
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      const { data } = await axios.get<UserUploadCampaignGetResponse>(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign/${this.campaignId.trim()}`);
      this.campaignData = data;

      this.isLoading = false;
    },
    clearValidationErrors(delaySeconds: number) {
      setTimeout(() => {
        this.validationErrors = [];
      }, delaySeconds * 1000);
    },
    validate(): boolean {
      this.validationErrors = [];
      if (this.images.length === 0) {
        this.validationErrors.push("Please upload some content!");
        this.clearValidationErrors(5);
        return false;
      }
      if (this.images.some(i => !i.finishedUploading)) {
        this.validationErrors.push("Content is still uploading...");
        this.clearValidationErrors(5);
        return false;
      }

      if (!this.acceptedTerms) this.validationErrors.push("Please accept the Terms before uploading.");
      if (this.uploaderName === null || this.uploaderName.trim().length === 0 || this.uploaderEmail === null || this.uploaderEmail.trim().length === 0)
        this.validationErrors.push("Please enter a name and email before uploading.");
      if (this.uploaderEmail && (!this.uploaderEmail.includes("@") || !this.uploaderEmail.includes("."))) this.validationErrors.push("Please enter a valid email.");

      return this.validationErrors.length === 0;
    },
    removeImageByServerId(serverId: string) {
      this.images = this.images.filter(i => i.serverId !== serverId);
    },
    async upload() {
      if (!this.validate()) return;

      this.saveMessage = "Uploading...";
      const config = { headers: { "Content-Type": "application/json" } };
      let uri = `${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign/${this.campaignId}`;
      let payload = {
        uploadersName: this.uploaderName.trim(),
        uploadersEmail: this.uploaderEmail.trim(),
        additionalInfo: this.additionalInfo?.trim() || null,
        photos: this.images.map(i => i.serverId),
      };
      await axios.post(uri, JSON.stringify(payload), config);
      this.saveMessage = "Uploaded!";

      this.uploadComplete = true;
    },
    isVideo(assetName: string) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
.validation-errors {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.save-message {
  display: inline;
  margin-left: 10px;
}
</style>
