<template>
  <div class="select-entities">
    <NoteWithIcon v-if="totalAmountOfEntities === 0" color="blue" style="margin-bottom: 22px">
      <template #icon><IconEmbedded name="info-simple_4" /></template>
      <template #text><div class="global-h5">As your creator visits complete, come back to easily share them on social media!</div></template>
    </NoteWithIcon>

    <template v-else>
      <!-- Search input -->
      <div class="search-input select-entities__search-input">
        <IconEmbedded class="search-input__icon" name="magnifier_2-5" />
        <input type="search" class="search-input__input-itself" :placeholder="`Search by ${shareStopSummaryStore.postTypeTitle.toLowerCase()}`" v-model="searchString" ref="searchFieldRef" />
      </div>
      <!-- / Search input -->

      <div class="select-entities__entities-list-wrap">
        <!-- Collabs list -->
        <transition-group v-if="shareStopSummaryStore.editedPost.contextType === 'collabInput'" class="collabs-list select-entities__collabs-list" tag="ul" name="list">
          <li class="collabs-list__collab-wrap1" v-for="collab in filteredCollabInputsList?.slice(0, howManyToShow)" :key="collab.collabInputId">
            <div class="collabs-list__collab-wrap2">
              <CollabSnippet class="collabs-list__collab" :collab="collab">
                <input type="radio" name="collab" :value="collab.collabInputId" v-model="shareStopSummaryStore.editedPost.contextId" />
              </CollabSnippet>
            </div>
          </li>
        </transition-group>
        <!-- / Collabs list -->

        <!-- Adventures list -->
        <transition-group v-if="shareStopSummaryStore.editedPost.contextType === 'adventure'" class="adventures-list select-entities__adventures-list" tag="ul" name="list">
          <li class="adventures-list__adventure-wrap1" v-for="itinerary in filteredItinerariesList?.slice(0, howManyToShow)" :key="itinerary.id">
            <div class="adventures-list__adventure-wrap2">
              <ItineraryTile class="adventures-list__adventure" :itinerary="itinerary" :allowShortImage="false" :key="itinerary.id">
                <template #inputRadio>
                  <input type="radio" name="Itinerary" :value="itinerary.id" v-model="shareStopSummaryStore.editedPost.contextId" />
                </template>
              </ItineraryTile>
            </div>
          </li>
        </transition-group>
        <!-- / Adventures list -->

        <!-- Locations list -->
        <transition-group v-if="shareStopSummaryStore.editedPost.contextType === 'location'" class="locations-list select-entities__locations-list" tag="ul" name="list">
          <li class="locations-list__location-wrap1" v-for="location in filteredLocationsList?.slice(0, howManyToShow)" :key="location.externalId">
            <div class="locations-list__location-wrap2">
              <LocationSnippet class="locations-list__location" :location="location" :key="location.externalId">
                <template #inputRadio>
                  <input type="radio" name="Location" :value="location.externalId" v-model="shareStopSummaryStore.editedPost.contextId" />
                </template>
              </LocationSnippet>
            </div>
          </li>
        </transition-group>
        <!-- / Locations list -->

        <LinkWithIcon v-if="howManyToShow < totalAmountOfEntities" class="select-entities__show-more-button" @click="showMore" isDottedUnderline>
          <template #icon><IconEmbedded name="caret-bottom_2" :size="25" /></template>
          <span>Show more</span><span style="border-color: transparent !important">&nbsp;({{ totalAmountOfEntities - howManyToShow }})</span>
        </LinkWithIcon>
      </div>

      <SrpButton class="select-entities__next-button" @click="loadDataAndSwitchToNext" color="orange" :isDisabled="!shareStopSummaryStore.editedPost.contextId || isLoading">
        Next
        <template #iconRight>
          <IconEmbedded v-if="isLoading" name="loader_3-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
          <IconEmbedded v-else name="arrow-bottom_2-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
        </template>
      </SrpButton>
    </template>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SelectEntities" };</script>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

// Types
import { Itinerary } from "@contracts/itinerary";
import { LocationShareable } from "@contracts/location";

// Components
import CollabSnippet from "@components/collabSnippets/CollabSnippet.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import LocationSnippet from "./LocationSnippet.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Stores
import { useCollabSummariesStore } from "@stores/collabSummaries";
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
import { useItinerariesStore } from "@stores/itineraries";
import { useLocationsStore } from "@stores/locations";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
const collabSummariesStore = useCollabSummariesStore();
const shareStopSummaryStore = useShareStopSummaryStore();
const itinerariesStore = useItinerariesStore();
const locationsStore = useLocationsStore();

const route = useRoute();

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Focus search field =========================================================
const searchFieldRef = ref(null);
onMounted(() => searchFieldRef?.value?.focus());

// Load entities ==============================================================
onMounted(async () => {
  const pageId = String(route.params.pageId);
  const postTypeId = shareStopSummaryStore.editedPost.contextType;

  if (postTypeId === "collabInput") await collabSummariesStore.loadAllCollabSummaries(pageId);
  else if (postTypeId === "adventure") await itinerariesStore.loadAllItineraries(pageId);
  else if (postTypeId === "location") await locationsStore.loadAllLocations(pageId);
});

// Search =====================================================================
const searchString = ref("");

// collabs ============================
const filteredCollabInputsList = computed(() => {
  if (searchString.value) {
    const _searchString = searchString.value.toLowerCase();
    return collabSummariesStore.allCollabSummaries.filter(cs => cs.collabName.toLowerCase().includes(_searchString));
  } else {
    return collabSummariesStore.allCollabSummaries;
  }
});

// itineraries ========================
const filteredItinerariesList = computed<Array<Itinerary>>(() => {
  if (searchString.value) {
    const _searchString = searchString.value.toLowerCase();
    return itinerariesStore.allItineraries.filter(i => i.title.toLowerCase().includes(_searchString));
  } else {
    return itinerariesStore.allItineraries;
  }
});

// locations ==========================
const filteredLocationsList = computed<Array<LocationShareable>>(() => {
  if (searchString.value) {
    const _searchString = searchString.value.toLowerCase();
    return locationsStore.allLocations.filter(i => i.title.toLowerCase().includes(_searchString));
  } else {
    return locationsStore.allLocations;
  }
});

// How many to show ===========================================================
const howManyToShow = ref(4);
onMounted(() => {
  howManyToShow.value = { collabInput: 4, adventure: 6, location: 6 }[shareStopSummaryStore.editedPost.contextType];
});
const showMore = () => (howManyToShow.value += { collabInput: 2, adventure: 6, location: 6 }[shareStopSummaryStore.editedPost.contextType]);

const totalAmountOfEntities = computed<number>(() => {
  const postType = shareStopSummaryStore.editedPost.contextType;

  if (postType === "collabInput") return filteredCollabInputsList.value?.length;
  else if (postType === "adventure") return filteredItinerariesList.value?.length;
  else if (postType === "location") return filteredLocationsList.value?.length;
  else return 0;
});

// Load data and switch to next ===============================================
const isLoading = ref(false);
async function loadDataAndSwitchToNext(): Promise<void> {
  const pageId = String(route.params.pageId);
  const postType = shareStopSummaryStore.editedPost.contextType;
  const contextId = shareStopSummaryStore.editedPost.contextId;

  isLoading.value = true;
  await shareStopSummaryStore.loadShareableSummaryDetails(pageId, postType, contextId);
  isLoading.value = false;

  emit("goToNextSlide");
}

// Clear the selected media list on selected entity id change =================
watch(
  () => shareStopSummaryStore.editedPost.contextId,
  () => (shareStopSummaryStore.editedPost.assetIds = [])
);

// Set entity title ===========================================================
watch(
  () => shareStopSummaryStore.editedPost.contextId,
  () => {
    let newTitle = "";
    const postType = shareStopSummaryStore.editedPost.contextType;
    const targetPostId = shareStopSummaryStore.editedPost.contextId;

    if (postType === "collabInput") {
      newTitle = collabSummariesStore.allCollabSummaries.find(ci => ci.collabInputId === targetPostId)?.collabName || null;
    } else if (postType === "adventure") {
      newTitle = itinerariesStore.allItineraries.find(i => i.id === targetPostId)?.title || null;
    } else if (postType === "location") {
      newTitle = locationsStore.allLocations.find(l => l.externalId === targetPostId)?.title || null;
    }

    shareStopSummaryStore.editedPost.contextTitle = newTitle;
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Transition =================================================================
.list-enter-active,
.list-leave-active {
  display: grid;
  grid-template-rows: 1fr;
  transition:
    grid-template-rows 0.1s ease-in-out,
    opacity 0.2s ease-in-out;
}
.list-enter-from,
.list-leave-to {
  grid-template-rows: 0fr;
  opacity: 0;
}

// Search input ===============================================================
.search-input {
  height: 40px;
  box-sizing: border-box;
  display: flex;
  position: relative;

  &__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    inset: 8px auto auto 13px;
    font-size: 20px;
    line-height: 20px;
    pointer-events: none;
  }

  &__input-itself {
    width: 100%;
    height: 100%;
    padding: 3px 12px 5px 37px;
    box-sizing: border-box;
    border: 1px #c1c1c1 solid;
    border-radius: 4px;
    font-family: sans-serif;
    line-height: 40px;
  }
}

// Collabs list ===============================================================
.collabs-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__collab-wrap1 {
    width: calc(50% - 8px);
    margin: 20px 16px 0 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  &__collab-wrap2 {
    height: 100%;
    padding: 0 10px 10px 0;
    position: relative;
    overflow: hidden;
  }

  &__collab {
    width: 100%;
    height: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .collabs-list {
    &__collab-wrap1 {
      width: calc(50% - 8px);
      margin: 20px 15px 0 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collabs-list {
    &__collab-wrap1 {
      width: calc(50% - 6px);
      margin: 14px 12px 0 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collabs-list {
    &__collab-wrap1 {
      width: calc(100% + 10px);
      margin: 8px 0 0 0;

      &:nth-child(1) {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 8px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collabs-list {
    &__collab-wrap1 {
      width: calc(100% + 10px);
      margin: 8px 0 0 0;

      &:nth-child(1) {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 8px;
      }
    }
  }
}

// Adventures list ============================================================
.adventures-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__adventure-wrap1 {
    width: calc(33.3% - 18px);
    margin: 27px 27px 0 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__adventure-wrap2 {
    height: 100%;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow:
      0 1px 3px 0 #d4d4d5,
      0 0 0 1px #d4d4d5;
  }

  &__adventure {
    width: 100%;
    height: 100%;

    &:hover {
      transform: none !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .adventures-list {
    &__adventure-wrap1 {
      width: calc(33.3% - 16px);
      margin: 24px 24px 0 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adventures-list {
    &__adventure-wrap1 {
      width: calc(33.3% - 15px);
      margin: 22px 22px 0 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventures-list {
    &__adventure-wrap1 {
      width: calc(50% - 9px);
      margin: 18px 18px 0 0;

      &:nth-child(3n) {
        margin-right: 18px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 18px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventures-list {
    &__adventure-wrap1 {
      width: calc(50% - 9px);
      margin: 17px 17px 0 0;

      &:nth-child(3n) {
        margin-right: 18px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 18px;
      }
    }
  }
}

// Locations list =============================================================
.locations-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__location-wrap1 {
    width: calc(33.3% - 18px);
    margin: 27px 27px 0 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__location-wrap2 {
    height: 100%;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow:
      0 1px 3px 0 #d4d4d5,
      0 0 0 1px #d4d4d5;
  }

  &__location {
    width: 100%;
    height: 100%;

    &:hover {
      transform: none !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .locations-list {
    &__location-wrap1 {
      width: calc(33.3% - 16px);
      margin: 24px 24px 0 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .locations-list {
    &__location-wrap1 {
      width: calc(33.3% - 15px);
      margin: 22px 22px 0 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .locations-list {
    &__location-wrap1 {
      width: calc(50% - 9px);
      margin: 18px 18px 0 0;

      &:nth-child(3n) {
        margin-right: 18px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 18px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .locations-list {
    &__location-wrap1 {
      width: calc(50% - 9px);
      margin: 17px 17px 0 0;

      &:nth-child(3n) {
        margin-right: 17px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 17px;
      }
    }
  }
}

// Select entities ============================================================
.select-entities {
  &__search-input {
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
  }

  &__entities-list-wrap {
    margin-bottom: 33px;
  }

  &__collabs-list {
    width: calc(100% + 10px);
    margin: 0 0 22px 0;
  }

  &__adventures-list {
    margin: 0 0 22px 0;
  }

  &__locations-list {
    margin: 0 0 22px 0;
  }

  &__show-more-button {
  }

  &__next-button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .select-entities {
    &__search-input {
      width: 307px;
      max-width: 70%;
      margin-bottom: 25px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .select-entities {
    &__search-input {
      width: 100%;
      max-width: 100%;
      margin-bottom: 25px;
    }
  }
}
</style>
