<template>
  <div class="edit-profile-page">
    <div v-if="!getViewingUserProfile" class="edit-profile-page__loader"><Loader /></div>

    <template v-if="getViewingUserProfile">
      <!--Note for Customer Admins-->
      <NoteWithIcon v-if="adminCommunityIdInContext" color="green" style="margin-bottom: 17px">
        <template #icon><IconEmbedded name="info-simple_4" /></template>
        <template #text>
          <div>
            <h5 class="global-h5">Want to edit your your Destination Page?</h5>
            <div>Details here will be shown while communicating with creators.</div>
            <div>
              <RouterLink :to="{ name: 'CommunityPageAdmin', params: { pageId: adminCommunityIdInContext } }"> Click here </RouterLink>
              to update your destination details.
            </div>
          </div>
        </template>
      </NoteWithIcon>

      <div class="edit-profile-page__columns-wrapper">
        <!-- col left -->
        <div class="edit-profile-page__col-left">
          <!-- Avatar snippet -->
          <div class="avatar-snippet edit-profile-page__avatar-snippet">
            <img
              class="avatar-snippet__img"
              :src="(getViewingUserProfile.imageIsExternal ? '' : `${contentBaseUri}/cms/images/profiles/`) + getViewingUserProfile.tileImageLocation"
              :alt="getViewingUserProfile.firstName"
            />

            <input type="text" v-model="getViewingUserProfile.tileImageLocation" style="display: none" />

            <!-- Upload avatar button -->
            <div class="upload-avatar-button avatar-snippet__upload-avatar-button">
              <IconEmbedded class="upload-avatar-button__icon" name="upload_2" />
              <label class="upload-avatar-button__input-label">
                <input class="upload-avatar-button__input" @change="uploadPhoto" type="file" id="imageInput" accept="image/*" />
              </label>
            </div>
            <!-- / Upload avatar button -->
          </div>
          <!-- / Avatar snippet -->

          <div class="fields">
            <div class="eight wide field">
              <label class="cms-section-title" style="margin-top: 15px !important">First Name</label>
              <input class="global-text-input" type="text" v-model="getViewingUserProfile.firstName" placeholder="Display Name" />
            </div>
          </div>

          <div class="field">
            <label class="cms-section-title" style="margin-top: 15px !important">
              Email
              <span v-if="isSuperOrSalesUser && !editEmail" style="display: inline; margin-left: 5px; margin-bottom: 5px">
                <button class="ui button mini" @click="editEmail = true" style="padding: 6px; margin-right: 20px; background-color: #2c058730">edit</button>
                <LinkWithIcon tag="RouterLink" :to="{ name: 'EditCreator', params: { id: `${getViewingUserProfile.sherpaId}` } }" target="_blank">
                  backend
                  <template #icon><IconEmbedded name="external-link_2-5" :size="15" /></template>
                </LinkWithIcon>
              </span>
            </label>
            <div v-if="isSuperOrSalesUser && editEmail">
              <input class="global-text-input" type="text" v-model="getViewingUserProfile.email" style="margin-bottom: 8px" />
              <SrpButton @click="updateEmail" size="small" fill="outlined" color="gray" style="background-color: #2c058730; margin-bottom: 8px">Update email (superuser only)</SrpButton>
            </div>
            <div v-else>
              <b>{{ getViewingUserProfile.email }}</b>
            </div>
          </div>
        </div>
        <!-- / col left -->

        <!-- col right -->
        <div class="edit-profile-page__col-right">
          <!-- Admin Notifications -->
          <div v-if="getViewingUserProfile?.adminOf?.length > 0" class="edit-profile-page__paid-collab-fields">
            <div class="fourteen wide column">
              <div>
                <h2 class="global-h2">Notifications</h2>
                <div class="cms">
                  As a destination admin, we'll send you notifications from time to time, for example:
                  <ul>
                    <li>Creator Visit messages and reminders</li>
                    <li>New content available</li>
                    <li>Billing and renewal reminders</li>
                  </ul>
                  If you have questions reach out to <CopyText /> any time, we're here to help!
                </div>
              </div>
            </div>
          </div>

          <!-- Buttons sticky panel -->
          <div class="buttons-sticky-panel edit-profile-page__buttons-sticky-panel">
            <!-- Errors list -->
            <div v-if="errors.length" class="errors-list buttons-sticky-panel__errors-list">
              <h4 class="global-h4 errors-list__title">Can't save due to:</h4>
              <ul class="errors-list__list-itself">
                <li class="errors-list__li" v-for="error in errors" :key="error">- {{ error }}</li>
              </ul>
              <SrpButton v-if="isSuperOrSalesUser" class="errors-list__override-save-btn" @click="superSaveOverride" size="tiny" color="gray" fill="outlined">(super) Override Save</SrpButton>
            </div>
            <!-- / Errors list -->

            <p v-if="saveStatus" class="buttons-sticky-panel__save-status">
              <b>{{ saveStatus }}</b>
            </p>

            <div class="buttons-sticky-panel__buttons-line">
              <br />
              <SrpButton @click="saveUserProfile(false)" isBiggerSidePaddings style="margin-right: 11px">Save</SrpButton>
              <SrpButton @click="cancelEdit" color="gray">Cancel</SrpButton>
              <div style="flex-grow: 1"></div>
              <SrpButton @click="logout" color="gray" fill="outlined">Log out</SrpButton>
            </div>
          </div>
          <!-- / Buttons sticky panel -->
        </div>
        <!-- / col right -->
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";
import FileUtils from "@logic/FileUtils";
import { mapActions, mapState } from "pinia";
import moment from "moment";
import OrgContext from "@logic/OrgContext";

// Components
import CopyText from "@components/CopyText.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import FeatureFlags from "@logic/FeatureFlags";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "CustomerAdminProfile",

  components: {
    IconEmbedded,
    CopyText,
    LinkWithIcon,
    Loader,
    SrpButton,
    SrpCheckbox,
    SrpDetailsSummary,
    SrpModal,
    NoteWithIcon,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      errors: [] as Array<string>,
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      uploadText: null as string | null,
      saveStatus: null as string | null,
      editEmail: false,

      userProfileStore: useUserProfileStore(),
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isImpersonating", "isSuperOrSalesUser"]),
    adminCommunityIdInContext(): string | null {
      // Adding the viewing profile check since this should only show for admins (ex. supers would have orgInContext even when impersonating so this always showed)
      if (this.getViewingUserProfile?.adminOf?.length > 0) {
        var orgInContext = OrgContext.getOrgInContext(this);
        return orgInContext?.customerId;
      }
      return null;
    },
  },

  async mounted() {
    useHead({ title: "Edit Profile" });
    // Should always be authenticated
    await this.authProfileLoaded();
  },

  methods: {
    ...mapActions(useUserProfileStore, ["setProfileForCurrentUser", "impersonateProfile", "stopImpersonation", "saveProfile"]),
    moment,
    async authProfileLoaded() {
      var creatorIdParam = this.$route.params.creatorId;
      this.globalLog.info(`AdminEditProfile for ${creatorIdParam}`);
      await this.initializeProfile(creatorIdParam as string);
    },
    async initializeProfile(creatorIdOfProfileToLoad: string | null) {
      // Check if we're an admin loading someone else's profile
      if (creatorIdOfProfileToLoad) {
        await this.impersonateProfile(creatorIdOfProfileToLoad);
      }
    },
    async superSaveOverride() {
      await this.saveUserProfile(true);
    },
    async saveUserProfile(superOverride = false): Promise<boolean> {
      // Cleanup
      if (this.getViewingUserProfile.firstName) {
        this.getViewingUserProfile.firstName = this.getViewingUserProfile.firstName.trim();
      }

      const validationPassed = true;
      this.globalRemoteLogger.info(`Profile.saveUserProfile: ${this.getViewingUserProfile?.authNameId}, isValid=${validationPassed}`);
      try {
        if (validationPassed) {
          this.saveStatus = "Saving...";
          await this.saveProfile(this.getViewingUserProfile);
          this.saveStatus = "Saved!";
          setTimeout(() => {
            this.saveStatus = null;
          }, 4000);
        }
      } catch (error) {
        this.globalRemoteLogger.error(`saveUserProfile Error: ${error.message} userId=${this.getViewingUserProfile?.authNameId}`);
        alert("Sorry! Something went wrong. Please try again or email support@shrpa.com.");
        this.saveStatus = null;
      }
      this.uploadText = null;
      return validationPassed;
    },
    async updateEmail() {
      var uri = `${import.meta.env.VITE_API_URL}/profile/${this.getViewingUserProfile.authNameId}/email?newEmail=${encodeURIComponent(this.getViewingUserProfile.email)}`;
      var updated = await axios.put(uri);
      this.globalRemoteLogger.info(`updateEmailBySuper: ${this.getViewingUserProfile.authNameId} ${this.getViewingUserProfile.email}, wasUpdated ${updated.data}`);
      alert("Email updated! Please refresh the page.");
    },
    uploadPhoto: async function () {
      // Note: Similar code in the CommunityPageAdmin
      this.uploadText = "Uploading...";
      var uploadInput: any = document.getElementById("imageInput");
      if (uploadInput.files.length > 0) {
        var file = uploadInput.files[0];
        var error = FileUtils.isFileSupportedForUpload(file.name, file.type, file.size);
        if (error?.length > 0) {
          this.uploadText = error;
        } else {
          // Upload
          var formData = new FormData();
          formData.append("file", file);
          var result = await axios.post(`${import.meta.env.VITE_API_URL}/images?type=profile`, formData);
          this.getViewingUserProfile.imageIsExternal = false;
          this.getViewingUserProfile.tileImageLocation = result.data[0];
          this.uploadText = "Remember to save!";
        }
      } else this.uploadText = "Remember to save!";
    },
    goToMyItineraries() {
      this.$router.push({ name: "MyItineraries" });
    },
    logout() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.globalAuth.logout();
      this.$router.push({ name: "Home" });
    },
    cancelEdit() {
      this.$router.push({ name: "CommunityAdminDashboard" });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

input:out-of-range {
  border: 1px solid red !important;
}

// Upload avatar button =======================================================
.upload-avatar-button {
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  text-align: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #e7edf0;
    transition: transform 0.05s ease-in-out;
  }

  &:hover::before {
    transform: scale(1.12);
  }

  &__icon {
    margin-top: -2px;
    color: #000;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    opacity: 0.6;
  }

  &__input-label {
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    position: absolute;
    inset: -7px auto auto -7px;
    z-index: 1;
    cursor: pointer;
  }

  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

// Avatar snippet =============================================================
.avatar-snippet {
  width: 160px;
  height: 160px;
  position: relative;

  &__img {
    aspect-ratio: 1/1;
    width: 100%;
    border-radius: 1000px;
  }

  &__upload-avatar-button {
    position: absolute;
    inset: auto auto 5px 5px;
  }
}

// Input section ==============================================================
.input-section {
  &__section-title {
    margin-bottom: 6px;
  }

  &__input-wrap {
    margin-bottom: 5px;
    position: relative;

    &--with-at-symbol {
      &::before {
        content: "@";
        width: 15px;
        height: 15px;
        position: absolute;
        inset: 12px auto auto 10px;
        color: #5b5b5b;
        font: 14px/14px sans-serif;
        pointer-events: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    &--bigger-left-padding {
      padding-left: 28px;
    }
  }

  &__test-link-line {
    color: #5b5b5b;
    font: 14px/14px sans-serif;
  }

  &__test-link-a {
    color: rgba(17, 134, 137, 1);
    text-decoration: underline;

    text-decoration-color: rgba(17, 134, 137, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;

    &:hover {
      text-decoration: none;
    }
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Errors list ================================================================
.errors-list {
  color: firebrick;

  &__title {
    margin-bottom: 8px;
    color: firebrick;
  }

  &__list-itself {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__li {
    font: 14px/18px sans-serif;
  }

  &__override-save-btn {
  }
}

// Buttons sticky panel =======================================================
.buttons-sticky-panel {
  width: 100%;
  padding-bottom: 17px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 40px);
    height: calc(100% + 36px);
    position: absolute;
    inset: auto auto 0 -20px;
    z-index: -1;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &__errors-list {
    margin-bottom: 12px;
  }

  &__save-status {
  }

  &__buttons-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .buttons-sticky-panel {
    padding-bottom: 83px;

    &::before {
      height: calc(100% + 66px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .buttons-sticky-panel {
    padding-bottom: 83px;

    &::before {
      height: calc(100% + 66px);
    }
  }
}

// Edit profile page ==========================================================
.edit-profile-page {
  width: 1116px;
  margin: 0 auto;

  &__loader {
  }

  &__columns-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__col-left {
    width: 354px;
    min-width: 354px;
    padding: 20px 28px;
    margin-right: 27px;
    box-sizing: border-box;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__col-right {
    flex-grow: 1;
    position: relative;
  }

  &__invite-snippet {
    margin: 0 -28px -20px;
  }

  &__avatar-snippet {
    margin-bottom: 20px;
    align-self: center;
  }

  &__superuser-data-snippet {
    padding: 8px 14px;
    margin-bottom: 15px;
    border-radius: 6px;
    background-color: #2c058730;
  }

  &__social-media-links {
    margin-bottom: 27px;
  }

  &__content-for-destinations {
    padding: 16px 20px 22px;
    margin-bottom: 27px;
    border-radius: 6px;
    background: #f3f3f3;

    &[open] summary {
      margin-bottom: 14px;
    }
  }

  &__paid-collab-fields {
    padding: 18px 28px;
    margin-bottom: 27px;
    border-radius: 6px;
    background: #f3f3f3;
  }

  &__interested-in-being-paid {
    margin-bottom: 10px;
  }

  &__buttons-sticky-panel {
    position: sticky;
    inset: auto auto 0 0;
    z-index: 3;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .edit-profile-page {
    width: 945px;

    &__col-left {
      width: 297px;
      min-width: 297px;
      margin-right: 27px;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .edit-profile-page {
    width: 822px;

    &__col-left {
      width: 258px;
      min-width: 258px;
      padding: 20px 18px;
      margin-right: 24px;
    }

    &__invite-snippet {
      margin: 0 -18px -20px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }

    &__social-media-links {
      margin-bottom: 24px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .edit-profile-page {
    width: 762px;

    &__col-left {
      width: 272px;
      min-width: 272px;
      padding: 20px 18px;
      margin-right: 22px;
    }

    &__invite-snippet {
      margin: 0 -18px -20px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }

    &__social-media-links {
      margin-bottom: 24px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .edit-profile-page {
    width: calc(100vw - 34px);

    &__columns-wrapper {
      flex-direction: column;
    }

    &__col-left {
      width: 100%;
      min-width: 100%;
      padding: 20px 18px;
      margin: 0 0 24px 0;
    }

    &__col-right {
      width: 100%;
      min-width: 100%;
    }

    &__invite-snippet {
      margin: 0 -18px -20px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .edit-profile-page {
    width: calc(100vw - 34px);

    &__columns-wrapper {
      flex-direction: column;
    }

    &__col-left {
      width: 100%;
      min-width: 100%;
      padding: 20px 18px;
      margin: 0 0 24px 0;
    }

    &__col-right {
      width: 100%;
      min-width: 100%;
    }

    &__invite-snippet {
      margin: 0 -18px -20px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }
  }
}
</style>
