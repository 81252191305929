<template>
  <div>
    <div v-if="pageDetailsNotFound" class="stackable very relaxed ui grid container">
      <br />
      <h4>
        Sorry, we couldn't find that community. <br /><br />
        Email us at support@shrpa.com if you think you found a bug!
      </h4>
    </div>

    <div v-else>
      <div v-if="isLoading"><Loader /></div>
      <div v-if="pageDetails">
        <div v-if="pageDetails.page.type === 'Organization'">
          <div class="content-wrapper">
            <div class="stackable very relaxed ui grid container">
              <div class="row">
                <div class="five wide column">
                  <a v-if="pageDetails.page.website" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website"
                    ><img class="ui image" :src="`${contentBaseUri}/cms/images/orgs/${pageDetails.page.icon}`"
                  /></a>
                  <img v-else class="ui image" :src="`${contentBaseUri}/cms/images/orgs/${pageDetails.page.icon}`" />
                  <!-- This position looks better for the Follow button on Desktop, but worse on mobile. It really depends on how much text you have in pageDetails.page.description -->
                  <!-- <div id="follow-button-wrapper">
                                <div @click="toggleFollow" class="ui basic orange button">{{isFollowed ? 'Unfollow' : 'Follow'}}
                                    Need to get the abbreviated pageDetails.page.title for the button or just punt and keep it as a generic "Follow"
                                </div>
                            </div> -->
                  <span v-if="pageDetails.page.inPartnershipWithIcon">
                    <h3 class="ui header" style="margin-top: 20px; text-align: center">
                      {{ pageDetails.page.inPartnershipWithTextOverride === null ? "A Co-op Program with" : pageDetails.page.inPartnershipWithTextOverride }}
                    </h3>
                    <a v-if="pageDetails.page.inPartnershipWithSite" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.inPartnershipWithSite">
                      <img class="ui image coop-logo" :src="pageDetails.page.inPartnershipWithIcon" />
                    </a>
                    <img v-else class="ui image coop-logo" :src="pageDetails.page.inPartnershipWithIcon" />
                  </span>
                </div>
                <div class="eleven wide column">
                  <router-link v-if="canEdit" :to="getEditRoute()" class="ui small basic primary right floated button" style="margin-top: 30px">Edit</router-link>
                  <h1 class="ui header">
                    {{ pageDetails.page.title }}
                  </h1>
                  <p style="white-space: pre-line">
                    <span v-if="pageDetails.page.description?.length > 0">
                      <ClampedText :visibleLines="17">
                        {{ pageDetails.page.description }}
                      </ClampedText>
                    </span>
                    <span v-else-if="!pageDetails.page.website">Coming soon...</span>
                  </p>
                  <div class="organization">
                    <a class="ui header" v-if="pageDetails.page.website" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website">
                      {{ pageDetails.page.websiteText && pageDetails.page.websiteText.trim().length > 0 ? pageDetails.page.websiteText : pageDetails.page.website }}
                    </a>
                  </div>
                  <div class="social-links" style="position: relative; z-index: 9; display: flex">
                    <SocialMediaLinksBar :socialFields="pageDetails.page" />
                  </div>

                  <h2 class="ui header" v-if="pageDetails.sherpas.length > 0">Contributing creators</h2>
                  <div class="ui grid creators-wrapper">
                    <div class="row">
                      <router-link
                        class="five wide column contributing-sherpa hide-on-mobile"
                        v-for="sherpa in pageDetails.sherpas.slice(0, 3)"
                        :key="'desktop' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>{{ sherpa.firstName }}</p>
                      </router-link>
                      <router-link
                        class="eight wide column contributing-sherpa hide-on-desktop"
                        v-for="sherpa in pageDetails.sherpas.slice(0, 3)"
                        :key="'mobile' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                    </div>
                    <div class="row" :class="{ hidden: !toggled }">
                      <router-link
                        class="five wide column contributing-sherpa hide-on-mobile"
                        v-for="sherpa in pageDetails.sherpas.slice(3)"
                        :key="'desktop' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                      <router-link
                        class="eight wide column contributing-sherpa hide-on-desktop"
                        v-for="sherpa in pageDetails.sherpas.slice(3)"
                        :key="'mobile' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                    </div>
                    <div class="toggle-text" v-if="pageDetails.sherpas.length > 3" @click.prevent.stop="onToggle">
                      {{ toggled ? "Collapse" : "Show All Creators" }}
                    </div>
                  </div>
                  <!--</div> -->
                </div>

                <!--<div class="six wide column" id="follow-button-wrapper">-->
                <!--  <div @click="toggleFollow" class="ui basic orange button">{{ isFollowed ? "Unfollow" : "Follow" }}</div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div>
            <div class="expedition-hero" :style="`background-image: url(${contentBaseUri}/cms/images/expeditions/${pageDetails.page.primaryImage})`">
              <div class="expedition-overlay wow fadeIn">
                <h1 class="ui inverted header header-title">{{ pageDetails.page.title }}</h1>
              </div>
            </div>

            <div class="stackable very relaxed ui grid container" style="clear: left">
              <div class="row">
                <div class="six wide column">
                  <div class="expedition-bring">
                    <h3>Address</h3>
                    <div class="ui grid">
                      <div class="row">
                        <div class="sixteen wide column">
                          {{ pageDetails.page.address }}
                        </div>
                      </div>
                    </div>
                    <h3>Website</h3>
                    <div class="ui grid">
                      <div class="row">
                        <div class="sixteen wide column">
                          <a lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website">
                            {{ pageDetails.page.websiteText && pageDetails.page.websiteText.trim().length > 0 ? pageDetails.page.websiteText : pageDetails.page.website }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <!--<div class="ui grid">-->
                    <!--  <div class="row">-->
                    <!--    <div class="sixteen wide column">-->
                    <!--      <img height="150" width="150" :src="`${contentBaseUri}/cms/images/pages/icon/${pageDetails.page.icon}`" />-->
                    <!--    </div>-->
                    <!--  </div>-->
                    <!--</div>-->
                  </div>
                </div>

                <div class="ten wide column">
                  <h2 class="ui header">Summary</h2>
                  <p style="white-space: pre-line">
                    {{ pageDetails.page.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />

        <div>
          <div class="ui container all-expeditions" v-if="pageDetails.itineraries.length > 0">
            <h2 class="ui header">Featured Adventures</h2>
            <div class="ui four doubling raised link cards adventure-cards">
              <ItineraryTile v-for="itinerary in pageDetails.itineraries" :itinerary="itinerary" :allowShortImage="false" :source="'commpage'" :key="itinerary.id" />
            </div>
          </div>
          <div v-else style="text-align: center">
            <h3>Adventures Coming Soon!</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import ItineraryTile from "@components/ItineraryTile.vue";
import Loader from "@components/Loader/Loader.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import { inject } from "vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import ClampedText from "@components/ui/ClampedText.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "PagesView",

  components: {
    ClampedText,
    ItineraryTile,
    Loader,
    SocialMediaLinksBar,
  },

  data() {
    return {
      globalLog: inject("globalLog"),

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      pageId: null,
      pageDetails: null,
      pageDetailsNotFound: false,
      isFollowed: false,
      title: "Shrpa",
      toggled: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "isSuperOrSalesUser"]),
    canEdit(): boolean {
      if (this.isSuperOrSalesUser) return true;
      if (this.getActingUserProfile?.adminOf.some(a => a.toUpperCase() === this.pageId?.toUpperCase()) === true) return true;

      return false;
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId;
    await this.loadPageDetails(this.pageId);
  },

  methods: {
    loadPageDetails: async function (identifier) {
      try {
        let uri = `${import.meta.env.VITE_API_URL}/pages/${identifier}?isPublicFacing=true`;
        const response = await axios.get(uri);
        this.pageDetails = response.data;
        if (this.pageDetails.page) this.title = this.pageDetails.page.title;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response.status == 404) {
          this.pageDetailsNotFound = true;
          return false;
        }
        return true;
      }
    },
    onToggle: function () {
      this.toggled = !this.toggled;
    },
    getEditRoute() {
      return { name: "CommunityPageAdmin", params: { pageId: this.pageDetails.page.id } };
    },
  },
};
</script>

<style scoped lang="scss">
.creators-wrapper {
  transition: all 0.5s ease;
  .hidden {
    display: none;
  }
}
.toggle-text {
  color: #058587;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.paid-sherpa {
  /*color: #EC563B;*/
  font-size: 14px;
}

.coop-logo {
  max-height: 150px;
}
</style>
