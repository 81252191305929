import { nextTick, onBeforeUnmount, onMounted, Ref, ref, watch } from "vue";
import lodashCloneDeep from "lodash-es/cloneDeep";
import lodashThrottle from "lodash-es/throttle";

export type AreaQuarter = 1 | 2 | 3 | 4; // top-left, top-right, bottom-right, bottom-left

export function defineQuarter(cursorCoordinates: { x: number; y: number }, elementDimensionsAndCoordinates: { x: number; y: number; width: number; height: number }): AreaQuarter {
  const { x, y } = cursorCoordinates;
  const { x: elementX, y: elementY, width, height } = elementDimensionsAndCoordinates;

  // Calc element center
  const centerX = elementX + width / 2;
  const centerY = elementY + height / 2;

  if (x < centerX) return y < centerY ? 1 : 4;
  else return y < centerY ? 2 : 3;
}

// TODO: use cursor position instead of dragover event to calculate the intersection (same as on the useTouchDragNDrop composable)
// TODO: implement the ability to move the element in both places: above and below any other element
// TODO: add "data-dragged-over-above" and "data-dragged-over-below" instead of the "elementIndexDraggedOver" reactive variables to set classes
export function useMouseDragNDrop(
  isEnabled: Ref<boolean>,
  updateKey: Ref<any>,
  parentContainer: Ref<HTMLElement | null>,
  dragNDropElementQuery: string,
  dragNDropHandleQuery: string,
  callback: Function
) {
  const isDragInProgress = ref<boolean>(false);
  const elementIdDragged = ref<string>("");
  const elementIdDraggedOver = ref<string>("");
  const elementIndexDragged = ref<number>(-1);
  const elementIndexDraggedOver = ref<number>(-1);
  const elementAreaQuarterDraggedOver = ref<AreaQuarter>(null);
  const elementDatasetDragged = ref<DOMStringMap>(null);
  const elementDatasetDraggedOver = ref<DOMStringMap>(null);
  const draggedElementClone = ref<Node | null>(null);

  // Add drag listeners =======================================================
  onMounted(async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    await nextTick();

    removeListeners();
    addListeners();
  });

  onBeforeUnmount(removeListeners);

  function addListeners() {
    parentContainer.value?.querySelectorAll(dragNDropElementQuery).forEach((domNode, index) => {
      domNode.addEventListener("dragover", handleDragOverThrottled);
    });

    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach((domNode, index) => {
      domNode.addEventListener("dragstart", handleDragStart);
      domNode.addEventListener("dragend", handleDragEnd);
      domNode.addEventListener("drop", handleDragOverThrottled);
      domNode.addEventListener("drag", handleDragMove);
      domNode.setAttribute("draggable", "true");
    });
  }

  function removeListeners() {
    parentContainer.value?.querySelectorAll(dragNDropElementQuery).forEach(domNode => {
      domNode?.removeEventListener("dragover", handleDragOverThrottled);
    });

    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      domNode?.removeEventListener("dragstart", handleDragStart);
      domNode?.removeEventListener("dragend", handleDragEnd);
      domNode?.removeEventListener("drop", handleDragOverThrottled);
      domNode?.removeEventListener("drag", handleDragMove);
    });
  }

  watch(updateKey, async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }
    await nextTick();
    removeListeners();

    await nextTick();
    addListeners();
  });

  const dragStartTimestamp = ref<number>(0);

  // Set draggable element same position as cursor ============================
  const cursorStartCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });
  const cursorCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });

  watch(() => cursorCoordinates.value, setDraggableElementSamePositionAsCursor);
  function setDraggableElementSamePositionAsCursor() {
    const draggableElement = document.querySelector("*[data-cloned-element-for-photos-section-touch-drag-event='true']") as HTMLElement;

    if (!draggableElement) return;

    draggableElement.style.left = (cursorCoordinates.value.x || -1000) - 30 + "px";
    draggableElement.style.top = (cursorCoordinates.value.y || -1000) - 20 + "px";
  }

  // ==========================================================================
  function handleDragMove(event: MouseEvent) {
    if (isDragInProgress.value) {
      cursorCoordinates.value = { x: event.x, y: event.y };
    }
  }

  // ==========================================================================
  /* Set pointer-events: none to all draggable elements child nodes 
     to be sure that "dragover" doesn't fire on child nodes */
  function setPointerEventsToAllChildNodes(value: "none" | "auto") {
    const allDraggableElementsDirectChildNodes = document.querySelectorAll(`${dragNDropElementQuery} > *`);
    allDraggableElementsDirectChildNodes.forEach(node => ((node as HTMLElement).style.pointerEvents = value));
  }

  // ==========================================================================
  function handleDragStart(event: MouseEvent) {
    setTimeout(() => setPointerEventsToAllChildNodes("none"), 0);

    dragStartTimestamp.value = Date.now();
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    const targetIndex = +(event.target as HTMLElement).dataset.index;
    elementIdDragged.value = (event.target as HTMLElement).dataset.draggedId;
    elementIndexDragged.value = targetIndex;
    elementDatasetDragged.value = (event.target as HTMLElement).dataset;

    setTimeout(() => {
      cloneAndAppendDraggedNode((event.target as HTMLElement).parentElement);
      isDragInProgress.value = true;
    }, 50); // dragging doesn't even start without the timeout. Probably it's because "pointer-events: none" on the image being set before Dragging initialization for some unknown reason

    cursorStartCoordinates.value = { x: event.x, y: event.y };
    cursorCoordinates.value = { x: event.x, y: event.y };
  }

  function cloneAndAppendDraggedNode(node: HTMLElement) {
    const targetSize = node.getBoundingClientRect();

    draggedElementClone.value = (node as HTMLElement).cloneNode(true);
    (draggedElementClone.value as HTMLElement).setAttribute("data-cloned-element-for-photos-section-touch-drag-event", "true");

    (draggedElementClone.value as HTMLElement).style.position = "fixed";
    (draggedElementClone.value as HTMLElement).style.width = targetSize.width + "px";
    (draggedElementClone.value as HTMLElement).style.height = targetSize.height + "px";
    (draggedElementClone.value as HTMLElement).style.opacity = "0.7";
    (draggedElementClone.value as HTMLElement).style.pointerEvents = "none";
    (draggedElementClone.value as HTMLElement).style.cursor = "grabbing";
  }

  function handleDragEnd() {
    setTimeout(() => setPointerEventsToAllChildNodes("auto"), 0);

    isDragInProgress.value = false;

    callback(elementIndexDragged.value, elementIndexDraggedOver.value, elementDatasetDragged.value, elementDatasetDraggedOver.value, elementAreaQuarterDraggedOver.value);

    elementIdDragged.value = "";
    elementIdDraggedOver.value = "";
    elementIndexDragged.value = -1;
    elementIndexDraggedOver.value = -1;
    elementDatasetDragged.value = null;
    elementDatasetDraggedOver.value = null;
    elementAreaQuarterDraggedOver.value = null;
  }

  // Handle drag over =========================================================
  const handleDragOverThrottled = lodashThrottle(handleDragOver, 100);

  function handleDragOver(event: DragEvent) {
    if (!isDragInProgress.value) return;
    const targetIndex = +(event.target as HTMLElement).dataset.index;
    elementIdDraggedOver.value = (event.target as HTMLElement).dataset.dragId;
    elementIndexDraggedOver.value = targetIndex;
    elementDatasetDraggedOver.value = (event.target as HTMLElement).dataset;

    const eventTargetBoundingClientRect: DOMRect = (event.target as HTMLElement).getBoundingClientRect();

    elementAreaQuarterDraggedOver.value = defineQuarter(
      { x: event.x, y: event.y },
      {
        x: eventTargetBoundingClientRect.left,
        y: eventTargetBoundingClientRect.top,
        width: eventTargetBoundingClientRect.width,
        height: eventTargetBoundingClientRect.height,
      }
    );
  }

  function handleDrop() {}

  // Append/remove draggable element ghost ====================================
  watch(
    () => isDragInProgress.value,
    () => {
      if (isDragInProgress.value) {
        parentContainer.value.appendChild(draggedElementClone.value);
        setDraggableElementSamePositionAsCursor();
      } else {
        document.querySelector("*[data-cloned-element-for-photos-section-touch-drag-event='true']").remove();
      }
    }
  );

  // Set data-is-dragged-over/data-dragged-over-q-* tag attrs =================
  watch(
    () => [elementIdDraggedOver.value, elementAreaQuarterDraggedOver.value, isDragInProgress.value],
    (currentValues, prevValues) => {
      if (!currentValues[2]) {
        Array.from(document.querySelectorAll(dragNDropElementQuery)).forEach(el => {
          el?.removeAttribute("data-is-dragged-over");
          el?.removeAttribute("data-dragged-over-q");
        });
        return;
      }

      if (prevValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${prevValues[0]}"]`);
        targetElem?.removeAttribute("data-is-dragged-over");
        targetElem?.removeAttribute("data-dragged-over-q");
      }

      if (currentValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${currentValues[0]}"]`);
        targetElem?.setAttribute("data-is-dragged-over", "true");
        targetElem?.setAttribute(`data-dragged-over-q`, String(currentValues[1]));
      }
    }
  );

  return {
    isDragInProgress,
    elementIdDragged,
    elementIdDraggedOver,
    elementIndexDragged,
    elementIndexDraggedOver,
    elementAreaQuarterDraggedOver,
    elementDatasetDragged,
    elementDatasetDraggedOver,
  };
}
