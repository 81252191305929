<template>
  <div>
    <div class="ui segment">
      <!--Community Engagement Intro Text-->
      <div v-if="!isHotel" style="margin-bottom: 30px">
        <h1>Engage your Community with Shrpa!</h1>
        <div>
          <div>
            Maximize user-generated-content potential by engaging locals that know your community better than anyone!
            <ol>
              <li class="item">Find active locals that have fun experiences to share.</li>
              <li class="item">Invite people of different ages, interests, and backgrounds to highlight all the amazing ways to experience your community.</li>
              <li class="item">Help kickstart creator ideas by providing content creation themes.</li>
            </ol>
            By simply providing basic guidance and inviting the right people, you can acquire top-notch content that shows travelers the best of your community!<br /><br />
            So let's get started!!!
          </div>
        </div>
      </div>
      <!--Hotel Intro Text-->
      <div v-else style="margin-bottom: 30px">
        <h1>Improve your Guest Experience with Shrpa!</h1>
        <div>
          <div>
            Maximize the number of experiences that are available for your guests!
            <ol>
              <li class="item">Any staff can create great local experiences.</li>
              <li class="item">Invite creators of different ages, interests, and backgrounds to highlight all the amazing ways to experience the community.</li>
              <li class="item">Guests can be guided by these experiences anytime!</li>
            </ol>
            Enable all of your staff to show guests great local experiences!<br /><br />
            So let's get started!
          </div>
        </div>
      </div>
    </div>

    <div class="ui segment">
      <!--Input Fields-->
      <h3>Themes &amp; Guidance For Your {{ isHotel ? "Staff" : "Creators" }}</h3>
      <div class="ui checkbox" style="display: flex; align-items: center">
        <input type="checkbox" toggle v-model="page.useGlobalGuidance" id="useGlobalGuidance" />
        <label for="useGlobalGuidance" style="cursor: pointer; user-select: none">Apply to all invited creators</label>
      </div>
      <div class="ui form" v-if="page.useGlobalGuidance">
        <div class="two fields" style="position: relative; background-color: white; margin-top: 30px">
          <div class="field">
            <div>
              <h3 style="display: inline">Creator Guidance</h3>
              <button @click="saveGuidance()" class="ui tiny basic button" style="display: inline; margin: 5px 20px">Save Guidance</button>
              <div v-if="isSavingGuidance" style="display: inline"><b>Saving...</b></div>
            </div>
            <textarea
              style="height: 100px"
              maxlength="1500"
              v-model="page.guidance"
              placeholder="Themes: Outdoors, local shops, etc...
Guidance: What should creators think about when creating adventures?"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="ui segment">
      <h3>Invite {{ isHotel ? "Hotel Staff" : "Community Member" }}</h3>
      <!--Input Fields-->
      <div class="ui form">
        <div class="two fields" style="position: relative; background-color: white">
          <div class="field">
            <input v-model="currentInviteEmail" placeholder="user@somewhere.com" />
            <div v-if="!page.useGlobalGuidance">
              <h4 style="margin: 15px 0 10px 0">Guidance</h4>
              <textarea style="margin-top: 5px; height: 100px" maxlength="1500" v-model="individualGuidance" placeholder="Advice or themes to consider when creating adventures?" />
            </div>
            <div class="text-left" style="margin-top: 20px">
              <h5 v-if="emailsEnteredCount > 1">Entered {{ emailsEnteredCount }} emails to invite</h5>
              <h5 v-if="emailsEnteredCount > maxInvitesAllowedAtOnce">
                <span style="color: red">Please limit invites to fewer than {{ maxInvitesAllowedAtOnce }} at a time.</span><br />
                If you have issues or questions let us know at <CopyText />
              </h5>
              <button class="ui primary button inviteButton" :class="{ empty: currentInviteEmail === '' }" @click="inviteUsers">
                <i class="paper plane outline icon" />
                <span>Invite</span>
              </button>
              <div v-if="isSaving" style="display: inline"><b>Sending...</b></div>
            </div>
          </div>
          <div class="field">
            <div>
              This will send an email to the creator with Getting Started instructions. <br />
              Example:
              <a href="https://explore.shrpa.com/invited-creator-flow" target="_blank" style="text-decoration: underline">here</a>
            </div>
            <div>
              <br />
              Creator not receiving these emails?<br />
              You can manually send them:
              <a @click="creatorInviteTemplate" style="text-decoration: underline; cursor: pointer; user-select: none">Creator Invite Template</a>
            </div>
          </div>
        </div>
        <div v-if="hasInvitedUsers">
          <br />
          <h3 style="margin-top: 0">{{ inviteUserTitle }}</h3>
          <h4 style="margin-top: -5px">{{ inviteUserSubtext }}</h4>
          <br />
        </div>
      </div>
    </div>

    <div class="ui segment invited-user" v-for="user in invitedUsers" :key="user.email">
      <div class="invited-email">
        <i class="icon check circle" />
        <h4 style="margin: 0">{{ user.email }}</h4>
        <h4 style="margin: 0 0 0 20px">Invited on {{ displayInviteDate(user.invitedOn) }}</h4>
        <h4 v-if="!hasUnusedInvite(user.email)" style="margin: 0 0 0 10px">(accepted)</h4>
        <i class="grey close icon" style="margin-left: 10px" @click="askToDelete(user.email)"></i>
      </div>
      <div style="padding: 2px 0 0 23px">
        <div style="white-space: pre-line" v-if="user.message">{{ user.message }}</div>
        <div v-else>(using creator guidance above)</div>
        <div style="color: #058587; font-weight: bold; margin-top: 10px; cursor: pointer" @click="askToUpdateGuidance(user)">Edit Guidance</div>
      </div>
    </div>

    <div v-if="!showAll" class="text-left">
      <button class="ui primary basic inverted button" @click="$emit('closeOnboarding')">Continue</button>
    </div>

    <InviteExampleModal :isVisible="showEmailExampleModal" :pageId="page.id" @close="showEmailExampleModal = false" />

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal">
      <template #header><h2 class="global-h2">Are you sure you want to remove this user?</h2></template>
      <template #footer>
        <div class="ui basic black button" @click="cancelDelete()">Cancel</div>
        <div class="ui orange button" @click="deleteInvite()">Yep, remove</div>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showEditGuidanceModal" :isClosable="!Boolean(!useGlobalToUpdateGuidance && textToUpdateGuidance)">
      <template #header>
        <h2 class="global-h2">Edit Guidance</h2>
      </template>
      <template #content>
        <label style="max-width: 270px; display: inline-flex; align-items: center; cursor: pointer; user-select: none">
          <input type="checkbox" v-model="useGlobalToUpdateGuidance" style="width: 13px; margin-right: 8px" />
          Use common creator guidance above
        </label>
        <textarea
          v-if="!useGlobalToUpdateGuidance"
          style="height: 100px; margin-top: 12px"
          maxlength="1500"
          v-model="textToUpdateGuidance"
          placeholder="Advice or themes to consider when creating adventures?"
        />
      </template>
      <template #footer>
        <SrpButton @click="cancelUpdate" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="updateGuidance">Update</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import moment from "moment";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { Page, PageInvite } from "@contracts/pages";
import InviteExampleModal from "./InviteExampleModal.vue";
import InviteRepo from "@repos/InviteRepo";
import OrgContext from "@logic/OrgContext";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

export default defineComponent({
  name: "CustomerOnboard",

  components: {
    SrpButton,
    SrpModal,
    Loader,
    InviteExampleModal,
    CopyText,
  },

  props: {
    page: { type: Object as () => Page, default: null },
    invitedUsers: { type: Array as () => Array<PageInvite>, default: () => [] },
    unusedInvites: { type: Array as () => Array<PageInvite>, default: () => [] },
    showAll: { type: Boolean, default: false },
  },

  emits: ["closeOnboarding", "update", "invite", "deleted"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      currentInviteEmail: "",
      individualGuidance: "",
      submitted: false,
      isSaving: false,
      isSavingGuidance: false,
      showEmailExampleModal: false,

      showDeleteModal: false,
      emailToDelete: null as string | null,

      isHotel: false,

      showEditGuidanceModal: false,
      emailToUpdateGuidance: null as string | null,
      textToUpdateGuidance: null as string | null,
      useGlobalToUpdateGuidance: false,

      maxInvitesAllowedAtOnce: 50,
    };
  },

  computed: {
    inviteUserTitle(): string {
      const invitedUserCount = this.invitedUsers?.length ?? 0;

      if (invitedUserCount >= 5) return "Great Job!";
      else if (invitedUserCount >= 3) return "Almost There!";
      else if (invitedUserCount >= 1) return "Keep it up!";
    },
    inviteUserSubtext(): string {
      const invitedUserCount = this.invitedUsers?.length ?? 0;
      if (invitedUserCount >= 5) return "Invite as many more creators as you like, and we'll notify you once they've created an adventure!";
      else if (invitedUserCount >= 3) return "Can you think of 5 creators to really kickstart community creation?";
      else if (invitedUserCount >= 1) return "Inviting a few creators helps highlight different ways to experience your community!";
    },
    hasInvitedUsers(): boolean {
      return this.invitedUsers && this.invitedUsers.length > 0;
    },
    emailsEnteredCount(): number {
      // Note: There is similar logic in the invite method below
      if (this.currentInviteEmail !== "") {
        // Split it up if there are multiple
        let emails = [this.currentInviteEmail] as Array<string>;
        if (this.currentInviteEmail.indexOf(",") > 0) emails = this.currentInviteEmail.split(",");
        if (this.currentInviteEmail.indexOf(";") > 0) emails = this.currentInviteEmail.split(";");
        // Filter whitespace
        emails = emails.filter(str => /\S/.test(str));
        return emails.length;
      }

      return 0;
    },
  },

  async mounted() {
    this.isHotel = OrgContext.isOrgInContextHotel(this);
  },

  methods: {
    saveGuidance() {
      this.isSavingGuidance = true;
      this.$emit("update", this.page);
      // Just faking it for now...
      setTimeout(() => {
        this.isSavingGuidance = false;
      }, 1500);
    },
    hasUnusedInvite(email: string) {
      if (!this.unusedInvites) return false;

      return this.unusedInvites.some(i => i.email === email);
    },
    async inviteUsers() {
      if (this.isSaving) {
        this.globalLog.info("inviteUsers: Already Saving!");
        return;
      }
      // NOTE: Allowing comma or semi-colon separated emails now
      if (this.currentInviteEmail !== "") {
        // Split it up if there are multiple
        let emails = [this.currentInviteEmail] as Array<string>;
        if (this.currentInviteEmail.indexOf(",") > 0) emails = this.currentInviteEmail.split(",");
        if (this.currentInviteEmail.indexOf(";") > 0) emails = this.currentInviteEmail.split(";");
        // Filter whitespace
        emails = emails.filter(str => /\S/.test(str));

        this.globalLog.info(`Inviting ${emails.length} users`);
        // Max limit check
        if (emails.length > this.maxInvitesAllowedAtOnce) {
          // Error is shown via the emailsEnteredCount computed logic
          return;
        }

        this.isSaving = true;
        for (let i = 0; i < emails.length; i++) {
          await this.inviteIndividualUser(emails[i]);
        }
        this.isSaving = false;
        // Note: sending global guidance at the same time to avoid timing issues
        // this.$emit('update', this.page);
      }
    },
    async inviteIndividualUser(emailToInvite: string) {
      // Invite the user (and save guidance)
      if (this.invitedUsers.filter(u => u.email === emailToInvite).length === 0) {
        // Note: Moved the server call here so we can run them serially and avoid any save conflicts/issues
        // We could also do this server-side but that would require rework of more client-side logic.
        var wasFirstInvite = await InviteRepo.inviteIndividualUser(this.page.id, emailToInvite, false, this.individualGuidance, this.page.guidance, this.page.useGlobalGuidance);
        // Let the parent component know so it can update collections
        this.$emit("invite", emailToInvite, wasFirstInvite, this.individualGuidance);
      }
    },
    askToDelete(email: string) {
      this.emailToDelete = email;
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.emailToDelete = null;
      this.showDeleteModal = false;
    },
    async deleteInvite() {
      const emailEncoded = encodeURIComponent(this.emailToDelete.trim());
      let uri = `${import.meta.env.VITE_API_URL}/pages/${this.page.id}/invite?email=${emailEncoded}`;
      await axios.delete(uri);
      this.$emit("deleted", this.emailToDelete);
      this.showDeleteModal = false;
    },
    askToUpdateGuidance(invite: PageInvite) {
      this.emailToUpdateGuidance = invite.email;
      this.textToUpdateGuidance = invite.message;
      this.useGlobalToUpdateGuidance = !(invite.message && invite.message.length > 0);
      this.showEditGuidanceModal = true;
    },
    cancelUpdate() {
      this.showEditGuidanceModal = false;
    },
    async updateGuidance() {
      const emailEncoded = encodeURIComponent(this.emailToUpdateGuidance.trim());
      const individualGuidanceEncoded = encodeURIComponent(this.textToUpdateGuidance.trim());
      let uri = `${import.meta.env.VITE_API_URL}/pages/${this.page.id}/invite/${emailEncoded}?useGlobalGuidance=${this.useGlobalToUpdateGuidance}&individualGuidance=${individualGuidanceEncoded}`;
      await axios.put(uri);
      // Update the invite parent's collection
      this.$emit("invite", this.emailToUpdateGuidance, false, this.useGlobalToUpdateGuidance ? "" : this.textToUpdateGuidance);
      this.showEditGuidanceModal = false;
    },
    creatorInviteTemplate() {
      this.showEmailExampleModal = true;
      // Note: Doing a mailto looked terrible since it's plain-text.  Ended up with a modal the user can copy from.
    },
    displayInviteDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
});
</script>

<style scoped lang="scss">
.inviteButton {
  transition: all 1s ease;
  i {
    margin-right: 10px;
  }
  &:not(.empty):focus,
  &:not(.empty):active,
  &:not(.empty):visited {
    i {
      -webkit-animation: bounce 1s ease;
      animation: bounce 1s ease;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
    span {
      -webkit-animation: blinker 1s ease;
      animation: blinker 1s ease;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}
.invited-user {
  -webkit-animation: pop-in 0.5s;
  animation: pop-in 0.5s;
}
.invited-email {
  display: flex;
  align-items: center;
  font-size: 16px;
  &:last-child {
    i {
      -webkit-animation: growUp 0.3s;
      animation: growUp 0.3s;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
  .icon {
    color: #058587;
    font-size: 18px;
    vertical-align: middle;
  }
}
@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}
@keyframes growUp {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateX(0) scale(1) rotate(0);
    transform: translateX(0) scale(1) rotate(0);
  }
  5% {
    -webkit-transform: translateX(0) scale(1) rotate(45deg);
    transform: translateX(0) scale(1) rotate(45deg);
  }
  50% {
    -webkit-transform: translateX(95px) scale(0.5) rotate(45deg);
    transform: translateX(95px) scale(0.5) rotate(45deg);
  }
  60% {
    -webkit-transform: translateX(90px) scale(0.8) rotate(45deg);
    transform: translateX(90px) scale(0.8) rotate(45deg);
  }
  100% {
    -webkit-transform: translateX(0px) scale(1) rotate(0);
    transform: translateX(0px) scale(1) rotate(0);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
