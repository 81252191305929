<template>
  <div>
    <div class="ui form">
      <div style="margin-bottom: 15px">
        <div>
          <h1 style="margin-bottom: 10px; display: inline">Community Uploaded Photos &amp; Videos</h1>
          <button class="ui small basic button right floated" @click="back()">back</button>
        </div>
        <div>
          Manage the photos and videos your community members have uploaded!<br />
          <!--You can see photos included in your adventures <router-link class="item" lang="en" hreflang="en" style="text-decoration:underline;"
            :to="{name: 'CommunityPhotosV2', params: { pageId: customerId }}">
            here</router-link>-->
        </div>
      </div>
    </div>

    <button
      v-if="userUploadGroups && userUploadGroups.length > 0"
      :disabled="imageIdsSelected.length === 0"
      @click="removeSelectedImages"
      class="ui small basic primary button"
      style="margin-bottom: 1em"
    >
      Delete Selected
    </button>

    <Loader v-if="isLoading" />
    <div v-else>
      <div v-for="(uploadGroup, index) in userUploadGroups" :key="`group-${index}`" class="ui one column grid">
        <div class="column">
          <div class="ui segment">
            <div class="ui grid">
              <div class="sixteen wide column">
                <div class="main-wrapper">
                  <div class="row">
                    <h3>{{ uploadGroup.uploadersName }}</h3>
                    <div style="display: flex"><i class="grey mail icon"></i> {{ uploadGroup.uploadersEmail }}</div>
                  </div>
                </div>
              </div>
              <div class="sixteen wide column">
                <div class="columns-layout columns-layout--4-col">
                  <div
                    v-for="(image, index) in uploadGroup.photos"
                    :key="index"
                    @click="selectImage(uploadGroup.uploadId, image)"
                    :class="{
                      'photo-div': true,
                      'selected-image': imageIdsSelected.indexOf(image) !== -1,
                      'columns-layout__item': true,
                    }"
                  >
                    <div class="ui compact segment select-photo">
                      <div class="ui fitted checkbox">
                        <input type="checkbox" name="select-individual-photo" :checked="imageIdsSelected.indexOf(image) !== -1" />
                        <label></label>
                      </div>
                    </div>
                    <template v-if="isVideo(image)">
                      <VideoRender :videoId="image" :customClasses="'photogallery-admin-asset'" />
                    </template>
                    <template v-else>
                      <img :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`" class="photogallery-admin-asset" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Remove Photo Modal-->
      <SrpModal v-model:isVisible="showRemovePhotoModal">
        <template #header>
          <h2 class="global-h2">Are you sure you remove these {{ imageIdsSelected.length }} photos?</h2>
        </template>
        <template #footer>
          <h3 style="display: inline">{{ saveStatus }}</h3>
          <div class="ui basic black button" @click="showRemovePhotoModal = false">Cancel</div>
          <button class="ui red button" :disabled="saveStatus != null" @click="removeImageConfirmed()">Yep, remove them.</button>
        </template>
      </SrpModal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import UploadPhotosNonAdventure from "../CMS/UploadPhotosNonAdventure.vue";
import VideoRender from "@components/VideoRender.vue";
import FileUtils from "@logic/FileUtils";
import SrpModal from "@components/ui/SrpModal.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "UserUploadedGalleryAdmin",

  components: {
    SrpModal,
    Loader,
    UploadPhotosNonAdventure,
    VideoRender,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Community Photo Uploads",
      customerId: null as string | null,
      isLoading: true,
      isLoadingMore: false,
      saveStatus: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      userUploadGroups: [],
      imageIdsSelected: [] as string[],
      showRemovePhotoModal: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    this.getAllData();
  },

  methods: {
    async getAllData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.customerId}/photos`);
      this.userUploadGroups = photoResult.data;
      this.isLoading = false;
    },
    selectImage(uploadId: string, image: string) {
      const key = image; // `${uploadId}---${image}`
      const index = this.imageIdsSelected.indexOf(key);
      if (index > -1) {
        this.imageIdsSelected.splice(index, 1);
      } else {
        this.imageIdsSelected.push(key);
      }
    },
    removeSelectedImages() {
      // Ask if they're sure
      this.showRemovePhotoModal = true;
    },
    async removeImageConfirmed() {
      this.saveStatus = "Removing...";
      // Save the changes
      const config = {
        headers: { "Content-Type": "application/json" },
        data: this.imageIdsSelected,
      };
      await axios.delete(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.customerId}/photos`, config);
      this.globalLog.info(`Removed ${this.imageIdsSelected.length} from the gallery`);
      // Remove these from the local list
      this.imageIdsSelected.forEach(imageId => {
        this.userUploadGroups.forEach(group => {
          const index = group.photos.indexOf(imageId);
          if (index > -1) {
            group.photos.splice(index, 1);
          }
        });
      });
      this.showRemovePhotoModal = false;
      this.imageIdsSelected.splice(0); // This is reactive in Vue
      this.saveStatus = null;
    },
    back() {
      // Could have come from a few places
      history.back();
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.photo-div {
  padding: 5px;
  position: relative;

  //Style moved to main.scss
}

.select-photo {
  position: absolute;
  bottom: 18px;
  right: 10px;
  cursor: pointer;
  z-index: 30;
  padding: 4px 4px 0px 4px !important;
  margin-bottom: 0 !important;
}

.ui.three.cards {
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

img {
  cursor: pointer;
}

.selected-image {
  img {
    border: 4px solid $brand-color;
  }
}
</style>
