<template>
  <div>
    <div class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <div>
        <h1 style="display: inline; margin-right: 25px">Easily Discover with a QR Code!</h1>
      </div>
      <p>
        QR codes allow {{ usersTerm }} to quickly and easily visit your homepage to find great adventures. <br />You can place this code anywhere you want people to discover great local experiences
        (kiosks, printouts, anywhere)!
      </p>

      <label class="cms-section-title">2{{ orgName }}2 QR Code</label>
      <Loader v-if="isLoading"></Loader>
      <p v-else>
        <img style="max-width: 200px" :src="`data:image/png;base64, ${imageData}`" />
        <br />
        <a style="margin-top: 15px" class="ui primary small button" download="shrpa-qrcode.png" :href="`data:image/png; base64,  ${imageData}`">Download</a>
        <span v-if="justCopied"><b>Done!</b></span>
      </p>

      <label class="cms-section-title">How do {{ usersTerm }} use this?</label>
      <p>
        Just open the camera app and hold the phone over this code.
        <br />
        iPhone example:
        <a style="text-decoration: underline" target="_blank" href="https://support.apple.com/guide/iphone/scan-a-qr-code-iphe8bda8762/ios"
          >https://support.apple.com/guide/iphone/scan-a-qr-code-iphe8bda8762/ios</a
        >
      </p>

      <label class="cms-section-title">Questions?</label>
      <p>Email us anytime at support@shrpa.com</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { Page } from "@contracts/pages";
import OrgContext from "@logic/OrgContext";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "QRCodes",

  components: {
    Loader,
  },

  data() {
    return {
      title: "QR Codes",
      pageId: "",
      orgName: null,
      imageData: null,
      justCopied: false,
      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isHotel: false,
    };
  },

  computed: {
    usersTerm(): string {
      return this.isHotel ? "guests" : "travelers";
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    let orgInContext = OrgContext.getOrgInContext(this);
    this.orgName = orgInContext?.name;
    this.title += " - " + this.orgName;
    this.isHotel = OrgContext.isOrgInContextHotel(this);
    this.getCode();
  },

  methods: {
    async getCode() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/qrcodes/${this.pageId}/base64`);
      this.imageData = data;
      this.isLoading = false;
    },
  },
});
</script>

<style scoped lang="scss"></style>
