<template>
  <div class="dashboard-content">
    <Loader v-if="!isAuthenticationInitialized || (isAuthenticated && !isProfileLoaded)" style="position: absolute" />

    <template v-else>
      <!-- Greeting section -->
      <div class="greeting-section dashboard-content__greeting-section">
        <div class="greeting-section__title-n-text">
          <template v-if="!isAuthenticated">
            <div class="global-h1 greeting-section__title">Welcome to Shrpa!</div>
            <div class="greeting-section__text">
              <p>New to Shrpa? Just authenticate with {{ emailHint ? emailHint : "the email your invite was sent to" }}. It's as easy as that!</p>
              <div>
                <button class="ui primary button" @click="isShowLoginModal = true">Continue</button>
              </div>
            </div>
          </template>

          <template v-if="isProfileLoaded">
            <!-- Not an admin -->
            <template v-if="!communityIdInContext && !isSuperOrSalesUser">
              <div class="global-h1 greeting-section__title">Hi There!</div>
              <div class="greeting-section__text">
                <p v-if="checkIfSignedInWithDifferentEmailFromSignUpHint">
                  It looks like {{ emailHint }} was invited but you are currently logged in as {{ getCurrentUsersEmail() }}.<br />
                  Try logging in with {{ emailHint }} or email <CopyTextNew>support@shrpa.com</CopyTextNew> to setup another admin email. Thanks!
                </p>
                <p v-else>
                  It doesn't look like we have you set up as a community admin.<br />
                  If you think this is in error, please email <CopyTextNew>support@shrpa.com</CopyTextNew>
                </p>
              </div>
            </template>

            <!-- Admin -->
            <template v-else>
              <div class="global-h1 greeting-section__title">
                {{ greeting }}
              </div>
              <div class="greeting-section__text">
                <p>
                  We're excited to have you be a part of Shrpa! This is a dashboard to help guide you through Shrpa's features.<br />
                  If you have any questions let us know at <CopyTextNew>support@shrpa.com</CopyTextNew>
                </p>

                <NoteWithIcon v-if="isCurrentCustomerDisabled" color="red" style="margin-top: 15px">
                  <template #icon><IconEmbedded name="warning_2-5" style="position: relative; top: -2px" /></template>
                  <template #text>
                    <div>
                      <h3 class="global-h3" style="margin-bottom: 3px">This account is no longer active.</h3>
                      <p>Reach out to <CopyText :text="'sales@shrpa.com'" /> to learn about the benefits of renewing!</p>
                    </div>
                  </template>
                </NoteWithIcon>

                <NoteWithIcon v-else-if="showSubscriptionEnding || showSubscriptionRenewing" color="yellow" style="margin-top: 15px">
                  <template #icon><IconEmbedded name="info-simple_4" /></template>
                  <template #text>
                    <div>
                      <template v-if="showSubscriptionEnding">
                        <h4 class="global-h4" style="margin-bottom: 3px">Your subscription is ending soon!</h4>
                        <div>At that time, Photo Walls, Adventure Walls, and Adventure Previews will no longer be displayed and you will lose access to the admin.</div>
                        <div style="margin-top: 10px; margin-bottom: 10px">Reach out if you have any questions or want to discuss potential renewal options.</div>
                      </template>
                      <template v-else>
                        <h4 class="global-h4" style="margin-bottom: 3px">Your subscription is renewing soon!</h4>
                      </template>
                      <RouterLink :to="{ name: 'SubscriptionSettings', params: { pageId: communityIdInContext } }" class="global-a global-a--orange"> View or Manage your Subscription </RouterLink>
                    </div>
                  </template>
                </NoteWithIcon>
              </div>
            </template>
          </template>
        </div>

        <!--SuperUser only-->
        <select
          v-if="isProfileLoaded && (communityIdInContext || isSuperOrSalesUser) && !isLoadingCommunityData && isSuperOrSalesUser"
          v-model="superUserManualCommunityIdSelection"
          :class="{
            'ui dropdown': true,
            'greeting-section__superuser-field': true,
          }"
          @change="superUserAddCommunity"
        >
          <option value="" disabled selected hidden>Select community</option>
          <option v-for="communityId in everyCommunityId" :key="`super-${communityId}`" :value="communityId">{{ communityId }}</option>
        </select>
      </div>
      <!-- / Greeting section -->

      <!-- Multiple account Admin section -->
      <div v-if="!isLoadingCommunityData && communityData.length > 1 && isMultipleAdminSectionVisible" class="admin-controls dashboard-content__admin-controls">
        <div
          :class="{
            'modal-close-button': true,
            'admin-controls__close-btn': true,
          }"
          @click="() => (isMultipleAdminSectionVisible = false)"
        >
          <IconEmbedded name="remove_3" />
        </div>

        <!--Multiple Community Admin-->
        <div v-if="communityData.length > 1" class="admin-controls__multiple-communities-admin">
          <p style="margin-bottom: 0; font: 14px/19px sans-serif">
            It looks like you're the admin for multiple communities!<br />
            Select the one you want to administer:
            <select v-model="communityIdInContext" class="ui dropdown" @change="communityChanged">
              <option v-for="(community, index) in communityData" :key="index" :value="community.communityId">
                {{ community.communityName }}
              </option>
            </select>
          </p>
        </div>
      </div>
      <!-- / Admin controls -->

      <div class="dashboard-content__horizontal-divider"></div>

      <template v-if="isProfileLoaded && (communityIdInContext || isSuperOrSalesUser)">
        <!-- Collab Summaries  section -->
        <Loader v-if="isCollabAndCreatorSummaryArrayLoading" style="margin: -10px 0 30px" />
        <template v-else-if="collabAndCreatorSummaryArray.length">
          <MyActiveCollabsSection :collabAndCreatorSummaryArray="collabAndCreatorSummaryArray" :communityId="communityIdInContext" style="margin-bottom: 45px" />

          <div class="dashboard-content__horizontal-divider" style="margin-bottom: 45px"></div>
        </template>

        <!-- Actions and Highlights -->
        <Loader v-if="isLoadingCommunityData" />
        <template v-else>
          <div
            v-if="!currentCustomerInContext?.isDisabled"
            :class="{
              'dashboard-content__actions-section': true,
              'dashboard-content__actions-section--row-arranged-children': !isAllThreeActionSectionsVisible,
            }"
          >
            <ActionSnippet
              :class="{
                'dashboard-content__action-snippet': true,
                'dashboard-content__action-snippet--no-bottom-margin': !isAllThreeActionSectionsVisible,
              }"
              title="Current Actions"
              :subTitle="actions.length > 0 ? 'Here are some ways to get the most out of Shrpa.' : 'You\'re all caught up. Nice job!'"
              :actionsList="actionsSorted"
            />

            <ActionSnippet
              v-if="otherActions.length > 0"
              :class="{
                'dashboard-content__action-snippet': true,
                'dashboard-content__action-snippet--no-bottom-margin': !isAllThreeActionSectionsVisible,
              }"
              title="Other Actions"
              :actionsList="otherActions"
            />

            <HighlightsList
              v-if="currentCommunityStats && (currentCommunityStats.communityAdventureCount > 0 || currentCommunityStats.paidAdventureCount > 0)"
              class="dashboard-content__highlights-list"
              :stats="currentCommunityStats"
              :communityId="communityIdInContext"
              :isHotel="isHotel"
            />
          </div>

          <!--Activity Feed-->
          <ActivityFeed viewingContext="customer" :allowShowMore="false" style="max-width: 720px" />

          <WeLoveToVisitSnippet class="dashboard-content__we-love-snippet" :destinationName="currentCommunityName" />
        </template>
      </template>

      <!--Modals-->
      <!--NOTE: The v-if is required here because of the logic in mount() in the LoginModal.  Should consider reworking at some point.-->
      <LoginModal v-if="isShowLoginModal" :startWithNewUserFlow="true" :isVisible="isShowLoginModal" :defaultEmail="emailHint" @close="isShowLoginModal = false" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import { getInstance } from "@auth/authWrapper";
import AdminContext from "@logic/AdminContext";
import { RouteHelper } from "@helpers/RouteHelper";
import OrgContext from "@logic/OrgContext";
import { useHead } from "@unhead/vue";

// Types
import { CustomerDashboardSummary } from "@contracts/pages";
import { CustomerStatsOverview } from "@contracts/pages";
import { CollabAndCreatorSummary } from "@contracts/collab";
import { CustomerCollabActionSummary } from "@contracts/collabActionSummaries";

// Components
import ActionSnippet from "./ActionsSnippet.vue";
import CopyText from "@components/CopyText.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
import HighlightsList from "./HighlightsList.vue";
import HighlightSnippet from "./HighlightSnippet.vue";
import Loader from "@components/Loader/Loader.vue";
import LoginModal from "@components/Modals/LoginModal.vue";
import WeLoveToVisitSnippet from "@components/WeLoveToVisitSnippet.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import ActivityFeed from "@components/ActivityFeed/index.vue";
import MyActiveCollabsSection from "./MyActiveCollabsSection.vue";

export default defineComponent({
  name: "Dashboard",

  components: {
    MyActiveCollabsSection,
    ActivityFeed,
    IconEmbedded,
    ActionSnippet,
    CopyText,
    CopyTextNew,
    HighlightsList,
    HighlightSnippet,
    Loader,
    LoginModal,
    WeLoveToVisitSnippet,
    NoteWithIcon,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      actions: [],
      completedActions: [],
      otherActions: [],
      isAuthenticationInitialized: false,
      isAuthenticated: false,
      isShowLoginModal: false,
      isProfileLoaded: false,
      communityIdInContext: null as string | null,
      title: "Admin Dashboard",
      // Note: We could probably remove this and just rely on the communityData
      adminOf: [] as Array<string>,
      isMultipleAdminSectionVisible: true,
      communityData: [] as Array<CustomerDashboardSummary>,
      isLoadingCommunityData: true,
      userProfileStore: useUserProfileStore(),
      // SuperUser fields
      everyCommunityId: [] as Array<string>,
      superUserManualCommunityIdSelection: "" as string | null,
      superUserManuallyAddedCommunityIds: [] as Array<string>,
      // If the org in context is a hotel or not
      isHotel: false,
      // Improved login flow
      emailHint: null as string,
      isEmailHint: false,
      isEmailHintGmail: false,

      isCollabAndCreatorSummaryArrayLoading: false,
      collabAndCreatorSummaryArray: [] as Array<CustomerCollabActionSummary>,

      currentCommunityStats: null as CustomerStatsOverview | null,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isSuperOrSalesUser"]),
    isAllThreeActionSectionsVisible(): boolean {
      return this.actions.length > 0 && this.otherActions.length > 0 && this.completedActions.length > 0;
    },

    actionsSorted(): Array<any> {
      return [...this.actions].sort((a, b) => a.priority - b.priority);
    },
    completedActionsSorted(): Array<any> {
      return [...this.completedActions].sort((a, b) => a.priority - b.priority);
    },
    greeting(): string {
      if (this.getViewingUserProfile?.firstName && !this.getViewingUserProfile.firstName.includes("@")) {
        return `Welcome ${this.getViewingUserProfile.firstName}!`;
      }
      return "Welcome!";
    },
    currentCommunityName(): string {
      if (!this.communityIdInContext || !this.communityData) {
        return "";
      }

      const community = this.communityData.filter(c => c.communityId === this.communityIdInContext);

      if (community && community.length > 0) {
        return community[0].communityName;
      }

      return "";
    },
    currentCustomerInContext() {
      if (!this.communityIdInContext || !this.communityData) return null;
      const customerInContext = this.communityData.find(c => c.communityId === this.communityIdInContext);

      return customerInContext ?? null;
    },
    showSubscriptionRenewing(): boolean {
      return this.currentCustomerInContext?.showSubscriptionRenewing ?? false;
    },
    showSubscriptionEnding(): boolean {
      return this.currentCustomerInContext?.showSubscriptionEnding ?? false;
    },
    isCurrentCustomerDisabled(): boolean {
      return this.currentCustomerInContext?.isDisabled ?? false;
    },
    exclamations(): string {
      const howExcited = 4 - this.actions.length;
      return "!".repeat(howExcited);
    },
    checkIfSignedInWithDifferentEmailFromSignUpHint(): boolean {
      if (this.emailHint) {
        const currentUserEmail = this.getCurrentUsersEmail();
        if (currentUserEmail) {
          // If they're signed in with an account that's not the hint we show them a special message.
          console.info(`checkIfSignedInWithDifferentEmailFromSignUpHint: currentUserEmail=${currentUserEmail}, invitedUser=${this.emailHint}`);
          return currentUserEmail.toLowerCase() !== this.emailHint.toLowerCase();
        }
      }
      return false;
    },
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      this.globalLog.info("Dashboard: loggedInUser watch fired");
      if (profile) {
        this.profileLoaded();
      }
    },
    $route(to, from) {
      // Needed since the left nav uses the redirect parameters currently
      this.checkForRedirect();
    },
  },

  mounted() {
    useHead({ title: () => this.title ?? "" });

    /* Note: This Init sequence is fairly complicated since we want to handle several scenarios:
     * 1. Auth is still loading (so we don't know much yet)
     * 2. User is not logged in
     * 3. Profile is loaded (user must be logged in)
     *
     * Additional logic pivots off that to handle edge cases like (has a profile but )
     */
    this.checkForEmailHint();
    const authService = getInstance();

    if (authService.loading === false) {
      this.authLoaded();
    }

    authService.$watch("loading", loading => {
      if (loading === false) {
        this.authLoaded();
      }
    });

    // This code is used if they got here via a link on the site.
    // On a fresh load, the watch will catch them once their profiles loads (after auth)
    // @ts-ignore
    if (this.getActingUserProfile) {
      this.globalLog.info("Dashboard: Profile already loaded");
      this.profileLoaded();
    }
  },

  methods: {
    authLoaded() {
      this.isAuthenticationInitialized = true;
      const authService = getInstance();
      this.isAuthenticated = authService.isAuthenticated;
      this.globalLog.info(`Dashboard.AuthLoaded: IsAuthenticated=${this.isAuthenticated}`);
    },
    async profileLoaded() {
      this.globalLog.info(`ProfileLoaded`);
      // @ts-ignore
      const adminOf = this.getActingUserProfile.adminOf;

      if (adminOf && adminOf.length > 0) {
        this.adminOf = adminOf;
        this.communityIdInContext = AdminContext.getCommunityIdInContext();
        this.globalLog.info(`Setting context to ${this.communityIdInContext}. Is adminOf ${adminOf.length} community(s)`);

        // Check for redirects to specific admin pages
        this.checkForRedirect();
      }

      this.isProfileLoaded = true;

      await this.checkForSuperUserFields();
      await this.loadData();
    },
    checkForRedirect() {
      // This provides quick redirects w/out having the communityId in the uri,
      // which makes it much easier to template emails
      // NOTE: This fires after the profile is loaded but can be before other data is fully loaded.
      let redirect = RouteHelper.getQueryStringParam("r");
      if (!redirect) redirect = RouteHelper.getQueryStringParam("r2");
      if (redirect) {
        // Ensure CommunityIdInContext is set
        if (!this.communityIdInContext) {
          if (this.getViewingUserProfile?.adminOf.length > 0) this.communityIdInContext = this.getViewingUserProfile.adminOf[0];
        }
        if (redirect.toUpperCase() === "COLLABS") {
          this.navigateToPaidCollab(true, false);
        } else if (redirect.toUpperCase() === "PLANNEDVISITS") {
          this.navigateToPaidCollab(true, true);
        } else if (redirect.toUpperCase() === "PAGE") {
          this.navigateToCommunityPageAdmin(true);
        } else if (redirect.toUpperCase() === "WIDGET" || redirect.toUpperCase() === "PHOTOWALL") {
          this.navigateToPhotoWalls(true);
        } else if (redirect.toUpperCase() === "ADVWALL") {
          this.navigateToAdventureWalls(true);
        } else if (redirect.toUpperCase() === "QR" || redirect.toUpperCase() === "PRINTMEDIA") {
          this.navigateToPrintMedia(true);
        } else if (redirect.toUpperCase() === "PHOTOS") {
          this.navigateToPhotos(true);
        } else if (redirect.toUpperCase() === "APPROVALS") {
          this.navigateToApprovals(true);
        } else if (redirect.toUpperCase() === "COMMUNITYUPLOAD") {
          this.navigateToCommunityUpload(true);
        } else if (redirect.toUpperCase() === "SHARESTOP") {
          this.navigateToShareStop(true);
        } else if (redirect.toUpperCase() === "SUBSCRIPTION") {
          this.navigateToManageSubscription(true);
        } else if (redirect.toUpperCase() === "USERADMIN") {
          this.navigateToUserAdmin(true);
        } else if (redirect.toUpperCase() === "ADVSUM") {
          this.navigateToAdventureSummary(true);
        }
      }
    },
    async loadData(communityIdToSelect: string = null) {
      // Call loadCommunityData first since it defaults the communityIdInContext
      await this.loadCommunityData(communityIdToSelect);
      // Not awaiting these so they run in parallel
      this.loadCollabSummaryWithActions();
      this.loadCustomerStats();
    },
    // Loads the collab summaries.
    // NOTE: communityIdInContext MUST be set before calling this.
    async loadCollabSummaryWithActions() {
      this.isCollabAndCreatorSummaryArrayLoading = true;
      let uri = `${import.meta.env.VITE_API_URL}/collab-summary/customers/${this.communityIdInContext}/summary-with-actions`;
      const { data } = await axios.get<Array<CustomerCollabActionSummary>>(uri);

      this.collabAndCreatorSummaryArray = data;
      this.isCollabAndCreatorSummaryArrayLoading = false;
    },
    async loadCustomerStats() {
      let uri = `${import.meta.env.VITE_API_URL}/pages/${this.communityIdInContext}/stats`;
      const { data } = await axios.get<CustomerStatsOverview>(uri);
      this.currentCommunityStats = data;
    },
    // Loads the passed community data OR defaults if not passed
    async loadCommunityData(communityIdToSelect: string = null) {
      let uri = `${import.meta.env.VITE_API_URL}/pages/dashboard`;

      if (this.superUserManuallyAddedCommunityIds.length > 0) {
        uri += "?";
        this.superUserManuallyAddedCommunityIds.forEach(id => (uri += `adds=${id}&`));
      }

      const response = await axios.get(uri);
      this.communityData = response.data;

      // Auto-select for superuser flow
      if (communityIdToSelect) {
        this.communityIdInContext = communityIdToSelect;
        this.setOrgInContext(this.getCommunityInContext());
      }

      // The superuser manual add flows means communityData might have nothing in it on the initial load
      if (this.communityData.length > 0) {
        this.defaultSelectedCommunityFromSession();
        this.setOrgInContext(this.getCommunityInContext());
        this.calculateActions();
      }

      this.isLoadingCommunityData = false;
    },
    getCommunityInContext(): CustomerDashboardSummary | null {
      const community = this.communityData.filter(c => c.communityId === this.communityIdInContext);
      if (community && community.length > 0) return community[0];
      else if (this.communityData.length > 0) {
        // Edge case: communityIdInContext is pretty sticky, if a user logs out/in as another in the same session,
        // then they may get a communityInContext that they don't actually have, so just pick the first one they do have;
        // And update the communityIdInContext
        this.communityIdInContext = this.communityData[0].communityId;
        this.setOrgInContext(this.communityData[0]);
        return this.communityData[0];
      }

      return null;
    },
    checkForEmailHint() {
      // Invites pass along the email as a hint so we can direct the signin/up flow to be less confusing (since Auth0s UI isn't great)
      const emailHint = RouteHelper.getQueryStringParam("email");

      if (emailHint && emailHint.length > 0) {
        this.isEmailHint = true;
        this.emailHint = emailHint;

        if (emailHint.toLowerCase().endsWith("gmail.com")) {
          this.isEmailHintGmail = true;
        }
      }
    },
    getCurrentUsersEmail(): string | null {
      return this.getViewingUserProfile?.email;
    },
    async checkForSuperUserFields() {
      try {
        if (this.isSuperOrSalesUser) {
          // Re-hydrate the ids that have been added
          const cache = sessionStorage.getItem("SuperUserManualCommunitySelections");
          if (cache) {
            this.superUserManuallyAddedCommunityIds = JSON.parse(cache);
          }
          this.getAllCommunityIds();
        }
      } catch (ex) {
        this.globalLog.error("checkForSuperUserFields Failed: " + ex);
      }
    },
    async getAllCommunityIds() {
      // Used for the superuser manual community add
      // Added back sales customres since we want Funtown and the hotel test
      const everyCommunityResult = await axios.get(`${import.meta.env.VITE_API_URL}/pages/ids?includeTestCustomers=true`);
      this.everyCommunityId = everyCommunityResult.data;
    },
    superUserAddCommunity() {
      if (this.superUserManualCommunityIdSelection && this.superUserManualCommunityIdSelection.length > 0) {
        this.superUserManuallyAddedCommunityIds.push(this.superUserManualCommunityIdSelection);
        // Save it in sessionStorage since we need this in the list when we use the Back button
        sessionStorage.setItem("SuperUserManualCommunitySelections", JSON.stringify(this.superUserManuallyAddedCommunityIds));
        // Reload the data
        this.loadData(this.superUserManualCommunityIdSelection);
      }
    },
    defaultSelectedCommunityFromSession() {
      const selectedCommunityId = AdminContext.getCommunityIdInContext();
      if (selectedCommunityId && selectedCommunityId !== null) {
        this.communityIdInContext = selectedCommunityId;
      }
    },
    async setOrgInContext(org: CustomerDashboardSummary) {
      this.globalRoot.orgInContext = AdminContext.setOrgInContext(
        null,
        org.topNavLogoUri,
        org.communityUniqueName,
        org.communityId,
        org.type,
        org.communityName,
        org.immutableId,
        org.isDisabled,
        org.enableCreateAdventures
      );

      // NOTE: Also set the local field for if this is a hotel or not
      this.isHotel = OrgContext.isOrgInContextHotel(this);
      this.setTitle();
    },
    setTitle() {
      // @ts-ignore
      if (this.globalRoot.orgInContext) {
        // @ts-ignore
        this.title = "Admin Dashboard - " + this.globalRoot.orgInContext.name;
      }
    },
    communityChanged() {
      // Change the icon in the header
      this.setOrgInContext(this.getCommunityInContext());
      // Re-calc the actions
      this.calculateActions();

      this.loadData();
    },
    calculateActions() {
      this.globalLog.info("Calculating Actions");
      // Calculate state of the actions
      this.actions = [];
      this.completedActions = [];
      this.otherActions = [];
      this.addCommunityDetailsAction();
      this.addPaidCollabAction();
      // V1.1 this.addTouchbaseAction();
      this.addPhotoWallAction();

      this.addSiteIntegrationAction();
      this.addPhotosAction();
      this.addQRCodeAction();
    },
    addCommunityDetailsAction() {
      const community = this.getCommunityInContext();
      const noun = this.isHotel ? "Hotel" : "Destination";

      if (community.minimumCommunityPageCompleted) {
        this.addAction(true, `Update ${noun} Page`, "Edit", true, 40, this.navigateToCommunityPageAdmin);
      } else {
        this.addAction(false, `Setup ${noun} Page`, "Get Started!", false, 10, this.navigateToCommunityPageAdmin);
      }
    },
    navigateToCommunityPageAdmin(noHistory = false) {
      const destination = { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    addPaidCollabAction() {
      const community = this.getCommunityInContext();
      if (!community.hasPaidCollabSubscription) {
        // Nothing to do here
        return;
      }

      // They have paid collabs in some state
      if (community.firstCollabNeeded) {
        this.addAction(false, "Kick Off First Creator Visit", "Initiate a Creator Visit!", false, 20, this.navigateToPaidCollab);
      } else {
        // Note: Keeping this in the "active" section
        if (community.collabInFlight) {
          this.addAction(false, "Review In-flight Creator Visit", "Review", true, 30, this.navigateToPaidCollab);
        } else if (community.remainingCollabCount > 0) {
          this.addAction(false, "Initiate Another Creator Visit", "Initiate Visit", false, 30, this.navigateToPaidCollab);
        } else {
          // Still show it so the user can review old collabs
          this.addAction(true, "Review Creator Visits", "Review Collabs", true, 50, this.navigateToPaidCollab);
        }
      }
    },
    navigateToPaidCollab(noHistory = false, showPlannedVisits = false) {
      const destination = { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext }, hash: "" };
      if (showPlannedVisits) {
        // Add a hash param to show the planned visits modal
        destination.hash = "#showPlannedVisits=true";
      }

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    addPhotosAction() {
      if (this.isHotel) return;

      // If there aren't stats then they won't have any photos either so hide this
      const community = this.getCommunityInContext();
      if (!(community.collabsCompleted === 0)) return;

      this.otherActions.push({
        title: "All Photos",
        status: "Download",
        callback: this.navigateToPhotos,
      });
    },
    navigateToPhotos(noHistory = false) {
      const destination = { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToApprovals(noHistory = false) {
      const destination = { name: "CommunityAdventureApproval", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToCommunityUpload(noHistory = false) {
      const destination = { name: "UserUploadCampaigns", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToShareStop(noHistory = false) {
      const destination = { name: "ShareStopSummaryV2", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToManageSubscription(noHistory = false) {
      const destination = { name: "SubscriptionSettings", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToUserAdmin(noHistory = false) {
      const destination = { name: "UserAdmin", params: { pageId: this.communityIdInContext } };
      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToAdventureSummary(noHistory = false) {
      const destination = { name: "AdventureSummary", params: { pageId: this.communityIdInContext } };
      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    addPhotoWallAction() {
      const community = this.getCommunityInContext();

      if (community.photoIntegrationHasHits) {
        this.addAction(true, "Photo Wall", "Add even more", true, 20, this.navigateToPhotoWalls);
      } else {
        this.addAction(false, "Photo Wall", "Add photos", false, 40, this.navigateToPhotoWalls);
      }
    },
    addSiteIntegrationAction() {
      this.otherActions.push({
        title: "Your Website Upgrades",
        status: "Upgrade",
        callback: this.navigateToPhotoWalls,
      });
    },
    navigateToPhotoWalls(noHistory = false) {
      const destination = { name: "WidgetDirections", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToAdventureWalls(noHistory = false) {
      const destination = { name: "AdventureWidgets", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    addQRCodeAction() {
      // Removing dashboard action, but leaving in left nav
      /* this.otherActions.push({
        title: "Print Media",
        status: "Easily Share",
        callback: this.navigateToPrintMedia,
      });*/
    },
    navigateToPrintMedia(noHistory = false) {
      const destination = { name: "PrintMedia", params: { pageId: this.communityIdInContext } };

      if (noHistory === true) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    addTouchbaseAction() {
      // Future, not adding yet.  Need to consider how the state changes here (ex. user marks it as 'done' or we manually update a field)
      this.addAction(false, "Schedule a Sync with Shrpa", "Setup a Call", false, 30, this.navigateToScheduleTouchbase);
    },
    navigateToScheduleTouchbase() {
      // Future
    },
    // Adds an action to the list (lower priority shows first)
    addAction(completed: boolean, title: string, status: string, showCheck: boolean, priority: number, callback: Function) {
      const action = {
        title: title,
        status: status,
        showCheck: showCheck,
        priority: priority,
        callback: callback,
      };

      if (completed) this.completedActions.push(action);
      else this.actions.push(action);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/modal-close-button.scss";

// Greeting section ===========================================================
.greeting-section {
  display: flex;
  align-items: flex-start;

  &__title-n-text {
    flex-grow: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__text,
  &__text p {
    margin-bottom: 0;
    font:
      400 14px/22px "Helvetica Neue",
      sans-serif;
  }

  &__copy-email-snippet {
  }

  &__superuser-field {
    width: 200px;
    min-width: 200px;
    margin-top: 40px;
    opacity: 0.6;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  // Greeting section ===========================================================
  .greeting-section {
    flex-direction: column;
    align-items: flex-start;

    &__title-n-text {
      margin-right: 0;
    }

    &__superuser-field {
      margin-top: 15px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .greeting-section {
    flex-direction: column;
    align-items: flex-start;

    &__title-n-text {
      margin-right: 0;
    }

    &__title {
      margin-bottom: 7px;
    }
    &__superuser-field {
      margin-top: 15px;
    }
  }
}

// Admin controls =============================================================
.admin-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &__close-btn {
    position: absolute;
    inset: -10px -10px auto auto;
    z-index: 2;
  }

  &__multiple-communities-admin {
    width: 100%;
    padding: 19px 25px 19px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: #f3f3f3;
    }
  }
}

// Dashboard content ==========================================================
.dashboard-content {
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  color: #5b5b5b;
  font:
    normal 14px/19px "Helvetica Neue",
    sans-serif;

  &__greeting-section {
    margin-bottom: 40px;
  }

  &__horizontal-divider {
    margin-bottom: 35px;
    border-bottom: 1px rgba(black, 0.1) solid;
  }

  &__highlights-list {
    width: 100%;
    margin-bottom: 70px;
  }

  &__admin-controls {
    margin-bottom: 45px;
  }

  &__actions-section {
    margin-bottom: 65px;
    column-gap: 30px;
    column-count: 2;

    &--row-arranged-children {
      display: flex;
      align-items: flex-start;
    }
  }

  &__action-snippet {
    width: 100%;
    margin-bottom: 30px;

    &--no-bottom-margin {
      margin-bottom: 0 !important;
    }
  }

  &__action-snippet + &__action-snippet {
    margin-bottom: 0;
  }

  &__we-love-snippet {
    margin-top: 30px;
    width: 100%;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .dashboard-content {
    &__greeting-section {
      margin-bottom: 30px;
    }

    &__horizontal-divider {
      margin-bottom: 30px;
    }

    &__highlights-list {
      margin-bottom: 55px;
    }

    &__actions-section {
      margin-bottom: 30px;
      column-gap: 26px;
    }

    &__action-snippet {
      margin-bottom: 26px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .dashboard-content {
    &__greeting-section {
      margin-bottom: 30px;
    }

    &__horizontal-divider {
      margin-bottom: 29px;
    }

    &__highlights-list {
      margin-bottom: 54px;
    }

    &__actions-section {
      margin-bottom: 32px;
      column-gap: 25px;
    }

    &__action-snippet {
      margin-bottom: 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .dashboard-content {
    &__greeting-section {
      margin-bottom: 25px;
    }

    &__horizontal-divider {
      margin-bottom: 29px;
    }

    &__highlights-list {
      margin-bottom: 53px;
    }

    &__actions-section {
      margin-bottom: 32px;
      column-count: 1;
      display: flex;
      flex-direction: column;
    }

    &__action-snippet {
      margin-bottom: 21px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .dashboard-content {
    &__greeting-section {
      margin-bottom: 27px;
    }

    &__horizontal-divider {
      margin-bottom: 25px;
    }

    &__highlights-list {
      margin-bottom: 40px;
    }

    &__actions-section {
      margin-bottom: 25px;
      column-count: 1;
      display: flex;
      flex-direction: column;
    }

    &__action-snippet {
      margin-bottom: 21px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
