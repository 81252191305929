// Names of FeatureFlags that can be checked
// NOTE! Don't change these names without consider migrating users
export type FlagNames = "EnableInlineProfileEditing" | "SocialCaptionGPT" | "SocialMediaManagerView" | "EnableLetsMakeTheMostOfThisSection";
// Default value for a given flag (so callers don't have to pass this)
export const FlagDefaults: Record<FlagNames, boolean> = {
  ["EnableInlineProfileEditing"]: true,
  ["SocialCaptionGPT"]: false,
  ["SocialMediaManagerView"]: false,
  ["EnableLetsMakeTheMostOfThisSection"]: false,
};

// Server Defined Flags
export const ReferralFlag = "refer";

import Logger from "@helpers/Logger";

export default {
  // Checks if the specific flag is active.  If not set, returns the default.
  isFlagActive(flag: FlagNames): boolean | null {
    const rawValue = localStorage.getItem(getFlagName(flag));
    if (!rawValue) return FlagDefaults[flag];

    const isFlagActive = rawValue == "true";
    return isFlagActive;
  },

  // Returns if a flag is set
  isFlagSet(flag: FlagNames): boolean {
    const rawValue = localStorage.getItem(getFlagName(flag));
    return rawValue != null;
  },

  // Note: May want a clearFlag method to differeniate between Inactive and never set

  // Persists a flag into localStorage
  setFlagValue(flag: FlagNames, value: boolean) {
    const flagName = getFlagName(flag);
    Logger.info("Setting Flag " + flagName + " to " + value);
    localStorage.setItem(flagName, JSON.stringify(value));
  },
};

function getFlagName(flag: FlagNames): string {
  return `Flag-${flag}`;
}
