<template>
  <router-link :to="{ name: 'ItineraryPreview', params: { itineraryId: itinerary.uniqueName } }" class="ui card">
    <div class="image">
      <img :src="`${contentBaseUri}/cms/images/expeditions/${itinerary.thumbnail}`" />
      <!--<div v-if="itinerary.isNew" class="new">New!</div>-->
    </div>
    <div class="content">
      <p>{{ itinerary.title }}</p>
      <div class="bottom">
        <div class="location">{{ itinerary.location }}</div>
        <div class="duration">{{ itinerary.timeRequired }}</div>
      </div>
    </div>
  </router-link>
</template>

<script>
/* REMINDER: There's a V1 version of this.
   - This is only used in a few places (homepage Slider primarily, and some places that are deprecated)
*/
import { MetricSender } from "@helpers/MetricSender";
import FeatureFlags from "@logic/FeatureFlags";

export default {
  name: "ItineraryTileV2",

  props: {
    itinerary: {
      type: Object,
      required: true,
    },
    // context to send along to metrics (so we can tell where this impression came from)
    source: {
      type: String,
      required: false,
      default: "notset",
    },
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  mounted() {
    // Fire the impression metric
    MetricSender.impressionAdventure(this.itinerary.id, this.itinerary.uniqueName, this.source);
  },
};
</script>

<style scoped>
.content > p {
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.content .bottom {
  display: flex;
  flex-direction: row;
}

.content .bottom .location {
  flex: 1;
  color: #4c7b82;
  font-weight: 500;
}

.content .bottom .duration {
  font-weight: 100;
}

.card .content {
  padding: 0.25rem 0.5rem;
  border: 0.5px solid #a7a7a7 !important;
  background-color: #eee;
  box-shadow: none;
}
</style>
