<template>
  <div class="ui form summaryForm">
    <NoteWithIcon
      v-if="fieldsWithErrors.map(i => i.field).includes('uniqueImages')"
      color="red"
      isTransparentBg
      style="padding-bottom: 13px; margin-bottom: 18px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"
      size="tiny"
    >
      <template #icon><IconEmbedded name="info-simple_4" /></template>
      <template #text>
        <h6 class="global-h6" style="margin-bottom: 2px">
          {{ fieldsWithErrors.find(i => i?.field === "uniqueImages").errorMsg }}
        </h6>
      </template>
    </NoteWithIcon>

    <div class="two fields" style="margin: 0 !important">
      <div class="field">
        <div
          :style="{
            padding: fieldsWithErrors.map(i => i.field).includes('tileImageLocation') ? '7px' : 0,
            border: '1px #e0b4b4 solid',
            borderWidth: fieldsWithErrors.map(i => i.field).includes('tileImageLocation') ? 1 : 0,
            background: fieldsWithErrors.map(i => i.field).includes('tileImageLocation') ? '#fff6f6' : 'transparent',
          }"
        >
          <div
            class="coverImg"
            :class="{ flex: itinerary.tileImageLocation?.length === 0 }"
            :style="`background-image: url(${coverImageUrl}); background-position: 50% 50%`"
            @click="$emit('fixTheFieldError', 'summary', 'tileImageLocation')"
          >
            <div class="uploadBtn" style="display: block; text-align: center" v-if="coverImageUrl.length == 0">
              <label class="coverLabel">Cover Photo</label>
              <label @click="showPhotoUploader = true" for="image" style="width: auto; margin-bottom: 1em !important">
                <SrpButton color="gray" size="small">Choose a Photo</SrpButton>
              </label>
            </div>
          </div>
          <template v-if="coverImageUrl.length > 0">
            <div class="flex" style="float: right; align-self: flex-end; margin: 0.5em 0">
              <a class="ui flex" style="color: #058587; font-weight: bold; cursor: pointer; margin: 0 !important" @click="userChangeCoverPhoto">Change Cover Photo</a>
            </div>
          </template>
        </div>
        <div v-if="fieldsWithErrors.map(i => i.field).includes('tileImageLocation')" style="color: red">
          {{ fieldsWithErrors.find(i => i?.field === "tileImageLocation").errorMsg }}
        </div>

        <div
          :class="{
            field: true,
            error: fieldsWithErrors.map(i => i.field).includes('title'),
          }"
        >
          <div class="input-section">
            <label class="subsection-title" style="margin-top: 2em !important">Title</label>
            <p class="cms">Make it unique and fun!</p>
            <input type="text" v-model="itinerary.title" @focus="$emit('fixTheFieldError', 'summary', 'title')" />
            <div v-if="fieldsWithErrors.map(i => i.field).includes('title')" style="color: red">
              {{ fieldsWithErrors.find(i => i?.field === "title").errorMsg }}
            </div>
          </div>
        </div>

        <div
          :class="{
            field: true,
            error: fieldsWithErrors.map(i => i.field).includes('whatToExpectText'),
          }"
        >
          <label class="subsection-title">What to Expect</label>
          <p class="cms">A quick summary of the trip. Tell everyone why they will love this adventure!</p>
          <textarea
            v-if="isTabActive"
            ref="whatToExpectInput"
            v-model="itinerary.whatToExpectText"
            autocapitalize="sentences"
            class="input-section"
            style="height: 10em"
            @focus="$emit('fixTheFieldError', 'summary', 'whatToExpectText')"
          ></textarea>
          <div style="float: right">{{ whatToExpectTextLengthMessage }}</div>
          <div v-if="fieldsWithErrors.map(i => i.field).includes('whatToExpectText')" style="color: red">
            {{ fieldsWithErrors.find(i => i?.field === "whatToExpectText").errorMsg }}
          </div>
        </div>
      </div>

      <div class="field" style="display: flex; flex-direction: column; justify-content: space-between; margin: 0 !important">
        <div>
          <div class="input-section">
            <label class="subsection-title">Signature Video</label>
            <p class="cms">Have a video that highlights or summarizes the adventure?!</p>
            <div class="signature-video flex" v-if="adventureVideos.length == 0">
              <div class="uploadBtn" style="display: block; text-align: center">
                <!--<label class="coverLabel">Signature Video</label>-->
                <label @click="showVideoUploader = true" for="image" style="width: auto; margin-bottom: 0 !important">
                  <SrpButton color="gray" size="small">Add a Video</SrpButton>
                </label>
              </div>
            </div>

            <template v-else>
              <div class="columns-layout columns-layout--3-col">
                <div
                  :class="{
                    'photo-div': true,
                    'photo-div--inactive': !(itinerary.signatureVideo && image.startsWith(itinerary.signatureVideo)),
                    'columns-layout__item': true,
                  }"
                  v-for="(image, i) in adventureVideos"
                  :key="`sig-video-${i}`"
                  @click="toggleSignatureVideo(image)"
                >
                  <SrpFileThumbnail :fileName="image" :class="`photowall-admin-asset ${itinerary.signatureVideo && image.startsWith(itinerary.signatureVideo) ? 'shrpa-selected-image' : ''}`" />
                </div>
              </div>
              <div class="flex" style="float: right; align-self: flex-end; margin: 0.5em 0">
                <a class="ui flex" style="color: #058587; font-weight: bold; cursor: pointer; margin: 0 !important" @click="userUploadMoreVideos">Upload more</a>
              </div>
            </template>
          </div>

          <div class="input-section">
            <label class="subsection-title">What to Bring</label>
            <p class="cms">Things not to forget! (comma separated)</p>
            <input type="text" :value="whatToBringAsString" @change="parseWhatToBring" />
          </div>

          <div class="input-section">
            <label class="subsection-title">Time</label>
            <p class="cms">How long will this trip typically take?</p>
            <select class="timeRequired" v-model="itinerary.timeRequired">
              <option disabled value="">Please select one</option>
              <option v-for="item in timeRequiredOptions" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>

          <details class="admin-details-section">
            <summary style="display: flex">
              <span class="admin-details-section__minus-sign">-</span>
              <span class="admin-details-section__plus-sign">+</span>
              Optional Overrides
            </summary>
            <div>
              <div class="optional-content-section">
                <label class="subsection-title"><i class="icon compass"></i>Walk, Drive, or Bike?</label>
                <p class="cms">What is the best way to travel between stops?</p>
                <select v-model="itinerary.directionsTravelMode" @change="travelModeChanged" style="max-width: 250px">
                  <option v-for="item in travelModes" :key="item" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="field optional-content-section">
                <label class="subsection-title"><i class="icon location arrow"></i>Location</label>
                <p class="cms">Override the Location of this adventure (defaults to the city of the first stop)</p>
                <input id="location" type="text" v-model="itinerary.location" />
              </div>

              <div class="field optional-content-section">
                <label class="subsection-title"><i class="icon map"></i>Map</label>
                <div class="ui checkbox">
                  <input type="checkbox" id="manuallyCreatedMap" v-model="itinerary.manuallyCreatedMap" />
                  <label>Manually create the map (we won't auto-generate it)</label>
                </div>
                <span v-if="itinerary.manuallyCreatedMap">
                  <p class="cms">
                    Create a map with directions and put the embed link here (details
                    <a lang="en" hreflang="en" class="cms-link" target="_blank" href="https://support.google.com/maps/answer/144361">here</a>)
                  </p>
                  <input id="map" type="text" @change="parseMapUri" v-model="itinerary.mapUrl" />
                </span>
              </div>

              <div class="field" v-if="itinerary.distanceInMiles === 0">
                <label class="subsection-title"><i class="icon road"></i>Distance</label>
                <p class="cms"></p>
                <select v-model="itinerary.distanceTraveled" class="marginBottom" style="box-sizing: border-box">
                  <option disabled value="">Please select one</option>
                  <option v-for="item in distanceOptions" :key="item" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </details>

          <details v-if="isSuperOrSalesUser" class="admin-details-section">
            <summary style="display: flex">
              <span class="admin-details-section__minus-sign">-</span>
              <span class="admin-details-section__plus-sign">+</span>
              Admin Fields
            </summary>
            <div>
              <div class="admin-data">
                <div class="ui small label grey">Id: {{ itinerary.id }}</div>
                <br />
                <div class="ui small label grey">UniqueName: {{ itinerary.uniqueName }}</div>
                <br />
                <div :class="'ui small label ' + itinerary.status">Status = {{ itinerary.status }}: {{ itinerary.secondaryStatus }}</div>
                <br />
                <span class="ui small label grey">CreatedOn: {{ itinerary.createdDateTime }}</span
                ><br />
                <span class="ui small label grey">UpdatedOn: {{ itinerary.updatedDateTime }}</span
                ><br />
                <span class="ui small label grey">FirstPublishedOn: {{ itinerary.firstPublishedDateTime }}</span
                ><br />
                <span class="ui small label grey">LastPublishedOn: {{ itinerary.publishedDateTime }}</span
                ><br />
              </div>

              <div class="field">
                <label class="cms-section-title">Creator Id</label>
                <input type="text" v-model="itinerary.sherpaId" />
                <button :disabled="updatingCreator" @click="updateCreator">Update Creator</button>
                <a target="_blank" :href="'/creator/' + itinerary.sherpaId">Creator details</a>
              </div>

              <div class="field">
                <label class="cms-section-title">CreatedFor</label>
                <select v-model="itinerary.createdFor" style="box-sizing: border-box">
                  <option key="" value="" />
                  <option v-for="item in pageIds" :key="item" :value="item">
                    {{ item }}
                  </option>
                </select>
                <button @click="$emit('updateCreatedFor')">Update CreatedFor</button>
              </div>

              <div class="field">
                <label class="cms-section-title">CollabInput Id</label>
                <input type="text" v-model="itinerary.collabInputId" />
                <a target="_blank" :href="`/admin/community/${itinerary.createdFor}/collabs#collab=${itinerary.collabInputId}`">Collab details</a>
                <button @click="$emit('updateCreatedFor')">Update CreatedFor</button>
              </div>

              <button v-if="isSuperOrSalesUser && itinerary.firstPublishedDateTime" class="ui small button right floated" @click="$emit('removeItinerary')">Remove Live</button>
              <br /><br />
            </div>
          </details>
        </div>

        <SrpButton v-if="!itinerary.firstPublishedDateTime" @click="$emit('deleteDraftItinerary')" size="tiny" fill="outlined" color="gray" style="align-self: flex-end">Delete Adventure</SrpButton>
      </div>
    </div>

    <SrpModal v-model:isVisible="showPhotoUploader" className="custom-modal" :isWithScroll="true" maxHeight="calc(100vh - 50px)">
      <template #content>
        <UploadPhotoForm
          :img="images"
          :activeServerId="itinerary.tileImageLocation"
          :collabImagesUsed="collabImagesUsed"
          :collabImagesUnused="collabImagesUnused"
          :singlePickMode="true"
          :autoSelect="true"
          :showClose="true"
          :showRemove="false"
          :isOpen="showPhotoUploader"
          :isCollab="isCollab"
          @close="showPhotoUploader = false"
          @imageUploadedToServer="onImageUploadedToServerForCoverPhoto"
          @removeMediaFile="$emit('removeMediaFile', $event)"
        />
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="showVideoUploader" className="custom-modal" :isWithScroll="true" maxHeight="calc(100vh - 50px)">
      <template #content>
        <UploadPhotoForm
          :img="images"
          :collabImagesUsed="collabImagesUsed"
          :collabImagesUnused="collabImagesUnused"
          :singlePickMode="false"
          :showClose="true"
          :showRemove="false"
          :isOpen="showVideoUploader"
          :isCollab="isCollab"
          @close="showVideoUploader = false"
          @imageUploadedToServer="onVideoUploadedToServerForSignatureVideo"
          @removeMediaFile="$emit('removeMediaFile', $event)"
        />
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import FileUtils from "@logic/FileUtils";
import { defineComponent, inject } from "vue";

// Types
import { Itinerary, ItineraryPreview, StepFieldsWithError } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";

// Components
import NoteWithIcon from "@components/NoteWithIcon.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";
import VideoRender from "@components/VideoRender.vue";
import VSelect from "@components/Select/VSelect.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import logger from "@helpers/Logger";
import { Sapling } from "@saplingai/sapling-js/observer";
import FeatureFlags from "@logic/FeatureFlags";
import StopsForm from "@views/CMS/StopsForm/index.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

export default defineComponent({
  name: "SummaryForm",

  components: {
    IconEmbedded,
    SrpFileThumbnail,
    StopsForm,
    SrpButton,
    VueDatePicker,
    SrpModal,
    NoteWithIcon,
    UploadPhotoForm,
    VideoRender,
    VSelect,
  },

  props: {
    itinerary: { type: Object as () => Itinerary | null, required: true },
    distanceOptions: { type: Array, default: () => [] },
    images: { type: Array as () => Array<UploadedImage> | null, default: () => [] },
    minStopDetailsChars: { type: Number, default: 0 },
    isCollab: { type: Boolean, default: false },
    fieldsWithErrors: { type: Array as () => Array<StepFieldsWithError> | null, default: () => [] },
    isTabActive: { type: Boolean, default: false }, // it's needed to fix the Sapling buggy behavior by removing inactive textarea
    collabImagesUsed: { type: Array as () => Array<string>, default: () => [] },
    collabImagesUnused: { type: Array as () => Array<string>, default: () => [] },
  },

  emits: {
    fixTheFieldError: (...args: any[]) => true,
    useWalkingDirectionsChanged: (...args: any[]) => true,
    imageUploadedToServer: (...args: any[]) => true,
    updateCreatedFor: (...args: any[]) => true,
    removeItinerary: (...args: any[]) => true,
    deleteDraftItinerary: (...args: any[]) => true,
    removeMediaFile: (...args: any[]) => true,
    imageUploadedFromCoverPhotoForm: (_image: UploadedImage) => true,
    signatureVideoUploaded: (_image: UploadedImage) => true,
    croppedCoverPhotoSelected: (image: UploadedImage, uncroppedImageServerId: string) => true,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      screenSize: inject("screenSize") as ScreenSize,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      // Binding directly to the prop now: itinerary: null as Itinerary,
      isDrag: false,
      timeRequiredOptions: [],
      pageIds: [],
      isShowOptionalContent: false,
      showPhotoUploader: false,
      showVideoUploader: false,
      // Note: Don't change these without updating downstream code that checks the directionsTravelMode field
      travelModes: ["Driving", "Walking", "Bicycling", "Transit"] as Array<string>,

      updatingCreator: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    // Note: Also in StopsForm.vue
    whatToExpectTextLengthMessage(): string {
      const len = this.itinerary.whatToExpectText?.trim().length ?? 0;
      if (len === 0) return "enter at least " + this.minStopDetailsChars + " characters";
      else if (len >= this.minStopDetailsChars) return "";
      else return this.minStopDetailsChars - len + " more to go...";
    },
    whatToBringAsString(): string {
      if (this.itinerary.whatToBring) return this.itinerary.whatToBring.join(", ");
      else return "";
    },
    coverImageUrl(): string {
      if (this.itinerary.tileImageLocation) return `${this.contentBaseUri}/cms/images/expeditions/${this.itinerary.tileImageLocation}`;
      else return "";
    },
    hasStopsWithGooglePlace(): boolean {
      const hasPlaceId = this.itinerary.steps.some(s => s.externalId != null && s.externalId != "");
      return hasPlaceId;
    },
    adventureVideos(): Array<string> {
      let results = [];
      this.itinerary.steps.forEach(step => {
        if (step?.images) results = results.concat(step.images.filter(s => FileUtils.isVideoFileType(s, null) && results.indexOf(s) < 0));
      });
      // Also add videos that aren't attached to steps
      this.itinerary.unusedPhotos.forEach(imageId => {
        if (FileUtils.isVideoFileType(imageId, null) && results.indexOf(imageId) < 0) results.push(imageId);
      });
      // Add the signature video (if not already there)
      if (this.itinerary.signatureVideo?.length > 0 && results.indexOf(this.itinerary.signatureVideo) < 0) {
        results.push(this.itinerary.signatureVideo);
      }
      return results;
    },
  },

  watch: {
    async isTabActive() {
      if (this.isTabActive) this.$nextTick(this.setupSapling);
      else this.unobserveSapling();
    },
    // Note: We used to have a watch on itinerary but now we're doing direct binding to the prop since it's all pass by ref (https://vuejs.org/v2/guide/components-props.html)
    // The proper Vue way to do this is manually passing the fields and firing events back as each changes.
    // We can do that as a fallback if issues come up from this shortcut.
  },

  async mounted() {
    await this.loadConfigData();
    this.defaultTravelMode();

    if (this.isTabActive) this.$nextTick(this.setupSapling);
  },

  methods: {
    async loadConfigData() {
      const timeResult = await axios.get(`${import.meta.env.VITE_API_URL}/config/timeOptions`);
      this.timeRequiredOptions = timeResult.data;
      if (this.isSuperOrSalesUser) {
        const pagesResult = await axios.get(`${import.meta.env.VITE_API_URL}/pages/ids?includeTestCustomers=true`);
        this.pageIds = pagesResult.data;
      }
    },
    async updateCreator() {
      // We have a special endpoint or this now (so need a specific button)
      this.updatingCreator = true;
      const { data } = await axios.post(`${import.meta.env.VITE_API_URL}/superuser/adventure-change-creator?adventureId=${this.itinerary.id}&newCreatorId=${this.itinerary.sherpaId}`);
      this.updatingCreator = false;
      alert("CreatorChanged=" + data);
    },
    defaultTravelMode() {
      // Defaults the new field
      if (!this.itinerary.directionsTravelMode || this.itinerary.directionsTravelMode.length === 0) {
        this.itinerary.directionsTravelMode = this.itinerary.useWalkingDirections ? "Walking" : "Driving";
      }
    },
    travelModeChanged(event) {
      // If they set this then the old field isn't valid anymore
      this.itinerary.useWalkingDirections = false;
      this.$emit("useWalkingDirectionsChanged");
    },
    parseWhatToBring(event) {
      const value = event.target.value as string | null;
      if (value != null) {
        this.globalLog.debug("whatToBring=" + value);
        this.itinerary.whatToBring = value.split(",").map(i => i.trim());
      }
    },

    defaultSeasonDates(season: string) {
      // NOTE: Month is zero-based
      var currentYear = new Date().getFullYear();
      if (season == "warm") {
        this.itinerary.seasonStartDate = new Date(currentYear, 3, 1, 0, 0, 0, 0).toJSON();
        this.itinerary.seasonEndDate = new Date(currentYear, 9, 31, 0, 0, 0, 0).toJSON();
      } else if (season == "none") {
        this.itinerary.seasonStartDate = null;
        this.itinerary.seasonEndDate = null;
      } else if (season == "cold") {
        this.itinerary.seasonStartDate = new Date(currentYear, 10, 1, 0, 0, 0, 0).toJSON();
        this.itinerary.seasonEndDate = new Date(currentYear + 1, 3, 1, 0, 0, 0, 0).toJSON();
      }
    },
    parseSeasonDate(dateString: string): string | null {
      if (dateString) {
        const date = new Date(dateString);
        return date.toISOString();
      }
      return null;
    },
    onImageUploadedToServerForCoverPhoto(image: UploadedImage, isSelected: boolean, uncroppedImageServerId: string | null) {
      if (uncroppedImageServerId) {
        // If uncropped image is provided, it means that the image was uploaded
        // from the cover photo cropper.
        this.$emit("croppedCoverPhotoSelected", image, uncroppedImageServerId);
        this.showPhotoUploader = false;
      } else {
        // otherwise, it was uploaded into the photo gallery
        // and should be added to the itinerary
        this.$emit("imageUploadedFromCoverPhotoForm", image);
      }
    },
    onVideoUploadedToServerForSignatureVideo(video: UploadedImage) {
      this.$emit("signatureVideoUploaded", video);
    },
    async checkForSelectedButNotChosenImage(): Promise<string> {
      // Deprecated
      return null;
    },
    userChangeCoverPhoto() {
      this.showPhotoUploader = true;
    },
    userUploadMoreVideos() {
      this.showVideoUploader = true;
    },
    parseMapUri: function () {
      var input = this.itinerary.mapUrl;
      if (!input) return input;

      var startIndex = input.indexOf("https://www.google.com");
      if (startIndex < 1) return input;
      var temp = input.substr(startIndex);
      var endQuoteIndex = temp.indexOf('"');
      if (endQuoteIndex > 0) {
        // Success! Update the model
        var result = temp.substr(0, endQuoteIndex);
        this.itinerary.mapUrl = result;
      }
      return input;
    },
    toggleSignatureVideo(videoId: string | null) {
      if (this.itinerary.signatureVideo === videoId) this.itinerary.signatureVideo = null;
      else this.itinerary.signatureVideo = videoId;
    },
    setupSapling() {
      try {
        let editor = this.$refs.whatToExpectInput as HTMLTextAreaElement;
        logger.info("Sapling setup for Summary: " + (editor ? "found" : "not found"));
        if (editor) {
          Sapling.observe(editor);
        }

        console.log(`Sapling is observing the Summary Tab whatToExpectInput`);
      } catch (e) {
        getGlobalRemoteLogger().error(`Error setting up Sapling for ${this.itinerary?.id}: ${e.message ?? e}`);
      }
    },
    unobserveSapling() {
      let editor = this.$refs.whatToExpectInput as HTMLTextAreaElement;
      if (!editor) return;
      Sapling.unobserve(editor);

      console.log(`Sapling unobserved the Summary Tab whatToExpectInput`);
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.shrpa-not-selected-image) {
  filter: opacity(25%);
}

// Admin details section ======================================================
.admin-details-section {
  margin-bottom: 14px;

  summary {
    margin-bottom: 8px;
    color: #058587;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  &__minus-sign,
  &__plus-sign {
    width: 12px;
    margin-right: 2px;
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: -1px;
    font-weight: 600;
    font-size: 20px;
  }

  &__minus-sign {
    display: none;
  }
  &__plus-sign {
    display: inline-flex;
  }
  &[open] &__minus-sign {
    display: inline-flex;
  }
  &[open] &__plus-sign {
    display: none;
  }
}

// ============================================================================
.custom-modal {
  overflow-y: auto;
  justify-content: flex-start;
  padding-top: 10vh;
}
.coverImg {
  padding-top: 75%;
  width: 100%;
  border: 1px dashed;
  border-radius: 10px;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;

  .drag {
    box-shadow: inset 0 0 5px #058587;
  }
}

.signature-video {
  width: 100%;
  border: 1px dashed;
  border-radius: 10px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
}

.uploadBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeRequired:focus {
  outline: none;
  border-color: #85b7d9;
}

.ui.accordion .title .add.icon:before,
.ui.accordion .accordion .title .add.icon:before {
  content: "\f067";
}

.ui.accordion .active.title .add.icon:before,
.ui.accordion .accordion .active.title .add.icon:before {
  content: "\f068";
}

.input-section {
  margin-bottom: 2em;
}

.optional-content-section {
  margin-bottom: 1.5em !important;
}

@media screen and (max-width: 420px) {
  .coverImg {
    min-height: 170px;
  }
}

.coverLabel {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 1em !important;
}

.marginBottom {
  margin-bottom: 1em !important;
}

//For selected video
.photo-div {
  padding: 5px;
  position: relative;
  //Note: Moved a style into main.scss

  &--inactive {
    opacity: 0.3;
  }
}
</style>
