<template>
  <div class="dropdown-wrapper">
    <div class="dropdown-wrapper__clickable-element" @click="toggleDropdown" ref="domRefClickableElem">
      <slot name="clickableElement" :isDropdownVisible="isDropdownVisible" />
    </div>

    <teleport to="body">
      <div
        :class="{
          'dropdown-wrapper__dropdown-itself': true,
          'dropdown-wrapper__dropdown-itself--invisible': !isDropdownVisible || screenSize === 'mobile',
        }"
        @mouseenter="handleDropdownMouseEnter"
        @mouseleave="handleDropdownMouseLeave"
        ref="domRefDropdown"
        :style="{
          width: props.width === 'auto' ? 'auto' : `${props.width}px`,
          ...dropdownPositionStyles,
        }"
        v-click-outside="
          () => {
            if (screenSize === 'mobile') return;
            isDropdownVisible = false;
          }
        "
      >
        <slot name="dropdown" :isDropdownVisible="isDropdownVisible" :close="closeDropdown" :computeDropdownPosition="computeDropdownPosition" />
      </div>
    </teleport>

    <SrpModal
      v-if="isDropdownVisible && screenSize === 'mobile'"
      v-model:isVisible="isDropdownVisible"
      :zIndex="10000"
      :width="props.width === 'auto' ? 0 : props.width"
      maxHeight="410px"
      :isContentPaddings="false"
      :isWithBottomGap="false"
    >
      <template #content>
        <slot name="dropdown" :isDropdownVisible="isDropdownVisible" :close="closeDropdown" :computeDropdownPosition="computeDropdownPosition" />
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { computePosition, type ComputePositionConfig, type Placement, shift, flip, offset as floatingUiOffset } from "@floating-ui/dom";
import { ref, onMounted, inject, Ref, nextTick, onBeforeUnmount, watch } from "vue";

// Components
import SrpModal from "@components/ui/SrpModal.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    offset?: number;
    width?: number | "auto";
    placement?: Placement;
  }>(),
  {
    offset: 0,
    width: "auto",
    placement: "top-start",
  }
);

// Toggle dropdown ============================================================
const isDropdownVisible = ref<boolean>(false);

let showTimeout = null;
let hideTimeout = null;

function toggleDropdown() {
  if (isDropdownVisible.value) {
    return;
  }

  if (screenSize.value === "mobile") {
  } else {
    computeDropdownPosition();
  }

  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
  showTimeout = setTimeout(() => (isDropdownVisible.value = true), 100);
}
function closeDropdown() {
  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
  hideTimeout = setTimeout(() => (isDropdownVisible.value = false), 100);
}

function handleDropdownMouseEnter() {
  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
}
function handleDropdownMouseLeave() {
  // hideTimeout = setTimeout(() => (isDropdownVisible.value = false), 100);
}

// Set dropdown position ======================================================
const domRefClickableElem = ref(null);
const domRefDropdown = ref(null);
const dropdownPositionStyles = ref({});

const dropdownPlacement = ref<Placement>("top");

onMounted(() => {
  if (screenSize.value !== "mobile") {
    computeDropdownPosition();
  }
});

function computeDropdownPosition(): void {
  const floatingUiOptions: ComputePositionConfig = {
    middleware: [floatingUiOffset(props.offset), flip(), shift()],
    placement: props.placement,
    strategy: "fixed",
  };

  computePosition(domRefClickableElem.value, domRefDropdown.value, floatingUiOptions).then(position => {
    dropdownPlacement.value = position.placement;

    dropdownPositionStyles.value = {
      top: `${position.y + window.scrollY}px`,
      left: `${position.x}px`,
    };
  });
}

// Recalculate position on global scroll ======================================
onMounted(() => document.addEventListener("scroll", computeDropdownPosition));
onBeforeUnmount(() => document.removeEventListener("scroll", computeDropdownPosition));

// Recalculate position on window resize ======================================
onMounted(() => window.addEventListener("resize", computeDropdownPosition));
onBeforeUnmount(() => window.removeEventListener("resize", computeDropdownPosition));

// Recalculate position on SrpModalScroll =====================================
// (if SrpDropdown is inside the modal)
const srpModalScrollTop = inject("srpModalScrollTop", null) as Ref<number> | null;
watch(srpModalScrollTop, computeDropdownPosition);
</script>

<style scoped lang="scss">
@import "@/scss/z-indexes.scss";

// Dropdown wrapper ===========================================================
.dropdown-wrapper {
  display: inline-flex;
  position: relative;

  &__clickable-element {
  }

  &__dropdown-itself {
    border-radius: 5px;
    position: absolute;
    z-index: $z-index-tooltip;
    color: #5b5b5b;
    font: 14px/18px sans-serif;
    background: #fff;
    opacity: 1;
    box-shadow: 0 5px 35px -9px rgba(0, 0, 0, 0.4);
    transition: opacity 0.05s ease-in-out;

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
