<template>
  <!--Note: Hide/show is owned by the parent since the buttons firing this live there-->
  <SrpModal className="create-collab-form" :isVisible="visible" @close="onClose" :isClosable="false" size="large" maxHeight="calc(100dvh - 120px)" isWithScroll :isWithBottomGap="false">
    <template #content>
      <div class="modal-description-wrap">
        <div class="modal-description">
          <StepIntro
            v-if="step === 0"
            :collabInput="collabInput"
            @changeStep="changeStep(1)"
            @cloneCollab="
              id => {
                collabIdToClone = id;
                changeStep(1);
              }
            "
            @mount="
              collabIdToClone = null //it's needed to clear the id when user get's back to the intro screen and selects any other option instead of clone
            "
          />

          <!--<StepsSummary v-if="step === 0" />-->

          <StepTiming
            v-if="step === createStep"
            :getCurrentCollabInput="getCurrentCollabInput"
            :collabInput="collabInput"
            :communityId="communityId"
            :collabDateResetCounter="collabDateResetCounter"
            :errors="errors"
          />

          <StepLocations v-if="step === locationsAndActivitiesStep" :collabInput="collabInput" :validationResult="validationResult" :clearValidation="clearValidation" :errors="errors" />

          <StepThemes v-if="step === 3" :collabInput="collabInput" :errors="errors" />

          <StepLodging v-if="step === lodgingStep" :collabInput="collabInput" :collabRemainingCounts="collabRemainingCounts" :errors="errors" />

          <StepLocationsNAdventures
            v-if="step === locationsNAdventuresStep"
            v-model:requestedLocationCount="collabInput.requestedLocationCount"
            v-model:requestedAdventureCount="collabInput.requestedAdventureCount"
            :errors="errors"
          />

          <StepGearNMaterial v-if="step === 6" :collabInput="collabInput" :errors="errors" />

          <StepBoundaries v-if="step === 7" :collabInput="collabInput" :errors="errors" />

          <StepName v-if="step === lastStep" :collabInput="collabInput" :errors="errors" />
        </div>
      </div>

      <StepsProgressBar v-if="step > 0" class="create-collab-form__steps-progressbar" :currentStep="step" :numberOfSteps="lastStep" />

      <!--Errors-->
      <div v-if="errors.length > 0" style="color: red; margin-bottom: 15px">
        <h5 style="margin: 10px 0 0 0">Required Fields</h5>
        <div v-for="(error, i) in errors" :key="'error' + i">- {{ error }}</div>
      </div>
    </template>

    <!--Hide the actions panel if it's a brand new collab (since choosing a themeType moves to the next step)-->
    <template #footer v-if="step > 0 || collabInputForEditFlow || plannedCollab">
      <label v-if="isSuperOrSalesUser && errors?.length > 0" style="width: auto">
        <input type="checkbox" v-model="superOverrideValidation" style="display: inline; width: auto" />
        skip validation (super)
      </label>
      <h3 style="margin: 0 15px 0 0; display: inline">{{ isSaving ? "Saving..." : "" }} {{ doneSavingMessage ? "Saved" : "" }}</h3>
      <SrpButton v-if="step !== 0" :isDisabled="isSaving" color="gray" @click="changeStep(-1)" fill="outlined" style="margin-right: 10px">Back</SrpButton>

      <SrpButton v-if="step !== lastStep" :isDisabled="isSaving" @click="changeStep(1)">
        {{ step === 0 && collabInputForEditFlow == null && plannedCollab == null ? "Let's Go!" : "Next" }}
      </SrpButton>
      <SrpButton v-else @click="changeStep(1)">Save</SrpButton>
    </template>
  </SrpModal>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import axios from "axios";
import CollabInputValidator from "@logic/CollabInputValidator";
import CollabStatuses from "@logic/CollabStatuses";
import { RouteHelper } from "@helpers/RouteHelper";
import { defineComponent, inject } from "vue";

// Contracts
import CollabConsts, { CollabInput, CustomerCollabRemainingCounts } from "@contracts/collab";
import { ItinerarySummary } from "@contracts/itinerary";
import { CollabInputValidationError } from "@logic/CollabInputValidator";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
// steps
import StepIntro from "./StepIntro.vue";
import StepsSummary from "./StepsSummary.vue";
import StepThemes from "./StepThemes.vue";
import StepTiming from "./StepTiming.vue";
import StepLocations from "./StepLocations.vue";
import StepLocationsNAdventures from "./StepLocationsNAdventures.vue";
import StepLodging from "./StepLodging.vue";
import StepGearNMaterial from "./StepGearNMaterial.vue";
import StepBoundaries from "./StepBoundaries.vue";
import StepName from "./StepName.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import { PlannedCollab } from "@repos/PlannedCollabsRepo";
import StepsProgressBar from "@components/StepsProgressBar.vue";

export default defineComponent({
  name: "CreateCollab",

  components: {
    StepsProgressBar,
    SrpButton,
    SrpModal,
    // steps
    StepIntro,
    StepsSummary,
    StepThemes,
    StepTiming,
    StepLocations,
    StepLocationsNAdventures,
    StepLodging,
    StepGearNMaterial,
    StepBoundaries,
    StepName,
  },

  props: {
    communityId: { type: String, required: true },
    // Expected to be empty on the Create flow and set on the Edit flow
    collabInputForEditFlow: { type: Object as () => CollabInput | null, required: false, default: null },
    collabRemainingCounts: { type: Object as () => CustomerCollabRemainingCounts | null, required: false, default: null },
    visible: { type: Boolean, required: true },
    openingEditFlow: { type: Number, required: false, default: null },
    plannedCollab: { type: Object as () => PlannedCollab | null, required: false, default: null },
  },

  emits: ["close", "collabCreated"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      step: 0,
      createStep: 1,
      locationsAndActivitiesStep: 2,
      lodgingStep: 4,
      locationsNAdventuresStep: 5,
      lastStep: 8,
      isSaving: false,
      isDirty: false,
      doneSavingMessage: false,

      collabIdToClone: null as string | null,

      newCollab: {} as CollabInput | null, // Set on mount
      collabDateResetCounter: 1,

      CollabInviteExpirationInDays: CollabConsts.CollabInviteExpirationInDays,
      DaysCreatorHasToCreateAdventures: CollabConsts.DaysCreatorHasToCreateAdventures,

      errors: [],
      superOverrideValidation: false,
      validationResult: null as CollabInputValidationError | null,
      version: 3, // Note: Just used for creating collabs, otherwise collab.version is used
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    collabInput: {
      // If the collabInputForEditFlow is set bind to that, otherwise we're in the newCollab flow so bind to that
      get(): CollabInput {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.collabInputForEditFlow?.id?.length > 0) return this.collabInputForEditFlow;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        else return this.newCollab;
      },
      set(value: CollabInput) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.collabInputForEditFlow?.id?.length > 0) console.error("Unexpected Code Path");
        // Should never hit this
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        else this.newCollab = value;
      },
    },
  },

  watch: {
    // Need an isDirty check so we save on next/back/close
    collabInputForEditFlow: {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          if (oldVal.id != newVal.id) {
            this.globalLog.info("Switching Collabs, resetting isDirty");
            this.setIsDirty(false);
          } else {
            this.setIsDirty(true);
          }
        }
      },
      deep: true,
    },
    newCollab: {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          this.setIsDirty(true);
        }
      },
      deep: true,
    },
    openingEditFlow: {
      handler(newVal, oldVal) {
        this.errors = [];
        // Jump back to the intro
        this.jumpToStep(0);
      },
    },
    visible(value) {
      if (!value) return;
      this.setNewCollabFields();
    },
  },

  mounted() {
    this.setNewCollabFields();
  },

  methods: {
    setNewCollabFields() {
      this.newCollab = {
        // Just the fields on the first panel, after that we'll populate this from the server
        name: "",
        preferredDateRangeStart: null,
        preferredDateRangeEnd: null,
        preferredTiming: null,
        reminderForFarOutRequested: false,
      } as CollabInput;

      if (!this.plannedCollab) return;
      this.newCollab.themeType = this.plannedCollab.themeType;
      this.newCollab.name = this.plannedCollab.name;
      this.newCollab.themes = this.plannedCollab.notes;
      if (this.plannedCollab.preferredDateRangeStart && this.plannedCollab.preferredDateRangeEnd) {
        this.newCollab.preferredDateRangeStart = this.plannedCollab.preferredDateRangeStart;
        this.newCollab.preferredDateRangeEnd = this.plannedCollab.preferredDateRangeEnd;
      }
    },
    async createNewCollab(): Promise<boolean> {
      if (!this.validate(this.newCollab, this.createStep)) return;

      this.isSaving = true;
      // Server generates the Id
      this.newCollab.version = this.version;
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/collabs/${this.communityId}${this.collabIdToClone ? `?collabInputIdToClone=${this.collabIdToClone}` : ""}`, this.newCollab);
      this.newCollab = response.data;

      if (this.collabIdToClone) this.globalLog.info(`Cloned! CollabInputId=${this.newCollab.id}`);
      else this.globalLog.info(`Created! CollabInputId=${this.newCollab.id}`);

      this.collabIdToClone = null;

      this.$nextTick(() => {
        this.setIsDirty(false);
      });
      // Tell the parent that the collab was created
      this.$emit("collabCreated", response.data);
      // Gets the CollabDatePicker to reset it's local data
      this.collabDateResetCounter++;
      this.isSaving = false;
      this.doneSavingMessage = true;
      setTimeout(() => {
        this.doneSavingMessage = false;
      }, 1500);
      return true;
    },
    // NOTE: Use this since we have two possible collab sources for the various flows (new vs. edit)
    getCurrentCollabInput(): CollabInput {
      return this.collabInputForEditFlow != null ? this.collabInputForEditFlow : this.newCollab;
    },
    async save(validate: boolean, stepToValidate: number | null, notify: boolean): Promise<boolean> {
      // Which collab to save depends on which flow we're in
      const collabToSave = this.getCurrentCollabInput();
      // Validation
      if (validate) {
        if (!this.validate(collabToSave, stepToValidate)) return false;
      }

      this.isSaving = true;
      // Note: We pass a notify param to reduce the volume of update notifications we send ourselves
      // Always notify if it's already been sent to creators
      if (this.isPastInputStatus()) notify = true;

      this.cleanupCollabFieldsBeforeSave(collabToSave);
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${collabToSave.id}?notify=${notify}`;
      const response = await axios.put(uri, collabToSave);
      this.globalLog.info(`Saved! CollabInputId=${collabToSave.id}`);
      this.setIsDirty(false);
      this.isSaving = false;
      this.doneSavingMessage = true;

      setTimeout(() => {
        this.doneSavingMessage = false;
      }, 1500);
      return true;
    },
    cleanupCollabFieldsBeforeSave(collab: CollabInput) {
      // Note: Decimals in the estimated value fields causes failures since they're integers server-side and the default server validation is surprisingly strict.
      if (collab.compedActivitiesEstimatedValue) {
        collab.compedActivitiesEstimatedValue = Math.trunc(collab.compedActivitiesEstimatedValue);
      }
      if (collab.hotelEstimatedValue) {
        collab.hotelEstimatedValue = Math.trunc(collab.hotelEstimatedValue);
      }
    },
    // Jumps to a specific step (used by the Edit button to jump into the flow)
    async jumpToStep(step: number) {
      this.step = step;
    },
    shouldSeeHotelStep(): boolean {
      return this.collabRemainingCounts?.showLodgingStep ?? true;
    },
    async defaultHotelCoverageUsed() {
      // console.info(`Hotel Coverage=${this.collabInput.hotelCoverageUsed}`);
      if (!this.collabInput.hotelCoverageUsed || this.collabInput.hotelCoverageUsed === "Unset") {
        console.info(`${this.collabRemainingCounts.collabHotelsGrandfathered} grandfathered and ${this.collabRemainingCounts.collabHotelsPaidRemaining} addons `);
        if (this.collabRemainingCounts.collabHotelsGrandfathered > 0) {
          this.collabInput.hotelCoverageUsed = "Grandfathered";
        } else if (this.collabRemainingCounts.allRemaininingHavePaidCoverage === true) {
          this.collabInput.hotelCoverageUsed = "PaidAddOn";
          // Also default this field since it was always set previously (hard to say if leaving it null would cause problems...)
          this.collabInput.hotelNightsCovered = 0;
        } else {
          // Let the customer select
        }
      }
    },
    // Moves back and forth steps (used by the main buttons) - SAVES if dirty
    async changeStep(value: number) {
      // If the user hits Next always validate
      if (value > 0) {
        const collabToValidate = this.getCurrentCollabInput();
        if (!this.validate(collabToValidate, this.step)) return;
      }
      if (value < 0) {
        this.clearValidation();
      }

      // Check if they should see the hotel step
      if (this.step + value === this.lodgingStep) {
        await this.defaultHotelCoverageUsed();
      }
      let shouldSeeHotelStep = this.shouldSeeHotelStep();

      // Ensure the estimate fields are unset of they don't apply (based on the UI logic above)
      if (this.step === this.locationsAndActivitiesStep) {
        if (this.collabInput.requestingBigTicketItems === false && this.collabInput.compedActivitiesTags.length === 0) {
          this.collabInput.compedActivitiesEstimatedValue = null;
          this.collabInput.bigTicketItemBookinNotes = null; // This also hides if no comps tags are selected
        }
      } else if (this.step === this.lodgingStep) {
        if (this.collabInput.hotelNightsCovered === 0) {
          this.collabInput.hotelEstimatedValue = null;
        }
      }

      const needToCreate = this.collabInputForEditFlow == null && !(this.newCollab.id?.length > 0);
      // Each steps saves except the first intro slide
      if (this.step !== 0 && this.isDirty) {
        // If we're in the create flow and the collab has no Id then create it, otherwise update it
        if (needToCreate) {
          // Create
          await this.createNewCollab();
        } else {
          // Save
          // notify=false here since other code paths cover create/done and we don't want to notify on each intermediate save.
          await this.save(false, null, false); // Validated above()
        }
      }
      if (this.step === this.lastStep && value > 0) {
        await this.onClose();
      } else if (this.step > 0 || this.step < this.lastStep) {
        this.step = this.step + value;
        // Check if we should skip the hotel stop
        if (this.step === this.lodgingStep && !shouldSeeHotelStep) {
          this.step = this.step + value;
        }
      }

      // reset this field
      this.superOverrideValidation = false;
    },
    setIsDirty(dirty: boolean) {
      // this.globalLog.info(`isDirty=${dirty}`);
      this.isDirty = dirty;
    },
    validate(collabToValidate: CollabInput, stepToValidation: number): boolean {
      // Note: The wizard validates step by step as the user progresses through the flow
      this.validationResult = CollabInputValidator.validateCollabInputs(collabToValidate, stepToValidation, this.isSuperOrSalesUser, this.superOverrideValidation);
      this.errors = this.validationResult.errors;
      return this.errors.length === 0;
    },
    clearValidation() {
      this.validationResult = null;
      this.errors = [];
    },
    async onClose() {
      const isPostedToCreators = this.isPastInputStatus();
      // We need to validate if they've submitted this collab, otherwise we let them close without passing validation
      if (isPostedToCreators) {
        if (!this.validate(this.getCurrentCollabInput(), null)) {
          this.$emit("close");
          return;
        }
      }
      // Save on close if there are changes (otherwise it'll look like they're saved but they might not have been)
      if (this.isDirty && (this.collabInputForEditFlow != null || this.newCollab.id?.length > 0)) {
        await this.save(isPostedToCreators, null, true);
      }
      this.errors = [];
      this.$nextTick(() => {
        this.setIsDirty(false);
      });
      this.collabDateResetCounter = 1;

      // Delay is needed to avoid the form from switching to the first slide before the modal closing animation ends
      setTimeout(() => (this.step = 0), 400);
      this.$emit("close");
    },
    isPastInputStatus(): boolean {
      return this.collabInputForEditFlow && this.collabInputForEditFlow.collaboration.status !== CollabStatuses.CommunityInputStatusId;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.modal-description-wrap {
  width: 100%;
  max-width: calc(100vw - 50px);
}

// Create collab form =========================================================
:global(.create-collab-form .srp-modal__content) {
  padding-bottom: 0 !important;
}

:global(.create-collab-form__steps-progressbar) {
  margin-bottom: 25px;
}

.themes-dropdown {
  color: #000 !important;
  font-weight: normal;
  .ui.label {
    color: #000 !important;
    font-weight: normal;
  }
}
//Override the default semantic min-height
.ui.form textarea {
  min-height: 60px;
  //Default height (override inline on some)
  height: 80px;
}
.step-counter {
  margin-bottom: -1rem;
}
.ui.header.all-set > i {
  font-size: 2rem !important;
}
.all-set-container {
  border-radius: 8px;
  padding: 1rem;
  background-color: $brand-color-lightest;
  text-align: center;
}
.intro-text {
  margin-top: 4px;
  margin-bottom: 0.5rem;
}
.modal-description {
  width: calc(100% + 120px);
  padding: 0 1rem 20px 120px;
  margin-left: -120px;
  box-sizing: border-box;
  overflow-y: auto;
  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: white;
  }
}
.ui.modal > .content {
  padding-right: 3rem !important;
  border-radius: 6px;
}
//needed to override the default label styling
.time-label {
  color: #058587 !important;
  font-weight: 500 !important;
  background-color: #05858700;
  font-size: 0.9em;
}
.current-step {
  background-color: $brand-color-lightest;
}
.ui.segment span.ui.header span.number {
  font-weight: 400;
  margin-right: 1rem;
}

.required-field {
  color: red;
  font-weight: bold;
  margin-left: 5px;
  font-size: 13px;
}
</style>
