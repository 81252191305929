<template>
  <div v-if="isCreator || hasStats">
    <router-link v-if="!isCreator && !isHotel && communityId" :to="{ name: 'CommunityStatsSummary', params: { pageId: communityId } }" class="ui tiny basic right floated button">
      Printable Summary
    </router-link>
    <h3 class="ui header" style="margin-bottom: 20px; padding-top: 5px">Highlights</h3>
    <div class="ui two column grid">
      <div class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/adventure.png`" />
            <h1>{{ stats.communityAdventureCount + stats.paidAdventureCount }}</h1>
          </div>
          <h4>Adventures</h4>
        </div>
      </div>
      <!--Show Communities how many creators there are-->
      <div v-if="!isCreator" class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/creator.png`" />
            <h1>{{ stats.communityCreatorCount + stats.paidCreatorCount }}</h1>
          </div>
          <h4>Creators</h4>
        </div>
      </div>
      <!--That obviously doesn't make sense to show creators so show Collab Count instead-->
      <div v-else class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/pencil.png`" />
            <h1>{{ stats.totalCollaborationsCount }}</h1>
          </div>
          <h4>Collabs</h4>
        </div>
      </div>

      <div class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/location.png`" />
            <h1>{{ abbreviatedFormat(stats.locationCount) }}</h1>
          </div>
          <h4>Locations</h4>
        </div>
      </div>
      <!--If we're showing the creator stats, we don't have a photos page.  Maybe add that in the future-->
      <div v-if="isCreator" class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/camera-square.png`" style="margin-top: 3px" />
            <h1>{{ abbreviatedFormat(stats.photoCount) }}</h1>
          </div>
          <h4>Photos</h4>
        </div>
      </div>

      <div v-if="!isCreator" class="column">
        <div class="ui segment">
          <component :is="communityId ? 'router-link' : 'div'" :to="{ name: 'CommunityPhotosV2', params: { pageId: communityId } }">
            <div class="state-score">
              <img :src="`${contentBaseUri}/images/admin/camera-square.png`" style="margin-top: 3px" alt="Photos count" />
              <h1>{{ abbreviatedFormat(stats.photoCount) }}</h1>
            </div>
            <h4>Photos</h4>
          </component>
        </div>
      </div>
      <!--Fully deprecated
      <div v-if="hasViewStats && !isHotel" class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/stop.png`" />
            <h1>{{ abbreviatedFormat(stats.impressionCount) }}</h1>
          </div>
          <h4>Impressions</h4>
        </div>
      </div>
      <div v-if="hasViewStats && !isHotel" class="column">
        <div class="ui segment">
          <div class="state-score">
            <img :src="`${contentBaseUri}/images/admin/stop.png`" />
            <h1 @click="showDetailedStats()">{{ abbreviatedFormat(stats.previewCount + stats.exploreCount) }}</h1>
          </div>
          <h4>Interactions</h4>
        </div>
      </div>
      <div v-if="hasViewStats && !isHotel && !isCreator && communityId" class="sixteen wide column">
        <router-link :to="{ name: 'CommunityAdventureMetrics', params: { pageId: communityId } }" class="ui small basic right floated button">More Details</router-link>
      </div>
      -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CustomerStatsOverview } from "@contracts/pages";
import NumberFormatting from "@logic/NumberFormatting";
import { inject } from "vue";

export default defineComponent({
  name: "CommunityStats",

  components: {},

  props: {
    stats: { type: Object as () => CustomerStatsOverview, default: null },
    communityId: { type: String, default: null },
    isHotel: { type: Boolean, default: false },
    isCreator: { type: Boolean, default: false },
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  computed: {
    hasStats(): boolean {
      if (!this.stats) return false;

      return this.stats.communityAdventureCount > 0 || this.stats.paidAdventureCount > 0;
    },
    hasViewStats(): boolean {
      if (!this.stats) return false;

      return this.isCreator || this.stats.previewCount > 0 || this.stats.exploreCount > 0;
    },
  },

  methods: {
    abbreviatedFormat(value: number): string {
      return NumberFormatting.abbreviatedFormat(value);
    },
    showDetailedStats() {
      this.globalLog.info(`${this.stats.previewCount} and ${this.stats.exploreCount}`);
    },
  },
});
</script>

<style scoped lang="scss">
.ui.two.column.grid {
  .column {
    padding: 0.5em;
    .ui.segment {
      .state-score {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        img {
          width: 30px;
          align-self: baseline;
          margin-right: 10px;
        }
        h1 {
          margin-top: 0;
        }
      }
      h4 {
        text-align: center;
        margin-top: 0;
      }
    }
  }
}

// @media screen and (max-width: 420px) {
//   .stats-section {
//     .section-content {
//       .ui.segment {
//         width: 48%;
//       }
//     }
//   }
// }
</style>
