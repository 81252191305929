<template>
  <div>
    <div v-if="itineraryNotFound" class="stackable very relaxed ui grid container">
      <br />
      <h4>
        Sorry, we couldn't find that adventure. <br /><br />
        Email us at support@shrpa.com if you think you found a bug!
      </h4>
    </div>

    <div v-else>
      <div v-if="isLoading"><Loader /></div>
      <div v-if="isLoading" class="ui stackable center aligned grid container">
        <br />
        <h3 class="ui">Loading your adventure, almost there...</h3>
      </div>

      <div
        v-if="itinerary && itinerary.tileImageLocation"
        class="expedition-hero"
        style="position: relative"
        :style="`background-image: url('${contentBaseUri}/cms/images/expeditions/${itinerary.tileImageLocation}')`"
      >
        <div class="expedition-overlay wow fadeIn hide-on-mobile">
          <h1 class="ui inverted header header-title">{{ itinerary.title }}</h1>
          <!--<h2 class="ui inverted header header-heading">{{itinerary.location}}</h2>-->
        </div>
        <div v-if="itinerary.signatureVideo" class="signature-video">
          <VideoRender
            :videoId="itinerary.signatureVideo"
            :showControls="false"
            :showViewerOnClick="true"
            :customClasses="'ui fluid rounded image middle aligned centered'"
            :customContainerStyles="'width: 100%;'"
            :showFullSizeImage="true"
          />
        </div>
      </div>

      <div class="ui center hide-on-desktop">
        <h2 class="ui header center" style="margin-bottom: 5px">{{ itinerary.title }}</h2>
        <div class="ui content center" style="margin-bottom: 15px">{{ itinerary.location }}</div>
      </div>

      <div v-if="!isLoading" class="stackable very relaxed ui grid container" style="clear: left">
        <div class="row">
          <div class="six wide column">
            <div class="like-share-explore-save">
              <a lang="en" hreflang="en" @click="navigateToSteps" class="ui fluid massive orange button explore-itinerary">
                Explore!
                <i class="right chevron icon"></i>
              </a>
              <br />
              <ShareButton :sourceContext="'preview'" :entityName="itinerary.title" :entityIdToShare="itinerary.id" />
              <span style="margin-top: 10px">
                <ListsButton :entityName="itinerary.title" :entityId="itinerary.id" />
                <ReportButton :entityName="itinerary.title" :entityId="itinerary.id" />
                <!--SuperUsers can edit adventures OR admins can edit collab adventures while they're in the CommunityReview status-->
                <!--Update: Opened it up to anytime, not just during CommunityReview-->
                <router-link
                  style="margin-top: 5px"
                  v-if="isSuperOrSalesUser || (itinerary.createdFor !== null && isAdminOfItineraryCollabCommunity)"
                  :to="{ name: 'CmsEditorV2', query: { id: itinerary.id } }"
                  class="ui large basic primary button"
                  lang="en"
                  hreflang="en"
                >
                  Edit
                </router-link>
              </span>
            </div>
            <!--
            <div v-if="itinerary.signatureVideo" class="signature-video">
                <VideoRender
                    :videoId="itinerary.signatureVideo"
                    :showControls="false"
                    :showViewerOnClick="true"
                    :customClasses="'ui fluid rounded image middle aligned centered'"
                    :customContainerStyles="'width: 100%;'"
                    :showFullSizeImage="true"
                    />
            </div> -->

            <div v-if="itinerary.whatToBring && itinerary.whatToBring.length > 0" class="expedition-bring">
              <h3>What to Bring</h3>
              <div class="ui grid">
                <div class="row">
                  <div class="sixteen wide column">
                    <div class="ui list">
                      <div v-for="itemToBring in itinerary.whatToBring" :key="itemToBring" class="item">
                        <i class="square outline large icon"></i>
                        <div class="content">{{ itemToBring }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="expedition-details">
              <div class="ui list">
                <div v-if="itinerary.timeRequired" class="item">
                  <i class="clock outline large icon"></i>
                  <div class="content">{{ itinerary.timeRequired }}</div>
                </div>
                <div class="item">
                  <i class="location arrow large icon"></i>
                  <div class="content">{{ itinerary.distanceTraveled }}</div>
                </div>
                <div v-if="itinerary.stepNames" class="item">
                  <i class="map outline large icon"></i>
                  <div class="content">{{ itinerary.stepNames.length }} Stops</div>
                </div>
              </div>
            </div>

            <div v-if="itinerary.mapUrl" class="expedition-location">
              <iframe :src="getMapUrl()" height="500" width="100%" frameborder="0" style="border: 0" allowfullscreen loading="lazy"></iframe>
            </div>

            <div>
              <a lang="en" hreflang="en" @click="navigateToSteps" class="ui fluid massive orange button explore-itinerary">
                Explore!
                <i class="right chevron icon"></i>
              </a>
            </div>
          </div>
          <div class="ten wide column">
            <p v-if="itinerary.previewText" style="white-space: pre-line">
              {{ itinerary.previewText }}
            </p>
            <h2 class="ui header">What to Expect</h2>
            <p class="what-to-expect-body">
              {{ itinerary.whatToExpectText }}
            </p>
            <a v-if="itinerary.customContentLink" :href="itinerary.customContentLink" target="_blank">{{ itinerary.customContentLink }}</a>

            <div class="large-spacer"></div>

            <h2 class="ui header">Destinations</h2>
            <DestinationsBar v-if="itinerary" :itinerary="itinerary" @clicked="navigateToSteps()" />

            <div class="large-spacer"><br /><br /></div>

            <div class="large-spacer"></div>

            <div class="about-sherpa" v-if="itinerary.sherpa" style="display: inline-block">
              <h2 class="ui header">About {{ itinerary.sherpa.firstName }}</h2>

              <OrgSummary :org="itinerary.sherpa.org" />

              <div style="margin-bottom: 35px; display: flex">
                <div
                  :style="{
                    width: screenSize === 'mobile' ? '100px' : '150px',
                    minWidth: screenSize === 'mobile' ? '100px' : '150px',
                    marginRight: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }"
                >
                  <router-link
                    lang="en"
                    hreflang="en"
                    class="ui small left floated circular image"
                    :to="{ name: 'CreatorProfilePublic', params: { creatorId: itinerary.sherpa.uriKey } }"
                    style="width: 100%; aspect-ratio: 1/1"
                  >
                    <img :src="itinerary.sherpa.profileImageUri" />
                  </router-link>

                  <router-link
                    lang="en"
                    hreflang="en"
                    class="ui basic orange button sherpa-button"
                    :to="{ name: 'CreatorProfilePublic', params: { creatorId: itinerary.sherpa.uriKey } }"
                    style="height: 40px; padding: 0; box-sizing: border-box; display: flex; justify-content: center; align-items: center"
                  >
                    Meet the Creator
                  </router-link>
                </div>

                <div style="display: flex; flex-direction: column">
                  <p v-if="itinerary?.sherpa?.shortBlurb?.length > 440 && !isSherpaTextFullyVisible" class="about-sherpa-text about-sherpa-text--collapsed">
                    {{ itinerary.sherpa.shortBlurb }}
                  </p>

                  <p v-else style="margin-bottom: 7px; white-space: pre-wrap">{{ itinerary.sherpa.shortBlurb }}</p>

                  <LinkWithIcon v-if="itinerary.sherpa.shortBlurb?.length > 440" @click="isSherpaTextFullyVisible = !isSherpaTextFullyVisible" color="blue">
                    <span>{{ isSherpaTextFullyVisible ? "show less" : "show more" }}</span>
                  </LinkWithIcon>
                </div>
              </div>
            </div>

            <!--
            <a lang="en" hreflang="en" href="~/" class="ui primary basic button">Back</a>
                -->
            <div v-if="itinerary.allImages?.length > 0">
              <h2 class="ui header">Adventure Photos &amp; Videos</h2>

              <SrpMasonry :items="itinerary.allImages" :columns="screenSize === 'mobile' ? 3 : 4" :columnGap="screenSize === 'mobile' ? 9 : 11">
                <template #default="{ item, rebuildMasonry }">
                  <div style="margin-bottom: 10px; display: flex">
                    <VideoRender
                      v-if="isVideo(item)"
                      :videoId="item"
                      :galleryPostfix="'-itinerary'"
                      :showControls="false"
                      :showViewerOnClick="true"
                      :customClasses="'ui fluid rounded image middle aligned centered wow fadeInUp'"
                      style="margin: 0 !important; display: inline-block !important"
                      @imgOnLoad="rebuildMasonry"
                    />
                    <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                    <a
                      v-else
                      lang="en"
                      hreflang="en"
                      data-fancybox="gallery-itinerary"
                      :href="`${contentBaseUri}/cms/images/expeditions/${item}`"
                      style="margin: 0 !important; display: inline-block !important"
                    >
                      <img class="ui fluid rounded image middle aligned centered wow fadeInUp" :src="`${contentBaseUri}/cms/images/expeditions/thumbmed/${item}`" @load="rebuildMasonry" />
                    </a>
                  </div>
                </template>
              </SrpMasonry>
            </div>

            <div v-if="community" class="org-section">
              <div class="large-spacer"></div>
              <router-link :to="attributedCustomerLink">
                <div class="ui icon message">
                  <img :src="`${contentBaseUri}/cms/images/orgs/${community.icon}`" style="max-width: 125px; max-height: 80px; margin-right: 1em" :alt="community.title" />
                  <div class="content">
                    <h4 class="ui header">
                      <span v-if="community.createdForCollab">In partnership with</span>
                      <span v-else>Find more adventures near</span>
                    </h4>
                    <div class="ui header primary" style="margin-bottom: 0.5em">{{ community.title }}</div>
                    <button v-if="!community.customerIsDisabled" class="ui basic primary tiny button">View {{ community.isHotel ? "Hotel" : "Community" }}</button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <div v-if="isSuperOrSalesUser" :style="`width: 100%; text-align: right; color: ${textSummaryColor}`" @click="getTextSummary()">...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AdminContext from "@logic/AdminContext";
import axios from "axios";
import { defineComponent, inject } from "vue";
import lodashDebounce from "lodash-es/debounce";
import FeatureFlags from "@logic/FeatureFlags";
import FileUtils from "@logic/FileUtils";
import { getInstance } from "@auth/authWrapper";
import { MetricSender } from "@helpers/MetricSender";
import { RouteHelper } from "@helpers/RouteHelper";

// Components
import DestinationsBar from "@components/DestinationsBar.vue";
import ListsButton from "@components/ListsButton.vue";
import Loader from "@components/Loader/Loader.vue";
import OrgSummary from "@components/Sherpa/OrgSummary.vue";
import ReportButton from "@components/ReportButton.vue";
import ShareButton from "@components/ShareButton.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import VideoRender from "@components/VideoRender.vue";

// Types
import type { FlagNames } from "@logic/FeatureFlags";
import GoogleMapGenerator from "@logic/GoogleMapGenerator";
import { Itinerary } from "@contracts/itinerary";
import { mapState } from "pinia";
import { ScreenSize } from "@contracts/screenSize";
import { useUserProfileStore } from "@stores/userProfileStore";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "ItineraryPreview",

  components: {
    LinkWithIcon,
    DestinationsBar,
    ListsButton,
    Loader,
    OrgSummary,
    ReportButton,
    ShareButton,
    SrpMasonry,
    SrpModal,
    VideoRender,
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,

      isLoading: true,
      itineraryNotFound: false,
      itinerary: {} as Itinerary,
      community: null, // as AttributedToCustomer | null,
      textSummaryColor: "black",
      title: "Adventure Preview",

      galleryMasonryContainer: null,

      isSherpaTextFullyVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "isSuperOrSalesUser"]),
    attributedCustomerLink(): any {
      if (!this.community) return null;
      if (this.community.isHotel) return { name: "Hotels", params: { pageId: this.community.uniqueName } };
      else return { name: "Pages", params: { pageId: this.community.uniqueName } };
    },
    isAdminOfItineraryCollabCommunity(): any {
      if (!this.itinerary || !this.itinerary.createdFor) return false;
      return this.getActingUserProfile?.adminOf.includes(this.itinerary.createdFor);
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // Load the main itinerary preview daa
    let id = this.$route.params.itineraryId;
    this.loadItinerary(id);
    this.getAttributedToCustomer(id);
  },

  methods: {
    loadItinerary: async function (identifier) {
      let uri = `${import.meta.env.VITE_API_URL}/itineraries/${identifier}/preview`;
      let status = RouteHelper.getQueryStringParam("status");
      if (status) uri += `?status=${status}`;
      const response = await axios.get(uri);
      if (response.status == 204) {
        this.itineraryNotFound = true;
      } else {
        this.itinerary = response.data;
        MetricSender.previewAdventure(this.itinerary.id, this.itinerary.uniqueName);
      }
      this.isLoading = false;
      this.setTitle();
    },
    async getAttributedToCustomer(id) {
      let uri = `${import.meta.env.VITE_API_URL}/itineraries/${id}/attributed-customer`;
      // Ensure we have the community in context since we want to show that if it's within range (since multiple comunities can be in range)
      await AdminContext.defaultOrgInContext();
      var communityIdInContext = AdminContext.getCommunityIdInContext();
      if (communityIdInContext) uri += `?preferredCommunityId=${communityIdInContext}`;
      const { data } = await axios.get(uri);
      this.community = data;
    },
    setTitle: function () {
      if (this.itinerary) {
        this.title = this.itinerary.title;
      }
    },
    getMapUrl: function () {
      var url = GoogleMapGenerator.getMapUrlWithCurrentKey(this.itinerary?.mapUrl);
      return url;
    },
    isDraft: function () {
      let draft = this.itinerary.status !== "Live";
      return draft;
    },
    navigateToSteps: function () {
      var identifier = this.itinerary.uniqueName ? this.itinerary.uniqueName : this.itinerary.id;
      var params = { itineraryId: identifier };
      var queryParams = {} as any;
      if (this.isDraft()) {
        queryParams.status = this.itinerary.status;
      }
      // Note: Set fromShrpa to true so we get smarter "back" functionality
      // @ts-ignore
      this.$router.push({ name: "ItineraryStepsV2", params: { itineraryId: identifier, fromShrpa: true }, query: queryParams });
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
    async getTextSummary() {
      // Get the adventure text summary and copy it to the clipboard
      this.textSummaryColor = "red";
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/itineraries/${this.itinerary.id}/text-summary`);
      let textSummary = data;
      navigator.clipboard.writeText(textSummary);
      this.textSummaryColor = "aqua";
    },
  },
});
</script>

<style scoped lang="scss">
.about-sherpa-text {
  margin-bottom: 7px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;

  &--collapsed {
    line-clamp: 10;
    -webkit-line-clamp: 10;
  }
}

// Gallery rows layout ========================================================
.gallery-rows-layout {
  display: flex;
  flex-wrap: wrap;

  &__item {
    height: 110px;
    margin: 0 12px 12px 0;
  }

  &__video {
  }

  &__a {
  }

  &__img {
    height: 100% !important;
  }
}

// ============================================================================
.org-section {
  margin: 1em 0 3em;
}

.signature-video {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 200px;
  @media screen and (max-width: 420px) {
    right: 10px;
    bottom: 10px;
    width: 92px;
  }
}
</style>
