<template>
  <div>
    <div v-if="selectedImages.length > 0">
      <div v-if="isEdited" style="margin-bottom: 3px; margin-top: -8px">Click a photo to select it and use the left and right arrow keys to move the image around.</div>
      <div class="shrpa-content" :style="`overflow: hidden; max-height: ${shrpaGetPhotoContainerHeight}px  !important; margin: 0 -${paddingPixels}px 0 -${paddingPixels}px  !important;`">
        <div
          v-for="(image, index) in selectedImages"
          :key="image"
          @click="selectImage(index)"
          :class="`shrpa-img-wrap ${index === selectedImageIndex ? 'selected-img' : ''}`"
          :style="{ height: `${imageHeightPixels}px !important`, margin: `${paddingPixels}px !important` }"
        >
          <div v-if="isEdited" class="remove-icon" @click.stop="removeImage(image)"><i class="close icon"></i></div>
          <template v-if="isVideo(image)">
            <VideoRender :videoId="image" :customClasses="'shrpa-img'" :customStyles="` height: ${imageHeightPixels}px !important; `" />
          </template>
          <template v-else>
            <img class="shrpa-img" :style="{ height: `${imageHeightPixels}px !important` }" loading="lazy" :src="`${contentBaseUri}/cms/images/expeditions/thumbmed/${image}`" />
          </template>
          <div v-if="isEdited" class="edit-arrows">
            <div class="prev-next-btn" @click.stop="changeImageOrder(index, -1)">
              <i class="angle left icon"></i>
            </div>
            <div class="prev-next-btn" @click.stop="changeImageOrder(index, 1)">
              <i class="angle right icon"></i>
            </div>
          </div>
        </div>
        <div class="shrpa-img-wrap last"></div>
      </div>
      <div v-if="!settings.alwaysShowAll && settings.allowShowMore" class="show-all-btn-wrapper">
        <a type="button" class="shrpa-show-all-btn" :style="customShowMoreStyles" @click="shrpaShowMore()">Show More</a>
      </div>
    </div>
    <div v-else>Select some photos below for your photo wall!</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VideoRender from "@components/VideoRender.vue";
import FileUtils from "@logic/FileUtils";

// NOTE! This markup and script is based on the photos.js photowall code, so there's duplication here,
// but since we don't have the modal it's not that much code.
// We are able to re-use the styles.

export default defineComponent({
  name: "PhotoWallPreview",

  components: {
    VideoRender,
  },

  props: {
    selectedImages: { type: Array, default: () => [] },
    settings: { type: Object, default: () => ({}) },
    isEdited: { type: Boolean, default: false },
    removeImage: { type: Function, required: true },
    changeImageOrder: { type: Function, required: true },
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      // NOTE: These multipliers are in photos.js also
      imageHeightMultiplier: 25,
      paddingMultiplier: 0.1,

      additionalRowsDisplayed: 0,
      selectedImageIndex: -1,
    };
  },

  computed: {
    imageHeightPixels(): number {
      return this.settings.imageHeight * this.imageHeightMultiplier;
    },
    paddingPixels(): number {
      return this.settings.padding * this.paddingMultiplier;
    },
    shrpaGetPhotoContainerHeight(): number {
      // NOTE: Similar to shrpaGetPhotoContainerHeight in Photos.js with minor tweaks (ex. multipliers)
      // Also show them all if in edit mode (so they can be moved around easily)
      if (this.settings.alwaysShowAll || this.isEdited) return 9999999;

      let rowCount = this.settings.rowsToShow + this.additionalRowsDisplayed;
      // Image (image height + padding * 2) * rows to show
      let newHeight = (this.settings.imageHeight * this.imageHeightMultiplier + this.settings.padding * this.paddingMultiplier * 2) * rowCount;
      return newHeight;
    },
    customShowMoreStyles(): string {
      if (this.settings.color?.trim().length > 0) {
        return `color: #${this.settings.color} !important; border-color: #${this.settings.color} !important;`;
      }
      return "";
    },
  },

  beforeMount() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {
    shrpaShowMore() {
      this.additionalRowsDisplayed += 1;
    },
    selectImage(index) {
      if (!this.isEdited) {
        this.selectedImageIndex = -1;
        return;
      }

      if (index != this.selectedImageIndex) this.selectedImageIndex = index;
      else {
        this.selectedImageIndex = -1; // unselect
      }
    },
    handleKeydown(e) {
      if (this.selectedImageIndex < 0 && this.isEdited) return;

      switch (e.keyCode) {
        case 37: // left
          this.changeImageOrder(this.selectedImageIndex, -1);
          this.selectedImageIndex -= 1;
          break;
        case 39: // right
          this.changeImageOrder(this.selectedImageIndex, 1);
          this.selectedImageIndex += 1;
          break;
      }
      if (this.selectedImageIndex < 0) this.selectedImageIndex = this.selectedImages.length - 1;
      if (this.selectedImageIndex >= this.selectedImages.length) this.selectedImageIndex = 0;
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/photowall.css";
.remove-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9;
  &:hover {
    background-color: rgb(0, 0, 0, 0.4);
  }
  i.close.icon {
    margin-left: 4px;
    margin-top: 2px;
    margin-right: 4px;
  }
}
.edit-arrows {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}
.prev-next-btn {
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9;
  i {
    margin: 4px;
    color: white;
  }
  &:hover {
    background-color: rgb(0, 0, 0, 0.4);
  }
}
.shrpa-img-wrap {
  &:hover {
    opacity: 0.8;
  }
  &.selected-img {
    border-radius: 4px;
    opacity: 0.9;
    outline: 4px solid rgba(5, 133, 135, 1);
    outline-offset: -4px;
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      background-color: rgba(5, 133, 135, 0.25);
    }
  }
}

.shrpa-show-all-btn {
  display: inline;
}
</style>
