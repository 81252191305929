<template>
  <div class="tooltip-wrapper">
    <div class="tooltip-wrapper__hoverable-element" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" ref="domRefHoverableElem">
      <slot name="hoverableElement" :isTooltipVisible="isTooltipVisible" />
    </div>

    <Teleport to="body">
      <div
        :class="{
          'tooltip-wrapper__tooltip-itself': true,
          'tooltip-wrapper__tooltip-itself--invisible': !isTooltipVisible,

          'tooltip-wrapper__tooltip-itself--arrow-top': tooltipPlacement === 'bottom',
          'tooltip-wrapper__tooltip-itself--arrow-right': tooltipPlacement === 'left',
          'tooltip-wrapper__tooltip-itself--arrow-bottom': tooltipPlacement === 'top',
          'tooltip-wrapper__tooltip-itself--arrow-left': tooltipPlacement === 'right',

          'tooltip-wrapper__tooltip-itself--arrow-bottom-left': tooltipPlacement === 'top-start',
          'tooltip-wrapper__tooltip-itself--arrow-top-left': tooltipPlacement === 'bottom-start',

          'tooltip-wrapper__tooltip-itself--arrow-bottom-right': tooltipPlacement === 'top-end',
          'tooltip-wrapper__tooltip-itself--arrow-top-right': tooltipPlacement === 'bottom-end',
        }"
        @mouseenter="handleTooltipMouseEnter"
        @mouseleave="handleTooltipMouseLeave"
        ref="domRefTooltip"
        :style="{
          width: props.width === 'auto' ? 'auto' : `${props.width}px`,
          ...tooltipPositionStyles,
        }"
      >
        <slot name="tooltip" :isTooltipVisible="isTooltipVisible">
          {{ text }}
        </slot>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { computePosition, type ComputePositionConfig, type Placement, shift, flip, offset as floatingUiOffset } from "@floating-ui/dom";
import { ref, onMounted, watch } from "vue";

const props = withDefaults(
  defineProps<{
    text?: string;
    offset?: number;
    width?: number | "auto";
    placement?: Placement;
  }>(),
  {
    text: "",
    offset: 10,
    width: "auto",
    placement: "top",
  }
);

// Toggle tooltip =============================================================
const isTooltipVisible = ref<boolean>(false);

let showTimeout = null;
let hideTimeout = null;

function handleMouseEnter() {
  computeTooltipPosition();

  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
  showTimeout = setTimeout(() => (isTooltipVisible.value = true), 100);
}
function handleMouseLeave() {
  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
  hideTimeout = setTimeout(() => (isTooltipVisible.value = false), 100);
}

function handleTooltipMouseEnter() {
  clearTimeout(showTimeout);
  clearTimeout(hideTimeout);
}
function handleTooltipMouseLeave() {
  hideTimeout = setTimeout(() => (isTooltipVisible.value = false), 100);
}

// Set tooltip position =======================================================
const domRefHoverableElem = ref(null);
const domRefTooltip = ref(null);
const tooltipPositionStyles = ref({});
const tooltipArrowPositionStyles = ref({});

const tooltipPlacement = ref<Placement>("top");

onMounted(computeTooltipPosition);

function computeTooltipPosition(): void {
  const floatingUiOptions: ComputePositionConfig = {
    middleware: [floatingUiOffset(props.offset), flip(), shift()],
    placement: props.placement,
  };

  computePosition(domRefHoverableElem.value, domRefTooltip.value, floatingUiOptions).then(position => {
    tooltipPlacement.value = position.placement;

    tooltipPositionStyles.value = {
      top: `${position.y}px`,
      left: `${position.x}px`,
    };
    tooltipArrowPositionStyles.value = {
      x: position.middlewareData?.arrow?.x,
      y: position.middlewareData?.arrow?.y,
    };
  });
}
</script>

<style scoped lang="scss">
@import "@/scss/z-indexes.scss";

// Tooltip wrapper ============================================================
.tooltip-wrapper {
  display: inline-flex;
  position: relative;

  &__hoverable-element {
  }

  &__tooltip-itself {
    padding: 10px 15px 12px;
    border-radius: 5px;
    position: absolute;
    z-index: $z-index-tooltip;
    color: #5b5b5b;
    font: 14px/18px sans-serif;
    background: #fff;
    opacity: 1;
    box-shadow: 0 5px 35px -9px rgba(0, 0, 0, 0.4);
    transition: opacity 0.09s ease-in-out;

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      z-index: -1;
      transform: rotate(45deg);
      background: #fff;
    }

    &--arrow-top {
      &::before {
        inset: -4px auto auto calc(50% - 7px);
      }
    }
    &--arrow-right {
      &::before {
        inset: calc(50% - 7px) -4px auto auto;
      }
    }
    &--arrow-bottom {
      &::before {
        inset: auto auto -4px calc(50% - 7px);
      }
    }
    &--arrow-left {
      &::before {
        inset: calc(50% - 7px) auto auto -4px;
      }
    }
    &--arrow-top-right {
      &::before {
        inset: -4px auto auto calc(100% - 17px);
      }
    }
    &--arrow-bottom-right {
      &::before {
        inset: auto auto -4px calc(100% - 17px);
      }
    }
    &--arrow-top-left {
      &::before {
        inset: -4px auto auto 4px;
      }
    }
    &--arrow-bottom-left {
      &::before {
        inset: auto auto -4px 4px;
      }
    }
  }
}
</style>
