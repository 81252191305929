import { ThemeType } from "@contracts/collab";
import collabThemeType from "@assets/images/collabThemeType/batchImport.js";

export function getThemeIcon(themeType: ThemeType): string {
  return collabThemeType[themeType];
}

export function getThemeTypeName(themeType: ThemeType): string {
  switch (themeType) {
    case "couple":
      return "Couple's Trip";
    case "event":
      return "Event Focused";
    case "family":
      return "Family";
    case "foodie":
      return "Foodie/Drinks Tour";
    case "guysgals":
      return "Gals/Guys Trip";
    case "location":
      return "Location Focused";
    case "outdoors":
      return "Outdoors";
    case "seasonal":
      return "Seasonal";
    case "town":
      return "Exploring Town";
    default:
      return "";
  }
}
