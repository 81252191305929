<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>
    <div v-else class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <div>
        <h1 style="display: inline; margin-right: 25px">Invite Community Members to Upload Photos and Videos!</h1>
      </div>
      <p>
        The best way to experience your community is through the eyes of those who love it most!
        <br />Feature photos and videos from members of your community by sending them an easy upload link.
      </p>

      <div class="ui stackable grid" style="margin-top: 3em">
        <div class="ten wide column">
          <template v-if="campaignData && campaignData.uploadedPhotoCount > 0">
            <div>
              <h3>View your uploaded photos and videos!</h3>
            </div>
            <div style="margin-top: 0.5em">
              You've got {{ campaignData.uploadedPhotoCount }} uploaded photos and videos.
              <router-link
                class="ui primary tiny button"
                lang="en"
                hreflang="en"
                :to="{ name: 'CommunityPhotosV2', params: { pageId: pageId }, hash: '#justCommunityUploads=true' }"
                style="margin-left: 8px"
                >View Uploads</router-link
              >
            </div>
          </template>

          <label class="cms-section-title">Upload Links</label>
          <p>
            These links allows community members to easily upload photos that you can use in your marketing!
            <br />
            Share this link via email, your website, or social media with community members to help them upload content.
          </p>
          <table v-if="unarchivedCampaigns.length > 0" class="ui very basic table">
            <thead>
              <tr>
                <th>
                  Upload Links <span style="font-weight: normal">({{ unarchivedCampaigns.length }} of {{ limitText }})</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(campaign, index) in unarchivedCampaigns" :key="campaign.id">
                <td>
                  <span @click="edit(campaign, index)" style="cursor: pointer"> {{ campaign.title }} <i class="icon edit outline" style="margin-left: 3px"></i> </span>
                </td>
                <td>
                  <button class="ui primary basic tiny button" @click="copy(campaign.campaignId)">Copy Link</button>
                  <button class="ui grey basic tiny button" @click="askToArchive(campaign)">Archive</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="justCopied" style="float: right"><b>Code Copied to Clipboard</b></div>
          <div>
            <template v-if="unarchivedCampaigns.length < campaignData.uploadCampaignsAllowed">
              <button class="ui primary tiny button" @click="askToCreate()">New Theme</button>
            </template>
            <template v-else>
              <div data-tooltip="Want more links? Contact us for an upgrade." data-position="top left">
                <button disabled class="ui primary tiny button">
                  New Theme
                  <i class="icon lock button-lock-icon"></i>
                </button>
              </div>
              <div class="limit-hit-message">Want more links? Contact us for an upgrade, <CopyText :text="'sales@shrpa.com'" /></div>
            </template>
          </div>

          <label class="cms-section-title" style="margin-top: 40px !important">Archived Links</label>
          <table v-if="archivedCampaigns.length > 0" class="ui very basic table">
            <thead>
              <tr>
                <th>Upload Links</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="campaign in archivedCampaigns" :key="campaign.id">
                <td>{{ campaign.title }}</td>
                <td>
                  <button v-if="unarchivedCampaigns.length < campaignData.uploadCampaignsAllowed" class="ui grey basic mini button" @click="unarchive(campaign)">Unarchive</button>
                  <button v-else disabled class="ui grey basic mini button">
                    Unarchive
                    <i class="icon lock button-lock-icon"></i>
                  </button>
                  <button class="ui basic tiny button" @click="askToSoftDelete(campaign)"><i class="icon trash"></i>Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="archivedCampaigns.length === 0">None</div>
        </div>
        <div class="six wide column">
          <img class="ui small image" style="width: 100%" src="https://cdn.shrpa.com/images/misc/integrations/CommunityPhotoUpload-med.jpg" />
        </div>
      </div>
    </div>

    <!--Edit Modal-->
    <SrpModal v-if="campaignToSave" v-model:isVisible="showEditModal" :isClosable="false">
      <template #header>
        <h2 class="global-h2">{{ campaignToSave.campaignId ? "Update the Campaign" : "Create a Campaign" }}</h2>
      </template>
      <template #content>
        <div class="ui form">
          <div class="field">
            <label class="subsection-title">Title</label>
            <p class="cms">Use a theme or category you want to highlight!</p>
            <div class="ui input">
              <input type="text" v-model="campaignToSave.title" style="max-width: 600px" placeholder="Ex. Fall colors, or Holiday lights, or Summer fun" />
            </div>
          </div>
          <div class="field">
            <label class="subsection-title">Description</label>
            <div class="ui fluid input">
              <textarea type="text" style="border-radius: 0.28571429rem" v-model="campaignToSave.description"></textarea>
            </div>
          </div>
          <div class="validation-errors">
            <div v-if="validationError">{{ validationError }}</div>
          </div>
          <div v-if="campaignToSave.campaignId" div class="field">
            <label class="subsection-title">Upload Link to Share<button class="ui primary tiny button" style="margin-left: 10px" @click="copy(campaignToSave.campaignId)">Copy Link</button></label>
            {{ getUploadLink(campaignToSave.campaignId) }}
            <div v-if="justCopied"><b>Link Copied to Clipboard!!</b></div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelCreate()">Cancel</div>
        <SrpButton :isDisabled="isSaving" @click="saveCampaign()">{{ isSaving ? "Saving..." : "Save" }}</SrpButton>
      </template>
    </SrpModal>

    <!--Archive Modal-->
    <SrpModal v-model:isVisible="showArchiveModal">
      <template #header><h2 class="global-h2">Ready to archive this?</h2></template>
      <template #content>
        <div>
          <b>Users will no longer be able to upload with this link.</b><br />
          Note that any content uploaded via this link won't be touched.
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Archiving...</h3>
        <div class="ui basic black button" @click="showArchiveModal = false">Cancel</div>
        <button class="ui grey button" :disabled="isSaving" @click="archiveConfirmed()">Yep, archive it.</button>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal">
      <template #header>
        <h2 class="global-h2">Are you sure you want to delete this?</h2>
      </template>
      <template #content>
        <div>
          <b>This link will be gone for good.</b><br />
          Note that any content uploaded via this link won't be touched.
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Deleting...</h3>
        <div class="ui basic black button" @click="showDeleteModal = false">Cancel</div>
        <button class="ui red button" :disabled="isSaving" @click="deleteConfirmed()">Yep, delete it.</button>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import OrgContext from "@logic/OrgContext";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "UserUploadCampaigns",

  components: {
    SrpButton,
    SrpModal,
    Loader,
    CopyText,
  },

  data() {
    return {
      title: "Community Uploads",
      pageId: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      orgInContext: null as PartnerNavInfo,
      // Campaign Data
      campaignData: null,
      justCopied: false,
      isLoading: true,

      showEditModal: false,
      campaignToSave: null, // The campaign being edited/created
      campaignBeforeEdit: null, // In case they hit cancel
      campaignEditIndex: -1,
      isSaving: false,
      validationError: null,

      showDeleteModal: false,
      showArchiveModal: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    unarchivedCampaigns(): number {
      return this.campaignData.campaigns.filter(c => !c.expiryDateTime);
    },
    archivedCampaigns(): number {
      return this.campaignData.campaigns.filter(c => c.expiryDateTime);
    },
    limitText(): string {
      return this.campaignData.uploadCampaignsAllowed < 50 ? this.campaignData.uploadCampaignsAllowed.toString() : "unlimited";
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId;
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    this.loadData();
  },

  methods: {
    async copy(campaignId: string) {
      let link = this.getUploadLink(campaignId);
      await navigator.clipboard.writeText(link);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    async loadData() {
      this.isLoading = true;
      const campaigns = await axios.get(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}`);
      this.campaignData = campaigns.data;

      this.isLoading = false;
    },
    askToCreate() {
      this.campaignToSave = {
        title: null,
        description: null,
      };
      this.campaignBeforeEdit = null;
      this.campaignEditIndex = -1;
      this.showEditModal = true;
    },
    cancelCreate() {
      if (this.campaignEditIndex >= 0) this.campaignData.campaigns[this.campaignEditIndex] = this.campaignBeforeEdit;
      this.showEditModal = false;
    },
    edit(campaign, index) {
      this.campaignToSave = campaign;
      // Shallow clone
      this.campaignBeforeEdit = { ...campaign };
      this.campaignEditIndex = index;
      this.showEditModal = true;
    },
    validate(): boolean {
      this.validationError = null;
      if (this.campaignToSave?.title == null || this.campaignToSave.title.trim().length === 0 || this.campaignToSave?.description == null || this.campaignToSave.description.trim().length === 0) {
        this.validationError = "Add a Title and Description";
        return false;
      }
      return true;
    },
    async saveCampaign(validate = true) {
      if (validate && !this.validate()) return;

      let isNew = this.campaignToSave.campaignId == null; // Using the abstract equality check here so we catch undefined also
      const config = { headers: { "Content-Type": "application/json" } };
      let result = await axios.put(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign`, JSON.stringify(this.campaignToSave), config);
      if (isNew) this.campaignData.campaigns.push(result.data);

      this.showEditModal = false;
    },
    getUploadLink(campaignId: string): string {
      return `${window.location.protocol}//${window.location.host}/upload/${this.pageId}/${campaignId}`;
    },
    askToArchive(campaign) {
      this.campaignToSave = campaign;
      this.showArchiveModal = true;
    },
    async archiveConfirmed() {
      this.isSaving = true;
      this.campaignToSave.expiryDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showArchiveModal = false;
      this.isSaving = false;
    },
    async unarchive(campaign) {
      this.isSaving = true;
      this.campaignToSave = campaign;
      this.campaignToSave.expiryDateTime = null;
      await this.saveCampaign(false);
      this.isSaving = false;
    },
    askToSoftDelete(campaign) {
      this.campaignToSave = campaign;
      this.campaignEditIndex = this.campaignData.campaigns.indexOf(campaign);
      this.showDeleteModal = true;
    },
    async deleteConfirmed() {
      this.isSaving = true;
      this.campaignToSave.deletedDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showDeleteModal = false;
      this.isSaving = false;
      this.campaignData.campaigns.splice(this.campaignEditIndex, 1);
    },
  },
});
</script>

<style scoped lang="scss">
.validation-errors {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
.limit-hit-message {
  margin-top: 10px;
}
</style>
