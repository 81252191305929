<template>
  <SrpModal class-name="phone-number-verification-modal" :is-visible="isVisible" @update:isVisible="onCancel" :z-index="zIndex">
    <template #header>
      <h2>Verify your Phone Number</h2>
    </template>
    <template #content>
      <template v-if="step === 'enterPhoneNumber'">
        <form @submit.prevent="requestVerification">
          <SrpPhoneNumberInput v-model="unmaskedPhoneNumber" :label="inputLabel" />
          <FormErrorsList :errors="errors" />
          <!-- / Errors list -->
          <div class="phone-number-verification-modal__actions">
            <SrpButton class="cancel" @click="onCancel">Cancel</SrpButton>
            <SrpButton tag="button" type="submit" :is-disabled="disableSubmitButton">Next</SrpButton>
          </div>
        </form>
      </template>
      <template v-else>
        <form @submit.prevent="onVerifyClicked">
          <label>
            Enter the verification code sent to your phone
            <input type="text" v-model="verificationCode" />
          </label>

          <!-- Errors list -->
          <div v-if="errors.length" class="errors-list buttons-sticky-panel__errors-list">
            <ul class="errors-list__list-itself">
              <li class="errors-list__li" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
          <div class="phone-number-verification-modal__actions">
            <SrpButton class="cancel" @click="onCancel">Cancel</SrpButton>
            <SrpButton tag="button" type="submit" :is-disabled="disableSubmitButton">Verify</SrpButton>
          </div>
        </form>
      </template>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import FormErrorsList from "@components/FormErrorsList.vue";
import SrpPhoneNumberInput from "@components/ui/SrpPhoneNumberInput.vue";
import PhoneValidation from "@logic/PhoneValidation";
import { useUserProfileStore } from "@stores/userProfileStore";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { MaskOptions, MaskaDetail, vMaska } from "maska";

import axios from "axios";

const maskOptions = reactive<MaskOptions>({
  mask: "+1 (###) ###-####",
});
const maskDetails = reactive<MaskaDetail>({ completed: false, masked: "", unmasked: "" });

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    zIndex?: number;
  }>(),
  { zIndex: undefined }
);

const userProfileStore = useUserProfileStore();

type ModalStep = "enterPhoneNumber" | "verifyPhoneNumber";
const step = ref<ModalStep>("enterPhoneNumber");

const verificationCode = ref("");
const unmaskedPhoneNumber = ref("");

onMounted(() => {
  resetForm();
});
watch(
  () => props.isVisible,
  isVisible => {
    if (isVisible) {
      resetForm();
    }
  }
);
const errors = ref<string[]>([]);
function resetForm() {
  step.value = "enterPhoneNumber";
  if (userProfileStore.getViewingUserProfile.phoneNumber) {
    unmaskedPhoneNumber.value = userProfileStore.getViewingUserProfile.phoneNumber;
  } else {
    unmaskedPhoneNumber.value = "";
  }
  verificationCode.value = "";
  errors.value = [];
}
const inputLabel = computed(() => {
  return userProfileStore.getViewingUserProfile.phoneNumber ? "Verify your phone number is correct" : "Enter your phone number to receive texts";
});

const disableSubmitButton = ref(false);
async function requestVerification() {
  errors.value = [];
  if (!PhoneValidation.isPhoneNumberValid(unmaskedPhoneNumber.value)) {
    errors.value.push("Please enter a valid phone number");
    return;
  }
  try {
    disableSubmitButton.value = true;
    await axios.post(`${import.meta.env.VITE_API_URL}/sms/user/${userProfileStore.getViewingUserProfile.authNameId}/request-verification`, { phoneNumber: unmaskedPhoneNumber.value });
  } catch (e) {
    errors.value.push("An unexpected error occurred.  Please retry or email support@shrpa.com");
    return;
  } finally {
    disableSubmitButton.value = false;
  }

  step.value = "verifyPhoneNumber";
}

async function onVerifyClicked() {
  errors.value = [];
  if (!verificationCode.value) {
    errors.value.push("Please enter a valid verification code");
    return;
  }

  const uri = `${import.meta.env.VITE_API_URL}/sms/user/${userProfileStore.getViewingUserProfile.authNameId}/confirm-verification`;
  try {
    disableSubmitButton.value = true;
    const { data } = await axios.post<{ phoneNumberIsVerified: boolean }>(uri, {
      phoneNumber: unmaskedPhoneNumber.value,
      otp: verificationCode.value,
    });

    if (data.phoneNumberIsVerified) {
      // manually update the phone number and verification status of the profile
      // Instead of fetching the profile again which would result in obliterating changes
      // on the edit profile page
      userProfileStore.getViewingUserProfile.phoneNumber = unmaskedPhoneNumber.value;
      userProfileStore.getViewingUserProfile.phoneNumberIsVerified = true;
      emit("update:isVisible", false);
      emit("phoneVerified");
      return;
    } else {
      errors.value.push("Invalid verification code");
    }
  } catch (e) {
    errors.value.push("An unexpected error occurred.  Please retry or email support@shrpa.com");
    return;
  } finally {
    disableSubmitButton.value = false;
  }
}

function onCancel() {
  emit("update:isVisible", false);
  emit("verificationCanceled");
}

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "verificationCanceled"): void;
  (e: "phoneVerified"): void;
}>();
</script>

<style scoped lang="scss">
.phone-number-verification-modal {
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancel {
    margin-right: 10px;
  }
}

.errors-list {
  margin-top: 1rem;
  color: firebrick;

  &__title {
    margin-bottom: 8px;
    color: firebrick;
  }

  &__list-itself {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__li {
    font: 14px/18px sans-serif;
  }
}
</style>
