import { shrpaApi } from "./axios";

export interface CmsImages {
  usedAssets: string[];
  unusedAssets: string[];
}
export default {
  async loadCollabCmsImages(collabInputId: string, communityId: string) {
    const uri = `/cms-images/collab/${collabInputId}/customer/${communityId}`;
    const { data } = await shrpaApi.get<CmsImages>(uri);
    return data;
  },
};
