<template>
  <div v-if="shouldShow">
    <div class="ui segment">
      <h1 class="title">Congrats for being a community creator!</h1>

      <div class="contents-wrapper" :class="{ hidden: !toggled }">
        <div class="custom-content">
          <h3><i class="icon mail" />Message from {{ community.page.title }}</h3>
          <div style="white-space: pre-wrap">{{ community.guidance }}</div>
        </div>
        <div class="custom-content">
          <h3><!--<i class="icon question circle" />-->Questions?</h3>
          <div>
            <!--<i class="icon linkify" />-->
            Here's an overview <a href="https://explore.shrpa.com/community-engagement">https://explore.shrpa.com/community-engagement</a>
          </div>
          <div>
            <!--<i class="icon mail" />-->
            Email us at <a href="mailto:support@shrpa.com">support@shrpa.com</a>
          </div>
        </div>
      </div>
      <div class="toggle-text" @click.prevent.stop="onToggle">
        {{ toggled ? "Minimize Community Guidance" : "Expand Community Guidance" }}
      </div>
    </div>
    <br />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { PageMembership } from "@contracts/pages";
import { getInstance } from "@auth/authWrapper";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

export default defineComponent({
  name: "MyCommunity",

  components: {
    Loader,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      membership: [] as Array<PageMembership>,
      community: null,
      toggled: true,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isImpersonating", "isSuperOrSalesUser"]),
    shouldShow(): boolean {
      if (this.membership && this.membership.length > 0 && this.community) {
        // Must actually have some data to show
        var hasGuidance = this.community.guidance && this.community.guidance.length > 0;
        if (hasGuidance) {
          return true;
        }
      }
      return false;
    },
  },

  async mounted() {
    // Note: This is annoying but the way things are currently setup I think we need to do
    // this to ensure a new user profile is setup before we load communities.
    // That's particularly relevent here since the community invite provisions users right into this flow.
    // Note: We could potentially just do a watch on the root profile here instead...
    let authService = getInstance();
    if (authService.loading === false) {
      this.loadMyCommunities();
    }
    authService.$watch("loading", loading => {
      if (loading === false) {
        this.loadMyCommunities();
      }
    });
  },

  methods: {
    loadMyCommunities: async function () {
      this.globalLog.info("Loading pages/membership");
      let query = "";
      if (this.isImpersonating) query = "?creatorId=" + this.getViewingUserProfile.sherpaId;
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/pages/membership${query}`);
      this.membership = data;
      // todo: Eventually support multiple.  Probably show a dropdown.
      if (this.membership.length > 0) {
        this.community = data[0];
      }
      this.isLoading = false;
    },
    onToggle: function () {
      this.toggled = !this.toggled;
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  color: #338587;
}
.contents-wrapper {
  /*height: 380px;*/
  overflow: hidden;
  transition: all 0.5s ease;
  &.hidden {
    height: 0;
  }
  .custom-content {
    background: #f8f8f8;
    padding: 10px;
    margin-bottom: 10px;
    a {
      color: #058587;
    }
  }
}
.toggle-text {
  color: #058587;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 18px;
  }
  .contents-wrapper {
    height: auto;
    .custom-content:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
