<template>
  <MenuLinksList :linksList="communityIdInContext ? navLinksSettings : []" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";

// Types
import { NavigationMenuItem } from "@contracts/navigationMenuItem";

// Components
import MenuLinksList from "@components/Navigation/MenuLinksList.vue";

// NOTE: Currently using the dashboard redirect parameter.
// An alternative would be to get the community in context here (but we'd have to react to dropdown changes on the dashboard anyway...)

export default defineComponent({
  name: "CustomerAdminLeftNav",

  components: {
    MenuLinksList,
  },

  data() {
    return {
      currentRouteName: "",
      communityIdInContext: "",
    };
  },

  computed: {
    showSettingsNav(): boolean {
      return this.$route.path.startsWith("/settings");
    },

    navLinksSettings(): Array<NavigationMenuItem> {
      return [
        { text: "Back to Dashboard", isSubItem: false, titleIcon: "caret-left_2-5", to: { name: "CommunityAdminDashboard", params: { communityId: this.communityIdInContext } } },

        { text: "Destination Page", isSubItem: true, isWithTopDivider: true, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } } },
        { text: "Subscriptions", isSubItem: true, to: { name: "SubscriptionSettings", params: { pageId: this.communityIdInContext } } },
        { text: "Users", isSubItem: true, to: { name: "UserAdmin", params: { pageId: this.communityIdInContext } } },
      ];
    },
  },

  watch: {
    $route(to, from) {
      this.setRouteName();
      console.info(this.currentRouteName);
    },
    // This would allow us to avoid the redirect through dashboard routes
    "globalRoot.orgInContext": {
      handler: async function (orgInContext) {
        if (orgInContext && orgInContext?.customerId != null) {
          this.setupOrgInContext();
        }
      },
    },
  },

  async mounted() {
    this.setRouteName();
    this.setupOrgInContext();
  },

  methods: {
    setRouteName() {
      this.currentRouteName = this.$router.currentRoute.value.name?.toString();
    },
    setupOrgInContext() {
      const orgInContext = OrgContext.getOrgInContext(this);

      if (orgInContext) {
        this.communityIdInContext = orgInContext?.customerId;
      }
    },
    isActive(name, name2?, name3?, name4?, name5?): boolean {
      return [name, name2, name3, name4, name5].includes(this.currentRouteName);
    },
  },
});
</script>
