<template>
  <SrpModal
    :isVisible="props.isVisible"
    @close="emit('update:isVisible', false)"
    size="large"
    isWithScroll
    maxHeight="calc(100dvh - 140px)"
    :isWithBottomGap="false"
    :zIndex="200"
    :isClosable="!selectedFiles.length"
  >
    <template #content>
      <div class="highlights-select-modal">
        <div class="highlights-select-modal__header">
          <h1 class="global-h1 highlights-select-modal__title">{{ props.title }}</h1>
          <div class="highlights-select-modal__subtitle">
            Choose ~20 of your best photos &amp; videos that summarize the collaboration for the destination.
            <br />
            &nbsp; - Images and videos must be used in adventures to be selected.
          </div>
        </div>

        <ul v-if="isLoadingAllMediaFiles" class="highlights-select-modal__thumnails-list highlights-select-modal__thumnails-list--skeleton">
          <li class="highlights-select-modal__thumbnail highlights-select-modal__thumbnail--skeleton" v-for="i in 100"></li>
        </ul>

        <ul v-else class="highlights-select-modal__thumnails-list">
          <SrpFileThumbnail
            class="highlights-select-modal__thumbnail"
            v-for="file in allMediaFiles.map(i => ({ serverId: i }))"
            :key="file.serverId"
            :fileName="file.serverId"
            galleryPostfix="highlightsSelectModal"
            :isDeleteButton="false"
            @delete="() => {}"
          >
            <input type="checkbox" :value="file.serverId" :checked="selectedFiles.map(f => f.serverId).includes(file.serverId)" @change="$event => selectOrDeselectFile($event.target.checked, file)" />
          </SrpFileThumbnail>
        </ul>

        <SelectedFilesPanel
          v-if="selectedFiles.length"
          class="highlights-select-modal__selected-files-panel"
          :selectedFiles="selectedFiles"
          @remove="serverId => selectOrDeselectFile(false, { serverId })"
          @reorder="newArray => (selectedFiles = newArray)"
          :isPreviewLinkVisible="false"
        />
      </div>
    </template>

    <template #footer>
      <div style="margin: 0 -12px; display: flex; flex-wrap: wrap; justify-content: flex-end">
        <SrpButton
          @click="emit('update:isVisible', false)"
          :style="`margin-right: ${screenSize === 'mobile' ? 8 : 12}px`"
          color="gray"
          fill="outlined"
          :size="screenSize === 'mobile' ? 'small' : 'normal'"
        >
          Cancel
        </SrpButton>

        <SrpButton @click="save" :size="screenSize === 'mobile' ? 'small' : 'normal'" :isDisabled="isSaving">
          {{ props.confirmButtonText }}
        </SrpButton>
      </div>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, Ref, watch, computed } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SelectedFilesPanel from "@views/CMS/StopsForm/SelectedFilesPanel.vue";
import axios from "axios";
import { ScreenSize } from "@contracts/screenSize";
import CmsImagesRepo, { type CmsImages } from "@repos/CmsImagesRepo";

// Injections
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const globalRemoteLogger = inject("globalRemoteLogger") as any;

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    title?: string;
    confirmButtonText?: string;
    collabInputId: string;
    communityId: string;
    creatorId: string;
    /**
     * Optional prop that when provided this component won't make any API calls to load data.
     * It will use the provided data to display the images.
     */
    highlightsData?: CmsImages;
  }>(),
  {
    isVisible: false,
    title: "Select Highlights for this Collab",
    confirmButtonText: "Done",
    collabInputId: "",
    communityId: "",
    creatorId: "",
    highlightsData: undefined,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: false): void;
  (e: "complete"): void;
}>();

// Load images ================================================================
const _allMediaFiles = ref<string[]>([]);
const allMediaFiles = computed(() => (props.highlightsData ? props.highlightsData.usedAssets : _allMediaFiles.value));
const isLoadingAllMediaFiles = ref(false);

watch(
  () => props.isVisible,
  () => {
    if (props.isVisible) loadTheData();
  }
);
watch(
  () => props.collabInputId,
  () => loadTheData()
);

onMounted(async () => {
  loadTheData();
});

async function loadTheData() {
  await loadAllImages();
  await loadExistingSelections();
}

async function loadAllImages() {
  if (props.highlightsData) {
    // skip loading data if highlights data was provided via prop
    return;
  }
  isLoadingAllMediaFiles.value = true;
  const data = await CmsImagesRepo.loadCollabCmsImages(props.collabInputId, props.communityId);
  isLoadingAllMediaFiles.value = false;

  _allMediaFiles.value = data.usedAssets;
}

async function loadExistingSelections() {
  let uri = `${import.meta.env.VITE_API_URL}/collabs/${props.communityId}/inputs/${props.collabInputId}/creator/${props.creatorId}/highlights`;
  const { data } = await axios.get(uri);

  selectedFiles.value = data.map((serverId: string) => ({ serverId }));
}

// Select file ================================================================
const selectedFiles = ref([]);

function selectOrDeselectFile(isSelect, file) {
  if (isSelect) {
    selectedFiles.value.push(file);
  } else {
    selectedFiles.value = selectedFiles.value.filter(({ serverId }) => serverId !== file.serverId);
  }
}

// Save =======================================================================
const isSaving = ref(false);

async function save(): Promise<void> {
  // Update July 2024: Let them save anytime, the validation takes place when submitting the collab.

  globalRemoteLogger.info(`Saving Collab Highlight photos: ${selectedFiles.value.length} for ${props.communityId}`, false, false, {
    communityId: props.communityId,
    collabInputId: props.collabInputId,
    creatorId: props.creatorId,
    selectedAssetCount: selectedFiles.value.length.toString(),
  });
  const saveHighlightsUri = `${import.meta.env.VITE_API_URL}/collabs/${props.communityId}/inputs/${props.collabInputId}/creator/${props.creatorId}/highlights`;

  const payload = JSON.stringify(selectedFiles.value.map(f => f.serverId));
  const config = { headers: { "Content-Type": "application/json" } };

  isSaving.value = true;
  await axios.put(saveHighlightsUri, payload, config);
  selectedFiles.value = [];
  isSaving.value = false;

  emit("complete");
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/loading-bg.scss";

// Highlights select modal ====================================================
.highlights-select-modal {
  color: #5b5b5b;
  font: 14px/19px sans-serif;

  &__header {
    margin-bottom: 33px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
  }

  &__thumnails-list {
    width: calc(100% + 15px);
    padding: 0;
    margin: 0 0 40px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &--skeleton {
      height: calc(100dvh - 300px);
      align-content: flex-start;
      position: relative;
      overflow: hidden;
      mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__thumbnail {
    width: 113px;
    margin: 0 11px 11px 0;

    &--skeleton {
      @include loadingBgAnimation;
      height: 85px;
    }
  }

  &__selected-files-panel {
    position: sticky;
    bottom: -20px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .highlights-select-modal {
    &__thumbnail {
      width: calc(100% / 3 - 12px);
    }

    &__selected-files-panel {
      width: calc(100% + 40px);
      margin-left: -20px;
      bottom: -37px;

      &::after {
        border-radius: 0;
      }
    }
  }
}
</style>
