<template>
  <div v-if="adventureList">
    <div v-if="adventureList.adventures.length > 0" class="ui container list-section">
      <label class="cms-section-title">Included Adventures</label>
      <p style="margin-top: 5px">Drag adventures to reorder</p>
      <DropList :items="adventureList.adventures" class="ui four doubling raised link cards adventure-cards" @reorder="onReorder($event, adventureList.adventures)">
        <template #item="{ item }">
          <Drag class="ui card drag" :key="`selected-${item.id}`">
            <ItineraryTile
              :styles="'margin: 0; box-shadow: none;'"
              :itinerary="item"
              :allowShortImage="false"
              :disableLink="true"
              :showRemoveButton="true"
              :source="null"
              @removeItinerary="removeAdventureFromList(item)"
            />
          </Drag>
        </template>
        <template #feedback></template>
      </DropList>
      <div class="ui container edit-lists-section">
        <div class="list-option">
          <button class="ui small primary button" @click="save()">Save Changes</button>
          <button class="ui small button" @click="cancelEdit()">Cancel</button>
          <h5 v-if="saveMessage">{{ saveMessage }}</h5>
        </div>
      </div>
    </div>

    <div class="ui form large-accordion">
      <div class="large-accordion-dropdown" @click="collapsed = !collapsed">
        <h2 style="display: inline">Pick {{ adventureList.adventures.length > 0 ? "additional" : "the" }} Adventures to include</h2>
        <span style="float: right; margin-top: 8px">
          <div v-if="collapsed" name="angle down" class="large angle down" />
          <div v-else name="angle up" class="large angle up" />
        </span>
      </div>

      <div v-if="!collapsed">
        <div class="ui container list-section" style="margin-top: 20px" v-if="allAdventures && allAdventures.length > 0">
          <div class="ui four doubling raised link cards adventure-cards">
            <ItineraryTile
              v-for="itinerary in allAdventures"
              :itinerary="itinerary"
              :allowShortImage="false"
              :key="itinerary.id"
              :source="null"
              :openNewWindow="true"
              :invertClickForView="true"
              :customButtonText="'View Adventure'"
              @customButtonClick="addAdventureToList"
            />
          </div>
        </div>
        <div v-else>No adventures yet</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Drag, DropList } from "vue-easy-dnd";
import Loader from "@components/Loader/Loader.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import ListRepo from "@logic/ListRepo";
import AdminContext from "@logic/AdminContext";
import { AdventureList } from "@contracts/lists";
import { PageAdventureList } from "@contracts/pages";
import { ItinerarySummary } from "@contracts/itinerary";

/* Provides a quick and easy way to pick and order a set of adventures.

  NOTE! This is conceptually similar to ListManager.vue 
  I decided to name this differently since the term "list" could get overloaded.  
  Currently Lists are built from the buttons on the preview page, whereas this 
  component shows a collection of adventures that the user can quick select from.
*/

// configureCompat({
//   COMPONENT_V_MODEL: false,
//   RENDER_FUNCTION: false,
// });

export default defineComponent({
  name: "AdventureSelector",

  components: {
    Drag,
    DropList,
    ItineraryTile,
    Loader,
  },

  props: {
    customerId: { type: String },
    saveMessage: { type: String, default: null },
    adventureList: { type: Object as () => PageAdventureList | null, default: null },
    allAdventures: { type: Array as () => Array<ItinerarySummary> | null, default: null },
  },

  emits: ["cancel", "save"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      isUpdating: false,
      isEditMode: false,

      collapsed: false,
    };
  },

  async mounted() {
    this.defaultCollapsed();
  },

  methods: {
    defaultCollapsed() {
      // If they've already picked adventures hide the 'all adventures' section by default
      this.collapsed = this.adventureList?.adventures?.length > 0;
    },
    addAdventureToList(adventure: ItinerarySummary) {
      this.adventureList.adventures.push(adventure);
      var index = this.allAdventures.findIndex(a => a === adventure);
      this.allAdventures.splice(index, 1);
    },
    removeAdventureFromList(adventure: ItinerarySummary) {
      // Add this as the first item
      this.allAdventures.unshift(adventure);
      var index = this.adventureList.adventures.findIndex(a => a === adventure);
      this.adventureList.adventures.splice(index, 1);
    },
    onReorder(event, adventureList) {
      const element = adventureList[event.from];
      adventureList.splice(event.from, 1);
      adventureList.splice(event.to, 0, element);
    },

    async cancelEdit() {
      this.$emit("cancel");
    },
    async save() {
      this.globalLog.info(`Saving Homepage Lists! for ${this.customerId}`);
      // Parent component does the actual saving since what the list is being used for varies (and has different endpoints)
      this.$emit("save");
    },
  },
});
</script>

<style scoped lang="scss">
.list-section {
  position: relative;
  margin-bottom: 30px;
  .edit-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.edit-lists-section {
  padding-bottom: 2rem;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.ui.card.drag {
  position: relative;
}
</style>
