<template>
  <div>
    <div style="padding-top: 10px" class="ui action fluid input" :style="locationTextboxVisibility">
      <button v-if="showSearchButton" class="ui button" @click="locationParametersChanged" style="padding-right: 5px; padding-left: 15px">
        <i class="search icon"></i>
      </button>
      <input type="text" id="locationBox" v-model="globalRoot.locationPreference.placeName" @change="locationTextChange" placeholder="Enter a city, address, or zip code" />
      <button class="ui button" @click="useCurrentLocation" style="padding-right: 5px; padding-left: 15px"><i class="location arrow icon"></i></button>
    </div>
    <div v-if="showProximitySection" class="apply-filter" style="padding: 5px">
      <span @click="isMinimized = false" :style="`float: left; ${locationSummaryVisibility}`">
        <span v-if="!globalRoot.locationPreference.placeName">
          Set your Location
          <i class="angle down icon"></i>
        </span>
        <span v-else>
          Adventures within {{ globalRoot.locationPreference.selectedProximity }} miles of {{ globalRoot.locationPreference.placeName }}
          <i class="angle down icon"></i>
        </span>
      </span>
      <span @click="isMinimized = true" :style="locationTextboxVisibility">
        within
        <select v-model="globalRoot.locationPreference.selectedProximity" @click.stop="" @change="proximityChanged">
          <option v-for="option in proximityOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
        miles
        <i class="angle up icon"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { MetricSender } from "@helpers/MetricSender";

export default {
  name: "MyLocation",

  props: {
    showProximitySection: { type: Boolean, default: true },
    showSearchButton: { type: Boolean, default: false },
    sourceContext: { type: String, default: "unknown" },
  },

  emits: ["locationParametersChanged"],

  data() {
    return {
      proximityOptions: [5, 25, 60, 200, 500],
      isMinimized: false,
      // NOTE! Most values are in main.js at locationPreference so state is shared across instances
    };
  },

  computed: {
    locationTextboxVisibility() {
      return this.isMinimized ? "display: none" : "";
    },
    locationSummaryVisibility() {
      return this.isMinimized ? "" : "display: none";
    },
  },

  async mounted() {
    // Only allow minimizing if the proximity section is shown
    if (this.showProximitySection) {
      this.isMinimized = true;
    }

    this.initializeAutocomplete();
  },

  methods: {
    locationParametersChanged: async function () {
      this.$emit("locationParametersChanged");
    },
    isFirstLocationSet: function () {
      let isFirstSet = true;
      var localStorageValue = localStorage.getItem("location");
      if (localStorageValue) isFirstSet = false;

      return isFirstSet;
    },
    locationChangedSendAnalytics: function (locationName, isFirstLocationSetOverride) {
      // NOTE! This should be called before storeLocation since we check if they've set a location before
      let isFirstSet = isFirstLocationSetOverride;
      if (typeof isFirstLocationSetOverride === "undefined") isFirstSet = this.isFirstLocationSet();
      MetricSender.locationChanged(locationName, isFirstSet, this.sourceContext);
    },
    proximityChanged: function () {
      this.storeLocation(this.globalRoot.locationPreference.selectedPlaceLat, this.globalRoot.locationPreference.selectedPlaceLon, this.globalRoot.locationPreference.placeName);
      // no analytics for proximity change
      this.locationParametersChanged();
    },
    initializeAutocomplete: function () {
      var self = this;
      var input = document.getElementById("locationBox");
      var options = {
        // bounds: defaultBounds,
        types: ["geocode"], // https://developers.google.com/places/supported_types#table3
      };
      var autocomplete = new globalThis.google.maps.places.Autocomplete(input, options);
      autocomplete.setFields(["name", "place_id", "formatted_address", "geometry"]);
      let autocompleteListener = globalThis.google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();

        // Note: Sending analytics first so we can check isFirstSet
        self.locationChangedSendAnalytics(place ? place.name : "");
        if (place.geometry) {
          self.storeLocation(place.geometry.location.lat(), place.geometry.location.lng(), place.name);
        } else {
          self.storeLocation(null, null, null);
        }
        self.locationParametersChanged();
      });
    },
    storeLocation: function (lat, lon, placeName) {
      // Store in the root so the widget on the homepage reflects updates
      this.globalRoot.locationPreference.selectedPlaceLat = lat;
      this.globalRoot.locationPreference.selectedPlaceLon = lon;
      this.globalRoot.locationPreference.placeName = placeName;
      // Note: selectedProximity is bound directly to the root object so not need to set it here
      // Save to localstorage
      localStorage.setItem("location", JSON.stringify(this.globalRoot.locationPreference));
    },
    locationTextChange: function () {
      if (!this.globalRoot.locationPreference.placeName) {
        this.locationChangedSendAnalytics("");
        this.storeLocation(null, null, null);
        this.locationParametersChanged();
      }
    },
    useCurrentLocation: async function () {
      var self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // Note: Reverse location lookup will set placeName
            let isFirstLocationSet = self.isFirstLocationSet();
            self.storeLocation(position.coords.latitude, position.coords.longitude, null);
            self.locationParametersChanged();
            self.reverseLocationLookup(position.coords.latitude, position.coords.longitude, isFirstLocationSet);
          },
          function (error) {
            // if (error.code == error.PERMISSION_DENIED)
            console.log("geolocation error: " + error.code + " " + error.message);
          }
        );
      }
    },
    reverseLocationLookup: function (inputLat, inputLon, isFirstLocationSet) {
      var self = this;
      // https://developers.google.com/maps/documentation/javascript/examples/geocoding-reverse
      // Cost is 1/2 cent per request
      var geocoder = new globalThis.google.maps.Geocoder();
      var latlng = { lat: inputLat, lng: inputLon };
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            // Try to find the first "locality" (usually a city)
            let firstLocality = results.find(self.isLocality);
            var locationName = "";
            if (firstLocality) {
              locationName = firstLocality.formatted_address;
            } else {
              // If not just try the first (the most precise)
              locationName = results[0].formatted_address;
            }
            // Clean up a bit if possible
            if (locationName.endsWith(", USA")) {
              locationName = locationName.replace(", USA", "");
            }
            self.storeLocation(inputLat, inputLon, locationName);
            // Need to pass around if this was the first location set because earlier code sets it in localStorage
            self.locationChangedSendAnalytics(locationName, isFirstLocationSet);
          } else {
            console.log("geocode no results");
          }
        } else {
          console.log("geocode Status != OK. Status=" + status);
        }
      });
    },
    isLocality: function (location) {
      return location && location.types && location.types.includes("locality");
    },
  },
};
</script>
