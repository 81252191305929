<template>
  <div class="collab-snippet-v2">
    <div class="collab-snippet-v2__image-wrap">
      <img v-if="props.collab.coverImage" class="collab-snippet-v2__image" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${props.collab.coverImage}`" alt="Collab photo" />
    </div>

    <div class="collab-snippet-v2__name">
      {{ props.collab.collabName }}
    </div>
    <div class="collab-snippet-v2__date">
      <IconEmbedded class="collab-snippet-v2__date-icon" name="calendar_2" />
      {{ DateUtils.formatDateRange(props.collab.completedDate, 0, true) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import DateUtils from "@logic/DateUtils";

// Types
import { CreatorCollabSummary } from "@contracts/collab";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

export interface Props {
  collab: CreatorCollabSummary;
}
const props = withDefaults(defineProps<Props>(), {
  collab: null,
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Collab snippet v2 ==========================================================
.collab-snippet-v2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__image-wrap {
    width: 200px;
    aspect-ratio: 2/1.1;
    margin-bottom: 12px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.05s ease-in-out;
  }
  &:hover &__image-wrap {
    transform: scale(1.06);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    flex-grow: 1;
    margin-bottom: 8px;
    font:
      500 17px/20px "Quicksand",
      sans-serif;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }
  &:hover &__name {
    text-decoration: none;
  }

  &__date {
    display: flex;
    align-items: center;
    color: #5b5b5b;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 14px;
  }

  &__date-icon {
    width: 15px;
    margin-right: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-snippet-v2 {
    &__image-wrap {
      width: 120px;
    }
  }
}
</style>
